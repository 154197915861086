import * as Sentry from '@sentry/react'

import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import { GalleryFilter, useClientGallery } from '../_main/contexts'
import { Grid, Stack } from '@mui/material'
import { useGalleryAdditionalVisuals, useGalleryAssignment, useGalleryConstants, useGalleryDeal, useGalleryOrder, useGalleryProduct, useGalleryVisualSelection, useGalleryVisualType, useGalleryVisuals, useGalleryVisualsDownloadArchive, useGalleryVisualsMeta } from '../../contexts'

import { APIRequestErrorType } from 'constants/API'
import { ClientGalleryBetaSelfEditingCard } from '../ClientGalleryPhoto/ClientGalleryBetaSelfEditingCard.module'
import { ClientGalleryPhotoSectionActionFilterPanels } from './ClientGalleryPhotoSectionActionFilterPanels'
import { ClientGalleryPhotoSectionFilteredFavourites } from './ClientGalleryPhotoSectionFilteredFavourites.module'
import { ClientGalleryPhotoSectionFilteredUnpurchased } from './ClientGalleryPhotoSectionFilteredUnpurchased'
import { ClientGalleryPhotoSectionNotPurchasedAny } from './ClientGalleryPhotoSectionNotPurchasedAny.module'
import { ClientGalleryPhotoStagingCard } from '../ClientGalleryPhoto/ClientGalleryPhotoStagingCard.module'
import { Color } from 'constants/assets'
import { DownloadDropdownItem } from 'components/common/Gallery/GalleryImage/DownloadDropdownIcon'
import { FeatureFlag } from 'utils/featureFlags'
import { GalleryImage } from 'components/common/Gallery/GalleryImage'
import { GallerySection } from 'components/common/Gallery/GallerySection'
import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { Path } from 'constants/router'
import ReactLoading from 'react-loading'
import { VisualClientDTO } from 'models/visuals'
import { VisualFileType } from 'constants/visual'
import { useFlag } from '@unleash/proxy-client-react'
import { useGetAssignmentVisualByTypeName } from 'dataQueries'
import { useImageRegex } from 'utils/hooks'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useTranslation } from 'react-i18next'

enum ListingType {
  PURCHASED = 'PURCHASED',
  UNPURCHASED = 'UNPURCHASED',
  FAVOURITES_PURCHASED = 'FAVOURITES_PURCHASED',
  FAVOURITES_UNPURCHASED = 'FAVOURITES_UNPURCHASED',
}

enum GridColumnSize {
  DEFAULT = 'DEFAULT',
  SMALL = 'SMALL',
}

/**
 * @interface Props
 */
interface Props {
  /** The list of favorite visuals */
  favorited: Set<string>
  /** setState action to open expanded visual popup */
  setIsExpandedVisualOpen: Dispatch<SetStateAction<boolean>>
  /** setState action to set expanded visual name */
  setExpandedVisualName: Dispatch<SetStateAction<string | undefined>>
  /** setState action to toggle favorite on image */
  toggleFavoriteOnImage: (filename?: string) => void
  /** Whether the favorite button has disabled clicking */
  isFavoriteDisabled: boolean,
  /** Metadata of an assignment visuals */
  visualsMetadata: Record<string, VisualClientDTO>
}

/**
 * Client gallery photo section.
 * 
 * @example <ClientGalleryPhotoSection />
 */
export const ClientGalleryPhotoSection: FC<Props> = ({
  favorited,
  setIsExpandedVisualOpen,
  setExpandedVisualName,
  toggleFavoriteOnImage,
  isFavoriteDisabled = false,
  visualsMetadata,
}) => {
  const isBetaSelfEditingCard = useFlag(FeatureFlag.BETA_SELF_EDIT_WIDGET)
  const allowPinturaEditing = useFlag(FeatureFlag.ALLOW_PINTURA_EDITING)

  const { t } = useTranslation(['gallery', 'visual_pintura_loading'])
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)
  const navigate = useNavigate()

  const { spawnErrorToast } = useSnackbar()
  const { initiateArchiveDownload } = useGalleryVisualsDownloadArchive()

  const [isBetaSelfEditingCardClosed, setIsBetaSelfEditingCardClosed] = useState(false)

  // DATA QUERIES
  const assignmentVisualByName = useGetAssignmentVisualByTypeName()

  const { stagingAssignments } = useGalleryDeal()
  const { sortEntriesFunction } = useGalleryOrder()

  const {
    purchasedVisualsKeys,
    purchasedVisualsExist,
    unPurchasedVisualsExist,
    unPurchasedVisualsKeys,
    prepurchasedVisualsRemaining,
  } = useGalleryVisualsMeta()

  const { isAdditionalVisualPurchaseDisabled } = useGalleryAdditionalVisuals()

  const {
    downloadVisualsEntries,
    listedVisuals,
  } = useGalleryVisuals()

  const {
    thumbnailType,
    webType,
  } = useGalleryVisualType()

  const {
    selected,
    selectedNotPurchasedVisualsKeys,
    toggleSelectOnImage,
  } = useGalleryVisualSelection()

  const {
    assignmentId,
    filter,
    isToolBarOpen,
    setIsToolBarOpen,
    enableStagingFlow
  } = useClientGallery()

  const {
    isVideo,
    isFloorPlan
  } = useGalleryProduct()

  const {
    product,
    assignmentData,
    logGalleryEvent,
  } = useGalleryAssignment()

  const { stagesUnlockingStagingFlow } = useGalleryConstants()

  const imageNameReplacePattern = useImageRegex(assignmentId)

  const onCloseBetaCard = () => {
    setIsBetaSelfEditingCardClosed(true)
    saveStateToLocalStorage(true)
    logAnalyticsEvent(AnalyticsEvent.PINTURA_CLOSE_UPSELL_ON_GALLERY, { assignmentId })
  }

  // Save state to local storage
  const saveStateToLocalStorage = useCallback((isBetaSelfEditingCardClosed: boolean) => {
    localStorage.setItem(
      'clientGalleryBetaSelfEditingCardClosed', JSON.stringify(isBetaSelfEditingCardClosed)
    )
  }, [])

  // Load state from local storage
  const loadStateFromLocalStorage = useCallback(() => {
    const state = localStorage.getItem('clientGalleryBetaSelfEditingCardClosed')
    if (state) {
      setIsBetaSelfEditingCardClosed(Boolean(state))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDownloadImageClick = useCallback((itemKey: DownloadDropdownItem, fileName: string) => {
    if (itemKey === DownloadDropdownItem.DIRECT_DOWNLOAD) {
      initiateArchiveDownload(new Set<string>([fileName]))
    }

    if (itemKey === DownloadDropdownItem.EXPORTING_OPTIONS) {
      // Set current image selection to open visuals editing page
      toggleSelectOnImage(fileName)
      setIsToolBarOpen(true)
      navigate(Path.GALLERY_VISUALS_EDIT.replace(':id', assignmentId.toString()))
    }
  }, [assignmentId, initiateArchiveDownload, navigate, setIsToolBarOpen, toggleSelectOnImage])

  // Load state on component mount
  useEffect(() => {
    loadStateFromLocalStorage()
  }, [loadStateFromLocalStorage])

  const onOpenExpandedVisualPopup = useCallback((name?: string) => {
    if (!name) return
    setIsExpandedVisualOpen(true)
    setExpandedVisualName(name)
    logGalleryEvent(AnalyticsEvent.GALLERY_PHOTO_FULL_PREVIEW, {
      productType: product?.type
    })
  }, [logGalleryEvent, product?.type, setExpandedVisualName, setIsExpandedVisualOpen])

  const onFloorPlannerLinkClick = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation()

    logAnalyticsEvent(AnalyticsEvent.FLOOR_PLANNER_LINK_CLICKED, {
      galleryType: 'CLIENT',
      assignmentId: assignmentData?.id,
      productCategory: product?.category,
      productKind: product?.kind,
    })
  }, [assignmentData?.id, product?.category, product?.kind])

  const fetchImageUrl = useCallback(async (visualName: string): Promise<string> => {
    try {
      const response = await assignmentVisualByName.mutateAsync({
        assignmentId,
        visualType: VisualFileType.POST,
        visualName,
      })

      if (response.data.url) {
        return response.data.url
      }
    } catch (error) {
      console.error('Error during image loading:', error)

      const sentryMessage = 'Loading POST image for Pintura editing ERROR'
      Sentry.captureException(sentryMessage, scope => {
        scope.clearBreadcrumbs()
        scope.setFingerprint([sentryMessage])
        scope.setExtra('visualName', visualName)
        scope.setExtra('assignmentId', assignmentId)
        scope.setExtra('visualType', VisualFileType.POST)
        return scope
      })

      spawnErrorToast(t('visual_pintura_loading:general_error', { error }))
    }
    return ''

  }, [assignmentVisualByName, assignmentId, spawnErrorToast, t])

  const onEditWithPinturaClick = useCallback(async (file: File, isFileVersioned: boolean) => {
    const imageUrl = await fetchImageUrl(file.name)

    const navigateToPinturaEditing = (file: File, imageUrl: string) => {
      navigate(Path.GALLERY_VISUAL_PINTURA_EDITING.replace(':id', assignmentId), {
        state: { file, imageUrl, assignmentId, isFileVersioned }
      })

      logAnalyticsEvent(AnalyticsEvent.PINTURA_OPEN, {
        galleryType: 'CLIENT',
        assignmentId,
        sourceVisualFilename: file.name,
        imageUrl,
      })
    }

    navigateToPinturaEditing(file, imageUrl)
  }, [fetchImageUrl, navigate, assignmentId])

  const preloadListing = useCallback(() => {
    const tupleArray: [string, string, JSX.Element][] = listedVisuals.map(visual => {
      const reactKey = visual.name
      return [visual.name, reactKey, (
        <GalleryImage key={reactKey}>
          <div className="text">
            <ReactLoading color={Color.GRAY_TEXT} type="cylon" className="spinner" />
          </div>
        </GalleryImage>
      )]
    })

    return tupleArray
  }, [listedVisuals])

  const downloadListing = useCallback(() => {
    const tupleArray: [string, string, JSX.Element][] = downloadVisualsEntries.map(([key, allTypes], index) => {
      const img = allTypes?.[thumbnailType]
      if (!img) throw new Error(`Image does not exist for key: ${key} and visual type: ${thumbnailType}`)
      const isError = img.request.error_type !== APIRequestErrorType.NONE
      const filename = img.file?.name ?? ''
      const label = filename.replace(imageNameReplacePattern, '')
      const reactKey = key
      const visualMetadata = visualsMetadata[img.file?.name ?? '']
      return [key, reactKey, (
        <GalleryImage
          key={reactKey}
          label={label}
          imageUrl={img.signedUrl}
          errorMessage={t('error_not_found_and_retry')}
          isError={isError}
          isSelectable={!isError}
          isSelected={selected.has(img.file?.name ?? '')}
          isHoverSelectable={!!purchasedVisualsExist && !isToolBarOpen && !selected.size}
          isFavoriteSelectable={!isError}
          isSelectionDisabled={isAdditionalVisualPurchaseDisabled && unPurchasedVisualsKeys.has(img.file?.name ?? '') && !selectedNotPurchasedVisualsKeys.has(img.file?.name ?? '') && selectedNotPurchasedVisualsKeys.size >= prepurchasedVisualsRemaining}
          isFavorite={favorited.has(img.file?.name ?? '')}
          isFavoriteHoverActive={(!isMobileView || !isToolBarOpen)}
          isFavoriteDisabled={isFavoriteDisabled}
          visualMetadata={visualMetadata ?? null}
          isEditable={!visualMetadata?.floorPlanSelfEditUrl && visualMetadata?.name && !isError ? purchasedVisualsKeys.has(visualMetadata.name) : false}
          isDownloadable={!isError && purchasedVisualsKeys.has(visualMetadata?.name)}
          onFloorPlannerLinkClick={onFloorPlannerLinkClick}
          onEditWithPinturaClick={() => onEditWithPinturaClick(img.file ? img.file : new File([], filename), visualsMetadata[img.file?.name ?? ''].selfEdited)}
          onSelect={() => {
            toggleSelectOnImage(img.file?.name)
            setIsToolBarOpen(true)
          }}
          onFavSelect={() => {
            toggleFavoriteOnImage(img.file?.name)
          }}
          onClick={() => {
            if (isMobileView && isToolBarOpen) return toggleSelectOnImage(img.file?.name)
            if (!(webType && allTypes?.[webType]?.signedUrl)) return
            onOpenExpandedVisualPopup(img.file?.name)
          }}
          onDownloadOptionClick={(itemKey) => handleDownloadImageClick(itemKey, img.file?.name ?? '')}
        />
      )]
    })

    return tupleArray
  }, [downloadVisualsEntries, favorited, handleDownloadImageClick, imageNameReplacePattern, isAdditionalVisualPurchaseDisabled, isFavoriteDisabled, isMobileView, isToolBarOpen, onEditWithPinturaClick, onFloorPlannerLinkClick, onOpenExpandedVisualPopup, prepurchasedVisualsRemaining, purchasedVisualsExist, purchasedVisualsKeys, selected, selectedNotPurchasedVisualsKeys, setIsToolBarOpen, t, thumbnailType, toggleFavoriteOnImage, toggleSelectOnImage, unPurchasedVisualsKeys, visualsMetadata, webType])

  const createVisualListing = (filteredEntries: [string, [string, JSX.Element]][], type: ListingType) => {
    const entriesCount = filteredEntries.length
    const isTwoColumnViewProduct = isVideo || (isFloorPlan && entriesCount > 1)
    const isOneColumnViewProduct = isFloorPlan && entriesCount === 1

    const getGridColumns = (size: GridColumnSize) => {
      if (isOneColumnViewProduct) return 12
      if (isTwoColumnViewProduct) return 6

      return size === GridColumnSize.DEFAULT ? 3 : 4
    }

    const addStagingTodoInfoCardIfNeeded = () => {
      const assignmentsToSet = stagingAssignments.filter((assignment) => stagesUnlockingStagingFlow.has(assignment.stage))
      if (!enableStagingFlow || type !== ListingType.PURCHASED || !assignmentsToSet.length) return

      if (allowPinturaEditing && isBetaSelfEditingCard && !isBetaSelfEditingCardClosed) {
        if (filteredEntries.length + 1 <= 3) {
          filteredEntries.push(['stagingTodoInfoCard', ['stagingTodoInfoCard', <ClientGalleryPhotoStagingCard key='staging-card' />]])
        } else {
          filteredEntries.splice(6, 0, ['stagingTodoInfoCard', ['stagingTodoInfoCard', <ClientGalleryPhotoStagingCard key='staging-card' />]])
        }
      } else {
        if (filteredEntries.length + 1 <= 3) {
          filteredEntries.push(['stagingTodoInfoCard', ['stagingTodoInfoCard', <ClientGalleryPhotoStagingCard key='staging-card' />]])
        } else {
          filteredEntries.splice(3, 0, ['stagingTodoInfoCard', ['stagingTodoInfoCard', <ClientGalleryPhotoStagingCard key='staging-card' />]])
        }
      }
    }

    addStagingTodoInfoCardIfNeeded()

    const addBetaSelfEditingCard = () => {
      // Show beta self editing card only if the user has an active subscription,
      // the feature flag is enabled, the card is not closed and there is at least one visual that can be self-edited (for Floor plans orders it means there is unless one floor plan without floorplanner link)
      if (!allowPinturaEditing || !isBetaSelfEditingCard || isBetaSelfEditingCardClosed || type !== ListingType.PURCHASED || !Object.values(visualsMetadata).some(visualMetadata => !visualMetadata?.floorPlanSelfEditUrl)) return

      if (filteredEntries.length + 1 <= 3) {
        filteredEntries.push(['betaSelfEditingCard', ['betaSelfEditingCard', <ClientGalleryBetaSelfEditingCard key='betaSelfEditingCard' onClose={onCloseBetaCard} />]])
      } else {
        filteredEntries.splice(3, 0, ['betaSelfEditingCard', ['betaSelfEditingCard', <ClientGalleryBetaSelfEditingCard key='betaSelfEditingCard' onClose={onCloseBetaCard} />]])
      }
    }

    addBetaSelfEditingCard()

    return (
      <Grid container spacing={2}>
        {filteredEntries.map(([key, [reactKey, node]]) => (
          <Grid key={reactKey} item xs={isOneColumnViewProduct ? 12 : 6} sm={getGridColumns(GridColumnSize.SMALL)} md={getGridColumns(GridColumnSize.DEFAULT)} lg={getGridColumns(GridColumnSize.DEFAULT)}>
            {node}
          </Grid>
        ))}
      </Grid>
    )
  }

  const visualsListing = (type: ListingType) => {
    const map: Map<string, [string, JSX.Element]> = new Map()
    let sortedEntries: [string, [string, JSX.Element]][]

    for (let [key, reactKey, node] of preloadListing()) map.set(key, [reactKey, node])
    for (let [key, reactKey, node] of downloadListing()) map.set(key, [reactKey, node])

    const entries: [string, [string, JSX.Element]][] = Array.from(map.entries())

    switch (type) {
      case ListingType.FAVOURITES_PURCHASED:
        sortedEntries = entries.filter(([visualName, visualProps]) => purchasedVisualsKeys.has(visualName) && visualProps[1].props.isFavorite).sort(sortEntriesFunction)
        break

      case ListingType.FAVOURITES_UNPURCHASED:
        sortedEntries = entries.filter(([visualName, visualProps]) => !purchasedVisualsKeys.has(visualName) && visualProps[1].props.isFavorite).sort(sortEntriesFunction)
        break

      case ListingType.PURCHASED:
        sortedEntries = entries.filter(([visualName]) => purchasedVisualsKeys.has(visualName)).sort(sortEntriesFunction)
        break

      case ListingType.UNPURCHASED:
        sortedEntries = entries.filter(([visualName]) => !purchasedVisualsKeys.has(visualName)).sort(sortEntriesFunction)
        break

      default:
        sortedEntries = []
        break
    }

    return createVisualListing(sortedEntries, type)
  }

  const purchasedVisuals = visualsListing(ListingType.PURCHASED)
  const unPurchasedVisuals = visualsListing(ListingType.UNPURCHASED)
  const favouritesPurchasedVisuals = visualsListing(ListingType.FAVOURITES_PURCHASED)
  const favouritesUnPurchasedVisuals = visualsListing(ListingType.FAVOURITES_UNPURCHASED)

  const showUnpurchasedSection = purchasedVisualsExist && unPurchasedVisualsExist && filter === GalleryFilter.ALL
  const showPurchasedSection = purchasedVisualsExist && filter !== GalleryFilter.FAVORITES
  const showFavouritesSection = filter === GalleryFilter.FAVORITES

  return (
    <Stack marginTop={{ xs: '2.4rem', md: '3.2rem' }}>

      {/** FLOW A - NOT PURCHASED ANY VISUALS YET - SHOWS INFO MESSAGE ABOUT REQUIRED SELECTION & FILTERS ALL + FAVOURITES */}
      {!purchasedVisualsExist &&
        <ClientGalleryPhotoSectionNotPurchasedAny unPurchasedVisuals={unPurchasedVisuals} />
      }

      {/** FLOW B - FILTER ALL - PURCHASED VISUALS - SHOWN THE FILTERS & ACTIONS PANEL WHEN VISUALS WERE SELECTED ALREADY */}
      {purchasedVisualsExist &&
        <ClientGalleryPhotoSectionActionFilterPanels />
      }

      {/** FLOW B - FILTERES PURCHASED ONLY */}
      {showPurchasedSection &&
        <GallerySection>{purchasedVisuals}</GallerySection>
      }

      {/** FLOW B - FILTERES ALL - ADDS UNPURCHASED VISUALS SECTION */}
      {showUnpurchasedSection &&
        <ClientGalleryPhotoSectionFilteredUnpurchased unPurchasedVisuals={unPurchasedVisuals} />
      }

      {/** FLOW B - FILTERES FAVOURITES ONLY */}
      {showFavouritesSection &&
        <ClientGalleryPhotoSectionFilteredFavourites favouritesPurchasedVisuals={favouritesPurchasedVisuals} favouritesUnPurchasedVisuals={favouritesUnPurchasedVisuals} />
      }

    </Stack>
  )
}
