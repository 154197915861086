import { BLUE_100, BLUE_600, GRAY_400, GRAY_900, WHITE } from 'constants/styling/theme'
import { FormControlLabel, FormControlLabelProps, Radio, RadioProps } from '@mui/material'
import React, { ReactNode } from 'react'

import { Label } from '../Label'
import { styled } from '@mui/material/styles'

/**
 * @interface Props Input properties
 */
export interface Props extends RadioProps {
  label?: string | ReactNode
  labelPlacement?: FormControlLabelProps['labelPlacement']
}

const StyledRadio = styled('span')(() => ({
  width: '1.6rem',
  height: '1.6rem',
  borderRadius: '50%',
  border: `0.1rem solid ${GRAY_400}`,
  backgroundColor: WHITE,
  transition: '.3s ease',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  'input:hover ~ &': {
    borderColor: BLUE_600
  }
}))

const StyledCheckedRadio = styled(StyledRadio)({
  backgroundColor: BLUE_100,
  borderColor: BLUE_600,
  '&:before': {
    display: 'block',
    width: '1.6rem',
    height: '1.6rem',
    backgroundImage: `radial-gradient(${BLUE_600},${BLUE_600} 28%,transparent 32%)`,
    content: '""',
  },
})

/**
 * @component MUI Radio 
 * @example
 * <Radio checked={true} onChange={() => {}}/>
 */
export const MUIRadio: React.FC<Props> = ({
  checked,
  disabled,
  label,
  labelPlacement,
  onChange,
  ...defaultProps
}) => {

  return (
    <FormControlLabel
      sx={{
        margin: 0,
        gap: '1rem',
        '.MuiFormControlLabel-label': {
          color: GRAY_900,
          fontSize: '1.2rem',
          lineHeight: 1,
        }
      }}
      control={(
        <Radio
          sx={{
            '&:hover': { bgcolor: 'transparent' },
            padding: 0
          }}
          checked={checked}
          disabled={disabled}
          disableRipple
          checkedIcon={<StyledCheckedRadio />}
          icon={<StyledRadio />}
          onChange={onChange}
          {...defaultProps}
        />
      )}
      label={typeof label === 'string' ? <Label text={label} /> : label}
      labelPlacement={labelPlacement}
    />
  )
}
