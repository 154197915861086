import { GalleryAssignmentContextProvider, GalleryDealContextProvider, GalleryProductContextProvider, GalleryVisualTypeContextProvider } from 'components/pages/Gallery/_main/contexts'

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import { ClientGalleryContextProvider } from 'components/pages/Gallery/_main/ClientGallery/_main'
import { ClientPropertyDocumentsGallery } from '../../ClientPropertyDocumentsGallery'
import { ClientPropertyEmptyContent } from '../../ClientPropertyEmptyContent'
import { FC } from 'react'
import { GRAY_900 } from 'constants/styling/theme'
import { Path } from 'constants/router'
import { useClientProperty } from '../contexts'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const ClientPropertyDocumentsPage: FC = () => {
  const { t } = useTranslation(['gallery'])
  const navigate = useNavigate()

  const { assignmentId, propertyId } = useClientProperty()

  if (!assignmentId) return (
    <ClientPropertyEmptyContent
      title={t('client_gallery.no_assignment_document')}
      buttonText={t('client_gallery.no_assignment_document_action')}
      onButtonClick={() => navigate({ pathname: Path.PURCHASE_FLOW, search: `?realtyId=${propertyId}` })}
      icon={<ArticleOutlinedIcon fontSize='large' sx={{ color: GRAY_900 }} />}
    />
  )

  return (
    <GalleryAssignmentContextProvider assignmentId={assignmentId}>
      <GalleryDealContextProvider assignmentId={assignmentId} keepPreviousData={true}>
        <GalleryVisualTypeContextProvider assignmentId={assignmentId}>
          <GalleryProductContextProvider assignmentId={assignmentId}>
            <ClientGalleryContextProvider assignmentId={assignmentId}>

              <ClientPropertyDocumentsGallery />

            </ClientGalleryContextProvider>
          </GalleryProductContextProvider>
        </GalleryVisualTypeContextProvider>
      </GalleryDealContextProvider>
    </GalleryAssignmentContextProvider>
  )
}
