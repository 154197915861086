import { GlobalShapeType, Wall } from 'constants/purchaseFlow'
import { useEffect, useState } from 'react'
import { usePurchaseFlowConfig, usePurchaseFlowProducts } from 'components/pages/PurchaseFlow/_main/contexts'

import { DocumentUploadType } from 'constants/documents'
import { OrientationType } from 'components/pages/PurchaseFlow/common/BuildingOrientation'
import { PurchaseFlowEndpoint } from 'constants/API'
import constate from 'constate'
import { useFileAPI } from 'components/common/FileAPI'

export type WallType = Partial<Record<Wall, { mounted: boolean; insulated: boolean; length: string; insulation: string }>>

export const [BuildingLayoutContextProvider, useBuildingLayout] = constate(() => {
  const { sessionId } = usePurchaseFlowConfig()
  const { selectedProducts } = usePurchaseFlowProducts()

  const [globalShapeType, setGlobalShapeType] = useState<GlobalShapeType>(GlobalShapeType.RECTANGLE)
  const [orientationType, setOrientationType] = useState<OrientationType>(OrientationType.NORTH)

  const initialWalls: WallType = Object.values(Wall).reduce((acc, wall) => {
    acc[wall] = { mounted: false, insulated: false, length: '', insulation: '' }
    return acc
  }, {} as WallType)

  const [walls, setWalls] = useState<WallType>(initialWalls)

  useEffect(() => {
    setWalls(prevWalls => {
      const updatedWalls = { ...initialWalls }

      let wallsToDelete: Wall[] = []

      if (globalShapeType === GlobalShapeType.RECTANGLE) {
        wallsToDelete = [Wall.E, Wall.F, Wall.G, Wall.H]
      } else if (globalShapeType === GlobalShapeType.L_SHAPE) {
        wallsToDelete = [Wall.G, Wall.H]
      } else if (globalShapeType === GlobalShapeType.OTHER) {
        wallsToDelete = Object.values(Wall)
      }

      wallsToDelete.forEach(wall => delete updatedWalls[wall])

      const wallsChanged = Object.keys(updatedWalls).length !== Object.keys(prevWalls).length

      return wallsChanged ? updatedWalls : prevWalls
    })
    // remove initialWalls from dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalShapeType])

  const buildingShapeUpload = useFileAPI('ENERGY_CERTIFICATE_BUILDING_SHAPE', {
    uploadUrlResolver: sessionId && selectedProducts ? (fileEntry, api) => (api.post(
      PurchaseFlowEndpoint.GET_DOCUMENT_UPLOAD_URL,
      { sessionId: sessionId!.toString() },
      {
        productId: Object.values(selectedProducts)[0].id.toString(),
        documentMetadata: {
          filename: fileEntry.originalFilename,
          contentType: fileEntry.fileObject.type,
          type: DocumentUploadType.INPUT,
          tag: 'GROUND_PLAN',
        }
      },
      true
    ))
      : undefined,
    deleteHandler: sessionId && selectedProducts ? (_, api) => api.delete(
      PurchaseFlowEndpoint.DELETE_UPLOADED_DOCUMENT,
      { sessionId: sessionId!.toString() },
      true
    ) : undefined
  })

  return {
    globalShapeType,
    setGlobalShapeType,
    orientationType,
    setOrientationType,
    walls,
    setWalls,
    buildingShapeUpload,
  }
})
