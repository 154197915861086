import { TemplatesDTO, WatermarksDTO } from 'models/visualsEditing'

/** Workspace user profile DTO */
export interface WorkspaceUserProfile {
  id: string
  name: string
  plan: SubscriptionPlan
  status: SubscriptionStatus
  role: WorkspaceRole
  isRestricted: boolean
  isMember: boolean
  invitationId: string
}

/** List of all workspaces the user is a member of */
export interface UserWorkspaceMembershipList {
  workspaces: UserWorkspaceMembership[]
}

export interface PendingInvitation {
  email: string
  id: string
  invitedBy: string
}

/** Describes DTO of workspace the user is a member of */
export interface UserWorkspaceMembership {
  workspaceId: string,
  creationDate: string,
  name: string,
  plan: SubscriptionPlan,
  tier: TierInfo,
  workspaceStatus: SubscriptionStatus
  members: WorkspaceMember[] | null
  watermarks: WatermarksDTO[]
  visualEditingTemplates: TemplatesDTO[]
  pendingInvitations: PendingInvitation[]
}

/** Describes DTO of a workspace member */
export interface WorkspaceMember {
  membershipId: string,
  userId: string,
  name: string,
  email: string,
  numberOfOrders: number,
  role: WorkspaceRole,
  isRestricted: boolean
}

/** Subscription status */
export interface SubscriptionStatusDTO {
  creationDate: string,
  id: string,
  numOfUsers: number,
  paymentInfoDTO: PaymentInfoDTO,
  subscriptionPlan: SubscriptionPlan,
  tierInfo: TierInfo,
  workspaceStatus: SubscriptionStatus
}

/** Subscription payment info */
export interface PaymentInfoDTO {
  isRunningTrial: boolean,
  subscriptionPeriod: SubscriptionPeriod,
  trialEnd: string
}

/** Subscription tier details (range of members) */
export interface TierInfo {
  maxUsers: number,
  minUsers: number
}

/** Subscription plans */
export enum SubscriptionPlan {
  PERSONAL = 'PERSONAL', // Free
  PRO = 'PRO',
  PRO_PER_SEAT = 'PRO_PER_SEAT',
  ENTERPRISE = 'ENTERPRISE',
  ENTERPRISE_PER_SEAT = 'ENTERPRISE_PER_SEAT',
  ENTERPRISE_FIXED_RATE = 'ENTERPRISE_FIXED_RATE',
}

/** Subscription plans */
export enum SubscriptionPlanType {
  PERSONAL = 'PERSONAL',
  PRO = 'PRO',
  ENTERPRISE = 'ENTERPRISE',
}

/** Possible periods for subscribing */
export enum SubscriptionPeriod {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

/** User roles in workspace */
export enum WorkspaceRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
}

/** Subscription status */
export enum SubscriptionStatus {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  IN_GRACE_PERIOD = 'IN_GRACE_PERIOD',
  ON_HOLD = 'ON_HOLD',
  PAUSED = 'PAUSED',
  EXPIRED = 'EXPIRED',
}

/** A set of all subscription statuses considered as active */
export const ActiveSubscriptionStatuses = new Set([
  SubscriptionStatus.NEW,
  SubscriptionStatus.ACTIVE,
  SubscriptionStatus.IN_GRACE_PERIOD,
])
