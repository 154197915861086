import React, { Fragment } from 'react'

import { EditorOptionApplyTemplate } from '../EditorOptions/EditorOptionApplyTemplate/EditorOptionApplyTemplate.module'
import { EditorOptionRatio } from '../EditorOptions/EditorOptionRatio'
import { EditorOptionWatermark } from '../EditorOptions/EditorOptionWatermark'

export const VisualEditingSettings: React.FC = () => {
  return (
    <Fragment>

      <EditorOptionApplyTemplate />

      <EditorOptionWatermark />

      <EditorOptionRatio />

    </Fragment>
  )
}
