import { AvailableStagingsListContextProvider, GalleryStagingFlowContextProvider, GalleryStagingStackProvider } from 'components/pages/Gallery/common'
import { GalleryAdditionalVisualsContextProvider, GalleryAssignmentContextProvider, GalleryDealContextProvider, GalleryOrderContextProvider, GalleryProductContextProvider, GalleryVirtualVisitContextProvider, GalleryVisualSelectionContextProvider, GalleryVisualTypeContextProvider, GalleryVisualsContextProvider, GalleryVisualsDownloadArchiveContextProvider, GalleryVisualsEditingStackProvider, GalleryVisualsMetaContextProvider } from 'components/pages/Gallery/_main/contexts'

import { ClientGalleryContextProvider } from 'components/pages/Gallery/_main/ClientGallery/_main'
import { ClientPropertyEmptyContent } from '../../ClientPropertyEmptyContent'
import { ClientPropertyVisualsGallery } from '../../ClientPropertyVisualsGallery'
import { FC } from 'react'
import FilterRoundedIcon from '@mui/icons-material/FilterRounded'
import { GRAY_900 } from 'constants/styling/theme'
import { GalleryStagingContextProvider } from 'components/pages/Gallery/pages/GalleryStaging'
import { Path } from 'constants/router'
import { ThreadContextProvider } from 'components/common/Threads/context'
import { useClientProperty } from '../contexts'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const ClientPropertyVisualsGalleryPage: FC = () => {
  const { t } = useTranslation(['gallery'])
  const navigate = useNavigate()

  const { assignmentId, propertyId } = useClientProperty()

  if (!assignmentId) return (
    <ClientPropertyEmptyContent
      title={t('client_gallery.no_assignment_visual')}
      buttonText={t('client_gallery.no_assignment_visual_action')}
      onButtonClick={() => navigate({ pathname: Path.PURCHASE_FLOW, search: `?realtyId=${propertyId}` })}
      icon={<FilterRoundedIcon fontSize='large' sx={{ color: GRAY_900 }} />}
    />
  )

  return (
    <GalleryAssignmentContextProvider assignmentId={assignmentId}>
      <GalleryDealContextProvider assignmentId={assignmentId} keepPreviousData={true}>
        <GalleryVisualTypeContextProvider assignmentId={assignmentId}>
          <GalleryProductContextProvider assignmentId={assignmentId}>
            <GalleryOrderContextProvider assignmentId={assignmentId}>
              <GalleryVisualsMetaContextProvider assignmentId={assignmentId}>
                <GalleryVisualsContextProvider assignmentId={assignmentId}>
                  <GalleryVirtualVisitContextProvider assignmentId={assignmentId}>
                    <GalleryVisualSelectionContextProvider assignmentId={assignmentId}>
                      <GalleryAdditionalVisualsContextProvider assignmentId={assignmentId}>
                        <GalleryVisualsDownloadArchiveContextProvider assignmentId={assignmentId}>
                          <GalleryVisualsEditingStackProvider assignmentId={assignmentId}>
                            <GalleryStagingStackProvider>
                              <GalleryStagingFlowContextProvider>
                                <GalleryStagingContextProvider>
                                  <AvailableStagingsListContextProvider>
                                    <ThreadContextProvider>
                                      <ClientGalleryContextProvider assignmentId={assignmentId}>

                                        <ClientPropertyVisualsGallery />

                                      </ClientGalleryContextProvider>
                                    </ThreadContextProvider>
                                  </AvailableStagingsListContextProvider>
                                </GalleryStagingContextProvider>
                              </GalleryStagingFlowContextProvider>
                            </GalleryStagingStackProvider>
                          </GalleryVisualsEditingStackProvider>
                        </GalleryVisualsDownloadArchiveContextProvider>
                      </GalleryAdditionalVisualsContextProvider>
                    </GalleryVisualSelectionContextProvider>
                  </GalleryVirtualVisitContextProvider>
                </GalleryVisualsContextProvider>
              </GalleryVisualsMetaContextProvider>
            </GalleryOrderContextProvider>
          </GalleryProductContextProvider>
        </GalleryVisualTypeContextProvider>
      </GalleryDealContextProvider>
    </GalleryAssignmentContextProvider>
  )
}
