import { bigFromDecimal, bigFromFee, percentageValue, valueAfterDiscount } from 'utils/price'

import Big from 'big.js'
import { Currency } from 'constants/misc'
import { DealDTO } from 'models/deal'
import { useMemo } from 'react'

const defaultsPriceData = {
  discountedTotal: new Big(0),
  totalWithoutDiscount: new Big(0),
  vat: new Big(0),
  percentageDiscount: new Big(0),
  currency: Currency.CHF,
}

/** React hook which provides the Order price details for multiple Properties  
 * @returns An object where each key is an orderId and the value contains price details including discountedTotal, totalWithoutDiscount, vat, percentageDiscount, and currency. Computed totalVat and totalAfterVat are also included.
 */
export function useOrderPriceBreakdownProperty(data?: DealDTO[]) {
  const priceDataByOrderId = useMemo(() => {
    if (!data) return {}

    return data.reduce((acc, deal) => {
      const orderId = deal.id
      if (!orderId) return acc

      const priceData = {
        discountedTotal: bigFromFee(deal.discountedFeePrice),
        totalWithoutDiscount: bigFromFee(deal.feePrice),
        vat: bigFromDecimal(deal.financials.vat),
        percentageDiscount: bigFromDecimal(deal.financials.discount),
        currency: deal.feePrice.currency,
      }

      const computedTotalAfterDiscount = valueAfterDiscount(priceData.totalWithoutDiscount, priceData.percentageDiscount)
      const computedTotalVat = percentageValue(computedTotalAfterDiscount, priceData.vat)
      const computedTotalAfterVat = computedTotalAfterDiscount.plus(computedTotalVat)

      const discountedTotalVat = percentageValue(priceData.discountedTotal, priceData.vat)
      const discountedTotalAfterVat = priceData.discountedTotal.plus(discountedTotalVat)

      const discountedValue = percentageValue(priceData.totalWithoutDiscount, priceData.percentageDiscount)

      acc[`orderId-${orderId}`] = {
        ...priceData,
        discountedTotalVat,
        discountedTotalAfterVat,
        computedTotalVat,
        computedTotalAfterVat,
        computedTotalAfterDiscount,
        discountedValue,
        orderId: String(orderId),
      }

      return acc
    }, {} as Record<string, typeof defaultsPriceData & {
      discountedTotalVat: Big
      discountedTotalAfterVat: Big
      computedTotalVat: Big
      computedTotalAfterVat: Big
      computedTotalAfterDiscount: Big
      discountedValue: Big
      orderId: string
    }>)
  }, [data])

  return priceDataByOrderId
}
