import './DealAssignmentCard.sass'

import { AssignmentDTO, AssignmentDeliverySpeed } from 'models/assignment'
import { AssignmentDTOIsAdministratorDTO, AssignmentDTOIsClientDTO, AssignmentDTOIsCreativeDTO, DealDTOIsClientDTO, DealDTOOrAssignmentDTOIsAssignmentDTO, DealDTOOrAssignmentDTOIsDealDTO } from 'utils/typeguards'
import { AssignmentLabels, AssignmentStage } from 'constants/assignment'
import { AutomationStatusBadge, Badge } from '../Badges'
import { ColorClass, IconType } from 'constants/assets'
import { CreativeProductInstruction, chooseProductContainingVisuals } from 'utils/product'
import { Fragment, useCallback, useMemo } from 'react'
import { OwnershipTab, Params } from 'constants/misc'
import { ProductKind, ProductSegment, ProductType, STAGING_PRODUCT_KINDS } from 'constants/product'
import { applyFeeDiscount, formatPrice } from 'utils/price'
import { isAssignmentAvailable, isAssignmentFinished, translateAssignmentStage } from 'utils/stages'

import { BLUE_100 } from 'constants/styling/theme'
import BlockInfo from '../BlockInfo/BlockInfo'
import Button from '../Button/Button'
import { DeadlineDate } from '../DeadlineDate'
import { DealAssignmentPriceBreakdown } from '../DealAssignmentPriceBreakdown/DealAssignmentPriceBreakdown'
import { DealDTO } from 'models/deal'
import { DealState } from 'constants/deal'
import { EditCalendar } from '@mui/icons-material'
import { EventTime } from '../EventTime'
import Icon from '../Icon/Icon'
import { MUITooltip } from '../MUITooltip'
import { ORDER_INFO_PATH } from 'components/pages/Gallery/_main/contexts/GalleryNavigation.context'
import { Path } from 'constants/router'
import { ProductCategory } from 'models/product'
import { QueryStatus } from '../QueryStatus'
import { Stack } from '@mui/material'
import { StagingType } from 'models/virtualStaging'
import TriangleIcon from '../TriangleIcon/TriangleIcon'
import { externalNavigate } from 'utils/helpers'
import { getSalesforceOrderLink } from 'utils/serialization'
import { isEditingCategory } from 'utils/validators'
import moment from 'moment'
import { useAcceptAssignment } from 'dataQueries'
import { useAuth0 } from 'utils/auth'
import { useBkbnSearchParams } from 'utils/hooks/useBkbnSearchParams'
import { useTimezone } from 'components/contexts/timezone.context'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
interface Props {
  /** The additional classes to append */
  className?: string
  /** The color of the deal card */
  color?: 'orange' | 'blue' | 'green' | 'red'
  /** The data object */
  data: DealDTO | AssignmentDTO
  /** Called upon clicking button "more info" */
  onMoreInfoClick?: () => unknown
}

const stagesUnlockingCreativeAcceptAssignment = new Set([
  AssignmentStage.MISSION_ORDER_PLACED,
  AssignmentStage.PRE_PRODUCTION,
  AssignmentStage.AUTOMATED,
  AssignmentStage.WAITING_FOR_CT_TO_ACCEPT,
])
const stagesUnlockingCreativeUploadVisuals = new Set([
  AssignmentStage.CREATIVE_BOOKED,
])
const stagesUnlockingCreativeSeeVisuals = new Set([
  AssignmentStage.VISUALS_SENT_BY_CT_TO_BKBN,
  AssignmentStage.VISUALS_SENT_BY_BKBN_TO_EDITOR,
  AssignmentStage.VISUALS_SENT_BY_EDITOR_TO_BKBN,
  AssignmentStage.VISUALS_SENT_TO_CLIENT,
])
const stagesLockingClientSeeCreative = new Set([
  AssignmentStage.MISSION_ORDER_PLACED,
  AssignmentStage.PRE_PRODUCTION,
  AssignmentStage.AUTOMATED,
  AssignmentStage.WAITING_FOR_CT_TO_ACCEPT,
])
const productKindsLockingAssignCreative = new Set([
  ProductKind.MATTERPORT_PHOTO,
  ProductKind.MATTERTAG,
  ProductKind.RESIDENTIAL_STAGING,
  ProductKind.COMMERCIAL_STAGING,
])

const stagesUnlockingVisualsUploadedDeadline = new Set([
  AssignmentStage.CREATIVE_BOOKED,
  AssignmentStage.WAITING_FOR_CT_TO_ACCEPT,
  AssignmentStage.WAITING_FOR_DATE_AND_TIME,
])

/**
 * @component Display deal overview in a styled card
 * @example
 */
export const DealAssignmentCard: React.FC<Props> = ({
  className = '',
  color,
  data,
  onMoreInfoClick
}) => {
  const { t, i18n } = useTranslation(['deal_assignment_card', 'deal_assignment', 'time_translations', 'category', 'product'])
  const { roles } = useAuth0()
  const { userTimezone } = useTimezone()
  const params = useBkbnSearchParams()

  const acceptAssignment = useAcceptAssignment()

  const isOrderOwnerShowing = useMemo(() => params.get(Params.OWNERSHIP) === OwnershipTab.ALL_ORDERS, [params])

  const orderCreatedAt = useMemo(() => {
    if (DealDTOOrAssignmentDTOIsDealDTO(data)) {
      return data?.createdAt ? moment(data.createdAt).locale(i18n.language).format('MMMM Do YYYY, h:mm A') : null
    }

    return null
  }, [data, i18n.language])

  /** Shows deadline for creative and admin assignments with no uploaded visuals */
  const isDeadlineVisible = useMemo(() => {
    if (!DealDTOOrAssignmentDTOIsAssignmentDTO(data)) return false
    if (roles.isClient) return false
    if (AssignmentDTOIsClientDTO(data)) return false
    if (!stagesUnlockingVisualsUploadedDeadline.has(data.stage)) return false

    return true
  }, [data, roles.isClient])

  const onMoreInfo = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault()
    if (onMoreInfoClick) onMoreInfoClick()
  }, [onMoreInfoClick])

  const cardColor = useMemo(() => {
    if (!color) {
      if (DealDTOOrAssignmentDTOIsDealDTO(data)) {
        if (data.state === DealState.FINISHED) return 'green'
        else if (data.state === DealState.CANCELLED) return 'red'
        else return 'orange'
      } else if (DealDTOOrAssignmentDTOIsAssignmentDTO(data)) {
        if (roles.isCreative && AssignmentDTOIsCreativeDTO(data)) {
          if (isAssignmentFinished(data, roles) && data.orderState === DealState.FINISHED) return 'green'
          else if (isAssignmentFinished(data, roles) && data.orderState === DealState.CANCELLED) return 'red'
          else if (isAssignmentAvailable(data)) return 'blue'
          else return 'orange'
        } else if (roles.isAdmin && AssignmentDTOIsAdministratorDTO(data)) {
          if (isAssignmentFinished(data, roles) && data.orderState === DealState.FINISHED) return 'green'
          else if (isAssignmentFinished(data, roles) && data.orderState === DealState.CANCELLED) return 'red'
          else return 'blue'
        } else return 'orange'
      } else return 'orange'
    } return color
  }, [color, data, roles])

  const product = useMemo(() => DealDTOOrAssignmentDTOIsAssignmentDTO(data) ? chooseProductContainingVisuals(data.products) : undefined, [data])
  const isFloorPlanWithTypeVirtualVisit = useMemo(() => !!product && product.kind === ProductKind.MATTERPORT_FLOOR_PLAN_WITH_VISIT && product.type === ProductType.VIRTUAL_VISIT, [product])
  const isCreativeRequired = useMemo(() => !!product && !!product.category && !isEditingCategory(product.category) && !productKindsLockingAssignCreative.has(product.kind) && !isFloorPlanWithTypeVirtualVisit, [product, isFloorPlanWithTypeVirtualVisit])

  if (DealDTOOrAssignmentDTOIsAssignmentDTO(data) && AssignmentDTOIsClientDTO(data)) return null

  if (data.isDeleted) className = `deleted ${className}`

  let category: ProductCategory | null = null
  if (DealDTOOrAssignmentDTOIsDealDTO(data)) category = data.productCategory as ProductCategory
  if (DealDTOOrAssignmentDTOIsAssignmentDTO(data) && data.products.length > 0) category = data.products[0].category as ProductCategory

  return (
    <div className={`dealassignmentcard ${cardColor} ${className}`.trim()}>
      <div className="content">
        <div className="topline">
          <span className="graytitle">
            {data.isDeleted &&
              <Fragment>
                <strong className="red-text">[{t('deal_assignment:deleted').toUpperCase()}]</strong>
                {' - '}
              </Fragment>
            }
            {((DealDTOOrAssignmentDTOIsDealDTO(data) && data.state === DealState.CANCELLED) || (DealDTOOrAssignmentDTOIsAssignmentDTO(data) && data.orderState === DealState.CANCELLED)) &&
              <Fragment>
                <strong className="red-text">[{t('deal_assignment:cancelled').toUpperCase()}]</strong>
                {' - '}
              </Fragment>
            }
            {category ? t(`category:${category}`) : 'N/A'}
          </span>
          <div className="right">

            {!!data && DealDTOOrAssignmentDTOIsAssignmentDTO(data) && AssignmentDTOIsAdministratorDTO(data) &&
              <div className="labels">
                {data.assignmentLabels.includes(AssignmentLabels.IMPORTANT) &&
                  <Badge color="red">{t('deal_assignment:important_assignment')}</Badge>
                }
                {data.deliverySpeed === AssignmentDeliverySpeed.FAST &&
                  <Badge color="blue">{t('deal_assignment:fast_delivery')}</Badge>
                }
                {data.scheduledByCT &&
                  <Badge color="orange">{t('deal_assignment:scheduled_by_ct')}</Badge>
                }
                {product && STAGING_PRODUCT_KINDS.has(product.kind) && Object.values(data.stagingDetails).some((staging) => staging?.type === StagingType.BKBN) &&
                  <Badge color="green">{t('staging_bkbn_decide')}</Badge>
                }
              </div>
            }

            <MUITooltip
              content={t('deal_assignment:placed_on', { date: orderCreatedAt })}
              type="secondary"
            >
              <EditCalendar />
            </MUITooltip>

            <span className="number">{'#'}{data.id}</span>

            {!!data && 'pipedriveId' in data && 'salesforceId' in data &&
              <>

                {data.pipedriveId && !data.salesforceId &&
                  <span>
                    PD: <a className="pipedrive-link" target="_blank" href={`https://backbone.pipedrive.com/deal/${String(data.pipedriveId)}`} rel="noreferrer">
                      {String(data.pipedriveId)}
                    </a>
                  </span>
                }

                {!data.pipedriveId && data.salesforceId &&
                  <span>
                    SF: <a className="pipedrive-link" target="_blank" href={getSalesforceOrderLink(String(data.salesforceId))} rel="noreferrer">
                      {String(data.salesforceId)}
                    </a>
                  </span>
                }

                {!data.pipedriveId && !data.salesforceId &&
                  <span style={{ textDecoration: 'line-through' }}>PD / SF</span>
                }

              </>
            }

          </div>
        </div>
        <div className="heading-wrap">

          {!!category && !isEditingCategory(category) &&
            <div className="heading-text">
              <div className='title-with-badge'>
                <h2 className="title">{data.hasManualAddress ? data.manualAddress : (data.address || '-')}</h2>
                {data.hasManualAddress &&
                  <Badge color="orange">{t('deal_assignment:manual')}</Badge>
                }
              </div>
              {data.hasManualAddress &&
                <div className="manual-title">
                  <span>{data.address}</span>
                </div>
              }
              <h3 className="subtitle">
                {(DealDTOOrAssignmentDTOIsDealDTO(data) || (DealDTOOrAssignmentDTOIsAssignmentDTO(data) && !AssignmentDTOIsCreativeDTO(data))) && !!data.reference
                  ? data.reference
                  : '-'
                }
              </h3>
            </div>
          }

          {!!category && isEditingCategory(category) &&
            <div className="heading-text">
              <h2 className="title">
                {(DealDTOOrAssignmentDTOIsDealDTO(data) || (DealDTOOrAssignmentDTOIsAssignmentDTO(data) && !AssignmentDTOIsCreativeDTO(data))) && !!data.reference
                  ? data.reference
                  : '-'
                }
              </h2>
            </div>
          }

          <div className="heading-price">

            {DealDTOOrAssignmentDTOIsDealDTO(data) &&
              <MUITooltip
                id={`price-tooltip-${data.id}`}
                leaveDelay={200}
                type="secondary"
                /* Show Price Breakdown tooltip only to Clients */
                content={
                  DealDTOIsClientDTO(data) &&
                  <DealAssignmentPriceBreakdown
                    data={data}
                  />
                }
              >
                <div className="price-wrapper with-tooltip" data-tip data-for={`price-tooltip-${data.id}`}>
                  {/* Show Price Breakdown tooltip (question mark icon) only to Clients */}
                  {DealDTOIsClientDTO(data) && <Icon className="tooltip-icon small" icon={IconType.QUESTION} />}

                  <strong className="price">{formatPrice(data.discountedFeePrice)}</strong>

                </div>
              </MUITooltip>
            }

            {DealDTOOrAssignmentDTOIsAssignmentDTO(data) &&
              <MUITooltip
                id={`total-price-tooltip-${data.id}`}
                content={t('deal_assignment:total_remuneration')}
                type="secondary"
              >
                <div className="price-wrapper">

                  <span className="group-icons">
                    <Icon className="small car" icon={IconType.CAR} />
                    <span className="plus-sign">+</span>
                    <Icon className="small sallary" icon={IconType.SALARY} />
                  </span>

                  <strong className="price">
                    {formatPrice(applyFeeDiscount(data.creativeRemuneration.totalRemuneration))}
                  </strong>

                </div>
              </MUITooltip>
            }

            {DealDTOOrAssignmentDTOIsAssignmentDTO(data) &&
              <MUITooltip
                className="transport-cost-tooltip"
                id={`transport-cost-tooltip-${data.id}`}
                content={t('deal_assignment:transport_cost')}
                type="secondary"
              >
                <span className="transport-price" data-tip data-for={`transport-cost-tooltip-${data.id}`}>
                  {formatPrice(applyFeeDiscount(data.creativeRemuneration.transportRemuneration))}
                  <Icon className="small gray" icon={IconType.CAR} />
                </span>
              </MUITooltip>
            }

          </div>

        </div>

        {DealDTOOrAssignmentDTOIsAssignmentDTO(data) && roles.isCreative && data.keyPickupDetails?.address &&
          <Stack
            gap={1}
            sx={{
              background: BLUE_100,
              padding: '1rem',
              width: 'auto',
            }}
          >
            <span>{t('key_pickup_warning')}</span>
            <strong>{data.keyPickupDetails.address}</strong>
          </Stack>
        }

        {DealDTOOrAssignmentDTOIsAssignmentDTO(data) && !!data.visualsUploadDeadline && isDeadlineVisible &&
          <div className="infoline">
            <DeadlineDate
              deadlineDate={data.visualsUploadDeadline}
              deadlineTimezone={data.timezone}
              userTimezone={userTimezone}
            />
          </div>
        }

        {DealDTOOrAssignmentDTOIsAssignmentDTO(data) && !!category && !isEditingCategory(category) && !!data.shootingStartDateTime &&
          <div className="infoline">
            <EventTime
              eventStart={data.shootingStartDateTime}
              eventTimezone={data.timezone}
              userTimezone={userTimezone}
              minDuration={data.timeOnSiteMin}
              maxDuration={data.timeOnSiteMax}
            />
          </div>
        }

        <div className="infoline">


          <div className="info">
            {(data.contactPerson && data.contactPerson.name) &&
              <>
                <span className="group">
                  <TriangleIcon icon={IconType.PROFILE} type={ColorClass.PRIMARY_BLUE} />
                  <span className="label">{t('deal_assignment:contact_on_site')}</span>
                </span>
                <span className="group">
                  <span><Icon className="small" icon={IconType.PROFILE} />{data.contactPerson.name}</span>
                </span>
                {data.contactPerson.phone &&
                  <span className="group">
                    <a href={`tel:${data.contactPerson.phone}`}><Icon className="small" icon={IconType.PHONE} />{data.contactPerson.phone}</a>
                  </span>
                }
              </>
            }

          </div>

          {DealDTOOrAssignmentDTOIsDealDTO(data) &&
            <div className="more">
              <Button
                type="primary light"
                height="thin"
                onClick={e => roles.isClient && AssignmentDTOIsClientDTO(data.missions[0])
                  ? window.open(Path.GALLERY_INFO.replace(':id', data.missions[0].id.toString()).replace(':tab', ORDER_INFO_PATH), '_blank')
                  : onMoreInfo(e)
                }
              >
                {t('see_more')}
              </Button>
            </div>
          }

        </div>

        {DealDTOOrAssignmentDTOIsAssignmentDTO(data) && data.creativeInstructionsUrl &&
          <div className="infoline">
            <div className="info">
              <Button
                type="secondary"
                className="creative-instruction-button"
                onClick={() => externalNavigate(data.creativeInstructionsUrl)}
              >
                {t('deal_assignment:follow_instruction_button')}
              </Button>
            </div>
          </div>
        }

        {DealDTOOrAssignmentDTOIsAssignmentDTO(data) && data.meetingInstructions && !roles.isCreative &&
          <div className="infoline">
            <div className="info">
              <span className="group">
                <TriangleIcon icon={IconType.HANDSHAKE} type={ColorClass.PRIMARY_BLUE} />
                <span className="label">{t('deal_assignment:meeting_instructions')}</span>
              </span>
              <BlockInfo className="comments blue">
                {data.meetingInstructions}
              </BlockInfo>
            </div>
          </div>
        }

        {roles.isAdmin && DealDTOOrAssignmentDTOIsAssignmentDTO(data) && AssignmentDTOIsAdministratorDTO(data) && data.shootingInstructions &&
          <div className="infoline">
            <div className="info">
              <span className="group">
                <TriangleIcon icon={IconType.CAMERA} type={ColorClass.PRIMARY_BLUE} />
                <span className="label">{t('deal_assignment:shooting_instructions')}</span>
              </span>
              <BlockInfo className="comments blue">
                {data.shootingInstructions}
              </BlockInfo>
            </div>
          </div>
        }

        {DealDTOOrAssignmentDTOIsAssignmentDTO(data) && (AssignmentDTOIsCreativeDTO(data) || AssignmentDTOIsAdministratorDTO(data)) && data.creativeInstructionsFromAdmin && !roles.isCreative &&
          <div className="infoline">
            <div className="info">
              <span className="group">
                <TriangleIcon icon={IconType.INFO} type={ColorClass.PRIMARY_BLUE} />
                <span className="label">{t('deal_assignment:creative_instructions_from_admin')}</span>
              </span>
              <BlockInfo className="comments blue">
                {data.creativeInstructionsFromAdmin}
              </BlockInfo>
            </div>
          </div>
        }

      </div>

      {DealDTOOrAssignmentDTOIsDealDTO(data) && isOrderOwnerShowing && !!data.missions[0]?.client &&
        <div className='owner-info'>
          <span className='owner-title'>{t('owner_of_order')}</span>
          <span className='owner-name'>{data.missions[0].client.name}</span>
        </div>
      }

      {/* ATTACHED PRODUCTS FOR DEAL */}
      {DealDTOOrAssignmentDTOIsDealDTO(data) &&
        <div className={`attached-products ${cardColor}`}>

          <svg className="top-border">
            <line x1="0" x2="100%" y1="0" y2="0" strokeWidth="2" strokeLinecap="round" strokeDasharray="8, 6" />
          </svg>

          <div className="product-list">
            {data.missions.sort((a, b) => a.id.toString().localeCompare(b.id)).map((assignment) => {
              const product = chooseProductContainingVisuals(assignment.products)
              const isFloorPlanWithTypeVirtualVisit = product?.kind === ProductKind.MATTERPORT_FLOOR_PLAN_WITH_VISIT && product?.type === ProductType.VIRTUAL_VISIT
              const isCreativeRequired = !!product?.category && !isEditingCategory(product?.category) && !productKindsLockingAssignCreative.has(product.kind) && !isFloorPlanWithTypeVirtualVisit

              if (!roles.isAdmin && assignment.isDeleted) return <Fragment key={assignment.id}></Fragment>

              return (
                <div className={`product ${assignment.isDeleted ? 'deleted' : ''} ${cardColor}`.replace(/\s+/g, ' ').trim()} key={assignment.id}>
                  <div className="productline deal">

                    <div className="productline-info">

                      {!!assignment && roles.isAdmin && AssignmentDTOIsAdministratorDTO(assignment) &&
                        <div className="labels">
                          {assignment.assignmentLabels.includes(AssignmentLabels.IMPORTANT) &&
                            <Badge type="outline" color="red">{t('deal_assignment:important_assignment')}</Badge>
                          }
                          {assignment.deliverySpeed === AssignmentDeliverySpeed.FAST &&
                            <Badge type="outline" color="blue">{t('deal_assignment:fast_delivery')}</Badge>
                          }
                          {assignment.scheduledByCT &&
                            <Badge type="outline" color="orange">{t('deal_assignment:scheduled_by_ct')}</Badge>
                          }
                          {product && STAGING_PRODUCT_KINDS.has(product.kind) && Object.values(assignment.stagingDetails).some((staging) => staging?.type === StagingType.BKBN) &&
                            <Badge type="outline" color="green">{t('staging_bkbn_decide')}</Badge>
                          }
                        </div>
                      }

                      <h3 className="productline-info_title">
                        {product ? (product.quantity > 1 ? `${product.quantity} x ` : '') + t(`product:p_${product.id}`) : 'N/A'}
                      </h3>

                      <div className="productline-info_info">

                        <span className="price">{formatPrice(applyFeeDiscount(assignment.feePrice))}</span>

                        {assignment.creative && (!roles.isClient || !stagesLockingClientSeeCreative.has(assignment.stage)) &&
                          <Fragment>
                            <span>
                              <Icon className="small" icon={IconType.CAMERA} />
                              {assignment.creative.name || 'N/A'}
                            </span>
                            {assignment.creative.phone &&
                              <a className="separate-left" href={`tel:${assignment.creative.phone}`}>
                                <Icon className="small" icon={IconType.PHONE} />
                                {assignment.creative.phone}
                              </a>
                            }
                          </Fragment>
                        }

                      </div>

                      {!!category && !isEditingCategory(category) && !!assignment.shootingStartDateTime && !!assignment.shootingDuration &&
                        <EventTime
                          eventStart={assignment.shootingStartDateTime}
                          eventTimezone={assignment.timezone}
                          userTimezone={userTimezone}
                          minDuration={assignment.timeOnSiteMin}
                          maxDuration={assignment.timeOnSiteMax}
                        />
                      }

                    </div>

                    <div id={String(assignment.id)} className={`productline-stage ${AssignmentDTOIsAdministratorDTO(assignment) || (category && !isEditingCategory(category)) ? '' : 'one-line'}`}>
                      {(roles.isAdmin || data.state !== DealState.CANCELLED) &&
                        <span className="state-text">
                          <span className="iconrectangle">
                            <Icon icon={IconType.PHOTO} />
                          </span>
                          <span className="inner-text">
                            {translateAssignmentStage(assignment.stage, roles).replace(/_/igm, '_\u200B')}
                          </span>
                        </span>
                      }
                      {/* For new Gallery redesign we want to show the View visuals button even for assignments where the visuals are in progress and not canceled */}
                      {roles.isClient && data.state !== DealState.CANCELLED &&
                        <Button
                          className="action-button"
                          type="secondary nobackground"
                          // With the Dashboard redesign, we want to open assignments in a blank page to easily handle the loading of a list of data with URL parameters.
                          onClick={() => window.open(Path.GALLERY.replace(':id', assignment.id.toString()), '_blank')}
                        >
                          {t('view_visuals')}
                        </Button>
                      }

                      {roles.isAdmin &&
                        <div className="status-button-wrapper">
                          {AssignmentDTOIsAdministratorDTO(assignment) && isCreativeRequired &&
                            <AutomationStatusBadge automationStatus={assignment.assignmentAutomationStatus} assignmentStage={assignment.stage} withArrow={true} className="margin-right" />
                          }
                          {!!AssignmentDTOIsAdministratorDTO(assignment) && Object.values(assignment.stagingDetails).some(staging => !!staging?.comment) &&
                            <Badge
                              icon={IconType.LIGHTBULB}
                              color="orange"
                              type="fill"
                              displayArrow
                            >
                              {t('staging_requires_action')}
                            </Badge>
                          }
                          <Button
                            className="action-button"
                            type="secondary nobackground"
                            // With the Dashboard redesign, we want to open assignments in a blank page to easily handle the loading of a list of data with URL parameters.
                            onClick={() => window.open(Path.GALLERY.replace(':id', assignment.id.toString()), '_blank')}
                          >
                            {t('see_visuals')}
                          </Button>
                        </div>
                      }

                    </div>

                  </div>
                </div>
              )
            })}
          </div>
        </div>
      }

      {/* ATTACHED PRODUCTS FOR ASSIGNMENT */}
      {DealDTOOrAssignmentDTOIsAssignmentDTO(data) &&
        <div className={`attached-products ${cardColor}`}>

          <svg className="top-border">
            <line x1="0" x2="100%" y1="0" y2="0" strokeWidth="2" strokeLinecap="round" strokeDasharray="8, 6" />
          </svg>

          <div className="product-list">
            <div className={`product ${cardColor}`}>
              <div className="productline assignment">

                <div className="productline-info">
                  {(() => {
                    const product = chooseProductContainingVisuals(data.products)
                    return (
                      <h3 className="productline-info_title">
                        {roles.isCreative && DealDTOOrAssignmentDTOIsAssignmentDTO(data) && AssignmentDTOIsCreativeDTO(data) && product ?
                          <Fragment>
                            <CreativeProductInstruction
                              category={product.category as ProductCategory}
                              type={product.type as ProductType}
                              kind={product.kind as ProductKind}
                              attributeX={data.creativeInstructionAttributeX}
                              attributeY={data.creativeInstructionAttributeY}
                              count={product.quantity}
                              segment={product.segments[0] as ProductSegment}
                            />
                          </Fragment>
                          :
                          <Fragment>
                            {data.products.map(prod => {
                              const productName = t(`product:p_${prod.id}`)
                              return prod.quantity > 1 ? `${prod.quantity} x ${productName}` : productName
                            }).join(', ') || 'N/A'}
                          </Fragment>
                        }
                      </h3>
                    )
                  })()}
                </div>

                <div className={`productline-stage ${(AssignmentDTOIsAdministratorDTO(data) && isCreativeRequired) ? '' : 'one-line'}`}>

                  {!(roles.isCreative && stagesUnlockingCreativeAcceptAssignment.has(data.stage)) &&
                    <span className="state-text">
                      <span className="iconrectangle">
                        <Icon icon={IconType.PHOTO} />
                      </span>
                      <span className="inner-text">
                        {translateAssignmentStage(data.stage, roles).replace(/_/igm, '_\u200B')}
                      </span>
                    </span>
                  }

                  {roles.isCreative && stagesUnlockingCreativeUploadVisuals.has(data.stage) &&
                    <Button
                      className="action-button"
                      type="secondary nobackground"
                      // With the Dashboard redesign, we want to open assignments in a blank page to easily handle the loading of a list of data with URL parameters.
                      onClick={() => window.open(Path.GALLERY.replace(':id', data.id.toString()), '_blank')}
                    >
                      {t('upload_visuals')}
                    </Button>
                  }
                  {(roles.isAdmin || (roles.isCreative && stagesUnlockingCreativeSeeVisuals.has(data.stage) && data.orderState !== DealState.CANCELLED)) &&
                    <div className="status-button-wrapper">
                      {!!AssignmentDTOIsAdministratorDTO(data) && !!data.assignmentAutomationStatus && isCreativeRequired &&
                        <AutomationStatusBadge automationStatus={data.assignmentAutomationStatus} assignmentStage={data.stage} withArrow={true} className="margin-right" />
                      }
                      {!!AssignmentDTOIsAdministratorDTO(data) && Object.values(data.stagingDetails).some(staging => !!staging?.comment) &&
                        <Badge
                          icon={IconType.LIGHTBULB}
                          color="orange"
                          type="fill"
                          displayArrow
                        >
                          {t('staging_requires_action')}
                        </Badge>
                      }
                      <Button
                        className="action-button"
                        type="secondary nobackground"
                        // With the Dashboard redesign, we want to open assignments in a blank page to easily handle the loading of a list of data with URL parameters.
                        onClick={() => window.open(Path.GALLERY.replace(':id', data.id.toString()), '_blank')}
                      >
                        {t('see_visuals')}
                      </Button>
                    </div>
                  }

                </div>

              </div>

              {roles.isCreative && stagesUnlockingCreativeAcceptAssignment.has(data.stage) &&
                <QueryStatus
                  query={acceptAssignment}
                  successMessage={t('deal_assignment:assignment_accepted')}
                  spaceTopRem={2}
                />
              }

            </div>
          </div>

        </div>
      }

    </div>
  )
}
