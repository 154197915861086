import { ClientGalleryOrderInformation, OrderInformationContext } from 'components/pages/Gallery/_main/ClientGallery/ClientGalleryOrderInformation'
import { FC, useMemo } from 'react'
import { GalleryAssignmentContextProvider, GalleryDealContextProvider } from 'components/pages/Gallery/_main/contexts'

import { ClientPropertyPriceSummaryCard } from '../../ClientPropertyPriceSummaryCard'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { useClientProperty } from '../contexts'
import { useGetPropertyOrders } from 'dataQueries'
import { useUserData } from 'components/contexts/UserDataContext'

export const ClientPropertyOrdersPage: FC = () => {
  const { propertyId } = useClientProperty()
  const { userPropertyWorkspaceId } = useUserData()

  const getPropertyOrdersQuery = useGetPropertyOrders(propertyId, userPropertyWorkspaceId)
  const propertyOrdersData = useMemo(() => getPropertyOrdersQuery.data?.data, [getPropertyOrdersQuery.data])

  return (
    <Grid container spacing={2.5} alignItems="start" direction={{ xs: 'column', sm: 'row' }} paddingTop="2.3rem">

      <Grid item xs={12} md={4} width="100%">
        <ClientPropertyPriceSummaryCard />
      </Grid>

      <Grid item xs={12} md={8} width="100%">
        {propertyOrdersData && propertyOrdersData.map((order) => (

          <GalleryAssignmentContextProvider assignmentId={order.missions[0].id}>
            <GalleryDealContextProvider assignmentId={order.missions[0].id} keepPreviousData={true}>

              <Stack key={order.id} gap={2}>
                <ClientGalleryOrderInformation context={OrderInformationContext.PROPERTY} />
              </Stack>

            </GalleryDealContextProvider>
          </GalleryAssignmentContextProvider>

        ))}
      </Grid>
    </Grid>
  )
}
