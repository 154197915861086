import { BLUE_400, BOX_SHADOW_SM, GRAY_700, GRAY_900 } from 'constants/styling/theme'

import { BorderBoxWrapper } from '../BorderBoxWrapper'
import Box from '@mui/material/Box'
import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

/** @interface Props for the ImageBoxButton component. */
export interface Props {
  /** The URL of the image to display. */
  imageUrl: string
  /** The alt text for the image. */
  altText?: string
  /** The label text to display below the image. */
  label: string
  /** The width of the image box. */
  width?: string
  /** The height of the image box. */
  height?: string
  /**  Whether the button is selected. */
  selected: boolean
  /** The function to call when the button is clicked. */
  onClick: () => void
}

/**
 * @component
 * ImageBoxButton renders an image with a label and a border that changes color when selected.
 *
 * @example
 * <ImageBoxButton
 *   width="200px"
 *   height="150px"
 *   selected={true}
 *   label="Example Label"
 *   altText="Example Image"
 *   imageUrl="https://example.com/image.jpg"
 *   onClick={() => console.log('Button clicked')}
 * />
 */
export const ImageBoxButton: React.FC<Props> = ({
  imageUrl,
  altText = '',
  label,
  selected,
  width = '15.4rem',
  height = '10.4rem',
  onClick,
}) => {

  return (
    <Stack
      sx={{
        gap: 1,
        cursor: 'pointer',
        maxWidth: '15.4rem',
        '&:hover .image-wrapper': {
          transform: 'scale(1.05)',
          boxShadow: BOX_SHADOW_SM,
        },
        '&:hover .label': {
          color: GRAY_900,
        }
      }}
      onClick={onClick}
    >

      <BorderBoxWrapper
        className="image-wrapper"
        borderColor={selected ? BLUE_400 : 'transparent'}
        elevation="none"
        sx={{
          width,
          height,
          overflow: 'hidden',
          borderWidth: selected ? '0.4rem' : '0',
        }}
      >
        <Box
          width="100%"
          height="100%"
          position="relative"
        >
          <img
            src={imageUrl}
            alt={altText}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              transition: 'transform 0.3s ease',
            }}
          />
        </Box>

      </BorderBoxWrapper>

      <Typography
        variant="text-md"
        color={selected ? GRAY_900 : GRAY_700}
        fontWeight={500}
        textAlign="center"
        className="label"
      >
        {label}
      </Typography>

    </Stack>
  )
}
