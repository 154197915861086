import { BEIGE_600, GRAY_900, MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { Dispatch, FC, Fragment, SetStateAction, useCallback, useMemo } from 'react'
import { PropertyTab, useClientProperty } from '../_main/contexts'

import { AssignmentDTOClient } from 'models/assignment'
import { AssignmentJob } from 'constants/assignment'
import { CircleIcon } from 'components/common/CircleIcon'
import FilterTab from 'components/common/FilterTab/FilterTab.module'
import { MUIBadge } from 'components/common/MUIBadge'
import { Path } from 'constants/router'
import { ProductKindsWithDocumentGallery } from 'constants/product'
import { RealEstatePropertyAssignmentDTO } from 'models/property'
import { getProductKindIcon } from 'utils/helpers/getProductKindIcon'
import { getProgressCategoryData } from 'components/common/Gallery/AssignmentProgress/AssignmentProgressSteps.utils'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface Props {
  /** Active documents assignment filter */
  activeAssignmentDocumentsFilterTab: string
  /** Callback to set active documents assignment filter */
  callback: Dispatch<SetStateAction<string>>
}

/**
 * Client document type assignment's filter component.
 * 
 * @example <ClientPropertyDocumentTypeFilter />
 */
export const ClientPropertyDocumentTypeFilter: FC<Props> = ({ activeAssignmentDocumentsFilterTab, callback }) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['gallery'])
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  const {
    propertyAssignmentsData,
    propertyId
  } = useClientProperty()

  const badgeColor = useCallback((status?: string) => {
    if (status !== AssignmentJob.ORDER_DELIVERED) return 'orange'
    return 'green'
  }, [])

  const productName = useCallback((assignment: AssignmentDTOClient | RealEstatePropertyAssignmentDTO) => {
    return assignment.products.map((product) => t(`product:p_${product.id}`)).join(', ')
  }, [t])

  const filterTabList = useMemo(() => {
    const filteredData = propertyAssignmentsData?.filter((assignment) =>
      assignment.products && ProductKindsWithDocumentGallery.has(assignment.products[0].kind)
    )

    return filteredData?.map((assignment) => {
      const productKind = assignment.products?.[0]?.kind

      const steps = getProgressCategoryData(assignment.stage, assignment.products)
      const activeStepData = steps?.find((step) => step.activeStep)

      return (
        <FilterTab
          key={assignment.id}
          height="5.6rem"
          isActive={activeAssignmentDocumentsFilterTab === assignment.id}
          icon={
            <CircleIcon
              size="3.2rem"
              circleColor={activeAssignmentDocumentsFilterTab !== assignment.id ? BEIGE_600 : undefined}
              icon={getProductKindIcon(productKind)}
            />
          }
          label={
            <Typography
              variant="text-sm"
              fontWeight="semiBold"
              color={GRAY_900}
              sx={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                lineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {productName(assignment)}
            </Typography>
          }
          badge={
            !!activeStepData &&

            <MUIBadge
              label={t(`order_informations.stages.${activeStepData?.jobDone}`)}
              size="sm"
              color={badgeColor(activeStepData.jobDone)}
            />
          }
          sx={{
            maxWidth: '35rem',
            flexShrink: 0
          }}
          onClick={(e) => {
            callback(assignment.id)
            navigate(Path.PROPERTY_DOCUMENTS_ASSIGNMENT.replace(':id', propertyId.toString()).replace(':tab', PropertyTab.DOCUMENTS).replace(':assignmentId', assignment.id))
          }}
        />

      )
    })
    // t, navigate skipped from deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyAssignmentsData, activeAssignmentDocumentsFilterTab, getProductKindIcon, productName, badgeColor])

  return (
    <Fragment>
      {isMobileView
        ? (
          <Box
            position="absolute"
            top="3.2rem"
            left="0"
            width="100vw"
            display="flex"
            gap=".8rem"
            overflow="auto"
            padding="0 2rem"
            boxSizing="border-box"
            sx={{
              '::-webkit-scrollbar': {
                display: 'none'
              }
            }}
          >
            {filterTabList}
          </Box>
        )
        : (
          <Grid container marginTop="3.2rem" gap=".8rem">
            {filterTabList}
          </Grid>
        )
      }
    </Fragment>
  )
}
