import { BEIGE_600, GRAY_900 } from 'constants/styling/theme'
import React, { useEffect } from 'react'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import Collapse from '@mui/material/Collapse'
import { ImageBoxButton } from 'components/common/ImageBoxButton'
import { MUIDivider } from 'components/common/MUIDivider'
import { MUISwitch } from 'components/common/MUISwitch'
import { SkyReplacementOption } from 'models/purchaseFlow/AIEditingDTO'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import sky_clear_thumb from 'assets/img/ai_settings/clear.webp'
import sky_cloudy_thumb from 'assets/img/ai_settings/cloudy.webp'
import sky_low_cloud_thumb from 'assets/img/ai_settings/low_cloud.webp'
import { usePurchaseFlowProducts } from 'components/pages/PurchaseFlow/_main/contexts'
import { useTranslation } from 'react-i18next'

const skyReplacementImages = {
  [SkyReplacementOption.NO_CLOUD]: sky_clear_thumb,
  [SkyReplacementOption.LOW_CLOUD]: sky_low_cloud_thumb,
  [SkyReplacementOption.CLOUDY]: sky_cloudy_thumb,
}

/**
 * @component
 * AIEditingProductSettings provides UI controls for AI-based photo editing settings.
 * 
 * @example
 * <AIEditingProductSettings />
 */
export const AIEditingProductSettings: React.FC = () => {

  const { t } = useTranslation(['ai_settings'])

  const { isSkyReplacementEnabled, selectedSkyReplacement, setSelectedSkyReplacement, setIsSkyReplacementEnabled } = usePurchaseFlowProducts()

  const getSkyReplacementImage = (option: SkyReplacementOption) => {
    return skyReplacementImages[option]
  }

  useEffect(() => {
    if (!isSkyReplacementEnabled && !!selectedSkyReplacement) {
      setSelectedSkyReplacement(null)
    }
  }, [isSkyReplacementEnabled, selectedSkyReplacement, setSelectedSkyReplacement])

  return (
    <Stack gap={1} marginTop="2.4rem">

      <Typography variant="text-md" fontWeight={600} color={GRAY_900}>
        {t('title')}
      </Typography>


      <BorderBoxWrapper
        padding={2}
        elevation="none"
        borderColor={BEIGE_600}
      >

        <MUISwitch
          label={
            <Typography variant="text-md" fontWeight={600} color={GRAY_900}>
              {t('sky_replacement_title')}
            </Typography>
          }
          labelPlacement="end"
          checked={isSkyReplacementEnabled}
          onChange={() => setIsSkyReplacementEnabled(prevState => !prevState)}
        />

        {/** Sky replacement options */}
        <Collapse in={isSkyReplacementEnabled}>

          <MUIDivider margin={16} />

          <Stack direction="row" gap={1.5}>

            {Object.values(SkyReplacementOption).map(option =>
              <ImageBoxButton
                key={option}
                altText={option}
                label={t(`sky_replacement_options.${option}`)}
                imageUrl={getSkyReplacementImage(option)}
                selected={selectedSkyReplacement === option}
                onClick={() => setSelectedSkyReplacement(option)}
              />
            )}

          </Stack>

        </Collapse>

      </BorderBoxWrapper>

    </Stack>
  )
}
