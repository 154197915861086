import { VirtualFurniturePropertyType, VirtualFurnitureRoomRemovableOption, VirtualFurnitureRoomType, VirtualFurnitureStyleCategory } from 'constants/virtualFurniture'
import { ImmutableMap, ImmutableSet, Nullable } from 'models/helpers'

/** Type of available renovations - derived from roomTypes */
export type RenovationTypes = VirtualFurnitureRoomType.WALL_MATERIALS
  | VirtualFurnitureRoomType.FLOORING
  // Backward compatibility
  | VirtualFurnitureRoomType.WHOLE_ROOM

/** Typeguard for checking whether roomType is member of RenovationTypes */
export function roomTypeIsRenovationType(type: VirtualFurnitureRoomType): type is RenovationTypes {
  return (type === VirtualFurnitureRoomType.WHOLE_ROOM
    || type === VirtualFurnitureRoomType.WALL_MATERIALS
    || type === VirtualFurnitureRoomType.FLOORING
  )
}

/**
 * Base object of room used for staging with virtual furniture products.
 * @member productId - id of product a room was created for
 * @member key{string} - key of room that is used as an identifier
 * @member name - name of room to be used as a human-readable identifier (not unique)
 * @member roomTypes - selected room types from virtual furniture catalogue
 * @member styleCodeMap - hash map of selected codes of selected virtual furniture style for roomType
 * @member styleCategory - category of styles per virtual furniture catalogue
 * @member roomCreationSortingOrder - order in which a room was created to be used for sorting
 * @member propertyType - whether the room has Residential or Commercial purpose
 * @member decorations - list of selected decorations
 * @member isComplete - whether the room staging is considered complete for this room
 * @member hasSelectedStyleCode - whether all room's types have assigned template code
 * @member renovations - hash map of selected renovations by their type (FLOORING/WALL_PAINTING/WHOLE_ROOM)
 * @member skipRenovations - user confirmation of no intention of selecting any renovation product (displayed as None button next to renovation selection buttons)
 * @member furnitureRemoval - selected furniture removal option
 * @member comment - special request for staging
 * @member type - whether the room is staging by BKBN decide or Custom 
 */
export interface StagingRoom {
  productId: number
  key: string
  name: string
  roomTypes: ImmutableSet<VirtualFurnitureRoomType>
  styleCodeMap: ImmutableMap<VirtualFurnitureRoomType, string>
  styleCategory: Nullable<VirtualFurnitureStyleCategory>
  propertyType: Nullable<VirtualFurniturePropertyType>
  decorations: ImmutableSet<string>
  roomCreationSortingOrder: number
  isComplete: boolean
  hasSelectedAllStyleCodes: boolean
  renovations: ImmutableMap<VirtualFurnitureRoomType, string>
  skipRenovations: boolean
  furnitureRemoval: Nullable<VirtualFurnitureRoomRemovableOption>
  comment: Nullable<string>
  type: StagingType
}

/**
 * Room object extended with image urls computed using relationships
 * @extends StagingRoom {@link StagingRoom}
 * @member imageUrls - array of either valid url string or undefined
 */
export interface ComputedStagingRoom extends StagingRoom {
  images: {
    id: string
    url?: string
  }[]
}

/** Interface representing item that can be selected as option for staging of the room */
export interface StagingItem {
  styleCode: string
  imgUrl: string
  style: VirtualFurnitureStyleCategory
  roomType: VirtualFurnitureRoomType
}

/** Map of rooms, indexed by their keys */
export type RoomInventory = Record<string, StagingRoom>

// -- API PAYLOADS --

export interface RoomStagingItem {
  roomType: VirtualFurnitureRoomType
  furnitureTemplate: Nullable<string>
}

export enum StagingType {
  BKBN = 'BKBN',
  CUSTOM = 'CUSTOM'
}

/** DTO describing an object for staging of an image */
export type StagingDetailsDTO = StagingDetailsBKBNChoiceDTO | StagingDetailsCustomChoiceDTO

/** Based configuration object for staging of an image */
export interface StagingDetailsDTOBase {
  productId: number
  roomStaging: Array<RoomStagingItem>
  decorations: Array<string>
  removeObjects: Nullable<VirtualFurnitureRoomRemovableOption>
  comment: Nullable<string>
  type: StagingType
}

/** Configuration object for staging of an image specific for BKBN choice */
export interface StagingDetailsBKBNChoiceDTO extends StagingDetailsDTOBase {
  wallPainting: boolean
  floor: boolean
}

/** Configuration object for staging of an image specific for custom choice */
export interface StagingDetailsCustomChoiceDTO extends StagingDetailsDTOBase {
  wallPainting: Nullable<string>
  floor: Nullable<string>
}

/** Map of configuration items index by image id */
export type StagingConfiguration = Record<string, StagingDetailsDTO>

/** Upsell details used in gallery staging */
export interface StagingUpsellInformationDTO {
  /** ID of renovation product */
  productId: number
  primaryBillingProductId: number
  stripePaymentIntentId: string
}

/** DTO containing all information to be sent to API */
export interface StagingRequestDTO {
  /** Staging configuration per room */
  details: StagingConfiguration
  /** Upsell information for gallery staging */
  upsellInformation?: StagingUpsellInformationDTO
}
