import { AdvancedMarker, Map, MapCameraChangedEvent } from '@vis.gl/react-google-maps'
import { BOX_SHADOW_MD, WHITE } from 'constants/styling/theme'
import { GoogleMap as DefaultGoogleMap, Marker } from '@react-google-maps/api'
import { GOOGLE_API_KEY, GOOGLE_MAP_ID } from 'constants/API'
import Geocode, { ReactGeocodePlace, ReactGeocodePoint } from 'react-geocode'
import { ReactNode, useCallback, useEffect } from 'react'

import { FeatureFlag } from 'utils/featureFlags'
import { isAddressValid } from 'utils/validators'
import { lookupPlaceFromAddress } from 'utils/helpers/lookUpPlaceFromAddress'
import { useAddressMap } from './AddressMap.context'
import { useFlag } from '@unleash/proxy-client-react'

Geocode.setApiKey(GOOGLE_API_KEY)
// Geocode.enableDebug()

export interface Props {
  /** Center point of the map and initial marker position if not specified */
  center?: ReactGeocodePoint
  /** Map container height */
  height: number | string
  /** The initial address input string */
  initialAddress?: string
  /** Initial marker position */
  initialMarker?: ReactGeocodePoint
  /** Initial zoom status */
  initialZoom?: number
  /** How long it takes for new props to populate the state */
  timeoutAfterPropsChange?: number
  /** Action called when address changes */
  handleChange?: (place: ReactGeocodePlace | null, isInitialLookup: boolean) => void
  /** Whether the map should try to obtain current location of the user */
  geolocation?: boolean
  /** Whether the map pin is draggable */
  draggable?: boolean
  /** Language used to initialize the google script */
  language?: string
  /** Country code used to specify where to search for addresses */
  countryCode?: string
  /** Optional slot for rendering circles/rectangles or other markers */
  mapObject?: ReactNode
  /** If the map pin should be adjusted from Geocode response */
  isMapPinAdjusted?: boolean
  /** Show second map pin */
  showSecondMapPin?: boolean
}

/**
 * @component
 * AdressMap component responsible for handling the address selection step in the purchase flow.
 *
 * @example
 * <AdressMap />
 */
export const AdressMap: React.FC<Props> = ({
  center,
  height,
  initialAddress,
  initialMarker,
  initialZoom,
  handleChange,
  geolocation,
  language,
  countryCode,
  mapObject,
  draggable = true,
  timeoutAfterPropsChange,
  isMapPinAdjusted = true,
  showSecondMapPin = false,
}) => {

  const allowNewMapLib = useFlag(FeatureFlag.ALLOW_NEW_MAP_LIB)

  const {
    zoom,
    mapCenter,
    markerPosition,
    placeObject,
    setZoom,
    setAddress,
    setMapCenter,
    setMarkerPosition,
    updateStateFromPlace,
  } = useAddressMap()

  const handleSetStateFromPlace = useCallback((place: ReactGeocodePlace | null, isInitialLookup: boolean = false) => {
    updateStateFromPlace(place, isMapPinAdjusted)
    handleChange?.(place, isInitialLookup)
  }, [handleChange, updateStateFromPlace, isMapPinAdjusted])

  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param e
   */
  const onMarkerDragEnd = useCallback(async (e: google.maps.MapMouseEvent) => {
    const lat = e.latLng?.lat() ?? 0
    const lng = e.latLng?.lng() ?? 0

    if (!e.latLng) console.error('latLng is null')

    setMarkerPosition({ lat, lng })
    setMapCenter({ lat, lng })

    try {
      const response = await Geocode.fromLatLng(lat, lng)
      let place_object: ReactGeocodePlace | null = null
      if (response.results.length > 0) {
        place_object = response.results.find((address) => isAddressValid(address)) ?? response.results[0]
      }
      handleSetStateFromPlace(place_object)
    } catch (error) {
      console.error(error)
      handleSetStateFromPlace(null)
    }

  }, [handleSetStateFromPlace, setMapCenter, setMarkerPosition])

  /** Geocode API lokup place from lat, lon geoposition */
  const lookupPlaceLatLng = useCallback(async (lat: number, lng: number) => {
    try {
      const response = await Geocode.fromLatLng(lat, lng)
      if (response.results.length === 0) return null
      return response.results[0]
    } catch (error) {
      console.error(error)
      return null
    }
  }, [])

  /** Handles the success of the geolocation request and updates the component's state
   *  based on the latitude and longitude information.
   */
  const handleGeolocationSuccess = useCallback(async (GeolocationPosition: GeolocationPosition) => {
    const { latitude, longitude } = GeolocationPosition.coords

    try {
      const response = await lookupPlaceLatLng(latitude, longitude)
      handleSetStateFromPlace(response, true)
    } catch (error) {
      console.error(error)
      handleSetStateFromPlace(null, true)
    }

  }, [handleSetStateFromPlace, lookupPlaceLatLng])

  /** Lookup current position via browser geolocation */
  const lookupFromCurrentPosition = useCallback(async () => {
    if (!window.navigator.geolocation) return

    window.navigator.geolocation.getCurrentPosition((GeolocationPosition) => {
      (async () => {
        await handleGeolocationSuccess(GeolocationPosition)
      })()
    })

  }, [handleGeolocationSuccess])

  const handleOnCameraChanged = useCallback((e: MapCameraChangedEvent) => {
    const center = e.map.getCenter()
    if (!center) return

    setMapCenter({ lat: center.lat(), lng: center.lng() })
    setZoom(e.detail.zoom)
  }, [setMapCenter, setZoom])

  /** Set initial place either from the the initial address string or geolocation lookup */
  const setInitialPlace = useCallback(() => {

    if (initialAddress) {
      const tryLookupAddress = async () => {
        try {
          const place = await lookupPlaceFromAddress(initialAddress ?? '')
          handleSetStateFromPlace(place, true)
        } catch (error) {
          console.error(error)
          handleSetStateFromPlace(null, true)
        }
      }
      tryLookupAddress()
    }

    if (geolocation) {
      lookupFromCurrentPosition()
    }

  }, [geolocation, handleSetStateFromPlace, initialAddress, lookupFromCurrentPosition])

  useEffect(() => {
    if (language) Geocode.setLanguage(language)
    if (countryCode) Geocode.setRegion(countryCode)

    setInitialPlace()

    // Set the initial language and country code if it is provided
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (center) {
      setMapCenter(center)
    }

    if (initialMarker) {
      setMarkerPosition(initialMarker)
    }

    // Set the initial marker position and center if it is provided
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Compare props and call API for initial address or coordinate search if necessary */
  useEffect(() => {

    setTimeout(() => {
      if (initialAddress) setAddress(initialAddress)
      if (initialMarker && isMapPinAdjusted) setMarkerPosition(initialMarker)
      if (initialMarker && isMapPinAdjusted) setMapCenter(initialMarker)
      if (initialZoom) setZoom(initialZoom)
      setInitialPlace()
    }, timeoutAfterPropsChange ?? 0)

    if (language) Geocode.setLanguage(language)
    if (countryCode) Geocode.setRegion(countryCode)

  }, [countryCode, initialAddress, initialMarker, initialZoom, isMapPinAdjusted, language, setAddress, setInitialPlace, setMapCenter, setMarkerPosition, setZoom, timeoutAfterPropsChange])

  return (
    <>
      {allowNewMapLib ?
        <Map
          mapId={GOOGLE_MAP_ID}
          style={{
            height,
            borderRadius: '8px',
            boxSizing: 'border-box',
            boxShadow: BOX_SHADOW_MD,
            border: `2px solid ${WHITE}`,
          }}
          zoom={zoom}
          center={{ lat: mapCenter.lat, lng: mapCenter.lng }}
          onZoomChanged={(e) => setZoom(e.detail.zoom)}
          onCameraChanged={handleOnCameraChanged}
        >

          {markerPosition && !showSecondMapPin &&
            <AdvancedMarker
              draggable={draggable}
              onDragEnd={onMarkerDragEnd}
              position={{ lat: markerPosition.lat, lng: markerPosition.lng }}
            />
          }

          {showSecondMapPin && placeObject &&
            <AdvancedMarker
              draggable={false}
              onDragEnd={onMarkerDragEnd}
              position={{ lat: placeObject.geometry.location.lat, lng: placeObject.geometry.location.lng }}
            />
          }

          {mapObject}
        </Map>
        :
        <DefaultGoogleMap
          mapContainerStyle={{
            height,
            borderRadius: '8px',
            boxSizing: 'border-box',
            boxShadow: BOX_SHADOW_MD,
            border: `2px solid ${WHITE}`,
          }}
          zoom={zoom}
          center={{ lat: mapCenter.lat, lng: mapCenter.lng }}
        >

          {markerPosition && !showSecondMapPin &&
            <Marker
              draggable={draggable}
              onDragEnd={onMarkerDragEnd}
              position={{ lat: markerPosition.lat, lng: markerPosition.lng }}
            />
          }

          {showSecondMapPin && placeObject &&
            <Marker
              draggable={false}
              onDragEnd={onMarkerDragEnd}
              position={{ lat: placeObject.geometry.location.lat, lng: placeObject.geometry.location.lng }}
            />
          }

          {mapObject}
        </DefaultGoogleMap>
      }
    </>

  )
}
