import { getImageMetadata } from 'utils/serialization'

export enum ImageQuality {
  INSUFFICIENT = 'INSUFFICIENT',
  BORDERLINE = 'BORDERLINE',
  GOOD = 'GOOD',
}

/**
 * Function that determines quality of image and rates it in 3 groups.    
 * INSUFFICIENT -> Image with undesired quality - longer side below 1500px  
 * BORDERLINE -> Image with processable quality - longer side between 1500px and 1920px  
 * GOOD -> Image of good quality - longer side above or equal 1920px  
 *   
 * @param imageFile - image file to check
 * @returns Promise which resolves into quality value
*/
export async function getImageQuality(imageFile: File) {
  try {
    const metadata = await getImageMetadata(imageFile)

    if (Math.max(metadata.height, metadata.width) >= 1920) return ImageQuality.GOOD

    if (Math.max(metadata.height, metadata.width) >= 1500) return ImageQuality.BORDERLINE

    return ImageQuality.INSUFFICIENT

  } catch (e) {
    return ImageQuality.INSUFFICIENT
  }
}
