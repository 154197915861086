import { FC, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { purgeSelectVisuals, selectVisuals } from 'redux/Individual/Visual/SelectVisuals'
import { useGalleryAdditionalVisuals, useGalleryAssignment, useGalleryVisualSelection, useGalleryVisualType } from 'components/pages/Gallery/_main/contexts'

import { StripeError } from '@stripe/stripe-js'
import { UpsellPayment } from 'components/pages/Gallery/common'
import { ZERO_DECIMAL } from 'constants/misc'
import { decimalFromBig } from 'utils/price'
import { listVisuals } from 'redux/Individual/Visual/ListVisuals'
import { useClientGallery } from 'components/pages/Gallery/_main/ClientGallery/_main'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'components/contexts/SnackbarService.context'

/**
 * Gallery upsell payment component.
 * @example <GalleryUpsellPayment />
 */
export const GalleryUpsellPayment: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['gallery'])
  const { product } = useGalleryAssignment()
  const { assignmentId } = useClientGallery()
  const { spawnSuccessToast } = useSnackbar()
  const {
    selected,
    selectVisualsRequest,
  } = useGalleryVisualSelection()
  const {
    normalizedOriginalType,
    transformTypes,
  } = useGalleryVisualType()
  const {
    currency,
    selectedAdditionalVisualsCount,
    totalForAdditionalVisualsAfterDiscount,
    additionalVisualProductId,
    isAdditionalVisualsPurchaseModalOpen,
    setIsAdditionalVisualsPurchaseModalOpen,
  } = useGalleryAdditionalVisuals()

  // Relist visuals if stripe payment fails and user leaves purchase modal without finishing the payment
  const handleAfterAdditionalPurchaseModalClose = useCallback((stripeError: StripeError | null) => {
    if (stripeError) {
      dispatch(listVisuals(assignmentId, normalizedOriginalType))
    }

    dispatch(purgeSelectVisuals(assignmentId))
  }, [dispatch, assignmentId, normalizedOriginalType])


  return (
    <UpsellPayment
      isOpen={isAdditionalVisualsPurchaseModalOpen}
      assignmentId={assignmentId}
      productId={additionalVisualProductId}
      productQuantity={selectedAdditionalVisualsCount}
      total={!!currency ? { value: decimalFromBig(totalForAdditionalVisualsAfterDiscount), currency, discountPercentage: ZERO_DECIMAL } : null}
      beforeConfirmAction={(paymentIntentId, billingProductId) => product?.id && dispatch(selectVisuals(assignmentId, transformTypes(), Array.from(selected), normalizedOriginalType, product.id, billingProductId, paymentIntentId))}
      beforeConfirmActionState={selectVisualsRequest?.state}
      beforeConfirmationActionRequest={selectVisualsRequest}
      onClose={() => setIsAdditionalVisualsPurchaseModalOpen(false)}
      afterClosed={handleAfterAdditionalPurchaseModalClose}
      onSuccess={() => {
        dispatch(listVisuals(assignmentId, normalizedOriginalType))
        spawnSuccessToast(t('visuals_selected_success'))
      }}
    >
      <Trans t={t} i18nKey="purchase_more_visuals_prompt">
        <span></span>
      </Trans>
    </UpsellPayment>
  )
}
