import { CreativeProductInstruction, chooseProductContainingVisuals } from 'utils/product'
import { FC, Fragment } from 'react'
import { Stack, Typography } from '@mui/material'
import { applyFeeDiscount, formatPrice } from 'utils/price'

import { AssignmentDTOCreative } from 'models/assignment'
import { AssignmentStage } from 'constants/assignment'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { GRAY_700 } from 'constants/styling/theme'
import { MUIBadge } from 'components/common/MUIBadge'
import { MUIButton } from 'components/common/MUIButton'
import { OpenInNew } from '@mui/icons-material'
import { Path } from 'constants/router'
import { externalNavigate } from 'utils/helpers'
import { useTranslation } from 'react-i18next'

const stagesUnlockingUpload = new Set([
  AssignmentStage.CREATIVE_BOOKED,
])

const stagesUnlockingGalleryLink = new Set([
  AssignmentStage.VISUALS_SENT_BY_CT_TO_BKBN,
  AssignmentStage.VISUALS_SENT_BY_BKBN_TO_EDITOR,
  AssignmentStage.VISUALS_SENT_BY_EDITOR_TO_BKBN,
  AssignmentStage.VISUALS_SENT_TO_CLIENT,
])

interface Props {
  assignmentData: AssignmentDTOCreative
}

export const AssignmentListItem: FC<Props> = ({ assignmentData }) => {
  const { t } = useTranslation(['deal_assignment_card', 'deal_assignment', 'time_translations', 'category', 'product', 'common'])

  const product = chooseProductContainingVisuals(assignmentData.products)

  return (
    <BorderBoxWrapper p={1.7} elevation="sm">
      <Stack direction="row" gap={2} width="100%">

        <Stack flex="1 1 auto" alignItems="flex-start" gap={.5}>

          <MUIBadge label={`#${assignmentData.id}`} color="gray" size="xs" sx={{ mb: .5 }} />

          {!!product &&
            <Typography variant="h4">
              {t(`product:p_${product?.id}`)}
            </Typography>
          }

          {!product &&
            <Typography variant="h4">
              {assignmentData.products.map(prod => {
                const productName = t(`product:p_${prod.id}`)
                return prod.quantity > 1 ? `${prod.quantity} x ${productName}` : productName
              }).join(', ') || t('common:not_applicable')}
            </Typography>
          }

          <Typography variant="text-sm" color={GRAY_700}>

            {!!product &&
              <CreativeProductInstruction
                category={product.category}
                type={product.type}
                kind={product.kind}
                attributeX={assignmentData.creativeInstructionAttributeX}
                attributeY={assignmentData.creativeInstructionAttributeY}
                count={product.quantity}
                segment={product.segments[0]}
              />
            }

            {!product &&
              <Fragment>
                {assignmentData.products.map(prod => {
                  const productName = t(`product:p_${prod.id}`)
                  return prod.quantity > 1 ? `${prod.quantity} x ${productName}` : productName
                }).join(', ') || t('common:not_applicable')}
              </Fragment>
            }

          </Typography>

        </Stack>

        <Stack flex="0 0 auto" alignItems="flex-end" justifyContent="space-between" gap={1}>

          <Typography variant="h4">
            {formatPrice(applyFeeDiscount(assignmentData.creativeRemuneration.baseRemuneration))}
          </Typography>

          {!stagesUnlockingUpload.has(assignmentData.stage) && !stagesUnlockingGalleryLink.has(assignmentData.stage) &&
            <MUIButton
              type="secondaryBorder"
              startIcon={<OpenInNew fontSize="large" />}
              onClick={() => externalNavigate(assignmentData.creativeInstructionsUrl)}
            >
              {t('deal_assignment:follow_instruction_button')}
            </MUIButton>
          }

          {stagesUnlockingUpload.has(assignmentData.stage) &&
            <MUIButton
              type="secondaryBorder"
              // Will be connected to new wizard upload flow
              onClick={() => externalNavigate(Path.GALLERY.replace(':id', assignmentData.id))}
            >
              {t('upload_visuals')}
            </MUIButton>
          }

          {stagesUnlockingGalleryLink.has(assignmentData.stage) &&
            <MUIButton
              type="secondaryBorder"
              startIcon={<OpenInNew fontSize="large" />}
              onClick={() => externalNavigate(Path.GALLERY.replace(':id', assignmentData.id))}
            >
              {t('see_visuals')}
            </MUIButton>
          }

        </Stack>

      </Stack>
    </BorderBoxWrapper>
  )
}
