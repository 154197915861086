import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { Box, Stack, Typography, useMediaQuery } from '@mui/material'
import { FC, useEffect, useMemo } from 'react'
import { GRAY_300, GRAY_900, SangBleuFontFamily } from 'constants/styling/theme'
import { Trans, useTranslation } from 'react-i18next'
import { useAcceptWorkspaceInvitation, useRevokeWorkspaceInvitation } from 'dataQueries'

import { MUIButton } from 'components/common/MUIButton'
import { MUIDivider } from 'components/common/MUIDivider'
import { Path } from 'constants/router'
import { QueryStatus } from 'components/common/QueryStatus'
import { externalNavigate } from 'utils/helpers'
import { redesignMUITheme } from 'constants/styling/redesignTheme'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { useUserData } from 'components/contexts/UserDataContext'
import { useWorkspacesProfileContext } from '../_main/WorkspacesProfile.context'
import { userMe } from 'redux/Individual/User/UserMe'

/**
 * @component Workspace invitation card
 * @example <WorkspaceInvitationCard />
 */

export const WorkspaceInvitationCard: FC = () => {
  const { t } = useTranslation(['workspace_profile'])
  const { clientData } = useUserData()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isMobileView = useMediaQuery(redesignMUITheme.breakpoints.down('sm'))

  const revokeInvitation = useRevokeWorkspaceInvitation()
  const acceptInvitation = useAcceptWorkspaceInvitation()

  const {
    workspaceId,
    workspaceData,
    userWorkspaceInvitedBy,
    userWorkspaceInvitedData
  } = useWorkspacesProfileContext()

  const inviteMemberName = useMemo(() => userWorkspaceInvitedBy ? userWorkspaceInvitedBy.name : t('deleted_user'), [t, userWorkspaceInvitedBy])

  useEffect(() => {
    if (revokeInvitation.isSuccess) {
      dispatch(userMe())
      navigate(Path.INDEX)
    }
  }, [dispatch, navigate, revokeInvitation.isSuccess])

  useEffect(() => {
    if (acceptInvitation.isSuccess) {
      dispatch(userMe())
    }
  }, [dispatch, acceptInvitation.isSuccess])

  if (!userWorkspaceInvitedData) return <></>

  return (
    <Stack
      className="workspaceMemberCard"
      boxSizing="border-box"
      gap="3rem"
      justifyContent="center"
      alignItems="center"
      margin="5rem auto"
      width={isMobileView ? '100%' : '42rem'}
      sx={{
        padding: isMobileView ? '2rem' : '5rem',
        border: `.1rem solid ${GRAY_300}`
      }}
    >

      <Typography variant="h6" fontWeight={400} fontFamily={SangBleuFontFamily} textAlign="center">{t('invitation_card_title')}</Typography>

      <Typography variant="text-md" color={GRAY_900} textAlign="center">
        <Trans
          t={t}
          i18nKey="invitation_card_content"
          values={{ member: inviteMemberName, workspace: workspaceData?.name }}
        />
      </Typography>

      <Stack gap="1.5rem">

        <Stack flexDirection="row" justifyContent="center" alignItems="center" gap=".8rem">
          <MUIButton
            type="secondaryBorder"
            variant="outlined"
            onClick={() => revokeInvitation.mutate({ workspaceId, invitationId: userWorkspaceInvitedData.id })}
          >
            {t('decline_invitation')}
          </MUIButton>

          <MUIButton
            isLoading={acceptInvitation.isPending}
            onClick={() => acceptInvitation.mutate({ workspaceId, invitationId: userWorkspaceInvitedData.id })}
          >
            {t('accept_invitation')}
          </MUIButton>
        </Stack>

        <Stack>
          <QueryStatus query={acceptInvitation} />
          <QueryStatus query={revokeInvitation} />
        </Stack>

      </Stack>


      <Box width="100%">
        <MUIDivider margin={12}><Typography>{t('or_divider')}</Typography></MUIDivider>
      </Box>

      <Typography variant="text-md" color={GRAY_900} textAlign="center">{t('invitation_card_note')}</Typography>

      <MUIButton
        onClick={() => {
          logAnalyticsEvent(AnalyticsEvent.MEMBER_INVITATION_UPSELL, {
            userEmail: clientData?.email,
            organizationId: clientData?.organizationId,
          })
          externalNavigate(Path.PRICING)
        }}
      >
        {t('create_plan')}
      </MUIButton>

    </Stack>
  )
}
