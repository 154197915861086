import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useGalleryAssignment, useGalleryProduct } from '../../contexts'

import { AssignmentDocumentsEndpoint } from 'constants/API'
import { ClientGalleryCreateRequestDrawer } from 'components/pages/Client/ClientGallery/ClientGalleryCreateRequestDrawer'
import { ClientGalleryDocumentTypeFilter } from '../ClientGalleryOrderTypeFilter'
import { DocumentUploadType } from 'constants/documents'
import { Options } from 'components/common/FileAPI'
import { RequestThread } from 'components/common/Threads'
import { Stack } from '@mui/material'
import { useClientGallery } from '../_main/contexts'
import { useGetAssignmentMessageThreads } from 'dataQueries/messageThreads'
import { useThreads } from 'components/common/Threads/context'
import { useTranslation } from 'react-i18next'

/**
 * Client gallery requests content to display the threads for each assignment.
 *
 * @example <ClientGalleryRequests />
 */
export const ClientGalleryRequests: FC = () => {

  const { t } = useTranslation(['threads'])

  const { assignmentId } = useClientGallery()
  const { setHeaderButtonText, setAllowUploadOfDocuments } = useThreads()
  const { isEnergyCertificateProduct, isAuthoritiesDocumentsProduct } = useGalleryProduct()
  const { allowClientToCreateThreads } = useGalleryAssignment()

  const [isCreateRequestDrawerOpen, setIsCreateRequestDrawerOpen] = useState(false)

  const currentAssignmentThreads = useGetAssignmentMessageThreads(assignmentId)

  const assignmentThreadsData = useMemo(() => currentAssignmentThreads.data?.data, [currentAssignmentThreads?.data])
  const allowCreateRequestEnergyCertificate = useMemo(() => isEnergyCertificateProduct && assignmentThreadsData?.length === 0, [isEnergyCertificateProduct, assignmentThreadsData])

  const fileAPIOptions: Options = {
    uploadUrlResolver: (fileEntry, api) => {
      return api.post(
        AssignmentDocumentsEndpoint.GET_UPLOAD_URL,
        { assignmentId },
        {
          filename: fileEntry.fileObject.name,
          contentType: fileEntry.fileObject.type,
          type: DocumentUploadType.INPUT,
        },
        true
      )
    },
    deleteHandler: (fileEntry, api) => api.delete(
      AssignmentDocumentsEndpoint.DELETE_DOCUMENT,
      { assignmentId },
      true,
      {
        params: {
          type: DocumentUploadType.INPUT,
          filename: fileEntry.gcFilename,
        }
      }
    )
  }

  const handleOnCreateRequestThread = useCallback(() => {
    if (allowCreateRequestEnergyCertificate || isAuthoritiesDocumentsProduct) {
      setIsCreateRequestDrawerOpen(true)
    }
  }, [allowCreateRequestEnergyCertificate, isAuthoritiesDocumentsProduct])

  const handleCloseCreateRequestDrawer = useCallback((refetchThreads?: boolean) => {
    setIsCreateRequestDrawerOpen(false)
    if (refetchThreads) currentAssignmentThreads.refetch()
  }, [currentAssignmentThreads])

  useEffect(() => {
    // Energy certificate product validations to allow the client to create threads.
    if (allowCreateRequestEnergyCertificate && allowClientToCreateThreads) {
      setHeaderButtonText(t('create_thread.title'))
    }

    // Authority documents product validations to allow the client to create threads.
    if (isAuthoritiesDocumentsProduct && allowClientToCreateThreads) {
      setHeaderButtonText(t('create_thread.title'))
    }

    if (isEnergyCertificateProduct) {
      setAllowUploadOfDocuments(false)
    }
    // No need to add isEnergyCertificateProduct to the dependencies array as it's included in allowCreateRequestEnergyCertificate
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowCreateRequestEnergyCertificate])

  return (
    <Stack width="100%">

      <ClientGalleryDocumentTypeFilter />

      {/** Content changes according to the selected assignment from the tabs. */}
      <Stack width={{ sx: '100%', md: '80%' }} marginTop="3rem">

        <RequestThread
          assignmentId={assignmentId}
          title={t('request_supplier_title')}
          threadMessageItems={assignmentThreadsData}
          fileUploadOptions={fileAPIOptions}
          onHeaderButtonClick={handleOnCreateRequestThread}
        />

      </Stack>

      <ClientGalleryCreateRequestDrawer
        isOpen={isCreateRequestDrawerOpen}
        onClose={handleCloseCreateRequestDrawer}
        allowUploadOfDocuments={!isEnergyCertificateProduct}
      />

    </Stack>
  )
}
