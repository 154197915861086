import axios, { AxiosProgressEvent, AxiosResponse } from 'axios'
import { FileAction, FileState } from './fileAPI.types'

import { captureException } from '@sentry/react'
import { wrap } from 'comlink'
import { Nullable } from 'models/helpers'
import { SignedURLDTO } from 'models/visuals'
import { loadVisualWorkerType } from 'workers/loadFileWorker'

export enum UploadType {
  STANDARD = 'STANDARD',
  RESUMABLE = 'RESUMABLE',
}

export const FileAvailableActions = new Set([
  FileAction.UPLOAD,
  FileAction.RE_UPLOAD,
  FileAction.LOAD,
])

export const FileUnavailableActions = new Set([
  FileAction.DELETE,
  FileAction.INIT,
])

export const FinishedFileStates = new Set([
  FileState.CANCELLED,
  FileState.ERROR,
  FileState.SUCCESS,
])

interface ContentRange {
  start: number
  end: number
  totalSize: number
}

interface UploadChunkParams {
  uri: SignedURLDTO
  chunk: Blob
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void
  abortSignal: AbortSignal
  contentRange: Nullable<ContentRange>
}

// -- PREP FOR RESUMABLE IMPLEMENTATION --
const contentRangeRegex = /bytes (\d+)-(\d+)\/(\d+)/

function _parseContentRange(contentRange: string): ContentRange {
  const match = contentRange.match(
    contentRangeRegex
  )
  if (!match) throw new Error('Invalid Content-Range header')

  const [, start, end, totalSize] = match
  return {
    start: parseInt(start),
    end: parseInt(end),
    totalSize: parseInt(totalSize),
  }
}

async function _getRange(uploadURI: string, file: File, chunkResult: AxiosResponse, ranges: [number, number][]) {
  await axios.put(uploadURI, null, {
    headers: {
      'Content-Length': '0',
      'Content-Range': `bytes */${file.size}`,
    }
  })
  const contentRangeHeader = chunkResult.request.response?.headers['Content-Range'] as string | undefined
  if (!contentRangeHeader) throw new Error('Content-Range header not found')
  return _parseContentRange(contentRangeHeader)
}


function _uploadChunk(params: UploadChunkParams) {
  const { abortSignal, onUploadProgress, uri, chunk } = params
  const contentRangeHeader = params.contentRange ? {
    'Content-Range': `bytes ${params.contentRange.start}-${params.contentRange.end}/${params.contentRange.totalSize}`,
  } : {}
  return axios.put(
    uri.signedURL,
    chunk,
    {
      signal: abortSignal,
      onUploadProgress,
      headers: {
        'Content-Type': chunk.type,
        ...contentRangeHeader,
        ...uri.headers,
      },
    }
  )
}

/** Axios call to signedUrl with resumable chunked upload and progress updates */
export const uploadToGCResumable = async (
  file: File,
  signedUrl: SignedURLDTO,
  abortSignal: AbortSignal,
  onProgress: (percentCompleted: number) => void,
  onSuccess: () => void,
  onCancel: () => void,
  onError: () => void,
  maxFailures = 10
) => {

  // Set up variables for chunking and upload
  let failures = 0
  let uploaded = 0

  // Initially send file as one chunk
  const ranges: [number, number][] = [[0, file.size]]

  // Initiate resumable upload
  const { headers, signedURL } = signedUrl
  const result = await axios.post(signedURL, null, {
    headers: {
      ...headers,
      'Content-Type': file.type,
      'X-Upload-Content-Type': file.type,
    }
  })
  const uploadURI: string = result.headers.location

  const handleProgress = (progressEvent: AxiosProgressEvent) => {
    uploaded += progressEvent.bytes

    const percentCompleted = Math.round(
      (uploaded * 100) / (progressEvent.total || 1)
    )

    onProgress(percentCompleted)
  }

  const handleError = async (response: AxiosResponse) => {
    const status = response.status

    captureException('FileAPI Resumable upload failure captured', {
      extra: {
        caughtError: response,
        currentFailureCount: failures,
        fileSize: file.size,
      }
    })

    if (status && (status === 500 || status === 503)) {
      let range: ContentRange | null = null

      try {
        range = await _getRange(uploadURI, file, response, ranges)
      } catch (e) {
        captureException('FileAPI Resumable upload failure - getRange failed', {
          extra: {
            caughtError: e,
            currentFailureCount: failures,
            fileSize: file.size,
          }
        })
      }

      if (!range) return

      captureException('FileAPI Resumable upload failure - new chunk ranges', {
        extra: {
          caughtError: response,
          currentFailureCount: failures,
          range,
          willPush: range.end < file.size,
          fileSize: file.size,
        }
      })

      if (range.end < file.size) {
        ranges.push([range.end, file.size])
      }
      failures++
    }
  }

  // Start uploading initial chunk and keep uploading chunks created by errors
  // until all chunks are uploaded or failure limit is reached
  while (ranges.length > 0 && failures < maxFailures) {
    const [start, end] = ranges.shift()!
    const chunk = file.slice(start, end, file.type)

    await _uploadChunk({
      uri: { signedURL: uploadURI, headers: {}, filename: signedUrl.filename },
      contentRange: {
        start,
        end: end - 1,
        totalSize: file.size
      },
      chunk,
      abortSignal,
      onUploadProgress: (progressEvent) => handleProgress(progressEvent),
    })
      .catch(async (e) => {
        if (axios.isCancel(e)) {
          onCancel()
          return
        } else {
          await handleError(e.response)
        }
      })
  }

  // Failed
  if (failures >= maxFailures) {
    onError()
    return
  }

  onSuccess()
}

/** Axios call to signedUrl with progress updates */
export const uploadToGC = async (
  file: File,
  signedUrl: SignedURLDTO,
  abortSignal: AbortSignal,
  onProgress: (percentCompleted: number) => void,
  onSuccess: () => void,
  onCancel: () => void,
  onError: () => void
) => {
  return _uploadChunk({
    uri: signedUrl,
    chunk: file,
    abortSignal,
    contentRange: null,
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / (progressEvent.total || 1)
      )

      onProgress(percentCompleted)
    },
  })
    .then(() => onSuccess())
    .catch((e) => {
      if (axios.isCancel(e)) {
        onCancel()
      } else {
        onError()
      }
    })
}

// Initiates workers for visual loading
export const startLoadWorker = (() => {

  const workerCount = process.env.NODE_ENV === 'test'
    ? 0
    : navigator.hardwareConcurrency || 4

  const workerPool = [...Array(workerCount).keys()]
    .map(() => {
      const worker = new Worker(
        new URL('workers/loadFileWorker', import.meta.url),
        {
          type: 'module',
        }
      )
      return wrap<loadVisualWorkerType>(worker)
    })

  let i = 0

  /** Calls loadVisualWorker */
  return (loadUrl: string) => {
    const result = workerPool[i](loadUrl)
    i = ++i % workerCount
    return result
  }
})()
