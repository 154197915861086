import { GRAY_800, GRAY_900 } from 'constants/styling/theme'

import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import IconButton from '@mui/material/IconButton'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import React from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

/** Enum for the download dropdown items, values matches the translation keys */
export enum DownloadDropdownItem {
  DIRECT_DOWNLOAD = 'direct_download',
  EXPORTING_OPTIONS = 'exporting_options',
}

interface Props {
  /** Callback when download item is clicked */
  onDownloadItemClick?: (itemKey: DownloadDropdownItem) => void
}

/**
 * `DownloadDropdownIcon` renders a dropdown menu for download-related actions.
 *
 * @example
 * <DownloadDropdownIcon />
 */
export const DownloadDropdownIcon: React.FC<Props> = ({ onDownloadItemClick }) => {

  const { t } = useTranslation(['gallery'])

  return (
    <MUIDropdown
      button={(_, action) => (
        <IconButton
          onClick={action}
          aria-label="download-dropwdown"
          size="small"
        >
          <CloudDownloadOutlinedIcon fontSize="small" sx={{ color: GRAY_800 }} />
        </IconButton>
      )}
    >
      {Object.values(DownloadDropdownItem).map((item) => (
        <MUIDropdownItem key={item} onClick={() => onDownloadItemClick?.(item)}>
          <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
            {t(item)}
          </Typography>
        </MUIDropdownItem>
      ))}
    </MUIDropdown>
  )
}
