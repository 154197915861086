import { FC, Fragment } from 'react'

import { AssignmentDTOIsCreativeDTO } from 'utils/typeguards'
import BlockInfo from 'components/common/BlockInfo/BlockInfo'
import Button from 'components/common/Button/Button'
import { CreativeAssignmentListCard } from 'components/common/DealAssignmentCard/CreativeAssignmentListCard'
import { Link } from 'react-router-dom'
import NotFound from 'components/pages/General/NotFound/NotFound'
import { Path } from 'constants/router'
import { isAssignmentAvailable } from 'utils/stages'
import styles from './AvailableAssignment.module.sass'
import { useAuth0 } from 'utils/auth'
import { useGetAssignment } from 'dataQueries'
import { useTranslation } from 'react-i18next'

export const AvailableAssignmentController: FC<{ assignmentId: string }> = ({ assignmentId }) => {
  const assignmentData = useGetAssignment(assignmentId)

  const { t } = useTranslation(['available_assignment'])
  const { roles, isAuthenticated } = useAuth0()

  if (!roles.isCreative) return <NotFound />

  return (
    <div className={`page ${isAuthenticated ? 'triangle-stripe-bg' : ''}`}>
      <div className={`${styles.content} page-content`}>
        <div className={styles.assignmentWrapper}>
          <h2 className="decorated blue title">{t('title')}</h2>
          {!!assignmentData.data?.data && AssignmentDTOIsCreativeDTO(assignmentData.data.data) &&
            <Fragment>
              {isAssignmentAvailable(assignmentData.data?.data)
                ? <CreativeAssignmentListCard assignment={assignmentData.data.data} />
                : <BlockInfo>{t('already_accepted')}</BlockInfo>
              }
            </Fragment>
          }
          {!assignmentData &&
            <BlockInfo>{t('no_longer_available')}</BlockInfo>
          }
        </div>
        <Link to={Path.INDEX}>
          <Button className={styles.marginTop}>{t('go_to_dashboard')}</Button>
        </Link>
      </div>
    </div>
  )
}
