import { FileAPIEntry, FileAction, useFileAPI } from 'components/common/FileAPI'
import { SignedURLDTO, UrlDTO } from 'models/visuals'
import { useCallback, useEffect, useMemo } from 'react'
import { useGalleryVisualType, useGalleryVisualsMeta } from '../_main/contexts'

import { UploadType } from 'components/common/FileAPI/fileUtils'
import { VisualFileType } from 'constants/visual'
import constate from 'constate'
import { useGetAssignmentVisuals } from 'dataQueries'
import { Nullable } from 'models/helpers'
import { UseAPIReturn } from 'utils/API'
import { VisualDTOIsClientDTO } from 'utils/typeguards'

/** Interface of possible metadata of gallery visuals */
export interface GalleryVisualMetadata {
  /** Whether visual has been purchased */
  isPurchased: boolean | undefined
  /** Whether visual has been favourited */
  isFavourited: boolean | undefined
  /** Link to external floor plan editing tool */
  floorPlannerLink: Nullable<string>
}

const getTypedId = (visualType: string, filename: string) => `${visualType}_${filename.substring(0, filename.lastIndexOf('.')) || filename}` // Safeguard for removing extension

const loadVisualsUrlResolver = (assignmentId: string, visualType: VisualFileType, fileEntry: FileAPIEntry<GalleryVisualMetadata>, api: UseAPIReturn<string>) => {
  return api.post<UrlDTO>(
    '/assignment/{assignmentId}/visual/{filename}',
    {
      assignmentId,
      filename: fileEntry.gcFilename!,
    },
    {},
    true,
    {
      params: {
        type: visualType,
      }
    }
  )
}

const uploadVisualsUrlResolver = (assignmentId: string, visualType: VisualFileType, fileEntry: FileAPIEntry<GalleryVisualMetadata>, api: UseAPIReturn<string>) => {
  return api.post<SignedURLDTO>(
    '/assignment/{assignmentId}/visual/uploadUrl',
    { assignmentId },
    {
      filename: fileEntry.fileObject.name,
      contentType: fileEntry.fileObject.type,
      type: visualType,
    },
    true,
    {
      params: {
        uploadType: UploadType.RESUMABLE,
      },
    }
  )
}

export const [FileAPIGalleryVisualsProvider, useFileAPIGalleryVisuals] = constate(({ assignmentId }: { assignmentId: string }) => {

  const { visualTypeSelect, webType, normalizedOriginalType } = useGalleryVisualType()
  const { purchasedVisualsKeys } = useGalleryVisualsMeta()

  // -- FILE CONTROLLER DEFINITION
  const visualController = useFileAPI<GalleryVisualMetadata>(`GALLERY_VISUALS:${assignmentId}`)

  const loadVisuals = useCallback((files: Array<string>, visualType: VisualFileType) => {
    visualController.loadFiles(files, {
      loadUrlResolverFnc: (fileEntry, api) => loadVisualsUrlResolver(assignmentId, visualType, fileEntry, api)
    })
  }, [assignmentId, visualController])

  const uploadVisuals = useCallback((files: FileList | Array<File>) => {
    visualController.uploadFiles(files, {
      tag: visualTypeSelect,
      uploadUrlResolverFnc: (fileEntry, api) => uploadVisualsUrlResolver(assignmentId, normalizedOriginalType, fileEntry, api),
      onSettled: (successIds) => {
        visualController.loadFiles(successIds, {
          loadUrlResolverFnc: (fileEntry, api) => loadVisualsUrlResolver(assignmentId, webType, fileEntry, api)
        })
      },
      fileIdCreatorFnc: (fileEntry) => getTypedId(visualTypeSelect, fileEntry.gcFilename!)
    })
  }, [assignmentId, normalizedOriginalType, visualController, visualTypeSelect, webType])

  // -- VISUAL DATA FROM ASSIGNMENT
  const assignmentVisuals = useGetAssignmentVisuals(assignmentId, webType)

  const assignmentVisualsData = useMemo(() => {

    return assignmentVisuals?.data?.data
  }, [assignmentVisuals.data?.data])

  const allVisuals = useMemo(() => assignmentVisualsData?.visuals ?? [], [assignmentVisualsData?.visuals])

  const currentlyVisibleVisuals = useMemo(() => visualController.filesByTag[visualTypeSelect] ?? [], [visualController.filesByTag, visualTypeSelect])

  // Input visual entries into FileAPI
  useEffect(() => {
    if (!allVisuals?.length) return

    visualController.mergeAddFiles((allVisuals).map((visual) => ({
      id: getTypedId(visualTypeSelect, visual.name),
      originalFilename: visual.originalName,
      tag: visualTypeSelect,
      gcFilename: visual.name,
      metadata: {
        isFavourited: VisualDTOIsClientDTO(visual) ? visual.favorited : undefined,
        isPurchased: purchasedVisualsKeys.has(visual.name),
        floorPlannerLink: VisualDTOIsClientDTO(visual) ? visual.floorPlanSelfEditUrl : undefined,
      },
    })))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allVisuals, purchasedVisualsKeys, visualTypeSelect])

  // Automatically load all visuals that are not yet loaded and should be currently visible
  useEffect(() => {
    const filesToLoad = currentlyVisibleVisuals.filter((file) => !file.displayUrl && file.action === FileAction.INIT).map(file => file.id)

    if (!filesToLoad.length) return

    loadVisuals(filesToLoad, webType)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlyVisibleVisuals, webType])

  // -- COMPUTED VALUES

  const computedVisualValues = useMemo(() => {

    const purchasedVisuals: Set<string> = new Set([])
    const unpurchasedVisuals: Set<string> = new Set([])
    const favouritedVisuals: Set<string> = new Set([])
    const favouritedPurchasedVisuals: Set<string> = new Set([])
    const favouritedUnpurchasedVisuals: Set<string> = new Set([])

    for (const file of visualController.allFilesArray) {
      if (file.metadata.isPurchased) {
        purchasedVisuals.add(file.id)
      }
      else {
        unpurchasedVisuals.add(file.id)
      }

      if (file.metadata.isFavourited) {
        favouritedVisuals.add(file.id)

        if (file.metadata.isPurchased) favouritedPurchasedVisuals.add(file.id)
        else favouritedUnpurchasedVisuals.add(file.id)
      }

    }

    return {
      purchasedVisuals,
      unpurchasedVisuals,
      favouritedVisuals,
      favouritedPurchasedVisuals,
      favouritedUnpurchasedVisuals,
    }
  }, [visualController.allFilesArray])

  return {
    ...visualController,
    ...computedVisualValues,
    assignmentVisualsQuery: assignmentVisuals,
    assignmentVisualsData,
    purchasedVisualsExist: computedVisualValues.purchasedVisuals.size > 0,
    currentlyVisibleVisuals,
    uploadVisuals,
  }
})
