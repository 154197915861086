import { Box, Slide, Stack } from '@mui/material'
import { FC, Fragment, ReactNode } from 'react'
import { GRAY_1000, MOBILE_VIEW_QUERY, WHITE } from 'constants/styling/theme'

import { MUIButton } from 'components/common/MUIButton'
import { MUIDivider } from 'components/common/MUIDivider'
import classNames from 'classnames'
import styles from './GalleryFloatingToolBar.module.sass'
import useMediaQuery from '@mui/material/useMediaQuery'

interface Props {
  /** Override or extend the styles applied to the component. */
  className?: string
  /** Whether the tool bar is open or not */
  isToolBarOpen: boolean
  /** The label in tool bar. */
  toolBarLabel?: ReactNode
  /** Small floating button for opening the tool bar in mobile version. */
  openToolBarButtonLabel?: string
  /** OnClick action to open mobile tool bar */
  onOpenToolBar?: () => void
  /** Buttons or actions container */
  contentActionContainer?: ReactNode
  /** Buttons or actions container for further steps */
  outputActionContainer?: ReactNode
  /** Wider version of toolbar to fit conditionally needed items */
  isWide: boolean
  /** Bg color of the tool bar (optional, default = GRAY_1000) */
  bgColor?: string
}

/**
 * Gallery floating tool bar component.
 * @example <GalleryFloatingToolBar isToolBarOpen={true} toolBarLabel="10 selected" />
 */
export const GalleryFloatingToolBar: FC<Props> = ({
  className,
  isToolBarOpen = false,
  toolBarLabel,
  openToolBarButtonLabel,
  onOpenToolBar,
  contentActionContainer,
  outputActionContainer,
  bgColor = GRAY_1000,
  isWide = false,
}) => {
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  return (
    <Fragment>
      {isMobileView ?
        <Fragment>
          <Slide direction="up" in={!isToolBarOpen} mountOnEnter unmountOnExit>
            <Stack
              width="100vw"
              position="fixed"
              boxSizing="border-box"
              bottom={0}
              left={0}
              flexDirection="row"
              justifyContent="center"
              padding="1.2rem 2rem"
            >
              {!!openToolBarButtonLabel &&
                <MUIButton
                  type='defaultPrimary'
                  onClick={onOpenToolBar && onOpenToolBar}
                >
                  {openToolBarButtonLabel}
                </MUIButton>
              }
            </Stack>
          </Slide>

          <Slide direction="up" in={isToolBarOpen} mountOnEnter unmountOnExit>
            <Stack
              className={classNames(styles.galleryFloatingToolBar, className)}
              width="100vw"
              position="fixed"
              boxSizing="border-box"
              bottom={0}
              left={0}
              bgcolor={bgColor}
              borderRadius="2rem 2rem 0rem 0rem"
              padding="1.2rem 2rem"
            >

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {!!toolBarLabel &&
                  <Box
                    padding="0 1.2rem"
                    fontSize="1.4rem"
                    fontWeight={600}
                    color={WHITE}
                    paddingBottom=".3rem"
                  >
                    {toolBarLabel}
                  </Box>
                }
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {contentActionContainer}
                </Stack>
              </Stack>

              <MUIDivider className={styles.divider} margin={12} />

              <Stack
                spacing={2}
                direction="row"
                justifyContent="space-between"
                sx={{
                  'span': { width: '100%' },
                  '& button': { width: '100%' }
                }}
              >
                {outputActionContainer}
              </Stack>

            </Stack>
          </Slide>
        </Fragment>
        :
        <Slide direction="up" in={isToolBarOpen} mountOnEnter unmountOnExit>
          <Stack
            className={classNames(styles.galleryFloatingToolBar, className)}
            width="100vw"
            position="fixed"
            boxSizing="border-box"
            bottom="1.6rem"
            left={0}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              width={isWide ? '85rem' : '75rem'}
              padding=".4rem .4rem"
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderRadius="4.8rem"
              bgcolor={bgColor}
              fontSize="1.4rem"
              fontWeight={600}
              color={WHITE}
              boxSizing="border-box"
            >

              {!!toolBarLabel &&
                <Box
                  padding="0 1.2rem"
                  fontSize="1.4rem"
                  fontWeight={600}
                  color={WHITE}
                >
                  {toolBarLabel}
                </Box>
              }

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                height="100%"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  height="100%"
                  fontWeight={600}
                >
                  {contentActionContainer}
                </Stack>

                <MUIDivider className={classNames(styles.divider, styles.vertical)} margin={12} orientation='vertical' />

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  height="100%"
                  fontWeight={600}
                  gap=".8rem"
                >
                  {outputActionContainer}
                </Stack>
              </Stack>

            </Stack>
          </Stack>
        </Slide>
      }
    </Fragment>
  )
}
