import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AnalyticsEvent } from 'utils/analytics'
import { Path } from 'constants/router'
import constate from 'constate'
import { useGalleryAssignment } from './GalleryAssignment.context'

export const ORDER_INFO_PATH = 'order-info'

export enum GalleryTab {
  GALLERY = 'gallery',
  ORDER_INFO = 'order_info',
  REQUESTS = 'requests',
  DOCUMENTS = 'documents',
}

/** Context provider for gallery navigation tabs */
export const [GalleryNavigationContextProvider, useGalleryNavigation] = constate(() => {
  const navigate = useNavigate()

  const { id, tab } = useParams<{ id: string, tab?: string }>()
  const { logGalleryEvent } = useGalleryAssignment()

  const [activeTab, setActiveTab] = useState<GalleryTab>(tab && tab in GalleryTab ? GalleryTab[tab as keyof typeof GalleryTab] : GalleryTab.GALLERY)

  const goToPath = (selectedTab: GalleryTab) => {
    if (!id) return

    if (Object.values(GalleryTab).includes(selectedTab)) navigate(Path.GALLERY_INFO.replace(':id', id.toString()).replace(':tab', selectedTab))
    else {
      navigate(Path.GALLERY.replace(':id', id.toString()))
      setActiveTab(GalleryTab.GALLERY)
    }
  }

  const handleChangeTab = (selectedTab: GalleryTab) => {
    setActiveTab(selectedTab)
    goToPath(selectedTab)

    let eventName = AnalyticsEvent.CLICK_GALLERY_TAB

    switch (selectedTab) {
      case GalleryTab.ORDER_INFO:
        eventName = AnalyticsEvent.CLICK_ORDER_INFO_TAB
        break
      default:
        eventName = AnalyticsEvent.CLICK_GALLERY_TAB
        break
    }

    if (eventName) {
      logGalleryEvent(eventName)
    }
  }

  useEffect(() => {
    setActiveTab(tab as GalleryTab)
    goToPath(tab as GalleryTab)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    handleChangeTab,
    setActiveTab,
    activeTab,
  }
})
