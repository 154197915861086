import { OldVirtualRemoveOptions, VirtualFurnitureRoomRemovableOption, VirtualFurnitureRoomType } from 'constants/virtualFurniture'
import { ComputedStagingRoom, RoomStagingItem, StagingDetailsDTO, StagingRoom, StagingType } from 'models/virtualStaging'

const OLD_REMOVE_TO_NEW_REMOVE_TYPES_MAP: Record<OldVirtualRemoveOptions, VirtualFurnitureRoomRemovableOption> = {
  REMOVE_FIXTURES: VirtualFurnitureRoomRemovableOption.FIXTURES,
  REMOVE_FURNITURE: VirtualFurnitureRoomRemovableOption.FURNITURE,
  REMOVE_FIXTURES_AND_FURNITURE: VirtualFurnitureRoomRemovableOption.FIXTURES_AND_FURNITURE
}

/** Serializes room object into format expected by BE */
export const getAPIRoomItemFromStagingRoom = (room: StagingRoom | ComputedStagingRoom): StagingDetailsDTO => {
  const roomStaging: Array<RoomStagingItem> = []

  for (const roomType of room.roomTypes) {
    roomStaging.push({
      roomType,
      furnitureTemplate: room.styleCodeMap.get(roomType),
    })
  }

  let backwardsCompatibleRemoveObjects: VirtualFurnitureRoomRemovableOption = VirtualFurnitureRoomRemovableOption.NONE

  const renovationBasedRemoveOption = room.renovations.get(VirtualFurnitureRoomType.WHOLE_ROOM)

  if (renovationBasedRemoveOption !== undefined) {
    backwardsCompatibleRemoveObjects = OLD_REMOVE_TO_NEW_REMOVE_TYPES_MAP[renovationBasedRemoveOption as OldVirtualRemoveOptions]
  } else if (room.furnitureRemoval) {
    backwardsCompatibleRemoveObjects = room.furnitureRemoval
  }

  const baseReturnItemObject = {
    productId: room.productId,
    removeObjects: backwardsCompatibleRemoveObjects === VirtualFurnitureRoomRemovableOption.NONE
      ? undefined
      : backwardsCompatibleRemoveObjects,
    roomStaging,
    decorations: room.decorations.toArray(),
    comment: !!room.comment ? room.comment : null,
    type: room.type
  }

  // If client select BKBN decide, floor and wallPainting items will be in boolean value instead of specific style code string.
  if (room.type === StagingType.BKBN) {
    return {
      ...baseReturnItemObject,
      floor: !!room.renovations.get(VirtualFurnitureRoomType.FLOORING),
      wallPainting: !!room.renovations.get(VirtualFurnitureRoomType.WALL_MATERIALS),
    }
  }

  return {
    ...baseReturnItemObject,
    floor: room.renovations.get(VirtualFurnitureRoomType.FLOORING),
    wallPainting: room.renovations.get(VirtualFurnitureRoomType.WALL_MATERIALS),
  }
}
