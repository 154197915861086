import { AdressMap, useAddressMap } from 'components/common/AdressMap'
import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { Box, Stack, Typography } from '@mui/material'
import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'
import { GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { useEffect, useState } from 'react'
import { usePurchaseFlowConfig, usePurchaseFlowRealEstateProperty } from 'components/pages/PurchaseFlow/_main/contexts'

import { Country } from 'constants/country'
import { FeatureFlag } from 'utils/featureFlags'
import { MUICheckCard } from 'components/common/MUICards'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUIInputField } from 'components/common/MUIInputField'
import i18n from 'translations/i18n'
import { lookupPlaceFromAddress } from 'utils/helpers/lookUpPlaceFromAddress'
import { useFlag } from '@unleash/proxy-client-react'
import { useTranslation } from 'react-i18next'

/**
 * @component ManualAddressCard component in Address step
 * @example <ManualAddressCard />
 */
export const ManualAddressCard: React.FC = () => {
  const { t } = useTranslation(['order', 'country'])
  const allowSecondMapPin = useFlag(FeatureFlag.ALLOW_SECOND_MAP_PIN)

  const {
    updateStateFromPlace,
    address,
    markerPosition,
  } = useAddressMap()
  const { setSelectedAssignmentPlace } = usePurchaseFlowConfig()

  const {
    isAddressManually,
    setIsAddressManually,
    addressManual,
    setAddressManual,
    cityManual,
    setCityManual,
    zipCodeManual,
    setZipCodeManual,
    countryManual,
    setCountryManual
  } = usePurchaseFlowRealEstateProperty()

  const [showError, setShowError] = useState<boolean>(false)

  const handleOnInputBlur = () => {
    if (!showError) setShowError(true)

    searchManualAddress()
  }

  const searchManualAddress = async () => {
    if (addressManual && cityManual && zipCodeManual && countryManual) {

      try {
        const manualAddressValue = `${addressManual}, ${cityManual}, ${zipCodeManual}, ${countryManual}`
        const lookupPlace = await lookupPlaceFromAddress(manualAddressValue)
        updateStateFromPlace(lookupPlace)
        setSelectedAssignmentPlace(lookupPlace)
      } catch (error) {
        console.error('Error searching manual address', error)
      }

    }
  }

  useEffect(() => {

    searchManualAddress()

    // Call the searchManualAddress function when the countryManual changes, using the useEffect hook to prevent async update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryManual])

  const onManualAddressCheck = () => {
    logAnalyticsEvent(isAddressManually ? AnalyticsEvent.MANUAL_ADDRESS_UNSELECTED : AnalyticsEvent.MANUAL_ADDRESS_SELECTED, {})
    setIsAddressManually(!isAddressManually)
  }

  return (
    <Stack>

      <MUICheckCard
        checked={isAddressManually}
        onCheck={onManualAddressCheck}
        isHeaderClickable
        expandableContent={(
          <Stack gap=".4rem">

            <Typography variant="text-xs" fontWeight={400} color={GRAY_700} marginBottom="2rem">
              {t('step_address.manual_address_instructions')}
            </Typography>

            <MUIInputField
              required
              label={t('step_address.address')}
              value={addressManual}
              isError={showError && !addressManual && isAddressManually}
              onChange={(e) => setAddressManual(e.target.value)}
              onBlur={handleOnInputBlur}
            />

            <Stack gap=".8rem" flexDirection="row" justifyContent="space-between">

              <MUIInputField
                required
                label={t('step_address.city')}
                value={cityManual}
                isError={showError && !cityManual && isAddressManually}
                onChange={(e) => setCityManual(e.target.value)}
                onBlur={handleOnInputBlur}
              />

              <MUIInputField
                required
                label={t('step_address.zip_code')}
                value={zipCodeManual}
                isError={showError && !zipCodeManual && isAddressManually}
                onChange={(e) => setZipCodeManual(e.target.value)}
                onBlur={handleOnInputBlur}
              />
            </Stack>

            <MUIDropdown
              button={(isOpen, action) => (
                <MUIDropdownInput
                  readOnly
                  required
                  isError={showError && !countryManual && isAddressManually}
                  label={t('step_address.country')}
                  type={DropdownInputTypes.SELECTOR}
                  value={countryManual ? t(`country:${countryManual}`) : countryManual}
                  onClick={action}
                />
              )}
            >
              <Box height="30rem">
                {Object.values(Country)?.map(countryKey => (
                  <MUIDropdownItem key={countryKey} onClick={() => setCountryManual(countryKey)}>
                    <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                      {t(`country:${countryKey}`, countryKey)}
                    </Typography>
                  </MUIDropdownItem>
                ))}
              </Box>
            </MUIDropdown>

            {/* Second map for debugging purpose */}
            {allowSecondMapPin &&
              <Stack gap="1rem" marginTop="2rem">

                <Typography variant="text-sm" fontWeight={500} color={GRAY_900}>Second map for debugging the snapped address</Typography>

                <AdressMap
                  showSecondMapPin
                  height={'40rem'}
                  draggable={false}
                  language={i18n.language}
                />

                <Typography variant="text-sm" fontWeight={500} color={GRAY_900}>Snapped address: {address}</Typography>
                <Typography variant="text-sm" fontWeight={500} color={GRAY_900}>GEO: {markerPosition?.lat}, {markerPosition?.lng}</Typography>

              </Stack>
            }

          </Stack>
        )}
      >

        <Stack gap=".4rem">
          <Typography variant="text-md" fontWeight={600} color={GRAY_900}>{t('step_address.manually_enter_address')}</Typography>
          <Typography variant="text-xs" fontWeight={400} color={GRAY_700}>{t('step_address.manually_enter_address_note')}</Typography>
        </Stack>

      </MUICheckCard>

    </Stack >
  )
}
