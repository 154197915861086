import './VisualPinturaEditingStylesOverrides.sass'

import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { BEIGE_500, GRAY_700, GRAY_900, WHITE } from 'constants/styling/theme'
import { Box, Stack, Typography } from '@mui/material'
import { FC, useCallback, useState } from 'react'
import {
  PinturaImageState,
  PinturaNode,
  PinturaNodeOptions,
  createDefaultImageScrambler,
  findNode,
  getEditorDefaults
} from '@pqina/pintura'
import { externalNavigate, getPinturaLocale } from 'utils/helpers'

import { ActionPage } from 'components/common/ActionPage/ActionPage.component'
import { FeatureFlag } from 'utils/featureFlags'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { MUIBadge } from 'components/common/MUIBadge'
import { MUIButton } from 'components/common/MUIButton'
import { Path } from 'constants/router'
import { PinturaEditor } from '@pqina/react-pintura'
import { SimpleImage } from 'components/common/SimpleImage'
import { VisualPinturaEditingFooter } from './VisualPinturaEditingFooter.module'
import self_editing_upsell_image from 'assets/img/self-editing/self_editing_upsell_image.webp'
import { useFlag } from '@unleash/proxy-client-react'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'
import { useVisualPinturaEditing } from './VisualPinturaEditing.context'

const PINTURA_UTILS = ['crop', 'finetune', 'filter', 'annotate', 'sticker', 'fill', 'redact', 'frame', 'resize']
const PINTURA_UTILS_NON_SUBSCRIBERS = ['crop']

const PINTURA_EXPORT_BTN_ID = 'export'
const PINTURA_REVERT_BTN_ID = 'revert'

const PINTURA_EDITOR_DEFAULTS = getEditorDefaults({
  stickers: ['😎', '🎉', '😄', '👍', '👎'],
})

/**
 * Visual Pintura editing controller for individual visual selected from Gallery
 * @example <VisualPinturaEditingController />
 */
export const VisualPinturaEditingController: FC = () => {
  const { t, i18n } = useTranslation(['visual_pintura_editing'])
  const isBetaSelfEditingCard = useFlag(FeatureFlag.BETA_SELF_EDIT_WIDGET)

  const [pinturaHistory, setPinturaHistory] = useState<PinturaImageState[] | undefined>([])

  const {
    hasUserActiveSubscription,
    clientData,
  } = useUserData()

  const {
    sourceVisualFilename,
    imageUrl,
    assignmentId,
    handleProcessImage,
    isProcessingImage,
    onActionPageClose,
    editorRef,
    isFileVersioned,
    handleUpdate,
  } = useVisualPinturaEditing()

  const customizeToolbarButtons = (toolbar: PinturaNode[]) => {
    // Find the export button in the toolbar
    const exportButton = findNode(PINTURA_EXPORT_BTN_ID, toolbar)
    // Find the revert button in the toolbar
    const revertButton = findNode(PINTURA_REVERT_BTN_ID, toolbar)

    const exportButtonOptions = (exportButton?.[2] as PinturaNodeOptions) ?? undefined
    const revertButtonOptions = (revertButton?.[2] as PinturaNodeOptions) ?? undefined

    if (exportButtonOptions) {
      // Hide the default export button as we use a custom export button
      exportButtonOptions.hideLabel = true
      // hide button class
      exportButtonOptions.class = ''
    }

    if (revertButtonOptions) {
      // Ensure the revert button label is visible
      revertButtonOptions.hideLabel = false
    }

    return toolbar
  }

  const logPinturaEvent = useCallback((eventName: string, params?: {}) => {
    logAnalyticsEvent(eventName, {
      userEmail: clientData?.email,
      organizationId: clientData?.organizationId,
      assignmentId,
      sourceVisualFilename,
      ...params
    })
  }, [clientData?.email, clientData?.organizationId, assignmentId, sourceVisualFilename])

  return (
    <ActionPage
      id={`visual-pintura-editing-assignment-${assignmentId}-img-${sourceVisualFilename}`}
      title={t('title')}
      onClose={onActionPageClose}
      footerChildren={
        <VisualPinturaEditingFooter
          isFileVersioned={isFileVersioned}
          onClose={onActionPageClose}
          onSave={(tag) => handleProcessImage(tag)}
          isProcessing={isProcessingImage}
          pinturaHistory={pinturaHistory}
        />
      }
    >
      <>
        {/* NON-SUBSCRIBERS AFTER BETA FLOW */}
        {!hasUserActiveSubscription && !isBetaSelfEditingCard &&
          <div style={{ height: '100%', display: 'grid', gridTemplateColumns: '1.2fr 2.8fr', backgroundColor: WHITE }}>
            <Box
              sx={{ backgroundColor: BEIGE_500, margin: 1.6, borderRadius: '.8rem', height: 'fit-content' }}
            >
              <Stack sx={{ padding: 1.6 }}>

                <MUIBadge
                  size="xs"
                  color="coral"
                  icon={<LockOutlinedIcon fontSize="small" />}
                  label={t('common:Subscribers_only')}
                  sx={{ marginBottom: 1.2, width: 'fit-content' }}
                />
                <Typography variant="text-sm" fontWeight="600" color={GRAY_900}>{t('non_subscribers_title')}</Typography>
                <Typography variant="text-xs" fontWeight="400" color={GRAY_700} marginTop={0.6} > {t('non_subscribers_description')}</Typography>

                <MUIButton
                  sx={{ marginTop: 1.2, marginBottom: 2, fontSize: '12px', width: 'fit-content' }}
                  size="sm"
                  onClick={() => {
                    logPinturaEvent(AnalyticsEvent.PINTURA_UPSELL_NON_SUBSCRIBER_BUTTON)
                    externalNavigate(Path.PRICING)
                  }}
                >
                  {t('subscribe_to_bkbn_button')}
                </MUIButton>

                <SimpleImage
                  key={'non-subscribers-self-editing-promo'}
                  url={self_editing_upsell_image}
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}
                  borderRadius="none"
                />

              </Stack>

            </Box>

            <PinturaEditor
              enableUtils={false}
              {...PINTURA_EDITOR_DEFAULTS}
              ref={editorRef}
              onWritehistory={() => setPinturaHistory(editorRef.current?.editor.history.get())}
              onUndo={() => {
                setPinturaHistory(editorRef.current?.editor.history.get())
                logPinturaEvent(AnalyticsEvent.PINTURA_UNDO)
              }}
              onRedo={() => {
                setPinturaHistory(editorRef.current?.editor.history.get())
                logPinturaEvent(AnalyticsEvent.PINTURA_REDO)
              }}
              src={imageUrl}
              willRenderToolbar={customizeToolbarButtons}
              utils={PINTURA_UTILS_NON_SUBSCRIBERS}
              locale={getPinturaLocale(i18n.language)}
            />
          </div>
        }

        {/* NON-SUBSCRIBERS IN BETA FLOW + SUBSCRIBERS FLOW */}
        {(hasUserActiveSubscription || (!hasUserActiveSubscription && isBetaSelfEditingCard)) &&
          <div style={{ height: '100%' }}>
            <PinturaEditor
              ref={editorRef}
              {...PINTURA_EDITOR_DEFAULTS}
              src={imageUrl}
              onWritehistory={() => setPinturaHistory(editorRef.current?.editor.history.get())}
              onUndo={() => {
                setPinturaHistory(editorRef.current?.editor.history.get())
                logPinturaEvent(AnalyticsEvent.PINTURA_UNDO)
              }}
              onRedo={() => {
                setPinturaHistory(editorRef.current?.editor.history.get())
                logPinturaEvent(AnalyticsEvent.PINTURA_REDO)
              }}
              utils={PINTURA_UTILS}
              willRenderToolbar={customizeToolbarButtons}
              // @ts-ignore
              imageScrambler={createDefaultImageScrambler({
                scrambleAmount: 8,
                blurAmount: 20,
                dataSizeScalar: 8
              })}
              onUpdate={handleUpdate}
              cropSelectPresetFilter={'landscape'}
              cropSelectPresetOptions={[
                [undefined, 'Custom'],
                [1, 'Square'],
                // shown when cropSelectPresetFilter is set to 'landscape'
                [2 / 1, '2:1'],
                [3 / 2, '3:2'],
                [4 / 3, '4:3'],
                [5 / 4, '5:4'],
                [16 / 10, '16:10'],
                [16 / 9, '16:9'],
                // shown when cropSelectPresetFilter is set to 'portrait'
                [1 / 2, '1:2'],
                [2 / 3, '2:3'],
                [3 / 4, '3:4'],
                [4 / 5, '4:5'],
                [10 / 16, '10:16'],
                [9 / 16, '9:16'],
              ]}
              locale={getPinturaLocale(i18n.language)}
            />
          </div>
        }
      </>
    </ActionPage>
  )
}
