import { Box, Stack, Typography } from '@mui/material'
import { CORAL_200, CORAL_600, GRAY_700, GRAY_900, GREEN_200, GREEN_600 } from 'constants/styling/theme'
import { CancellationReason, DUPLICATE_DEAL_CANCELLATION_ERROR_CODE, DocumentProductCancellationReason, maxCancellationReasonTextCharacters } from 'constants/deal'
import { KeyboardEvent, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { DealDTOClient } from 'models/deal'
import { KeyboardEventKey } from 'constants/misc'
import { Label } from '../Label'
import { LinearProgressBar } from 'components/pages/PurchaseFlow/common/LinearProgressBar'
import { MUIBadge } from '../MUIBadge'
import { MUIButton } from '../MUIButton'
import { MUIInputField } from '../MUIInputField'
import { MUIRadio } from '../MUIRadio'
import Modal from '../Modals/Modal/Modal'
import { QueryStatus } from '../QueryStatus'
import TransitionAppear from '../TransitionAppear/TransitionAppear'
import { getTermsLink } from 'utils/localization'
import i18n from 'translations/i18n'
import moment from 'moment-timezone'
import { standardEnterEffect } from 'utils/animations'
import styles from './OrderCancellationPopup.module.sass'
import { supportEmail } from 'constants/contacts'
import { useCancelOrder } from 'dataQueries/order.query'
import { useGalleryDeal } from 'components/pages/Gallery/_main/contexts/GalleryDeal.context'
import { useGalleryProduct } from 'components/pages/Gallery/_main/contexts/GalleryProduct.context'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** Whether the popup is open */
  isOpen: boolean
  /** The data object */
  data: DealDTOClient
  /** Current Date time */
  dynamicNow: moment.Moment
  /** Onclick action triggered when user clicks the close button*/
  onClickClose: () => void
}

/**
 * @component Display order cancellation details in a popup
 * @example
 * <OrderCancellationPopup isOpen={true} data={data} onClickClose={() => {}} />
 */
export const OrderCancellationPopup: React.FC<Props> = ({
  isOpen,
  data,
  dynamicNow,
  onClickClose,
}) => {
  const { t } = useTranslation(['order_cancallation_popup', 'cancellation_reasons', 'actions'])

  const cancelOrder = useCancelOrder()

  const { shootingStart, earliestAssignment } = useGalleryDeal()
  const { isDocumentProduct } = useGalleryProduct()

  const [cancellationReason, setCancellationReason] = useState<CancellationReason | DocumentProductCancellationReason | undefined>(undefined)
  const [cancellationReasonText, setCancellationReasonText] = useState<string>('')

  const cancellationReasonOther = useMemo(() => CancellationReason.OTHER || DocumentProductCancellationReason.OTHER, [])
  const orderCreatedAt = useMemo(() => data?.createdAt ? moment(data.createdAt).toISOString() : null, [data])
  const lastCancelDateTimeWithoutFee = useMemo(() => moment(shootingStart).subtract(1, 'day'), [shootingStart])
  const hoursBeforeShooting = useMemo(() => moment.duration(moment(shootingStart).diff(dynamicNow, 'hours')), [dynamicNow, shootingStart])
  const isMaxCancellationReasonTextCharacters = useMemo(() => cancellationReasonText.length === maxCancellationReasonTextCharacters, [cancellationReasonText])
  const isCancelConfirmDisabled = useMemo(() => !cancellationReason || (cancellationReason === CancellationReason.OTHER && !cancellationReasonText), [cancellationReason, cancellationReasonText])

  /** 
   * Hide the cancellation shooting information when:
   * order has document products
   */
  const hideCancellationShootingInfo = useMemo(() => isDocumentProduct, [isDocumentProduct])

  /**
  * If data is of type deal, dealStart and timezone are set, calculate whether shooting starts within 24 hours.
  * Default to false otherwise.
  */
  const isLateCancellation = useMemo(() => data
    && !!shootingStart
    && !!earliestAssignment?.timezone
    && moment(shootingStart).isSameOrBefore(moment(dynamicNow).add(1, 'day')), [shootingStart, data, dynamicNow, earliestAssignment])

  const progressWithoutFee = useMemo(() => {

    if (isLateCancellation) return 100

    const totalValue = lastCancelDateTimeWithoutFee.diff(moment(orderCreatedAt))
    const currentValue = moment(dynamicNow).diff(moment(orderCreatedAt))

    return (currentValue / totalValue) * 100
  }, [dynamicNow, isLateCancellation, lastCancelDateTimeWithoutFee, orderCreatedAt])

  const progressWithFee = useMemo(() => {

    if (!isLateCancellation) return 0
    if (moment(shootingStart).isSameOrBefore(moment(dynamicNow))) return 100

    const totalValue = moment(shootingStart).diff(moment(lastCancelDateTimeWithoutFee))
    const currentValue = moment(dynamicNow).diff(moment(lastCancelDateTimeWithoutFee))

    return (currentValue / totalValue) * 100
  }, [dynamicNow, isLateCancellation, lastCancelDateTimeWithoutFee, shootingStart])

  const onExitPopUp = useCallback(() => {
    onClickClose()
    setCancellationReason(undefined)
    setCancellationReasonText('')
  }, [onClickClose])

  const handleConfirmCancel = useCallback(() => {
    const reason = cancellationReason ?? cancellationReasonText

    if (!reason) return

    cancelOrder.mutate({ orderId: data.id, cancellationReason: reason }, {
      onSuccess: () => setTimeout(() => onExitPopUp(), 3000)
    })
  }, [cancelOrder, cancellationReason, cancellationReasonText, data.id, onExitPopUp])

  const handleInputKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === KeyboardEventKey.ENTER) e.preventDefault()
  }, [])

  const getCancellationReason = useCallback(() => {
    if (isDocumentProduct) return DocumentProductCancellationReason

    return CancellationReason
  }, [isDocumentProduct])

  return (
    <Modal
      className={styles.orderCancellationPopup}
      modalContentClassName={styles.orderCancellationPopupModalContent}
      isOpen={isOpen}
      onClickOutside={onClickClose}
      onClose={onClickClose}
      title={t('cancel_popup_title')}
      footerContent={
        <Stack direction="row" justifyContent="space-between" alignItems="center" paddingBottom=".8rem" gap={2}>

          <Typography variant="text-xs" color={GRAY_700}>
            <Trans t={t} i18nKey="cancel_terms_and_condition">
              <a className={styles.termsLink} href={getTermsLink(i18n.language)} rel="noopener noreferrer" target="_blank">&nbsp;</a>
            </Trans>
          </Typography>

          <Stack direction="row" gap=".8rem">
            <MUIButton type="secondaryNoBorder" onClick={onClickClose}>
              {t('actions:Cancel')}
            </MUIButton>

            <MUIButton type="orangePrimary" disabled={isCancelConfirmDisabled} onClick={handleConfirmCancel}>
              {t('cancel_order')}
            </MUIButton>
          </Stack>

        </Stack>
      }
    >
      <Stack gap="1.6rem" paddingBottom="2.4rem">

        <Stack gap=".2rem">
          <Typography variant="text-sm" color={GRAY_900}>
            <Trans
              t={t}
              i18nKey="fee_meter_title"
              values={{ order: data.id }}
            />
          </Typography>

          {/** SHOOTING INFORMATION */}
          {!hideCancellationShootingInfo &&
            <Stack gap=".2rem">
              <Stack direction="row" gap=".4rem" width="100%">
                <Box flexGrow={1} padding="1rem 0">
                  <LinearProgressBar value={progressWithoutFee} barBackgroundColor={GREEN_200} barColor={GREEN_600} />
                </Box>

                <MUIBadge label="24h" size="sm" color={isLateCancellation ? 'coral' : 'gray'} />

                <Box flexGrow={1} maxWidth="10rem" padding="1rem 0">
                  <LinearProgressBar value={progressWithFee} barBackgroundColor={CORAL_200} barColor={CORAL_600} />
                </Box>
              </Stack>

              <Typography variant="text-xs" color={isLateCancellation ? CORAL_600 : GRAY_700}>
                <Trans
                  t={t}
                  i18nKey={isLateCancellation ? 'cancel_order_with_fee' : 'cancel_order_without_fee'}
                  values={{ hours: hoursBeforeShooting }}
                >
                  <a href={`mailto:${supportEmail}`} style={{ textDecoration: 'none' }}>&nbsp;</a>
                </Trans>
              </Typography>

              <Typography variant="text-sm" color={GRAY_700}>
                {t('cancel_order_reschedule')}
              </Typography>
            </Stack>
          }

        </Stack>

        {/** REASONS */}
        <Stack gap=".8rem">
          <Label text={t('cancel_order_reason_message')} />

          {Object.values(getCancellationReason()).map(reason => {
            return (
              <Stack key={reason} gap=".8rem" flexDirection="row">
                <MUIRadio
                  checked={cancellationReason === reason}
                  onChange={() => setCancellationReason(reason)}
                />

                <Label color={GRAY_900} text={t(`cancellation_reasons:${reason}`)} />
              </Stack>
            )
          })}

          <TransitionAppear visible={cancellationReason === cancellationReasonOther} onEnter={standardEnterEffect}>
            <MUIInputField
              label={t('cancellation_reasons:text')}
              value={cancellationReasonText}
              isError={!!isMaxCancellationReasonTextCharacters}
              errorText={t('cancellation_reason_characters_length_message', { count: maxCancellationReasonTextCharacters })}
              onChange={e => setCancellationReasonText(e.target.value.replace(/(\r\n|\n|\r)/gm, '').slice(0, maxCancellationReasonTextCharacters))}
              onKeyDown={e => handleInputKeyDown(e)}
            />
          </TransitionAppear>
        </Stack>

        <QueryStatus
          query={cancelOrder}
          onPurge={() => cancelOrder.reset()}
          successMessage={t('cancel_success')}
          spaceTopRem={2}
          errorMessage={cancelOrder.data?.data.response?.status === DUPLICATE_DEAL_CANCELLATION_ERROR_CODE
            ? t('already_cancelled')
            : undefined
          }
        />

      </Stack>
    </Modal>
  )
}
