import React, { useState } from 'react'
import { useGalleryAssignment, useGalleryDeal } from '../../contexts'

import { AnalyticsEvent } from 'utils/analytics'
import { ClientGalleryOrderDetailDrawer } from '../ClientGalleryOrderDetailDrawer'
import { ClientGalleryPriceSummaryCard } from '../ClientGalleryPriceSummaryCard'
import { ClientGalleryUploadPowerAttorney } from '../ClientGalleryUploadPowerAttorney'
import { ClientOrderDetail } from 'components/common/Gallery/ClientOrderDetail'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'

export enum OrderInformationContext {
  GALLERY = 'GALLERY',
  PROPERTY = 'PROPERTY'
}

export interface Props {
  /** Whether the Information tab is within Gallery or Property*/
  context: OrderInformationContext
}

/**
 * @component Client Gallery Order Information tab content
 * @example <ClientGalleryOrderInformation />
 */
export const ClientGalleryOrderInformation: React.FC<Props> = ({ context = OrderInformationContext.GALLERY }) => {

  const { logGalleryEvent } = useGalleryAssignment()
  const { requiresPowerOfAttorneyDocuments } = useGalleryDeal()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true)

    logGalleryEvent(AnalyticsEvent.CLICK_ORDER_DETAILS)
  }

  return (
    <>
      {context === OrderInformationContext.GALLERY &&
        <Grid container spacing={2.5} alignItems="start" direction={{ xs: 'column', sm: 'row' }} paddingTop="2.3rem">

          <Grid item xs={12} md={4} width="100%">
            <ClientGalleryPriceSummaryCard />
          </Grid>

          <Grid item xs={12} md={8} width="100%">
            <ClientOrderDetail onOpenDrawer={handleOpenDrawer} />

            {requiresPowerOfAttorneyDocuments &&
              <ClientGalleryUploadPowerAttorney />
            }

          </Grid>
        </Grid>
      }

      {context === OrderInformationContext.PROPERTY &&
        <Stack marginBottom={2.4}>
          <ClientOrderDetail onOpenDrawer={handleOpenDrawer} />

          {requiresPowerOfAttorneyDocuments &&
            <ClientGalleryUploadPowerAttorney />
          }
        </Stack>
      }

      <ClientGalleryOrderDetailDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  )
}
