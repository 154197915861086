import { GRAY_400, GRAY_900 } from 'constants/styling/theme'
import React, { useCallback, useEffect, useState } from 'react'
import { supportedDocumentFileTypes, supportedImageFileTypes } from 'constants/misc'

import { AssignmentDocumentsEndpoint } from 'constants/API'
import { CorrectionReasonType } from 'models/messageThreads'
import { DocumentUploadType } from 'constants/documents'
import { FileAPIUpload } from 'components/common/FileAPIUpload'
import { Label } from 'components/common/Label'
import { MUIButton } from 'components/common/MUIButton'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUIInputField } from 'components/common/MUIInputField'
import { QueryStatus } from 'components/common/QueryStatus'
import { ResponsiveDrawer } from 'components/common/ResponsiveDrawer'
import Stack from '@mui/material/Stack'
import { SystemMessage } from 'components/common/SystemMessage'
import Typography from '@mui/material/Typography'
import { useClientGallery } from 'components/pages/Gallery/_main/ClientGallery/_main'
import { useCreateFloorPlanCorrectionRequest } from 'dataQueries/messageThreads'
import { useFileAPI } from 'components/common/FileAPI'
import { useGalleryDeal } from 'components/pages/Gallery/_main/contexts'
import { useTranslation } from 'react-i18next'

const AvailableCorrectionReasons = new Set<CorrectionReasonType>([
  CorrectionReasonType.WRONG_IMPLEMENTATION,
  CorrectionReasonType.SUBSEQUENT_CHANGE
])

/** interface @Props for the ClientCreateFloorPlanRequestDrawer component. */
interface Props {
  /** Determines if the drawer is open. */
  isOpen: boolean
  /** External reference for the current selected document (file name). */
  externalReference: string
  /** Function to call when the drawer is closed. */
  onClose: () => void
}

/** File API scope for the thread drawer upload */
const fp_thread_drawer_upload_scope = 'CREATE_FP_THREAD_DRAWER'

/**
 * @component
 * Drawer for creating a new request thread in the client gallery for FLOOR PLAN CERTIFICATION products.
 *
 * @example
 * <ClientCreateFloorPlanRequestDrawer isOpen={true} externalReference="originalFileName.pdf" onClose={() => setOpenDrawer(false)} />
 */
export const ClientCreateFloorPlanRequestDrawer: React.FC<Props> = ({
  isOpen,
  externalReference,
  onClose,
}) => {

  const { t } = useTranslation(['threads', 'actions', 'common'])

  const { assignmentId } = useClientGallery()
  const { refetchTotalRequestsCount } = useGalleryDeal()
  const createFloorPlanCorrectionRequest = useCreateFloorPlanCorrectionRequest()

  const [newThreadMessage, setNewThreadMessage] = useState('')
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false)
  const [correctionType, setCorrectionType] = useState<CorrectionReasonType | null>(null)

  /** Initializes File API for the request drawer */
  const drawerThreadUpload = useFileAPI(fp_thread_drawer_upload_scope, {
    uploadUrlResolver: (fileEntry, api) => {
      return api.post(
        AssignmentDocumentsEndpoint.GET_UPLOAD_URL,
        { assignmentId },
        {
          filename: fileEntry.fileObject.name,
          contentType: fileEntry.fileObject.type,
          type: DocumentUploadType.INPUT,
        },
        true
      )
    },
    deleteHandler: (fileEntry, api) => api.delete(
      AssignmentDocumentsEndpoint.DELETE_DOCUMENT,
      { assignmentId },
      true,
      {
        params: {
          type: DocumentUploadType.INPUT,
          filename: fileEntry.gcFilename,
        }
      }
    )
  })

  /** Handles clearing the input fields and purge files. */
  const handleClearDrawer = useCallback(() => {
    if (newThreadMessage !== '') setNewThreadMessage('')
    if (correctionType !== null) setCorrectionType(null)
    if (drawerThreadUpload.allFilesArray.length > 0) drawerThreadUpload.purgeFilesScope(fp_thread_drawer_upload_scope)
  }, [correctionType, drawerThreadUpload, newThreadMessage])

  /** Handles closing the drawer, clearing all the drawer data and resetting the query. */
  const handleCloseDrawer = useCallback(() => {
    handleClearDrawer()
    onClose()
    // Reset the request query so the user can make another request
    createFloorPlanCorrectionRequest.reset()
  }, [createFloorPlanCorrectionRequest, handleClearDrawer, onClose])

  /** Handles the creation of a new request thread. */
  const handleCreateRequestThread = useCallback(() => {
    const newThreadFilenames = drawerThreadUpload.allFilesArray.map((file) => file.gcFilename).filter((filename): filename is string => !!filename)

    if (!correctionType) return

    createFloorPlanCorrectionRequest.mutate(
      {
        assignmentId,
        fileNameToCorrect: externalReference,
        message: newThreadMessage,
        correctionTemplateFileName: newThreadFilenames[0],
        correctionReason: correctionType,
      },
      {
        onSuccess: () => {
          handleClearDrawer()
          setTimeout(() => {
            handleCloseDrawer()
            refetchTotalRequestsCount()
          }, 3000)
        }
      }
    )
  }, [drawerThreadUpload.allFilesArray, correctionType, createFloorPlanCorrectionRequest, assignmentId, externalReference, newThreadMessage, handleClearDrawer, handleCloseDrawer, refetchTotalRequestsCount])

  // Disable the submit button if the title, message, correctionType are empty or no files are uploaded
  useEffect(() => {
    setDisabledSubmitButton(
      !newThreadMessage || !drawerThreadUpload.allFilesArray.length || !correctionType
    )
  }, [newThreadMessage, drawerThreadUpload.allFilesArray.length, correctionType])

  return (
    <ResponsiveDrawer
      isOpen={isOpen}
      onClose={handleCloseDrawer}
      title={t('new_request_title')}
      actionsSlot={
        <Stack direction="row" gap={1}>

          <MUIButton type="secondaryBorder" onClick={handleCloseDrawer}>
            {t('actions:Cancel')}
          </MUIButton>

          <MUIButton
            disabled={disabledSubmitButton || !createFloorPlanCorrectionRequest.isIdle}
            isLoading={createFloorPlanCorrectionRequest.isPending}
            onClick={handleCreateRequestThread}
          >
            {t('send_request')}
          </MUIButton>

        </Stack>
      }
    >
      <Stack gap={2}>

        <SystemMessage
          message={t('create_thread.payment_info_message')}
        />

        <MUIDropdown
          button={(isOpen, action) => (
            <MUIDropdownInput
              required
              readOnly
              label={t('correction_type.label')}
              placeholder={t('correction_type.placeholder')}
              value={!!correctionType ? t(`correction_type.options.${correctionType}`) : ''}
              onClick={action}
            />
          )}
        >
          {Array.from(AvailableCorrectionReasons).map((type) => (
            <MUIDropdownItem key={type} onClick={() => setCorrectionType(type)}>
              <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                {t(`correction_type.options.${type}`)}
              </Typography>
            </MUIDropdownItem>
          ))}
        </MUIDropdown>

        <MUIInputField
          required
          isMultiline={true}
          value={newThreadMessage}
          label={t('create_thread.label_message')}
          onChange={(e) => setNewThreadMessage(e.target.value)}
        />

        <Stack gap={0.6}>
          <Label text={t('create_thread.label_attachments')} required />

          <FileAPIUpload
            boxElevation="xs"
            borderBoxColor={GRAY_400}
            files={drawerThreadUpload.allFilesArray}
            maxNumberOfFiles={1}
            acceptedFileTypes={[...supportedImageFileTypes, ...supportedDocumentFileTypes]}
            uploadHandler={(acceptedFiles) => drawerThreadUpload.uploadFiles(acceptedFiles)}
            onDelete={(fileId) => drawerThreadUpload.deleteFiles([fileId])}
          />
        </Stack>

        <SystemMessage
          message={t('create_thread.upload_info_message')}
        />

      </Stack>

      <QueryStatus
        query={createFloorPlanCorrectionRequest}
        successMessage={t('create_thread.success')}
        spaceTopRem={2}
        onPurge={() => createFloorPlanCorrectionRequest.reset()}
      />

    </ResponsiveDrawer>
  )
}
