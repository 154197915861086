import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { Box, Fade, Typography } from '@mui/material'
import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { MUIInputField, StyledMUIInput } from 'components/common/MUIInputField'
import { MeasurementExtraProductWithResidential, extraProductsForMeasurementsPropertyType, useMeasurements } from './MeasurementStep.context'
import { MeasurementOnSiteAdditionalInformationType, MeasurementOnSitePropertyType } from 'models/purchaseFlow/measurementDTO'
import { Trans, useTranslation } from 'react-i18next'
import { usePurchaseFlowConfig, usePurchaseFlowProducts } from '../../_main/contexts'

import { CheckboxItem } from '../../common/CheckboxItem'
import { GRAY_900 } from 'constants/styling/theme'
import { Label } from 'components/common/Label'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUIInputFieldButton } from 'components/common/MUIInputField/MUIInputFieldButton'
import { MUIInputFieldCounter } from 'components/common/MUIInputFieldCounter'
import { Nullable } from 'models/helpers'
import { ProductKind } from 'constants/product'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import { StepWrapper } from '../../common/StepWrapper'
import { SystemMessage } from 'components/common/SystemMessage'

/**
 * Controller for the Measurement requirements step in the Purchase Flow.
 * 
 * @example
 * <MeasurementStepController />
 */
export const MeasurementStepController: FC = () => {
  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'measurement_step' })
  const { extraCatalogueOptionProduct, selectedCategory } = usePurchaseFlowConfig()
  const {
    selectedKinds,
    selectedProductTypes,
    selectedProducts,
    selectOptionProduct,
    unselectOptionProduct,
  } = usePurchaseFlowProducts()

  const {
    surfaceArea,
    numberOfRooms,
    numberOfFloors,
    isAtticChecked,
    isBasementChecked,
    measurementComments,
    numberOfPropertyUnit,
    measurementPropertyType,
    additionalInformation,
    setSurfaceArea,
    setNumberOfRooms,
    setNumberOfFloors,
    setIsAtticChecked,
    setIsBasementChecked,
    setMeasurementComments,
    setNumberOfPropertyUnit,
    setMeasurementPropertyType,
    setAdditionalInformation,
  } = useMeasurements()

  const [selectedExtraPropertyType, setSelectedExtraPropertyType] = useState<Nullable<MeasurementOnSitePropertyType>>(null)

  const measurementParentProductId = useMemo(() => {
    const product = Object.values(selectedProducts).find(product => product.kind === ProductKind.MEASUREMENT_ON_SITE)
    return product ? product.id : null
  }, [selectedProducts])

  const handleOptionProductSelection = useCallback((productKind: ProductKind, isSelected: boolean, quantity: number = 1) => {
    if (!measurementParentProductId) return

    const extraOptionProduct = extraCatalogueOptionProduct(productKind)
    if (isSelected) selectOptionProduct(measurementParentProductId, extraOptionProduct, quantity)
    else unselectOptionProduct(measurementParentProductId, extraOptionProduct.id)
  }, [extraCatalogueOptionProduct, measurementParentProductId, selectOptionProduct, unselectOptionProduct])

  const handleNumberOfRoomsChange = useCallback((value: number) => {
    setNumberOfRooms(value)
    handleOptionProductSelection(ProductKind.EXTRA_MEASURED_ROOM, value > 10, value - 10)
  }, [handleOptionProductSelection, setNumberOfRooms])

  const handleNumberOfFloorsChange = useCallback((value: number) => {
    setNumberOfFloors(value)

    if (measurementPropertyType === MeasurementOnSitePropertyType.SINGLE_FAMILY_HOUSE) {
      if (value < 2) {
        handleOptionProductSelection(ProductKind.EXTRA_MEASURED_FLOOR, false)
        return
      } else {
        handleOptionProductSelection(ProductKind.EXTRA_MEASURED_FLOOR, value > 2, value - 2)
        return
      }
    }

    handleOptionProductSelection(ProductKind.EXTRA_MEASURED_FLOOR, value > 1, value - 1)
  }, [handleOptionProductSelection, measurementPropertyType, setNumberOfFloors])

  const handleNumberOfPropertyUnitChange = useCallback((value: number) => {
    setNumberOfPropertyUnit(value)
    handleOptionProductSelection(ProductKind.EXTRA_MEASURED_RESIDENTIAL_UNIT, value > 2, value - 2)
  }, [handleOptionProductSelection, setNumberOfPropertyUnit])

  const propertyTypeDropdownOptionText = useCallback((type: MeasurementOnSitePropertyType) => {
    const optionType = extraProductsForMeasurementsPropertyType.get(type)
    let extraPrice = 0
    if (optionType) {
      const extraOptionProduct = extraCatalogueOptionProduct(optionType)
      extraPrice = extraOptionProduct.price
    }
    const extraPriceText = extraPrice ? `(+ €${extraPrice})` : ''

    return `${t(`property_type.${type}`)} ${extraPriceText}`
  }, [extraCatalogueOptionProduct, t])

  useEffect(() => {
    if (!measurementParentProductId) return
    if (measurementPropertyType === selectedExtraPropertyType) return

    if (selectedExtraPropertyType) {
      const selectedExtraProductPropertyType = extraProductsForMeasurementsPropertyType.get(selectedExtraPropertyType)
      if (!selectedExtraProductPropertyType) return
      const selectedExtraProductData = extraCatalogueOptionProduct(selectedExtraProductPropertyType)
      unselectOptionProduct(measurementParentProductId, selectedExtraProductData.id)
      setSelectedExtraPropertyType(null)
    }

    const measurementExtraPropertyType = extraProductsForMeasurementsPropertyType.get(measurementPropertyType)

    if (measurementExtraPropertyType) {
      const extraProductData = extraCatalogueOptionProduct(measurementExtraPropertyType)
      selectOptionProduct(measurementParentProductId, extraProductData, 1)
      setSelectedExtraPropertyType(measurementPropertyType)
    }

    if (!MeasurementExtraProductWithResidential.has(measurementPropertyType)) {
      handleNumberOfPropertyUnitChange(0)
    }

    handleNumberOfFloorsChange(0)

    // Only trigger when measurementPropertyType changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurementPropertyType])

  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvent.ENTER_ORDER_MEASUREMENTS_PAGE_SCREEN, {
      productTypes: Array.from(selectedProductTypes),
      productKinds: Array.from(selectedKinds),
      products: Object.values(selectedProducts),
      category: selectedCategory,
    })

    // Only trigger when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StepWrapper
      title={t('section_title')}
      subtitle={t('section_description')}
    >
      <Fade in={true}>
        <Stack gap={2} width="100%" display="flex" justifyContent="space-between">

          <SystemMessage variant="system" message={t('measurement_note')} />

          {/* Property Information */}
          <SectionedBorderBox title={t('property_information.title')}>
            <Stack gap="1.8rem">

              {/* Property Type selection */}
              <MUIDropdown
                button={(isOpen, action) => (
                  <MUIDropdownInput
                    readOnly
                    label={t('property_information.property_type')}
                    type={DropdownInputTypes.SELECTOR}
                    value={propertyTypeDropdownOptionText(measurementPropertyType)}
                    onClick={action}
                  />
                )}
              >
                <Box>
                  {Object.values(MeasurementOnSitePropertyType).map(type => (
                    <MUIDropdownItem key={type} onClick={() => setMeasurementPropertyType(type)}>
                      <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                        {propertyTypeDropdownOptionText(type)}
                      </Typography>
                    </MUIDropdownItem>
                  ))}
                </Box>
              </MUIDropdown>

              {/* Number of property unit - Only in APARTMENT_BUILDING property type */}
              {MeasurementExtraProductWithResidential.has(measurementPropertyType) &&
                <MUIInputFieldCounter
                  counterValue={numberOfPropertyUnit}
                  counterOnChange={handleNumberOfPropertyUnitChange}
                  inputBoxWidth="14rem"
                  label={t('property_information.number_of_property_unit')}
                  hintText={
                    <Trans
                      t={t}
                      i18nKey="property_information.number_of_property_unit_hint"
                      values={{ price: extraCatalogueOptionProduct(ProductKind.EXTRA_MEASURED_RESIDENTIAL_UNIT).price }}
                    />
                  }
                />
              }

              {/* Surface Area */}
              <MUIInputField
                inputBoxWidth="16rem"
                label={t('property_information.surface_area')}
                hintText={
                  <Trans
                    t={t}
                    i18nKey="property_information.surface_area_hint"
                    values={{ price: extraCatalogueOptionProduct(ProductKind.EXTRA_SURFACE_MEASUREMENT).price }}
                  />
                }
                customInput={
                  <>
                    <StyledMUIInput
                      value={surfaceArea}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, '')
                        setSurfaceArea(value)
                        handleOptionProductSelection(ProductKind.EXTRA_SURFACE_MEASUREMENT, parseInt(value) > 149, parseInt(value) - 149)
                      }}
                    />
                    <MUIInputFieldButton sx={{ padding: '0 1.2rem' }}>
                      <Typography variant="text-md" color={GRAY_900} fontWeight="medium">m²</Typography>
                    </MUIInputFieldButton>
                  </>
                }
              />

              {/* Number of rooms */}
              <MUIInputFieldCounter
                counterValue={numberOfRooms}
                counterOnChange={handleNumberOfRoomsChange}
                inputBoxWidth="14rem"
                label={t('property_information.number_of_rooms')}
                hintText={
                  <Trans
                    t={t}
                    i18nKey="property_information.number_of_rooms_hint"
                    values={{ price: extraCatalogueOptionProduct(ProductKind.EXTRA_MEASURED_ROOM).price }}
                  />
                }
              />

              {/* Number of floors */}
              <MUIInputFieldCounter
                counterValue={numberOfFloors}
                counterOnChange={handleNumberOfFloorsChange}
                inputBoxWidth="14rem"
                label={t('property_information.number_of_floor')}
                hintText={
                  <Trans
                    t={t}
                    i18nKey={`property_information.${measurementPropertyType === MeasurementOnSitePropertyType.SINGLE_FAMILY_HOUSE ? 'number_of_floor_hint_2nd_included' : 'number_of_floor_hint'}`}
                    values={{ price: extraCatalogueOptionProduct(ProductKind.EXTRA_MEASURED_FLOOR).price }}
                  />
                }
              />

              {/* Additional areas */}
              <Stack gap=".6rem">
                <Label text={t('property_information.additional_area')} />

                <CheckboxItem
                  title={t('property_information.basement')}
                  description={t('property_information.basement_description')}
                  checked={isBasementChecked}
                  onClick={() => {
                    handleOptionProductSelection(ProductKind.EXTRA_MEASURED_BASEMENT, !isBasementChecked)
                    setIsBasementChecked(!isBasementChecked)
                  }}
                />
                <CheckboxItem
                  title={t('property_information.attic')}
                  description={t('property_information.attic_description')}
                  checked={isAtticChecked}
                  onClick={() => {
                    handleOptionProductSelection(ProductKind.EXTRA_MEASURED_ATTIC, !isAtticChecked)
                    setIsAtticChecked(!isAtticChecked)
                  }}
                />
              </Stack>

            </Stack>
          </SectionedBorderBox>

          {/* Measurement additional information */}
          <SectionedBorderBox title={t('additional_information.title')}>
            <Stack gap=".8rem">
              <Label text={t('additional_information.description')} />

              {Object.values(MeasurementOnSiteAdditionalInformationType).map(type => (
                <CheckboxItem
                  key={type}
                  title={t(`additional_information_type.${type}`)}
                  checked={additionalInformation.has(type)}
                  onClick={() =>
                    additionalInformation.has(type)
                      ? setAdditionalInformation(new Set([...additionalInformation].filter(item => item !== type)))
                      : setAdditionalInformation(new Set([...additionalInformation, type]))
                  }
                />
              ))}
            </Stack>
          </SectionedBorderBox>

          {/* Measurement Comments */}
          <SectionedBorderBox title={t('comments.title')}>
            <MUIInputField
              hintText={t('comments.comment_hint')}
              isMultiline
              value={measurementComments}
              onChange={(e) => setMeasurementComments(e.target.value)}
            />
          </SectionedBorderBox>

        </Stack>
      </Fade>
    </StepWrapper>
  )
}
