import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { BEIGE_600, GRAY_400, GRAY_700, GRAY_800, GRAY_900 } from 'constants/styling/theme'
import { CONST_PRODUCT_SERIAL, EditingCategory, UnlimitedUploadKinds } from 'constants/product'
import { FileManipulationKey, VisualFileType } from 'constants/visual'
import { FileRejection, useDropzone } from 'react-dropzone'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { ImageQuality, getImageQuality, isEditingFloorPlanProductKind } from 'utils/validators'
import { ImageQualityError, UploadInfoBlock, UploadLimitError, UploadVisualItem } from 'components/common/UploadVisuals'
import { acceptedProductVisualMimetypes, acceptedProductVisualMimetypesWithPDF } from 'constants/misc'
import { deletePurchaseSessionVisual, uploadPurchaseSessionVisual } from 'redux/Individual/Visual/LoadPurchaseSessionVisual'
import { useDispatch, useSelector } from 'react-redux'

import { APIRequestErrorType } from 'constants/API'
import { ActionTypeAPIData } from 'constants/redux'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import Box from '@mui/material/Box'
import { CatalogueProduct } from 'dataQueries/purchase.query'
import { CircleIcon } from 'components/common/CircleIcon'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import Collapse from '@mui/material/Collapse'
import Link from '@mui/material/Link'
import { RootStore } from 'models/redux'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { fileIsImage } from 'utils/typeguards'
import { uniqueId } from 'lodash'
import { useDebouncedMemo } from 'utils/helpers'
import { useImageRegex } from 'utils/hooks'
import { usePurchaseFlowConfig } from '../../_main/contexts'
import { usePurchaseFlowProducts } from '../../_main/contexts'
import { useTargetOrderUser } from '../../_main/contexts'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props for the EditingProductUpload component.
 */
interface Props {
  /** Product object */
  product: CatalogueProduct
  /** Whether a file is being dragged over the browser window */
  isDraggingFile: boolean
  /** Whether to disabled selecting/deselecting */
  disabledSelection?: boolean
}

/**
 * @component Component which displays editing product upload
 * @example
 * <EditingProductUpload />
 */
export const EditingProductUpload: React.FC<Props> = ({
  product,
  isDraggingFile,
  disabledSelection
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['order'])
  const { adminSelectedUserEmail } = useTargetOrderUser()
  const { sessionId, selectedCategory } = usePurchaseFlowConfig()
  const { selectedProducts } = usePurchaseFlowProducts()

  if (!sessionId) throw new Error('Missing sessionId')

  const productId = useMemo(() => product.id, [product])
  const productQuantity = useMemo(() => selectedProducts[productId]?.quantity ?? 0, [productId, selectedProducts])

  const listedPurchaseSessionVisuals = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.LIST_PURCHASE_SESSION_VISUALS]?.[sessionId])
  const loadPurchaseSessionVisuals = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL]?.[sessionId])

  const listedProductVisuals = useMemo(() => listedPurchaseSessionVisuals?.data?.visuals?.[productId], [listedPurchaseSessionVisuals, productId])
  const downloadProductVisuals = useMemo(() => loadPurchaseSessionVisuals?.[productId]?.[CONST_PRODUCT_SERIAL]?.[FileManipulationKey.DOWNLOAD], [loadPurchaseSessionVisuals, productId])
  const uploadProductVisuals = useMemo(() => loadPurchaseSessionVisuals?.[productId]?.[CONST_PRODUCT_SERIAL]?.[FileManipulationKey.UPLOAD], [loadPurchaseSessionVisuals, productId])

  const sortFunction = useCallback((aKey: string, bKey: string) => {
    if (aKey > bKey) return 1
    else if (aKey < bKey) return -1
    else return 0
  }, [])

  const sortEntriesFunction = useCallback(([aKey, aObject]: [string, any], [bKey, bObject]: [string, any]) => {
    return sortFunction(aKey, bKey)
  }, [sortFunction])

  const downloadVisualsEntries = useMemo(() => (downloadProductVisuals ? Object.entries(downloadProductVisuals).filter(([key, allTypes]) => allTypes?.[VisualFileType.RAW_THUMB]).sort(sortEntriesFunction) : []), [downloadProductVisuals, sortEntriesFunction])
  const uploadVisualsEntries = useMemo(() => (uploadProductVisuals ? Object.entries(uploadProductVisuals).filter(([key, allTypes]) => allTypes?.[VisualFileType.RAW]).sort(sortEntriesFunction) : []), [uploadProductVisuals, sortEntriesFunction])
  const downloadVisualsKeys = useMemo(() => (downloadVisualsEntries.filter(([key, allTypes]) => allTypes?.[VisualFileType.RAW_THUMB]).map(([key, allTypes]) => key)), [downloadVisualsEntries])
  const uploadVisualsKeys = useMemo(() => (uploadVisualsEntries.filter(([key, allTypes]) => allTypes?.[VisualFileType.RAW]).map(([key, allTypes]) => key)), [uploadVisualsEntries])
  const uploadVisualsOriginalFilenames = useMemo(() => Object.values(uploadProductVisuals ?? {}).map(visual => visual?.[VisualFileType.RAW]?.originalFilename ?? '').filter(filename => !!filename), [uploadProductVisuals])
  const visualsKeys = useMemo(() => [...uploadVisualsKeys, ...downloadVisualsKeys].sort(sortFunction), [uploadVisualsKeys, downloadVisualsKeys, sortFunction])
  const visualsCount = useDebouncedMemo(() => visualsKeys.length || 0, [visualsKeys], 100)
  const listedVisualKeys = useMemo(() => (listedProductVisuals ?? []).sort(sortFunction), [listedProductVisuals, sortFunction])

  const isEditingFloorPlan = useMemo(() => selectedCategory === EditingCategory.EDITING && isEditingFloorPlanProductKind(selectedProducts[productId]?.kind ?? ''), [productId, selectedCategory, selectedProducts])
  const isUnlimitedUpload = useMemo(() => UnlimitedUploadKinds.has(product.kind), [product.kind])

  const imageNameReplacePattern = useImageRegex(sessionId)

  const [fileSizeMap, setFileSizeMap] = useState<Record<string, number>>({})

  const [badQualityVisuals, setBadQualityVisuals] = useState<Set<string>>(new Set([]))
  const [borderlineQualityVisuals, setBorderlineQualityVisuals] = useState<Set<string>>(new Set([]))

  const onDrop = useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections && fileRejections.length > 0) {
      const formats: string[] = []
      for (let fileRejection of fileRejections) {
        const split = fileRejection.file.name.split('.')
        if (split.length < 2) formats.push(fileRejection.file.name)
        else formats.push(split.slice(1).join('.'))
      }
      const formatsString = Array.from(new Set(formats).keys()).join(', ')
      alert(`${t('step_product.unsupported_file_format')}\n${formatsString}`)
    }

    const duplicateFiles = []
    for (let file of acceptedFiles) {
      if (!uploadVisualsOriginalFilenames.includes(file.name)) {

        const imageQuality = await getImageQuality(file)

        if (fileIsImage(file) && imageQuality === ImageQuality.INSUFFICIENT && selectedCategory !== EditingCategory.EDITING) {
          logAnalyticsEvent(AnalyticsEvent.VISUAL_QUALITY_DECLINED, {})

          setBadQualityVisuals((prev) => {
            const newSet = new Set(prev)
            newSet.add(uniqueId(file.name))

            return newSet
          })
        } else {
          if (imageQuality === ImageQuality.BORDERLINE) {
            setBorderlineQualityVisuals((prev) => {
              const newSet = new Set(prev)
              newSet.add(file.name)

              return newSet
            })
          }

          setFileSizeMap((prev) => ({ ...prev, [file.name]: file.size }))
          dispatch(uploadPurchaseSessionVisual(sessionId, productId, CONST_PRODUCT_SERIAL, file, adminSelectedUserEmail))
        }

      } else duplicateFiles.push(file.name)
    }
    if (duplicateFiles.length > 0) {
      alert(t('step_product.duplicate_file_alert', { count: duplicateFiles.length, files: duplicateFiles.join(', ') }))
    }
  }, [t, uploadVisualsOriginalFilenames, selectedCategory, dispatch, sessionId, productId, adminSelectedUserEmail])

  const uploadWithPDF = useMemo(() => {
    return isEditingFloorPlan
  }, [isEditingFloorPlan])

  const acceptedMimetypes = useMemo(() => {
    if (uploadWithPDF) {
      return acceptedProductVisualMimetypesWithPDF
    }

    return acceptedProductVisualMimetypes
  }, [uploadWithPDF])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedMimetypes,
  })

  const onDeleteImage = useCallback((reactKey: string) => {
    dispatch(deletePurchaseSessionVisual(sessionId, productId, CONST_PRODUCT_SERIAL, reactKey))
  }, [dispatch, productId, sessionId])

  const preloadListing = useCallback(() => {
    const tupleArray: [string, string, JSX.Element][] = listedVisualKeys.map(key => {
      const reactKey = key
      const fileName = key.replace(imageNameReplacePattern, '')
      return [key, reactKey, (
        <UploadVisualItem
          key={key}
          reactKey={key}
          type='preload'
          fileName={fileName}
          disabledSelection={disabledSelection}
          onDelete={onDeleteImage}
          size={fileSizeMap[fileName] ?? 0}
        />
      )]
    })

    return tupleArray
  }, [listedVisualKeys, imageNameReplacePattern, disabledSelection, onDeleteImage, fileSizeMap])

  const downloadListing = useCallback(() => {
    const tupleArray: [string, string, JSX.Element][] = downloadVisualsEntries.map(([key, allTypes]) => {
      const img = allTypes?.[VisualFileType.RAW_THUMB]
      if (!img) throw new Error(`Image does not exist for key: ${key} and visual type: ${VisualFileType.RAW_THUMB}`)
      const isError = img.request.error_type !== APIRequestErrorType.NONE
      const label = img.file?.name.replace(imageNameReplacePattern, '') + (img.originalFilename ? ` | ${img.originalFilename}` : '')
      const reactKey = key

      return [key, reactKey, (
        <UploadVisualItem
          key={key}
          reactKey={key}
          isLowQuality={borderlineQualityVisuals.has(img.originalFilename ?? '')}
          type="download"
          image={img}
          label={label}
          isError={isError}
          disabledSelection={disabledSelection}
          onDelete={onDeleteImage}
          size={fileSizeMap[img.originalFilename || ''] ?? 0}
        />
      )]
    })

    return tupleArray
  }, [downloadVisualsEntries, imageNameReplacePattern, borderlineQualityVisuals, disabledSelection, onDeleteImage, fileSizeMap])

  const badQualityListing = useCallback(() => {
    const tupleArray: [string, string, JSX.Element][] = Array.from(badQualityVisuals).map((key) => {
      const reactKey = key

      return [key, reactKey, (
        <ImageQualityError
          key={reactKey}
          onDelete={() => setBadQualityVisuals((prev) => {
            const prunedSet = new Set(prev)
            prunedSet.delete(key)

            return prunedSet
          })}
        />
      )]
    })

    return tupleArray
  }, [badQualityVisuals])

  const uploadListing = useCallback(() => {
    const tupleArray: [string, string, string, JSX.Element][] = uploadVisualsEntries.map(([key, allTypes]) => {
      const img = allTypes?.[VisualFileType.RAW]
      if (!img) throw new Error(`Image does not exist for key: ${key} and visual type: ${VisualFileType.RAW}`)
      // For sorting fresh uploads on top
      const sortKey = (img.replaces || imageNameReplacePattern.exec(key)) ? key : `${sessionId}-bkbn-ZZZZZ-${img.droppedIn}-${key}`
      const reactKey = img.originalFilename ?? key
      const fileName = img.file ? img.file.name.replace(imageNameReplacePattern, '') : ''
      const originalFilename = img.originalFilename ? ` | ${img.originalFilename}` : ''
      const label = fileName ? fileName + originalFilename : originalFilename

      return [key, reactKey, sortKey, (
        <UploadVisualItem
          key={key}
          reactKey={key}
          image={img}
          type='uploading'
          label={label}
          disabledSelection={disabledSelection}
          onDelete={onDeleteImage}
          isLowQuality={borderlineQualityVisuals.has(img.originalFilename ?? '')}
          size={fileSizeMap[originalFilename || ''] ?? 0}
        />
      )]
    })

    return tupleArray
  }, [uploadVisualsEntries, imageNameReplacePattern, sessionId, disabledSelection, onDeleteImage, borderlineQualityVisuals, fileSizeMap])

  const visualsListing = useCallback(() => {
    const map: Map<string, [string, JSX.Element]> = new Map()

    for (let [key, reactKey, node] of preloadListing()) map.set(key, [reactKey, node])
    for (let [key, reactKey, node] of downloadListing()) map.set(key, [reactKey, node])
    for (let [key, reactKey, node] of badQualityListing()) map.set(key, [reactKey, node])
    for (let [key, reactKey, sortKey, node] of uploadListing()) {
      map.delete(key)
      map.set(sortKey, [reactKey, node])
    }

    const entries: [string, [string, JSX.Element]][] = Array.from(map.entries())
    const sortedEntries = [...entries].sort(sortEntriesFunction)

    return (
      <Fragment>
        {sortedEntries.map(([key, [reactKey, node]]) => (
          <Fragment key={reactKey}>
            {node}
          </Fragment>
        ))}
      </Fragment>
    )
  }, [sortEntriesFunction, preloadListing, downloadListing, badQualityListing, uploadListing])

  return (
    <BorderBoxWrapper elevation='sm' padding={2} borderColor={BEIGE_600}>

      {/** TITLE */}
      <Stack marginBottom={1} gap={0.5} alignItems="center" direction="row">

        <Typography variant='text-sm'>
          {t('step_product.upload_visuals')}
        </Typography>

        {!isUnlimitedUpload &&
          <Typography variant='text-sm' fontWeight={600} color={GRAY_900}>
            {`${visualsCount} of ${productQuantity}`}
          </Typography>

        }
      </Stack>

      {/** UPLOAD ZONE */}
      {!disabledSelection &&
        <div {...getRootProps()}>
          <BorderBoxWrapper
            padding={4}
            elevation='none'
            textAlign="center"
            sx={{ cursor: 'pointer' }}
            border={`2px dashed ${GRAY_400}`}
          >

            {/** UPLOAD INPUT */}
            <input {...getInputProps()} />

            {/** SUBMIT & DROP */}
            <Stack gap={1} direction="column" alignItems="center">
              <CircleIcon
                size="5rem"
                icon={<CloudUploadOutlinedIcon fontSize="large" sx={{ color: GRAY_800 }} />}
                circleColor={BEIGE_600}
              />

              {isDragActive || isDraggingFile
                ? (
                  <Typography variant="text-sm" color={GRAY_900} fontWeight={500}>
                    {t('step_product.drop_here')}
                  </Typography>
                )
                : (
                  <Fragment>
                    <Box>
                      <Link variant="text-sm" color={GRAY_900} fontWeight={500}>
                        {t('step_product.upload_dropzone.click_upload')}
                      </Link>
                      <Typography variant="text-sm" color={GRAY_900}>
                        {` ${t('step_product.upload_dropzone.drag_n_drop')}`}
                      </Typography>
                    </Box>

                    <Typography variant="text-sm" color={GRAY_700}>
                      {uploadWithPDF ? t('step_product.upload_dropzone.suggestionWithPdf') : t('step_product.upload_dropzone.suggestion')}
                    </Typography>
                  </Fragment>
                )
              }
            </Stack>
          </BorderBoxWrapper>
        </div>
      }

      {/** LIST OF UPLOADED VISUALS */}
      {(visualsCount > 0 || badQualityVisuals.size > 0) && visualsListing()}

      {/** ERROR (visuals limit exceeded) */}
      <div>
        <Collapse in={!isUnlimitedUpload && downloadVisualsEntries.length > productQuantity}>
          <UploadLimitError />
        </Collapse>
      </div>

      {/** INFO (editing floor plan files) */}
      <Box>
        <Collapse in={visualsCount > 0 && isEditingFloorPlan}>
          <UploadInfoBlock description={t('step_product.floor_plan_upload_info')} />
        </Collapse>
      </Box>

    </BorderBoxWrapper>
  )
}
