import { BEIGE_600, GRAY_900, MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { FC, Fragment, useCallback, useMemo } from 'react'

import { AssignmentDTOClient } from 'models/assignment'
import { AssignmentJob } from 'constants/assignment'
import { CircleIcon } from 'components/common/CircleIcon'
import { DealDTOIsClientDTO } from 'utils/typeguards'
import FilterTab from 'components/common/FilterTab/FilterTab.module'
import { MUIBadge } from 'components/common/MUIBadge'
import { Path } from 'constants/router'
import { ProductKindsWithDocumentGallery } from 'constants/product'
import { getProductKindIcon } from 'utils/helpers/getProductKindIcon'
import { getProgressCategoryData } from 'components/common/Gallery/AssignmentProgress/AssignmentProgressSteps.utils'
import { useClientGallery } from '../_main/contexts'
import { useGalleryDeal } from '../../contexts'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

/**
 * Client gallery for assignments with document type filters.
 * 
 * @example <ClientGalleryDocumentTypeFilter />
 */
export const ClientGalleryDocumentTypeFilter: FC = () => {
  const { t } = useTranslation(['gallery'])
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)
  const navigate = useNavigate()

  const { assignmentId } = useClientGallery()
  const { dealData, dealAssignmentsForTab } = useGalleryDeal()

  const order = useMemo(() => {
    if (dealData && !DealDTOIsClientDTO(dealData)) return null

    return dealData
  }, [dealData])

  const badgeColor = useCallback((status?: string) => {
    if (status !== AssignmentJob.ORDER_DELIVERED) return 'orange'
    return 'green'
  }, [])

  const productName = useCallback((assignment: AssignmentDTOClient) => {
    return assignment.products.map((product) => t(`product:p_${product.id}`)).join(', ')
  }, [t])

  const filterTabList = useMemo(() => {
    const filteredData = order?.missions.filter((assignment) => ProductKindsWithDocumentGallery.has(assignment.products[0].kind))

    return filteredData?.map((assignment) => {
      const productKind = assignment.products[0].kind

      const steps = getProgressCategoryData(assignment.stage, assignment.products)
      const activeStepData = steps?.find((step) => step.activeStep)

      return (
        <FilterTab
          key={assignment.id}
          height="5.6rem"
          isActive={assignmentId === assignment.id}
          icon={
            <CircleIcon
              size="3.2rem"
              circleColor={assignmentId !== assignment.id ? BEIGE_600 : undefined}
              icon={getProductKindIcon(productKind)}
            />
          }
          label={
            <Typography
              variant="text-sm"
              fontWeight="semiBold"
              color={GRAY_900}
              sx={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                lineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {productName(assignment)}
            </Typography>
          }
          badge={
            !!activeStepData &&

            <MUIBadge
              label={t(`order_informations.stages.${activeStepData?.jobDone}`)}
              size="sm"
              color={badgeColor(activeStepData.jobDone)}
            />
          }
          sx={{
            maxWidth: '35rem',
            flexShrink: 0
          }}
          onClick={() => navigate(Path.GALLERY.replace(':id', assignment.id.toString()))}
        />

      )
    })
    // t, navigate skipped from deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentId, order?.missions, dealAssignmentsForTab, productName, badgeColor])

  return (
    <Fragment>
      {isMobileView
        ? (
          <Box
            position="absolute"
            top="3.2rem"
            left="0"
            width="100vw"
            display="flex"
            gap=".8rem"
            overflow="auto"
            padding="0 2rem"
            boxSizing="border-box"
            sx={{
              '::-webkit-scrollbar': {
                display: 'none'
              }
            }}
          >
            {filterTabList}
          </Box>
        )
        : (
          <Grid container marginTop="3.2rem" gap=".8rem">
            {filterTabList}
          </Grid>
        )
      }
    </Fragment>
  )
}
