import { useCallback, useState } from 'react'
import { ReactGeocodePlace, ReactGeocodePoint } from 'react-geocode'

import constate from 'constate'
import { Nullable } from 'models/helpers'

export const initialZoom = 4
export const zoomedIn = 14
export const initialCenter: ReactGeocodePoint = { lat: 46.4601654, lng: 7.5588792 }
export const libraries: ('places' | 'drawing' | 'geometry' | 'localContext' | 'visualization')[] = ['places']

export const [AddressMapContextProvider, useAddressMap] = constate(() => {

  const [address, setAddress] = useState<string>('')
  const [placeObject, setPlaceObject] = useState<Nullable<ReactGeocodePlace>>(null)
  const [mapCenter, setMapCenter] = useState<ReactGeocodePoint>(initialCenter)
  const [markerPosition, setMarkerPosition] = useState<Nullable<ReactGeocodePoint>>(null)
  const [zoom, setZoom] = useState<number>(initialZoom)

  /** Set component state from the Geocode API place object */
  const updateStateFromPlace = useCallback((place: ReactGeocodePlace | null, isMapPinAdjusted: boolean = true) => {

    setAddress(place?.formatted_address ?? '')
    setPlaceObject(place)

    if (place !== null && isMapPinAdjusted) {
      const { lat, lng } = place.geometry.location

      setMapCenter({ lat, lng })
      setMarkerPosition({ lat, lng })
    }
  }, [])

  return {
    address,
    setAddress,
    placeObject,
    setPlaceObject,
    mapCenter,
    setMapCenter,
    markerPosition,
    setMarkerPosition,
    zoom,
    setZoom,
    initialCenter,
    zoomedIn,
    updateStateFromPlace,
  }
})
