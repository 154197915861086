import React, { Fragment } from 'react'
import { Stack, Typography } from '@mui/material'
import { useOrderPriceBreakdownGallery, useOrderProducts } from 'utils/hooks'

import { GRAY_900 } from 'constants/styling/theme'
import { GalleryInfoCard } from 'components/common/Gallery/GalleryInfoCard'
import { MUIDivider } from 'components/common/MUIDivider'
import { ProductLine } from '../common/ProductLine'
import { formatPrice } from 'utils/price'
import { useGalleryDeal } from '../../contexts'
import { useTranslation } from 'react-i18next'

/**
 * @component Price summary card component display in client gallery information tab
 * @example <ClientGalleryPriceSummaryCard />
 */

export const ClientGalleryPriceSummaryCard: React.FC = () => {
  const { t } = useTranslation(['gallery'])
  const {
    dealData,
  } = useGalleryDeal()
  const {
    vat,
    currency,
    computedTotalVat,
    computedTotalAfterVat,
    computedTotalAfterDiscount,
    percentageDiscount,
    totalWithoutDiscount,
    discountedValue
  } = useOrderPriceBreakdownGallery(dealData)
  const {
    purchasedProducts,
    organizationProducts
  } = useOrderProducts(dealData)

  return (
    <GalleryInfoCard title={t('order_informations.summary')}>
      {purchasedProducts.length > 0 &&
        <Stack width="100%">
          {purchasedProducts.map((product, index) => <ProductLine key={`${index}-${product.productId}`} product={product} />)}
          <MUIDivider margin={16} />
        </Stack>
      }

      {organizationProducts.length > 0 &&
        <Stack width="100%">
          {organizationProducts.map((product, index) => <ProductLine key={`${index}-${product.productId}`} product={product} />)}
          <MUIDivider margin={16} />
        </Stack>
      }

      <Stack width="100%">
        {percentageDiscount.gt(0) &&
          <Fragment>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-end"
              marginBottom=".7rem"
            >
              <Typography variant="text-xs" fontWeight="regular">{t('order_informations.sum')}</Typography>
              <Typography variant="text-sm" fontWeight="semiBold" color={GRAY_900}>{formatPrice(totalWithoutDiscount, currency)}</Typography>
            </Stack>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-end"
              marginBottom=".7rem"
            >
              <Typography variant="text-xs" fontWeight="regular">{t('order_informations.discount')} {formatPrice(percentageDiscount)} % </Typography>
              <Typography variant="text-sm" fontWeight="semiBold" color={GRAY_900}>- {formatPrice(discountedValue, currency)}</Typography>
            </Stack>
          </Fragment>
        }
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
          marginBottom=".7rem"
        >
          <Typography variant="text-xs" fontWeight="medium">{t('order_informations.total_pricing')}</Typography>
          <Typography variant="text-xl" fontWeight="semiBold" color={GRAY_900}>{formatPrice(computedTotalAfterDiscount, currency)}</Typography>
        </Stack>
        <Stack
          flexDirection="row"
          justifyContent="flex-end"
        >
          <Typography variant="text-xs" fontWeight="regular" marginRight=".4rem">{t('order_informations.vat')} ({formatPrice(vat)} %)</Typography>
          <Typography variant="text-xs" fontWeight="semiBold" color={GRAY_900}>{formatPrice(computedTotalVat, currency)}</Typography>
        </Stack>
        <Stack
          flexDirection="row"
          justifyContent="flex-end"
        >
          <Typography variant="text-xs" fontWeight="regular" marginRight=".4rem">{t('order_informations.after_vat')}</Typography>
          <Typography variant="text-xs" fontWeight="semiBold" color={GRAY_900}>{formatPrice(computedTotalAfterVat, currency)}</Typography>
        </Stack>
      </Stack>
    </GalleryInfoCard>
  )
}
