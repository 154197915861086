import { RoomRemovableObjects, RoomTypes } from '../RoomStep'
import { useCallback, useLayoutEffect, useState } from 'react'

import { AnalyticsEvent } from 'utils/analytics'
import { BEIGE_500 } from 'constants/styling/theme'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { MUIButton } from 'components/common/MUIButton'
import { RoomItems } from '../RoomItems'
import { SelectedRoomItemSection } from '../SelectedRoomItemSection'
import Stack from '@mui/material/Stack'
import { StagingSpecialRequestPopup } from '../StagingSpecialRequestPopup'
import { StagingSpecialRequestSection } from '../StagingSpecialRequestSection'
import { SystemMessage } from 'components/common/SystemMessage'
import { VirtualFurnitureRoomRemovableOption } from 'constants/virtualFurniture'
import { useStyleSelection } from '../main/contexts'
import { useTranslation } from 'react-i18next'

export enum VisualStyleSelectionContainerState {
  ROOM_TYPES_AND_REMOVABLE_OPTIONS,
  ROOM_ITEMS,
}

/** 
 * @component
 * VisualStyleSelectionsContainer displays several selection steps - room types, room items (decorations, floor, furniture, add-ons...)
 * 
 * @example
 * <VisualStyleSelectionsContainer />
 */
export const VisualStyleSelectionsContainer = () => {
  const { t } = useTranslation(['style_selection_order_step'])

  const { activeRoom, isRoomItemSelected, isBKBNDecide, stagingFlowAnalytics } = useStyleSelection()

  const [visualStyleSelectionStep, setVisualStyleSelectionStep] = useState<VisualStyleSelectionContainerState>(() => {
    if (activeRoom?.roomTypes.size) return VisualStyleSelectionContainerState.ROOM_ITEMS
    else return VisualStyleSelectionContainerState.ROOM_TYPES_AND_REMOVABLE_OPTIONS
  })

  const isRoomTypesAndOptionsStep = visualStyleSelectionStep === VisualStyleSelectionContainerState.ROOM_TYPES_AND_REMOVABLE_OPTIONS
  const isRoomItemsStep = visualStyleSelectionStep === VisualStyleSelectionContainerState.ROOM_ITEMS

  const isSpecialRequestComments = activeRoom?.comment

  const saveRoomType = useCallback(() => {
    stagingFlowAnalytics(AnalyticsEvent.ROOM_TYPE_SAVED, { RoomTypes: activeRoom.roomTypes.toArray() })
    setVisualStyleSelectionStep(VisualStyleSelectionContainerState.ROOM_ITEMS)
  }, [activeRoom.roomTypes, stagingFlowAnalytics])

  useLayoutEffect(() => {
    setVisualStyleSelectionStep(() => {
      if (activeRoom?.roomTypes.size) return VisualStyleSelectionContainerState.ROOM_ITEMS
      else return VisualStyleSelectionContainerState.ROOM_TYPES_AND_REMOVABLE_OPTIONS
    })

    // Only use the key for triggering his code
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRoom?.key])

  return (
    <Stack gap="1.6rem" width="70%" flexShrink={0} boxSizing="border-box">

      <BorderBoxWrapper padding={2} height="100%" width="100%" backgroundColor={BEIGE_500}>

        {/** ROOM TYPES & REMOVABLE OBJECTS SELECTION */}
        {activeRoom && isRoomTypesAndOptionsStep &&
          <Stack gap={3} alignItems="center">

            {/** Room types */}
            <RoomTypes />

            {/** Room removable objects */}
            <RoomRemovableObjects />

            <MUIButton
              type={'defaultPrimary'}
              variant='contained'
              disabled={activeRoom.roomTypes.isEmpty() || !activeRoom.furnitureRemoval}
              onClick={saveRoomType}>
              {t('style_selection_order_step:save_room_type')}
            </MUIButton>

          </Stack>
        }

        {/** STAGING TYPE & ROOM ITEMS SELECTION */}
        {isRoomItemsStep && <RoomItems setVisualStyleSelectionStep={setVisualStyleSelectionStep} />}

      </BorderBoxWrapper>

      {isRoomTypesAndOptionsStep && activeRoom.furnitureRemoval === VirtualFurnitureRoomRemovableOption.NONE &&
        <SystemMessage title={t('style_selection_order_step:none_removals_title')} message={t('style_selection_order_step:none_removals_text')} variant="system" />
      }

      {isRoomItemsStep && activeRoom.skipRenovations &&
        <SystemMessage title={t('style_selection_order_step:none_renovations_title')} message={t('style_selection_order_step:none_renovations_text')} variant="system" />
      }

      {isRoomItemsStep && isRoomItemSelected && !isBKBNDecide && <SelectedRoomItemSection />}

      {isRoomItemsStep && !!isSpecialRequestComments && <StagingSpecialRequestSection />}

      <StagingSpecialRequestPopup />

    </Stack>
  )
}
