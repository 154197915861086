import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { EntityKeys, QueryType, queryClient } from 'utils/reactQuery'
import { FC, Fragment, useEffect, useLayoutEffect, useMemo } from 'react'
import { SubscriptionPlan, SubscriptionStatus, WorkspaceMember, WorkspaceRole } from 'models/user'

import { AddMembersPopup } from '../AddMembersPopup/AddMembersPopup.module'
import BlockInfo from 'components/common/BlockInfo/BlockInfo'
import { Box } from '@mui/material'
import Button from 'components/common/Button/Button'
import { CancelSubscription } from '../CancelSubscription'
import { ChangeMemberRolePopup } from '../ChangeMemberRolePopup'
import { ChargebeeModal } from 'components/common/ChargebeeModal'
import DynamicQueryContent from 'components/common/DynamicQueryContent/DynamicQueryContent'
import { GeneralTab } from 'components/common/GeneralTab'
import { KeyboardEventKey } from 'constants/misc'
import { MUIBadge } from 'components/common/MUIBadge'
import { PageTransition } from 'utils/animations'
import { Path } from 'constants/router'
import { QueryStatus } from 'components/common/QueryStatus'
import { RemoveMemberPopup } from '../RemoveMemberPopup'
import { TIMEOUT_AFTER_SUCCESSFUL_CALLBACK } from 'constants/application'
import { WorkspaceInvitationCard } from '../WorkspaceInvitationCard'
import { WorkspaceMemberCard } from 'components/pages/Client/WorkspacesProfile/WorkspaceMemberCard'
import { WorkspaceProfileSettingDrawer } from '../WorkspaceProfileSettingDrawer/WorkspaceProfileSettingDrawer.module'
import classnames from 'classnames'
import styles from './WorkspacesProfile.module.sass'
import { useAuth0 } from 'utils/auth'
import { useDispatch } from 'react-redux'
import { useGetPortalLink } from 'dataQueries/subscriptions.query'
import { useNavigate } from 'react-router'
import { useRevokeWorkspaceInvitation } from 'dataQueries'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'
import { useWorkspacesProfileContext } from './WorkspacesProfile.context'

export interface ChangeMemberData {
  type: ChangeMemberType
  memberId: WorkspaceMember['userId']
  name: WorkspaceMember['name']
  email: WorkspaceMember['email']
  isRestricted: WorkspaceMember['isRestricted']
  newRole: WorkspaceRole
}

export enum ChangeMemberType {
  ROLE,
  ACCESS
}

/** Controller for user's workspaces profile */
export const WorkspacesProfileController: FC<{
  workspaceId: string
}> = ({
  workspaceId,
}) => {
    const { roles } = useAuth0()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { baseUserData, hasUserActiveSubscription, activeSubscribedUserWorkspaces } = useUserData()
    const { t } = useTranslation(['workspace_profile'])
    const revokeInvitation = useRevokeWorkspaceInvitation()

    const manageSubscriptionPortalLink = useGetPortalLink(workspaceId, window.location.href)

    const {
      allUserWorkspacesResponse,
      workspaceData,
      workspaceDataOfUser,
      workspaceCreationDate,
      canUserSeeMembersListSection,
      userWorkspaceInvitedData,
      addMembersPopup,
      setAddMembersPopup,
      removeMember,
      setRemoveMember,
      changeMemberRole,
      setChangeMemberRole,
      isSettingDrawerOpen,
      setIsSettingDrawerOpen,
    } = useWorkspacesProfileContext()

    const subscriptionStatus = useMemo(() => {
      if (!workspaceData) return

      const plan = t(`workspace_plan.${workspaceData.plan}`)

      if ([SubscriptionPlan.ENTERPRISE_FIXED_RATE, SubscriptionPlan.ENTERPRISE_PER_SEAT, SubscriptionPlan.ENTERPRISE, SubscriptionPlan.PRO_PER_SEAT].includes(workspaceData.plan)) {
        return plan
      }

      const tier = `${workspaceData.tier.maxUsers ? workspaceData.tier.maxUsers : t('max_users')} ${t('seats')}`
      const status = workspaceData.workspaceStatus === SubscriptionStatus.CANCELED ? `(${t('canceled_status')})` : ''

      return `${plan} - ${tier} ${status}`
    }, [t, workspaceData])

    // log entering this page
    useEffect(() => {
      logAnalyticsEvent(AnalyticsEvent.WORKSPACE_PROFILE_ENTERED_WORKSPACES_PROFILE, {
        userId: baseUserData?.id,
        planId: workspaceId,
      })
    }, [baseUserData, workspaceId])

    useLayoutEffect(() => {
      if (revokeInvitation.isSuccess) {
        window.setTimeout(() => {
          revokeInvitation.reset()
        }, TIMEOUT_AFTER_SUCCESSFUL_CALLBACK)
      }
    }, [revokeInvitation, dispatch])

    const onResetChangeMemberRole = () => {
      setChangeMemberRole(null)
    }

    if (!roles.isClient || !hasUserActiveSubscription) {
      navigate(Path.INDEX)
      return <></>
    }

    return (
      <PageTransition>
        <div className={`page ${styles.workspace}`}>
          <div className="page-content">
            {!!allUserWorkspacesResponse &&
              <DynamicQueryContent
                query={allUserWorkspacesResponse}
                statusPosition='above'
              >
                <Fragment>
                  <h1 className={styles.heading}>
                    {t('workspaces')}
                  </h1>
                  <div className={styles.tabs}>

                    {activeSubscribedUserWorkspaces && activeSubscribedUserWorkspaces.map((workspace) => (
                      <GeneralTab
                        key={workspace.id}
                        active={workspaceId === workspace.id}
                        onClick={() => navigate(Path.WORKSPACES_PROFILE.replace(':id', workspace.id))}
                      >
                        <strong className={styles.workspaceName}>{workspace.name}</strong>
                        {workspace.invitationId &&
                          <Box marginLeft="1.2rem">
                            <MUIBadge color="coral" size="sm" label={t('pending_invitations')} />
                          </Box>
                        }
                      </GeneralTab>
                    ))}
                  </div>

                  {userWorkspaceInvitedData && <WorkspaceInvitationCard />}

                  {!!workspaceData && !userWorkspaceInvitedData &&
                    <div className={styles.content}>
                      <div className={styles.boxWrapperSubscription}>
                        <div className={styles.subscription}>
                          <div className={styles.section}>

                            <div className={styles.infoWrapper}>
                              <div className={styles.midline}></div>
                              <h2 className={styles.subheading}>{workspaceData.name}</h2>
                              <div className={styles.midline}></div>
                            </div>
                            <div className={styles.infoWrapper}>
                              <span className={`${styles.orange} ${styles.marginBottom}`}>{t('workspace_plan.title')}</span>
                              <a
                                href={Path.PRICING}
                                className={styles.plan}
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                              >
                                {subscriptionStatus}
                              </a>
                            </div>
                          </div>

                          <div className={classnames(styles.section, styles.right)}>
                            <BlockInfo color="blue" className={styles.infoWrapper}>
                              <span>{t('start_date')}</span>
                              <span>{workspaceCreationDate}</span>
                            </BlockInfo>
                          </div>
                        </div>

                        {workspaceDataOfUser?.role === WorkspaceRole.ADMIN &&
                          <div className={styles.manageSubscription}>

                            <Button
                              className={styles.action}
                              type="secondary nobackground"
                              disabled={manageSubscriptionPortalLink.isFetching}
                              isLoading={manageSubscriptionPortalLink.isFetching}
                              onClick={() => {
                                manageSubscriptionPortalLink.refetch()
                                logAnalyticsEvent(AnalyticsEvent.WORKSPACE_PROFILE_MANAGE_BILLING, {
                                  planId: workspaceId,
                                })
                              }}
                            >
                              {t('manage_subscription')}
                            </Button>

                            <QueryStatus
                              query={manageSubscriptionPortalLink}
                              spaceTopRem={2}
                              showStates={['error']}
                            />

                            <ChargebeeModal
                              title={t('manage_subscription')}
                              hostedPageUrl={manageSubscriptionPortalLink.data?.url}
                              isOpen={!!manageSubscriptionPortalLink.data}
                              handleClose={() => queryClient.resetQueries({ queryKey: [EntityKeys.PORTAL_LINK, QueryType.GET] })
                              }
                            />

                          </div>
                        }
                      </div>

                      {canUserSeeMembersListSection && <WorkspaceMemberCard workspaceId={workspaceId} />}

                      {!!changeMemberRole?.memberId &&
                        <ChangeMemberRolePopup
                          isOpen={!!changeMemberRole}
                          workspaceId={workspaceId}
                          changeMemberRole={changeMemberRole}
                          onClickOutsideOrClose={() => onResetChangeMemberRole()}
                          onKeyDown={e => e.key === KeyboardEventKey.ESCAPE && onResetChangeMemberRole()}
                          callbackAfterSuccessfulResponse={() => onResetChangeMemberRole()}
                        />
                      }

                      {!!removeMember &&
                        <RemoveMemberPopup
                          isOpen={!!removeMember}
                          workspaceId={workspaceId}
                          memberEmail={removeMember}
                          onClickOutsideOrClose={() => setRemoveMember(undefined)}
                          onKeyDown={e => e.key === KeyboardEventKey.ESCAPE && setRemoveMember(undefined)}
                          callbackAfterSuccessfulResponse={() => setRemoveMember(undefined)}
                        />
                      }

                      <AddMembersPopup
                        workspaceId={workspaceId}
                        isOpen={addMembersPopup}
                        onClickOutside={() => setAddMembersPopup(false)}
                        onClickClose={() => setAddMembersPopup(false)}
                        onKeyDown={(e) => e.key === KeyboardEventKey.ESCAPE && setAddMembersPopup(false)}
                      />

                      <WorkspaceProfileSettingDrawer
                        isOpen={isSettingDrawerOpen}
                        onClose={() => setIsSettingDrawerOpen(false)}
                      />

                      {(workspaceDataOfUser?.role === WorkspaceRole.ADMIN) && <CancelSubscription workspaceId={workspaceId} />}

                    </div>
                  }
                </Fragment>
              </DynamicQueryContent>
            }
          </div>
        </div>
      </PageTransition>
    )
  }
