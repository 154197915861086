import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { MeasurementOnSiteAdditionalInformationType, MeasurementOnSitePropertyType, MeasurementOnSiteRequestDTO } from 'models/purchaseFlow/measurementDTO'
import { useCallback, useState } from 'react'

import { ProductKind } from 'constants/product'
import constate from 'constate'

/** Mapping property type with extra measurement product */
export const extraProductsForMeasurementsPropertyType = new Map<MeasurementOnSitePropertyType, ProductKind>([
  [MeasurementOnSitePropertyType.APARTMENT_SINGLE_FLOORS, ProductKind.EXTRA_SINGLE_FLOOR_HOUSE_TYPE],
  [MeasurementOnSitePropertyType.SEMI_DETACHED_HOUSE, ProductKind.EXTRA_DETACHED_HOUSE_TYPE],
  [MeasurementOnSitePropertyType.APARTMENT_BUILDING, ProductKind.EXTRA_DETACHED_HOUSE_TYPE],
  [MeasurementOnSitePropertyType.SINGLE_FAMILY_HOUSE, ProductKind.EXTRA_SINGLE_FAMILY_HOUSE_2ND_FLOOR],
])

/** Mapping property type with extra measurement product with residential unit */
export const MeasurementExtraProductWithResidential = new Set([
  MeasurementOnSitePropertyType.SEMI_DETACHED_HOUSE,
  MeasurementOnSitePropertyType.APARTMENT_BUILDING,
])

export const [MeasurementsContextProvider, useMeasurements] = constate(() => {
  const [measurementPropertyType, setMeasurementPropertyType] = useState<MeasurementOnSitePropertyType>(MeasurementOnSitePropertyType.SINGLE_FAMILY_HOUSE)
  const [surfaceArea, setSurfaceArea] = useState<string>('')
  const [numberOfPropertyUnit, setNumberOfPropertyUnit] = useState<number>(0)
  const [numberOfRooms, setNumberOfRooms] = useState<number>(0)
  const [numberOfFloors, setNumberOfFloors] = useState<number>(0)
  const [isBasementChecked, setIsBasementChecked] = useState<boolean>(false)
  const [isAtticChecked, setIsAtticChecked] = useState<boolean>(false)
  const [measurementComments, setMeasurementComments] = useState<string>('')
  const [additionalInformation, setAdditionalInformation] = useState<Set<MeasurementOnSiteAdditionalInformationType>>(new Set())

  const getMeasurementRequestData = useCallback((): MeasurementOnSiteRequestDTO => {
    return {
      propertyType: measurementPropertyType,
      surfaceArea: Number(surfaceArea),
      numberOfRooms,
      numberOfFloors,
      numberOfResidentialUnits: numberOfPropertyUnit,
      basementAreaToMeasure: isBasementChecked,
      atticAreaToMeasure: isAtticChecked,
      additionalInformation: Array.from(additionalInformation),
      comment: measurementComments
    }
  }, [additionalInformation, isAtticChecked, isBasementChecked, measurementComments, measurementPropertyType, numberOfFloors, numberOfPropertyUnit, numberOfRooms, surfaceArea])

  const logMeasurementRequirements = useCallback(() => {
    const additionalAreas = []
    if (isBasementChecked) additionalAreas.push(ProductKind.EXTRA_MEASURED_BASEMENT)
    if (isAtticChecked) additionalAreas.push(ProductKind.EXTRA_MEASURED_ATTIC)

    logAnalyticsEvent(AnalyticsEvent.MEASUREMENTS_REQUIREMENT_SET_UP, {
      PropertyType: measurementPropertyType,
      SurfaceArea: surfaceArea,
      NumberRooms: numberOfRooms,
      NumberResidentialUnit: numberOfPropertyUnit,
      AdditionalAreas: additionalAreas,
      MeasurementReason: Array.from(additionalInformation),
      Comments: measurementComments,
      NumberFloors: numberOfFloors,
    })
  }, [additionalInformation, isAtticChecked, isBasementChecked, measurementComments, measurementPropertyType, numberOfFloors, numberOfPropertyUnit, numberOfRooms, surfaceArea])

  return {
    surfaceArea,
    numberOfRooms,
    numberOfFloors,
    isAtticChecked,
    isBasementChecked,
    measurementComments,
    numberOfPropertyUnit,
    additionalInformation,
    measurementPropertyType,
    setSurfaceArea,
    setNumberOfRooms,
    setNumberOfFloors,
    setIsAtticChecked,
    setIsBasementChecked,
    setMeasurementComments,
    setNumberOfPropertyUnit,
    setMeasurementPropertyType,
    getMeasurementRequestData,
    setAdditionalInformation,
    logMeasurementRequirements
  }
})
