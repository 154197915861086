import { VirtualFurnitureRoomRemovableOption, VirtualFurnitureRoomType } from 'constants/virtualFurniture'
import { CatalogueOptionProduct, OptionProductType } from 'dataQueries/purchase.query'
import { useCallback, useMemo } from 'react'
import { bigFromDecimal, formatPrice } from 'utils/price'

import constate from 'constate'
import { Nullable } from 'models/helpers'
import { ProductKindDTO } from 'models/product'
import { ComputedStagingRoom } from 'models/virtualStaging'

/** Props required for the RenovationProductProvider context. */
interface RenovationProductProviderProps {
  /** Renovation product data. */
  renovationProduct: Nullable<ProductKindDTO>
}

function useRenovationProductsAPI({ renovationProduct }: RenovationProductProviderProps) {

  /** Whether the given room has a renovation product. */
  const hasRoomRenovationProducts = useCallback((room: ComputedStagingRoom) => {
    const isEmptyRoom = room.roomTypes.has(VirtualFurnitureRoomType.EMPTY_ROOM)
    const selectedWall = room.renovations.has(VirtualFurnitureRoomType.WALL_MATERIALS)
    const selectedFloor = room.renovations.has(VirtualFurnitureRoomType.FLOORING)
    const selectedRemovalObject = !!room.furnitureRemoval && room.furnitureRemoval !== VirtualFurnitureRoomRemovableOption.NONE

    if (isEmptyRoom) {
      return selectedRemovalObject && (selectedWall || selectedFloor)
    } else {
      return selectedWall || selectedFloor || selectedRemovalObject
    }
  }, [])

  /** Returns a set of room keys which has a renovation product. */
  const getRoomsWithRenovationProducts = useCallback((allRooms: ComputedStagingRoom[]) => {
    let roomsWithRenovation: Set<string> = new Set()

    if (allRooms.length > 0) {
      allRooms.forEach((room) => {
        const hasRenovationProducts = hasRoomRenovationProducts(room)

        if (hasRenovationProducts) {
          roomsWithRenovation.add(room.key)
        }

        if (!hasRenovationProducts && roomsWithRenovation.has(room.key)) {
          roomsWithRenovation.delete(room.key)
        }
      })
    }

    return roomsWithRenovation
  }, [hasRoomRenovationProducts])

  /** Returns the product to be added into the cart. */
  const renovationCartProduct = useMemo((): CatalogueOptionProduct | undefined => {
    if (!renovationProduct) return undefined

    return ({
      ...renovationProduct,
      duration: renovationProduct.shootingDuration,
      value: renovationProduct.price,
      type: OptionProductType.INTEGER
    })

  }, [renovationProduct])

  /** Price of one renovation product (Big). */
  const renovationProductUnitPrice = useMemo(() => renovationCartProduct ? bigFromDecimal(renovationCartProduct.feePrice.value || 0) : null, [renovationCartProduct])

  /** Price of one renovation product with currency. (String) */
  const renovationProductStringPrice = useMemo(() => formatPrice(renovationProduct?.feePrice), [renovationProduct?.feePrice])

  return {
    renovationProduct,
    renovationCartProduct,
    renovationProductUnitPrice,
    renovationProductStringPrice,
    getRoomsWithRenovationProducts,
  }
}

export const [RenovationProductsContextProvider, useRenovationProducts] = constate(useRenovationProductsAPI)
