import { BEIGE_400, BLACK_TEXT, GRAY_400, GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { FC, ReactNode, useCallback, useMemo, useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { AssignmentDTOCreative } from 'models/assignment'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { DateTimeValidationError } from '@mui/x-date-pickers'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import { FeatureFlag } from 'utils/featureFlags'
import { Label } from 'components/common/Label'
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded'
import { MUIButton } from 'components/common/MUIButton'
import { MUICheckbox } from 'components/common/MUICheckBox'
import { MUIDatePicker } from 'components/common/MUIDatePicker'
import { MUIInputField } from 'components/common/MUIInputField'
import { MUIRadio } from 'components/common/MUIRadio'
import { MUISwitch } from 'components/common/MUISwitch'
import { MUITooltip } from 'components/common/MUITooltip'
import Modal from 'components/common/Modals/Modal/Modal'
import { QueryStatus } from 'components/common/QueryStatus'
import { SchedulePayload as ScheduleAssignmentPayload } from 'dataQueries'
import { UseMutationResult } from '@tanstack/react-query'
import moment from 'moment-timezone'
import styles from './ScheduleMeetingPopup.module.sass'
import { supportEmailHref } from 'constants/contacts'
import { useDynamicNow } from 'utils/hooks'
import { useFlag } from '@unleash/proxy-client-react'
import { useTimezone } from 'components/contexts/timezone.context'

export enum LateShootingScheduleReason {
  WEATHER_RESTRICTIONS = 'WEATHER_RESTRICTIONS',
  PROPERTY_NOT_READY = 'PROPERTY_NOT_READY',
  DATE_REQUESTED_BY_CONTACT_PERSON = 'DATE_REQUESTED_BY_CONTACT_PERSON',
  EARLIER_CALLS_UNANSWERED = 'EARLIER_CALLS_UNANSWERED',
}

const ContactInfo: FC<{ title: string, icon: ReactNode }> = ({
  title,
  icon
}) => (
  <Stack flexDirection="row" justifyContent="center" alignItems="center" gap=".4rem">
    {icon}
    <Typography variant="text-sm" fontWeight={600} color={BLACK_TEXT}>{title}</Typography>
  </Stack>
)

interface Props {
  /** Creative assignment data to display and use */
  assignment: AssignmentDTOCreative
  /** Mutation objet that handles the schedule */
  mutation: UseMutationResult<any, any, any>
  /** Function that handles submit action */
  handleSubmit: (payload: ScheduleAssignmentPayload) => void
  /** Whether the popup is open or not */
  isOpen: boolean
  /** OnClose action to close the popup */
  onClose: () => void
}

/**
 * Popup for creative schedule meeting date and time with client.
 * @example
 *  <ScheduleMeetingPopup
 *    assignment={assignment}
 *    isOpen={true}
 *    onClose={() => setIsOpen(false)}
 *  />
 */
export const ScheduleMeetingPopup: FC<Props> = ({
  assignment,
  isOpen,
  mutation,
  handleSubmit,
  onClose,
}) => {
  const minutesStep = 15
  const { t } = useTranslation(['deal_assignment_card', 'common', 'schedule_meeting_popup'])
  const { userTimezone } = useTimezone()

  const dynamicNow = useDynamicNow()

  const defaultDate = useMemo(() => {
    const now = moment()
    const minutesDiff = minutesStep - (now.minutes() % minutesStep)

    return moment(now)
      .add(minutesDiff, 'minutes')
      .set({ seconds: 0, milliseconds: 0 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const maxDate = useMemo(() => moment().add(1, 'month'), [])

  const [meetingDate, setMeetingDate] = useState(defaultDate)
  const [lateScheduleReason, setLateScheduleReason] = useState<LateShootingScheduleReason>()
  const [lateScheduleComment, setLateScheduleComment] = useState<string>('')
  const [autoScheduleChecked, setAutoScheduleChecked] = useState<boolean>(false)
  const [agreementChecked, setAgreementChecked] = useState<boolean>(false)
  const [validationError, setValidationError] = useState<DateTimeValidationError>(null)

  const allowGrouped = useFlag(FeatureFlag.ALLOW_GROUPED_CT_ASSIGNMENTS)

  // Compare deadline with dynamically updating now, in edge case of no deadline, treat as on time
  const isPastDeadline = useMemo(
    () => {
      if (!assignment.scheduledByCTDeadline) return false
      return moment(assignment.scheduledByCTDeadline).isBefore(dynamicNow)
    },
    [assignment.scheduledByCTDeadline, dynamicNow]
  )

  const isSubmitDisabled = useMemo(() => {
    if (!agreementChecked || validationError) return true
    if (isPastDeadline && !lateScheduleReason) return true

    return false
  }, [agreementChecked, lateScheduleReason, isPastDeadline, validationError])

  const submitArrangeDateTimeMeeting = useCallback(() => {
    if (!meetingDate || !agreementChecked) return

    if (isSubmitDisabled) return

    handleSubmit(
      {
        assignmentId: assignment.id,
        dateTime: meetingDate.toISOString(true),
        arrangeAllAssignments: allowGrouped || autoScheduleChecked,
        lateSchedulingReason: isPastDeadline ? lateScheduleReason : undefined,
        lateSchedulingComment: isPastDeadline ? lateScheduleComment : undefined,
      }
    )
  }, [agreementChecked, allowGrouped, assignment.id, autoScheduleChecked, handleSubmit, isPastDeadline, isSubmitDisabled, lateScheduleComment, lateScheduleReason, meetingDate])

  const resetPopup = useCallback(() => {
    mutation.reset()
    setMeetingDate(defaultDate)
    setAgreementChecked(false)
    setAutoScheduleChecked(false)
    setLateScheduleReason(undefined)
    setLateScheduleComment('')
  }, [defaultDate, mutation])

  return (
    <Modal
      isOpen={isOpen}
      title={t('schedule_meeting_popup.title')}
      modalContentClassName={styles.modal}
      onClose={onClose}
      afterClosed={resetPopup}
      footerContent={
        <Stack direction="row" justifyContent="flex-end" paddingTop={2} gap={2}>

          <MUIButton type="secondaryBorder" onClick={onClose}>
            {t('Cancel')}
          </MUIButton>

          <MUITooltip content={!!validationError && t('schedule_meeting_popup.validation_datetime_error')}>
            <MUIButton
              disabled={isSubmitDisabled}
              onClick={() => submitArrangeDateTimeMeeting()}
              isLoading={mutation.isPending}
            >
              {t('schedule_meeting_popup.submit_schedule_meeting')}
            </MUIButton>
          </MUITooltip>

        </Stack>
      }
    >

      <Stack gap="2.4rem">

        <Typography variant="text-md" color={GRAY_900}>
          <Trans
            t={t}
            i18nKey={'schedule_meeting_popup.description'}
          >
            <a className={styles.contactLink} href={supportEmailHref()}>&nbsp;</a>
          </Trans>
        </Typography>

        <Stack gap=".9rem">
          <Label color={BLACK_TEXT} text={t('schedule_meeting_popup.contact_person')} />

          <BorderBoxWrapper
            boxShadow={undefined}
            backgroundColor={BEIGE_400}
            padding="1.2rem"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <ContactInfo title={assignment.contactPerson.name} icon={<AccountCircleOutlinedIcon />} />

            <ContactInfo title={assignment.contactPerson.phone} icon={<LocalPhoneRoundedIcon />} />

            <ContactInfo title={assignment.contactPerson.email || '—'} icon={<EmailRoundedIcon />} />
          </BorderBoxWrapper>
        </Stack>

        <MUIDatePicker
          defaultValue={defaultDate}
          value={meetingDate}
          maxDate={maxDate}
          minDateTime={defaultDate}
          onChange={(date) => setMeetingDate(date || defaultDate)}
          timeSteps={{ minutes: minutesStep }}
          timezone={userTimezone}
          disablePast
          format='DD/MM/YYYY HH:mm'
          ampm={false}
          label={t('schedule_meeting_popup.date_and_time')}
          onError={(error) => setValidationError(error)}
          isError={validationError === 'invalidDate'}
          errorText={t('schedule_meeting_popup.validation_datetime_error')}
        />

        {isPastDeadline &&
          <BorderBoxWrapper elevation="none" borderColor={GRAY_400} padding={2}>
            <Stack
              direction="column"
              gap={2}
            >

              <Stack direction="column" gap={.5}>

                <span style={{ marginBottom: '1rem' }}>
                  <Trans t={t} i18nKey="schedule_meeting_popup.late_schedule_info" parent={null} />
                </span>

                <Stack direction="column" gap={1} alignItems="flex-start">
                  {Object.values(LateShootingScheduleReason).map((reason) => (
                    <MUIRadio
                      key={reason}
                      checked={lateScheduleReason === reason}
                      onChange={() => setLateScheduleReason(reason)}
                      value={reason}
                      label={t(`schedule_meeting_popup.reasons.${reason}`)}
                    />
                  ))}
                </Stack>

              </Stack>

              <MUIInputField
                label={t('schedule_meeting_popup.late_schedule_comment_label')}
                value={lateScheduleComment}
                onChange={(e) => setLateScheduleComment(e.target.value)}
                isMultiline
              />

            </Stack>
          </BorderBoxWrapper>
        }

        {!allowGrouped &&
          <MUISwitch
            checked={autoScheduleChecked}
            onChange={() => setAutoScheduleChecked(!autoScheduleChecked)}
            size="small"
            mt={1}
            labelPlacement="end"
            label={
              <Stack gap={.5}>
                <Typography fontWeight={600} variant="text-xs" color={GRAY_900}>{t('schedule_meeting_popup.auto_schedule_title')}</Typography>
                <Typography variant="text-xs" color={GRAY_700}>{t('schedule_meeting_popup.auto_schedule_description')}</Typography>
              </Stack>
            }
          />
        }

        <Stack flexDirection="row" justifyContent="flex-start" alignItems="center" gap="1rem">
          <MUICheckbox
            checked={agreementChecked}
            onChange={(e) => setAgreementChecked(e.target.checked)}
            label={<Label color={GRAY_900} text={t('schedule_meeting_popup.confirm_checkbox')} required />}
          />
        </Stack>

      </Stack>

      {/* QUERY STATUS */}
      <Stack marginTop={2}>
        <QueryStatus query={mutation} spaceTopRem={1} showStates={['error']} />
      </Stack>

    </Modal>
  )
}
