import { BEIGE_600, GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { Box, Stack, TableCell, TableRow, Typography } from '@mui/material'

import { FC } from 'react'
import { MUIButton } from 'components/common/MUIButton'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Path } from 'constants/router'
import { ReactComponent as PropertyPlaceholderIcon } from 'assets/img/icons/property_placeholder.svg'
import { RealEstatePropertyListItemDTO } from 'models/property'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { externalNavigate } from 'utils/helpers'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props
 */
interface Props {
  /** Property data. */
  property: RealEstatePropertyListItemDTO
}

/**
 * @component Properties dashboard table row
 * @example
 * <PropertiesDashboardTableRow />
 */
export const PropertiesDashboardTableRow: FC<Props> = ({ property }) => {
  const { t } = useTranslation(['properties_dashboard'])

  return (
    <TableRow key={property.id}>

      {/* Title */}
      <TableCell component="th" scope="row">
        <Stack flexDirection="row" alignItems="center" gap="1.6rem">

          <Box borderRadius=".4rem" padding=".6rem .6rem .4rem .6rem" sx={{ background: BEIGE_600 }}>
            <PropertyPlaceholderIcon />
          </Box>

          <Typography variant="text-sm" fontWeight="semiBold" color={GRAY_900}>{property.title ? property.title : '-'}</Typography>

        </Stack>
      </TableCell>

      {/* Address */}
      <TableCell scope="row">
        <Stack>

          <Typography variant="text-sm" fontWeight={500} color={GRAY_900}>{property.address?.postalCode} {property.address?.street}</Typography>

          <Typography
            variant="text-xs"
            color={GRAY_700}
            fontWeight={400}
            sx={{
            }}
          >
            {property.address?.city}, {property.address?.country}
          </Typography>

        </Stack>
      </TableCell>

      {/* Created date */}
      <TableCell>
        <Typography variant="text-sm" fontWeight={400} color={GRAY_900}>{moment(property.createdOn).format('DD/MM/YYYY')}</Typography>
      </TableCell>

      {/* Property manager */}
      <TableCell>
        <Typography variant="text-sm" fontWeight={400} color={GRAY_900}>{property.broker?.name ? property.broker.name : '-'}</Typography>
      </TableCell>

      {/* Action */}
      <TableCell
        align="right"
        sx={{
          position: 'relative',
        }}
      >
        <MUIDropdown
          button={(isOpen, action) => (
            <MUIButton
              isIconButton
              size="sm"
              type="secondaryNoBorder"
              onClick={(e) => action(e)}
            >
              <MoreVertIcon fontSize="large" />
            </MUIButton>
          )}
        >
          <MUIDropdownItem>
            <MUIButton
              size="sm"
              isIconButton
              startIcon={<VisibilityOutlinedIcon />}
              type="secondaryNoBorder"
              onClick={() => externalNavigate(Path.PROPERTY_VISUALS_GALLERY.replace(':id', property.id))}
            >
              {t('show')}
            </MUIButton>
          </MUIDropdownItem>
        </MUIDropdown>
      </TableCell>

    </TableRow>
  )
}
