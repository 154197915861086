import { ClientPropertyInformationContent } from '../../ClientPropertyInformation'
import { FC } from 'react'

export const ClientPropertyInformationPage: FC = () => {

  return (
    // TODO ADD PROVIDERS HERE WHEN THE CONTENT IS DEVELOPED
    <ClientPropertyInformationContent />
  )
}
