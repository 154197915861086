import { ClientPropertyContextProvider, PropertyTab } from '../contexts'
import { FC, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { ClientPropertyPageLayout } from '../controllers/ClientPropertyPageLayout.controller'
import { DealDTOClient } from 'models/deal'
import NotFound from 'components/pages/General/NotFound/NotFound'
import { PageTransition } from 'utils/animations'
import { Path } from 'constants/router'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { getPropertyAssignmentsByTab } from 'utils/helpers/getPropertyAssignmentsByTab'
import { redesignMUITheme } from 'constants/styling/redesignTheme'
import { useGetPropertyAssignments } from 'dataQueries'
import { useUserData } from 'components/contexts/UserDataContext'

export interface OutletProps {
  propertyId: string
  tab: string
  assignmentId: string
  propertyAssignments: DealDTOClient
}

export const ClientProperty: FC = () => {
  const { id, tab, assignmentId } = useParams<{ id: string, tab: PropertyTab, assignmentId: string }>()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { userPropertyWorkspaceId } = useUserData()

  const derivedTab = useMemo(() => {
    if (tab) return tab
    const regex = new RegExp(`property/${id}/([^/]+)`)

    const match = pathname.match(regex)

    return match ? match[1] as PropertyTab : PropertyTab.GALLERY
  }, [id, tab, pathname])

  const propertyId = useMemo(() => id, [id])
  const workspaceId = useMemo(() => userPropertyWorkspaceId, [userPropertyWorkspaceId])

  const getPropertyAssignments = useGetPropertyAssignments(propertyId ?? '', workspaceId ?? '')
  const propertyAssignmentsData = useMemo(() => getPropertyAssignments.data?.data ?? null, [getPropertyAssignments.data])
  const filteredPropertyAssignmentsDataByTab = useMemo(() => getPropertyAssignmentsByTab(derivedTab, propertyAssignmentsData), [propertyAssignmentsData, derivedTab])
  const firstAssignmentId = filteredPropertyAssignmentsDataByTab[0]?.id

  useEffect(() => {
    if (derivedTab === PropertyTab.AI_TEXT_GENERATOR || derivedTab === PropertyTab.ORDERS || derivedTab === PropertyTab.INFORMATION) return

    if (!assignmentId && firstAssignmentId)
      navigate(Path.PROPERTY_TAB_ASSIGNMENT.replace(':id', propertyId!).replace(':tab', derivedTab).replace(':assignmentId', firstAssignmentId))

    if (!assignmentId && !firstAssignmentId)
      navigate(Path.PROPERTY_TAB.replace(':id', propertyId!).replace(':tab', derivedTab))

    // exclude navigate() from dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentId, firstAssignmentId, propertyId])

  if (!propertyId) return <NotFound />

  return (
    <ThemeProvider theme={redesignMUITheme}>
      <ClientPropertyContextProvider
        propertyId={propertyId}
        tab={derivedTab}
        assignmentId={assignmentId ?? firstAssignmentId}
        propertyAssignmentsData={propertyAssignmentsData}
      >
        <PageTransition>
          <ClientPropertyPageLayout />
        </PageTransition>
      </ClientPropertyContextProvider>
    </ThemeProvider>
  )
}
