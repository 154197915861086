import { FC, ReactElement } from 'react'

import { useApiIsLoaded } from '@vis.gl/react-google-maps'

/**
 * Props for the InnerWrapper component.
 * @interface InnerWrapperProps
 */
export interface InnerWrapperProps {
  /** The children components or content to be rendered - specific for wrapping Google Map and Autocomplete component. */
  children: ReactElement
}

/**
 * @component
 * InnerWrapper component for wrapping the Google map.
 * @vis.gl/react-google-maps API will always render the children no matter what. We need to make sure that the children are only rendered when the API is loaded.
 * 
 * @example
 * <InnerWrapper>
 *   <AddressMap />
 * </InnerWrapper>
 */
export const InnerWrapper: FC<InnerWrapperProps> = ({
  children
}) => {
  const apiIsLoaded = useApiIsLoaded()

  if (apiIsLoaded) return children

  return <></>
}
