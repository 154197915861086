import React, { Fragment, useMemo } from 'react'
import { Stack, Typography } from '@mui/material'

import { GRAY_900 } from 'constants/styling/theme'
import { GalleryInfoCard } from 'components/common/Gallery/GalleryInfoCard'
import { MUIDivider } from 'components/common/MUIDivider'
import { formatPrice } from 'utils/price'
import { useClientProperty } from '../_main/contexts'
import { useGetPropertyOrders } from 'dataQueries'
import { useOrderPriceBreakdownProperty } from 'utils/hooks'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @component Price summary card component display in client gallery information tab
 * @example <ClientPropertyPriceSummaryCard />
 */

export const ClientPropertyPriceSummaryCard: React.FC = () => {
  const { t } = useTranslation(['gallery'])

  const { propertyId } = useClientProperty()
  const { userPropertyWorkspaceId } = useUserData()

  const getPropertyOrdersQuery = useGetPropertyOrders(propertyId, userPropertyWorkspaceId)
  const propertyOrdersData = useMemo(() => getPropertyOrdersQuery.data?.data, [getPropertyOrdersQuery.data])

  const priceDataByOrderId = useOrderPriceBreakdownProperty(propertyOrdersData)

  if (!propertyOrdersData) return <></>

  const totalComputedAfterDiscount = Object.values(priceDataByOrderId).reduce((acc, order) => acc + order.computedTotalAfterDiscount.toNumber(), 0)
  const currency = Object.values(priceDataByOrderId)[0].currency
  const vat = Object.values(priceDataByOrderId)[0].vat
  const totalComputedVat = Object.values(priceDataByOrderId).reduce((acc, order) => acc + order.computedTotalVat.toNumber(), 0)
  const totalComputedAfterVat = Object.values(priceDataByOrderId).reduce((acc, order) => acc + order.computedTotalAfterVat.toNumber(), 0)

  return (
    <GalleryInfoCard title={t('order_informations.summary')}>

      {Object.values(priceDataByOrderId).map((order) => {
        const {
          totalWithoutDiscount,
          percentageDiscount,
          discountedValue,
          computedTotalAfterDiscount,
          currency,
          orderId
        } = order
        return (
          <Stack width="100%">

            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-end"
              marginBottom=".7rem"
            >
              <Typography variant="text-md" fontWeight="semiBold" color={GRAY_900}>{`${t('order_informations.order')} #${orderId}`}</Typography>
              <Typography variant="text-md" fontWeight="semiBold" color={GRAY_900}>{formatPrice(computedTotalAfterDiscount, currency)}</Typography>
            </Stack>

            {percentageDiscount.gt(0) &&
              <Fragment>
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  marginBottom=".7rem"
                >
                  <Typography variant="text-xs" fontWeight="regular">{t('order_informations.sum')}</Typography>
                  <Typography variant="text-sm" fontWeight="semiBold" color={GRAY_900}>{formatPrice(totalWithoutDiscount, currency)}</Typography>
                </Stack>
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  marginBottom=".7rem"
                >
                  <Typography variant="text-xs" fontWeight="regular">{t('order_informations.discount')} {formatPrice(percentageDiscount)} % </Typography>
                  <Typography variant="text-sm" fontWeight="semiBold" color={GRAY_900}>- {formatPrice(discountedValue, currency)}</Typography>
                </Stack>
              </Fragment>
            }

          </Stack>
        )
      })}

      <Stack width="100%">

        <MUIDivider margin={8} />

        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
          marginBottom=".7rem"
        >
          <Typography variant="text-xs" fontWeight="medium">{t('order_informations.total_pricing')}</Typography>
          <Typography variant="text-xl" fontWeight="semiBold" color={GRAY_900}>{formatPrice(totalComputedAfterDiscount, currency)}</Typography>
        </Stack>
        <Stack
          flexDirection="row"
          justifyContent="flex-end"
        >
          <Typography variant="text-xs" fontWeight="regular" marginRight=".4rem">{t('order_informations.vat')}</Typography>
          <Typography variant="text-xs" fontWeight="semiBold" color={GRAY_900}>{formatPrice(totalComputedVat, currency)}</Typography>
        </Stack>
        <Stack
          flexDirection="row"
          justifyContent="flex-end"
        >
          <Typography variant="text-xs" fontWeight="regular" marginRight=".4rem">{t('order_informations.after_vat')}</Typography>
          <Typography variant="text-xs" fontWeight="semiBold" color={GRAY_900}>{formatPrice(totalComputedAfterVat, currency)}</Typography>
        </Stack>

      </Stack>

    </GalleryInfoCard>
  )
}
