import { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { ProductKind, ProductType } from 'constants/product'
import { bigFromDecimal, percentageValue, valueAfterDiscount } from 'utils/price'

import { AdditionalVisualPriceDTO } from 'models/visuals'
import { AxiosResponse } from 'axios'
import Big from 'big.js'
import { Currency } from 'constants/misc'
import { PIPEDRIVE_INFINITY } from 'constants/pipedrive'
import { ProductCategory } from 'models/product'
import { isEditingCategory } from 'utils/validators'
import { useAuth0 } from 'utils/auth'
import { useGalleryAssignment } from './GalleryAssignment.context'
import { useGalleryConstants } from './GalleryConstants.context'
import { useGalleryProduct } from './GalleryProduct.context'
import { useGalleryVisualSelection } from './GalleryVisualSelection.context'
import { useGalleryVisualType } from './GalleryVisualType.context'
import { useGalleryVisualsMeta } from './GalleryVisualsMeta.context'
import { useGetAdditionalVisualPrice } from 'dataQueries'

const disabledVisualsAssignmentTypes = new Set([
  ProductType.FLOOR_PLAN,
  ProductType.VIRTUAL_VISIT,
  ProductType.VIDEO,
])

const disabledVisualsProductKinds = new Set([
  ProductKind.COMMERCIAL_STAGING,
  ProductKind.RESIDENTIAL_STAGING,
])

interface GalleryAdditionalVisualsInterface {
  getAdditionalVisualPriceResponse?: AxiosResponse<AdditionalVisualPriceDTO, any>
  currency?: Currency
  pricePerAdditionalVisual: Big
  vat: Big
  discount: Big
  selectedAdditionalVisualsCount: number
  selectedFreeVisualsCount: number
  selectedPaidVisualsCount: number
  totalForAdditionalVisualsBeforeDiscount: Big
  totalForAdditionalVisualsAfterDiscount: Big
  totalVatForAdditionalVisuals: Big
  totalAfterVatForAdditionalVisuals: Big
  areAdditionalVisualsSelected: boolean
  isAdditionalVisualPurchaseDisabled: boolean
  additionalVisualProductId?: number
  isAdditionalVisualsPurchaseModalOpen: boolean
  setIsAdditionalVisualsPurchaseModalOpen: Dispatch<SetStateAction<boolean>>
}

const defaultGalleryAdditionalVisualsValue: GalleryAdditionalVisualsInterface = {
  pricePerAdditionalVisual: new Big(0),
  vat: new Big(0),
  discount: new Big(0),
  selectedAdditionalVisualsCount: 0,
  totalForAdditionalVisualsBeforeDiscount: new Big(0),
  totalForAdditionalVisualsAfterDiscount: new Big(0),
  totalVatForAdditionalVisuals: new Big(0),
  totalAfterVatForAdditionalVisuals: new Big(0),
  areAdditionalVisualsSelected: false,
  isAdditionalVisualPurchaseDisabled: false,
  additionalVisualProductId: undefined,
  isAdditionalVisualsPurchaseModalOpen: false,
  setIsAdditionalVisualsPurchaseModalOpen: () => { },
  selectedFreeVisualsCount: 0,
  selectedPaidVisualsCount: 0,
}

/** Gallery additional visuals context */
export const GalleryAdditionalVisualsContext = createContext<GalleryAdditionalVisualsInterface>(defaultGalleryAdditionalVisualsValue)
/** Gallery additional visuals context hook */
export const useGalleryAdditionalVisuals = (): GalleryAdditionalVisualsInterface => useContext(GalleryAdditionalVisualsContext)

/** Context provider for gallery additional visuals */
export const GalleryAdditionalVisualsContextProvider: FC<{
  assignmentId: string
  children?: ReactNode
}> = ({
  assignmentId,
  children,
}) => {
    const { roles } = useAuth0()

    const {
      VisualTypeSelectEnum,
    } = useGalleryConstants()

    const {
      assignmentData,
      product,
    } = useGalleryAssignment()

    const {
      visualTypeSelect,
    } = useGalleryVisualType()

    const {
      isVirtualVisit,
    } = useGalleryProduct()

    const {
      purchasedVisualsExist,
      prepurchasedVisualsTotal,
      prepurchasedVisualsRemaining,
    } = useGalleryVisualsMeta()

    const {
      selected,
      canSelectVisuals,
      selectedNotPurchasedVisualsCount,
    } = useGalleryVisualSelection()

    const [isAdditionalVisualsPurchaseModalOpen, setIsAdditionalVisualsPurchaseModalOpen] = useState(false)

    // React query
    const getAdditionalVisualPrice = useGetAdditionalVisualPrice(assignmentId)

    const getAdditionalVisualPriceResponse = getAdditionalVisualPrice.data

    const isAdditionalVisualPurchaseDisabled = useMemo(() => {
      if (!assignmentData) return true
      if (assignmentData.type && disabledVisualsAssignmentTypes.has(assignmentData?.type)) return true
      if (!product) return true
      // Excludes AI photo editing type from the check
      if (isEditingCategory(product.category as ProductCategory) && product.type !== ProductType.AI_PHOTO_EDITING) return true
      if (disabledVisualsProductKinds.has(product.kind)) return true

      return false
    }, [assignmentData, product])

    const currency = useMemo(() => getAdditionalVisualPriceResponse?.data?.fee.currency, [getAdditionalVisualPriceResponse])
    const pricePerAdditionalVisual = useMemo(() => bigFromDecimal(getAdditionalVisualPriceResponse?.data?.fee.value || 0), [getAdditionalVisualPriceResponse])
    const vat = useMemo(() => bigFromDecimal(getAdditionalVisualPriceResponse?.data?.vat.value || 0), [getAdditionalVisualPriceResponse])
    const discount = useMemo(() => bigFromDecimal(getAdditionalVisualPriceResponse?.data?.discount.value || 0), [getAdditionalVisualPriceResponse])
    const additionalVisualProductId = useMemo(() => getAdditionalVisualPriceResponse?.data?.productId, [getAdditionalVisualPriceResponse])

    const selectedAdditionalVisualsCount = useMemo(() => {
      if (isAdditionalVisualPurchaseDisabled) return 0

      if (!purchasedVisualsExist) {
        if (selected.size > prepurchasedVisualsTotal) return (selected.size - prepurchasedVisualsTotal)
        return 0
      }

      if (prepurchasedVisualsRemaining > 0) {
        if (selectedNotPurchasedVisualsCount > prepurchasedVisualsRemaining) return (selectedNotPurchasedVisualsCount - prepurchasedVisualsRemaining)
        return 0
      }

      return selectedNotPurchasedVisualsCount
    }, [isAdditionalVisualPurchaseDisabled, purchasedVisualsExist, prepurchasedVisualsRemaining, selectedNotPurchasedVisualsCount, selected.size, prepurchasedVisualsTotal])

    const selectedPaidVisualsCount = useMemo(() => {
      const paidCount = Math.max(selectedNotPurchasedVisualsCount - prepurchasedVisualsRemaining, 0)

      if (paidCount > 0 && isAdditionalVisualPurchaseDisabled) return 0

      return paidCount
    }, [isAdditionalVisualPurchaseDisabled, prepurchasedVisualsRemaining, selectedNotPurchasedVisualsCount])

    const selectedFreeVisualsCount = useMemo(() => Math.min(selectedNotPurchasedVisualsCount, prepurchasedVisualsRemaining), [prepurchasedVisualsRemaining, selectedNotPurchasedVisualsCount])

    const totalForAdditionalVisualsBeforeDiscount = useMemo(() => pricePerAdditionalVisual.times(selectedAdditionalVisualsCount), [pricePerAdditionalVisual, selectedAdditionalVisualsCount])
    const totalForAdditionalVisualsAfterDiscount = useMemo(() => valueAfterDiscount(totalForAdditionalVisualsBeforeDiscount, discount), [totalForAdditionalVisualsBeforeDiscount, discount])
    const totalVatForAdditionalVisuals = useMemo(() => percentageValue(totalForAdditionalVisualsAfterDiscount, vat), [totalForAdditionalVisualsAfterDiscount, vat])
    const totalAfterVatForAdditionalVisuals = useMemo(() => totalForAdditionalVisualsAfterDiscount.plus(totalVatForAdditionalVisuals), [totalForAdditionalVisualsAfterDiscount, totalVatForAdditionalVisuals])
    const areAdditionalVisualsSelected = useMemo(
      () => !isVirtualVisit && canSelectVisuals && (roles.isClient || roles.isAdmin) && (visualTypeSelect === VisualTypeSelectEnum.POST || visualTypeSelect === VisualTypeSelectEnum.POST_WATERMARK) && !isAdditionalVisualPurchaseDisabled && selectedAdditionalVisualsCount > 0 && prepurchasedVisualsTotal !== PIPEDRIVE_INFINITY && !getAdditionalVisualPriceResponse,
      [isVirtualVisit, canSelectVisuals, roles.isClient, roles.isAdmin, visualTypeSelect, VisualTypeSelectEnum.POST, VisualTypeSelectEnum.POST_WATERMARK, isAdditionalVisualPurchaseDisabled, selectedAdditionalVisualsCount, prepurchasedVisualsTotal, getAdditionalVisualPriceResponse]
    )

    // Fetch additional visual price action when selected size exceeds the bought value
    useEffect(() => {
      if (areAdditionalVisualsSelected) {
        getAdditionalVisualPrice.refetch()
      }
    }, [areAdditionalVisualsSelected, assignmentId, getAdditionalVisualPrice])

    return (
      <GalleryAdditionalVisualsContext.Provider
        value={{
          getAdditionalVisualPriceResponse,
          currency,
          pricePerAdditionalVisual,
          vat,
          discount,
          selectedAdditionalVisualsCount,
          totalForAdditionalVisualsBeforeDiscount,
          totalForAdditionalVisualsAfterDiscount,
          totalVatForAdditionalVisuals,
          totalAfterVatForAdditionalVisuals,
          areAdditionalVisualsSelected,
          isAdditionalVisualPurchaseDisabled,
          additionalVisualProductId,
          isAdditionalVisualsPurchaseModalOpen,
          selectedFreeVisualsCount,
          selectedPaidVisualsCount,
          setIsAdditionalVisualsPurchaseModalOpen,
        }}
      >
        {children}
      </GalleryAdditionalVisualsContext.Provider>
    )
  }
