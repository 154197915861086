import { ArchiveVisualsTag, archiveVisuals } from 'redux/Individual/Visual/ArchiveVisuals'
import React, { FC, useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { VisualDownloadError, VisualDownloadFinished, VisualDownloadLoading, VisualDownloadReady } from '../VisualDownloadStatuses'
import { useGalleryAssignment, useGalleryVisualSelection, useGalleryVisualType, useGalleryVisualsMeta } from '../../../_main/contexts'

import { AssignmentDTOIsCreativeDTO } from 'utils/typeguards'
import Confetti from 'react-confetti'
import { CustomerRating } from '../CustomerRating'
import Modal from 'components/common/Modals/Modal/Modal'
import { VisualDownloadImagesShowcase } from '../VisualDownloadImagesShowcase'
import classnames from 'classnames'
import { logDownloadRetryAfterError } from '../../../_main/galleryUtils'
import styles from './VisualDownloadPopup.module.sass'
import { trackIntercomEvent } from 'utils/analytics'
import { useAuth0 } from 'utils/auth'
import { useDebouncedMemo } from 'utils/helpers'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'
import { useVisualDownloadPopup } from './VisualDownloadPopup.context'

type LeftMode = 'loading' | 'ready' | 'finished' | 'error'
type RightMode = 'csat' | 'showcase'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Decides if modal is open */
  isOpen: boolean
  /** Onclick action triggered when user clicks on the close button */
  onClickClose?: (e?: React.MouseEvent) => unknown
}

/**
 * Controller for VisualDownloadPopup.
 * 
 * DEPENDENCIES:
 * - Auth0 Context
 * - GalleryAssignmentContext
 * - GalleryVisualTypeContext
 * - GalleryStagingFlowContext
 * - UserDataContext
 * - GalleryVisualSelectionContext
 * - VisualDownloadStagingContext
 * - VisualDownloadPopupContext
 * 
 * @example
 * <VisualDownloadPopupController isOpen={true} onClickClose={() => undefined} />
 * 
 */
export const VisualDownloadPopupController: FC<Props> = ({
  isOpen,
  onClickClose,
}) => {

  // -- CONTEXTS AND HOOKS
  const { t } = useTranslation(['visual_download_popup'])
  const dispatch = useDispatch()
  const { roles } = useAuth0()

  const isSmall = useMediaQuery('(max-width:960px)')

  const {
    baseUserData,
    organizationData,
    nonPersonalUserWorkspaces,
  } = useUserData()

  const {
    product,
    assignmentData,
  } = useGalleryAssignment()

  const { prepurchasedVisualsTotal } = useGalleryVisualsMeta()

  const { transformTypes } = useGalleryVisualType()

  const { selected } = useGalleryVisualSelection()

  const {
    isCSATFinished,
    isDownloadReady,
    hasDownloadStarted,
    hasDownloadFailed,
    downloadRequest,
    downloadUrl,
    setHasDownloadStarted,
    resetPopup,
  } = useVisualDownloadPopup()

  // -- STATE VALUES

  const [runConfetti, setRunConfetti] = useState<boolean>(false)

  // -- COMPUTED VALUES

  // Debounce to prevent animation crashes
  const leftMode = useDebouncedMemo<LeftMode>(
    () => {
      if (hasDownloadFailed) return 'error'
      if (!isDownloadReady) return 'loading'
      if (hasDownloadStarted) return 'finished'
      return 'ready'
    },
    [hasDownloadFailed, hasDownloadStarted, isDownloadReady],
    150,
    1000
  )

  const rightMode = useMemo<RightMode>(
    () => {
      if (!isCSATFinished) return 'csat'
      return 'showcase'
    },
    [isCSATFinished]
  )

  // -- CALLBACKS

  const handleTrackingIntercomDownloadEvent = useCallback(() => {
    if (!assignmentData || !product) return
    if (AssignmentDTOIsCreativeDTO(assignmentData)) return

    trackIntercomEvent('visual_download_triggered', {
      productKind: product.kind,
      productType: product.type,
      productCategory: product.category,
      assignmentId: assignmentData.id,
      orderId: assignmentData.dealId,
      numberOfVisualsOrdered: prepurchasedVisualsTotal,
      numberOfVisualsDownloaded: selected.size,
    })
  }, [assignmentData, product, prepurchasedVisualsTotal, selected.size])

  const handleDownloadClick = useCallback(() => {
    if (!downloadUrl) return
    window.location.replace(downloadUrl)
    setHasDownloadStarted(true)
    setRunConfetti(true)
    handleTrackingIntercomDownloadEvent()
  }, [downloadUrl, handleTrackingIntercomDownloadEvent, setHasDownloadStarted])

  const retryArchiveDownload = useCallback(async () => {
    if (!product) return
    if (!assignmentData) return

    // Trigger downloading of selected visuals, user retry flow
    dispatch(archiveVisuals(assignmentData.id, transformTypes(), Array.from(selected), ArchiveVisualsTag.CLIENT_ARCHIVE, false))

    // Track archive download retry
    logDownloadRetryAfterError(product, roles, baseUserData?.id, organizationData?.id, nonPersonalUserWorkspaces)
  }, [product, assignmentData, dispatch, transformTypes, selected, roles, baseUserData, organizationData, nonPersonalUserWorkspaces])

  // -- EFFECTS

  // Reset confetti in case of closing before they fall off
  useLayoutEffect(() => {
    if (!isOpen) setRunConfetti(false)
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClickClose}
      onClickOutside={onClickClose}
      className={styles.modalWrapper}
      afterClosed={() => resetPopup()}
      modalContentClassName={classnames(styles.modalContent, {
        [styles.isGreen]: !['loading', 'reminder'].includes(leftMode),
        [styles.isRed]: leftMode === 'error',
        [styles.isSmall]: isSmall,
      })}
      scrollableContentClassName={styles.scrollableContent}
    >

      <Confetti
        style={{ zIndex: '0' }}
        width={window.innerWidth}
        height={window.innerHeight}
        recycle={false}
        confettiSource={{
          x: 0,
          y: window.innerHeight,
          w: 1600,
          h: 0,
        }}
        initialVelocityX={25}
        initialVelocityY={20}
        gravity={0.025}
        wind={0.01}
        run={runConfetti}
        numberOfPieces={500}
        tweenDuration={200}
        onConfettiComplete={(confetti) => {
          setRunConfetti(false)
          confetti?.reset()
        }}
      />

      {/* LEFT (DOWNLOAD) PANEL */}
      <div className={classnames(
        styles.left,
        { [styles.isFullscreen]: isCSATFinished }
      )}>

        {/* LOADING */}
        <VisualDownloadLoading
          className={styles.leftStep}
          visible={leftMode === 'loading'}
          isMobile={isSmall && !isCSATFinished}
        />

        {/* READY */}
        <VisualDownloadReady
          className={styles.leftStep}
          visible={leftMode === 'ready'}
          isMobile={isSmall && !isCSATFinished}
          onDownloadClick={!hasDownloadStarted ? handleDownloadClick : () => undefined}
        />

        {/* ERROR */}
        <VisualDownloadError
          className={styles.leftStep}
          visible={leftMode === 'error'}
          isMobile={isSmall && !isCSATFinished}
          onRetryClick={retryArchiveDownload}
          errorData={downloadRequest?.error?.data}
        />

        {/* FINISHED FULLSCREEN */}
        <VisualDownloadFinished
          className={styles.leftStep}
          visible={leftMode === 'finished'}
          isMobile={isSmall && !isCSATFinished}
          onClickClose={onClickClose}
          onRestartDownload={handleDownloadClick}
        />

      </div>

      {/* RIGHT (WORK) PANEL */}
      <div className={classnames(styles.right, { [styles.isFullscreen]: isCSATFinished })}>
        <div className={classnames(styles.scrollable, { [styles.noscroll]: rightMode === 'showcase' })}>

          {/* CSAT */}
          {rightMode === 'csat' &&
            <div className={styles.csat}>
              <div className={styles.titleBar}>
                <h3>{t('csat_subtitle')}</h3>
                <h1>{t('csat_title')}</h1>
              </div>
              <CustomerRating />
            </div>
          }

          {/* SHOWCASE DECORATION */}
          {rightMode === 'showcase' && <VisualDownloadImagesShowcase />}

        </div>

      </div>

    </Modal>
  )

}
