import React, { Fragment, ReactNode, forwardRef } from 'react'
import { Stack, SxProps, Theme, ThemeProvider } from '@mui/material'

import Button from '@mui/material/Button'
import { ButtonMUITheme } from 'constants/styling/buttonTheme'
import ReactLoading from 'react-loading'
import classnames from 'classnames'
import styles from './MUIButton.module.sass'

/**
 * @typedef ButtonVariant Available button variant
 */
export type ButtonVariant = 'contained' | 'outlined' | 'text'

/**
 * @typedef ButtonType Available button type
 */
export type ButtonType = 'defaultPrimary' | 'orangePrimary' | 'darkPrimary' | 'secondaryNoBorder' | 'darkSecondaryNoborder' | 'orangeSecondaryNoBorder' | 'secondaryBorder' | 'orangeSecondaryBorder' | 'greenPrimary' | 'greenSecondary'

/**
 * @typedef ButtonSize Available button size
 */
export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg'

/**
 * @type ButtonBorder Available button border styles
 */
export type ButtonBorder = 'sharp' | 'rounded'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Button id. */
  id?: string
  /** Override or extend the styles applied to the component. */
  className?: string
  /** Button type */
  type?: ButtonType
  /** Button size */
  size?: ButtonSize
  /** Whether the button is full width (100%) or not */
  fullWidth?: boolean
  /** Button border style */
  borderStyle?: ButtonBorder
  /** Button variant */
  variant?: ButtonVariant
  /** Whether the button is disabled or not */
  disabled?: boolean
  /** Whether the button is disabled or not */
  isLoading?: boolean
  /** Whether the button is disabled or not */
  isIconButton?: boolean
  /** Button icon in the start position */
  startIcon?: ReactNode
  /** Button icon in the end position */
  endIcon?: ReactNode
  /** Custom tab sx styles props */
  sx?: SxProps<Theme>,
  /** Onclick action to be called */
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  children?: ReactNode
}

/**
 * @component MUIButton 
 * @example
 * <MUIButton type="defaultPrimary" size="md" variant="contained" />
 */
export const MUIButton = forwardRef<HTMLButtonElement, Props>(({
  id = '',
  className = '',
  type = 'defaultPrimary',
  borderStyle = 'rounded',
  size = 'md',
  fullWidth = false,
  variant,
  disabled,
  startIcon,
  endIcon,
  isLoading,
  isIconButton = false,
  onClick,
  children,
  sx,
  ...props
}, ref) =>
  <ThemeProvider theme={ButtonMUITheme}>
    <Button
      id={id}
      aria-describedby={id}
      className={classnames(className, styles.muiButton, styles[borderStyle])}
      onClick={e => onClick && onClick(e)}
      variant={variant}
      size={size}
      disabled={disabled}
      fullWidth={fullWidth}
      disableRipple
      sx={{
        borderWidth: '1px',
        borderStyle: 'solid',
        color: `${type}.label`,
        bgcolor: `${type}.main`,
        borderColor: `${type}.border`,
        '&:disabled': {
          bgcolor: `${type}.disabled`,
          color: `${type}.disabledLabel`,
          borderColor: `${type}.disabledWithBorder`,
        },
        '&:hover': {
          bgcolor: `${type}.hover`,
          borderColor: `${type}.borderOnHover`,
        },
        padding: isIconButton ? '.4rem' : '',
        ...sx,
      }}
      ref={ref}
      {...props}
    >
      {isLoading ?
        <Stack height="2.4rem" flexDirection="row" alignItems="center">
          <ReactLoading color="currentColor" type="spin" width="2rem" height="2rem" />
        </Stack>
        :
        <Fragment>
          {!!startIcon && startIcon}
          {children}
          {!!endIcon && endIcon}
        </Fragment>
      }
    </Button>
  </ThemeProvider>
)
