import { AddCard, AddPhotoAlternate } from '@mui/icons-material'
import { Box, useMediaQuery } from '@mui/material'
import { CurrentActionType, ToolBarType, useClientGallery } from '../_main/contexts'
import { FC, Fragment, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { decimalFromBig, formatPrice } from 'utils/price'
import { useGalleryAdditionalVisuals, useGalleryAssignment, useGalleryDeal, useGalleryProduct, useGalleryVisualSelection, useGalleryVisualType, useGalleryVisualsDownloadArchive, useGalleryVisualsMeta } from '../../contexts'

import { AnalyticsEvent } from 'utils/analytics'
import { FeatureFlag } from 'utils/featureFlags'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { GalleryFloatingToolBar } from 'components/common/Gallery/GalleryFloatingToolBar'
import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { MUIButton } from 'components/common/MUIButton'
import { PIPEDRIVE_INFINITY } from 'constants/pipedrive'
import { Path } from 'constants/router'
import { externalNavigate } from 'utils/helpers'
import { listVisuals } from 'redux/Individual/Visual/ListVisuals'
import { useActionPopup } from 'utils/hooks'
import { useDispatch } from 'react-redux'
import { useFlag } from '@unleash/proxy-client-react'
import { useNavigate } from 'react-router-dom'
import { useSelectVisuals } from 'dataQueries'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useUserData } from 'components/contexts/UserDataContext'

enum ToolbarMode {
  SELECTION = 'SELECTION',
  EXPORT_DOWNLOAD = 'EXPORT_DOWNLOAD',
}

/**
 * Floating tool bar for Client Gallery.
 * @example <ClientGalleryFloatingToolBar />
 */
export const ClientGalleryFloatingToolBar: FC = () => {
  const { t } = useTranslation(['gallery'])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)
  const allowEditingToolNonSubscribedUsers = useFlag(FeatureFlag.ALLOW_EDITING_TOOL_NON_SUBSCRIBED_USERS)

  const { hasUserActiveSubscription } = useUserData()
  const { initiateArchiveDownload } = useGalleryVisualsDownloadArchive()
  const {
    assignmentId,
    isToolBarOpen,
    setIsToolBarOpen,
    setToolBarType,
    setCurrentActionType
  } = useClientGallery()
  const {
    prepurchasedVisualsTotal,
    prepurchasedVisualsRemaining,
    purchasedVisualsExist,
    purchasedVisualsKeys,
    unPurchasedVisualsKeys,
    allVisualsFilenames,
  } = useGalleryVisualsMeta()
  const {
    pricePerAdditionalVisual,
    selectedAdditionalVisualsCount,
    totalForAdditionalVisualsAfterDiscount,
    selectedPaidVisualsCount,
    selectedFreeVisualsCount,
    currency,
    isAdditionalVisualPurchaseDisabled,
    setIsAdditionalVisualsPurchaseModalOpen,
  } = useGalleryAdditionalVisuals()
  const {
    selected,
    selectedPurchasedVisualsCount,
    selectedNotPurchasedVisualsCount,
    selectAllPurchased,
    selectAllUnpurchased,
    deselectAll,
  } = useGalleryVisualSelection()
  const { normalizedOriginalType } = useGalleryVisualType()
  const { isVideo } = useGalleryProduct()
  const { logGalleryEvent, product } = useGalleryAssignment()
  const { dealData } = useGalleryDeal()
  const { showConfirm } = useActionPopup()
  const { spawnSuccessToast } = useSnackbar()

  const selectVisualsMutation = useSelectVisuals()

  const allowSelectAllButton = useMemo(() => {
    if (unPurchasedVisualsKeys.size > prepurchasedVisualsRemaining) return !isAdditionalVisualPurchaseDisabled
    return true
  }, [isAdditionalVisualPurchaseDisabled, prepurchasedVisualsRemaining, unPurchasedVisualsKeys.size])

  const mode = useMemo(() => {
    if (!purchasedVisualsExist) return ToolbarMode.SELECTION
    if (selectedNotPurchasedVisualsCount > 0) return ToolbarMode.SELECTION
    return ToolbarMode.EXPORT_DOWNLOAD
  }, [purchasedVisualsExist, selectedNotPurchasedVisualsCount])

  /** Disabled visuals editing for subscription clients with no purchased visuals exist and no selected visuals */
  const isVisualsEditingDisabled = useMemo(() => hasUserActiveSubscription && (!purchasedVisualsExist || !selected.size), [hasUserActiveSubscription, purchasedVisualsExist, selected.size])
  const totalExtraPrice = formatPrice(decimalFromBig(totalForAdditionalVisualsAfterDiscount))
  const selectionModeLabel = useMemo(() => {

    const selectedValue = Math.min(selectedNotPurchasedVisualsCount, prepurchasedVisualsRemaining)
    const isShowExtraValue = selectedPaidVisualsCount > 0 && pricePerAdditionalVisual
    const extraValue = isShowExtraValue ? t('selected_n_extra', { plus: '+', count: selectedPaidVisualsCount, totalPrice: currency ? `(${totalExtraPrice}${currency})` : null }) : ''
    const selectedValueWithMaxValue = `${selectedValue}/${prepurchasedVisualsRemaining}`

    return (
      <Fragment>
        {prepurchasedVisualsRemaining > 0 && <>{selectedValueWithMaxValue} {purchasedVisualsExist ? 'extra' : ''} <Box fontWeight={400} display='inline'>{t('prepaid_visuals')}</Box></>} {!isMobileView && extraValue}
        {isMobileView && <Box fontWeight={400} fontSize={12} display='flex'>{extraValue}</Box>}
      </Fragment>
    )
  }, [selectedNotPurchasedVisualsCount, prepurchasedVisualsRemaining, selectedPaidVisualsCount, pricePerAdditionalVisual, t, currency, totalExtraPrice, purchasedVisualsExist, isMobileView])

  const handleVisualsSelection = useCallback(async () => {
    if (!product) return
    if (!selectedFreeVisualsCount && !selectedPaidVisualsCount) return

    logGalleryEvent(AnalyticsEvent.CLICK_CONFIRM_VISUAL_SELECTION, {
      assignmentOwnerId: dealData?.customer.id,
      assignmentOwnerEmail: dealData?.customer.email,
      productType: product?.type,
      productKind: product?.kind,
      numberOfVisuals: selectedNotPurchasedVisualsCount,
    })

    // Special case for unlimited selection -> mark all as purchased
    if (prepurchasedVisualsTotal === PIPEDRIVE_INFINITY) {
      if (
        !purchasedVisualsExist
        && (await showConfirm(<Trans t={t} i18nKey="select_visuals_confirm_prompt_unlimited_selection">&nbsp;</Trans>, { title: t('select_visuals_confirm_title') }))
      ) {
        selectVisualsMutation.mutate(
          {
            assignmentId,
            filenames: allVisualsFilenames,
            productId: product.id,
          },
          {
            onSuccess: (_, { assignmentId }) => {
              spawnSuccessToast(t('visuals_selected_success'))
              dispatch(listVisuals(assignmentId, normalizedOriginalType))
            }
          }
        )
        return
      }

      return
    }

    // Payment is necessary
    if (selectedPaidVisualsCount > 0) {
      setIsAdditionalVisualsPurchaseModalOpen(true)
      return
    }

    // No payment necessary
    if (selectedFreeVisualsCount > 0 && selectedPaidVisualsCount === 0) {

      if (!(await showConfirm(
        <Trans
          t={t}
          i18nKey={selectedFreeVisualsCount < prepurchasedVisualsRemaining ? 'select_visuals_confirm_prompt_free_remaining' : 'select_visuals_confirm_prompt'}
          values={{ remaining: prepurchasedVisualsRemaining - selectedFreeVisualsCount }}
        >
          &nbsp;
        </Trans>,
        {
          title: t('select_visuals_confirm_title')
        }
      ))) return

      selectVisualsMutation.mutate(
        {
          assignmentId,
          filenames: Array.from(selected),
          productId: product.id,
        },
        {
          onSuccess: (_, { assignmentId }) => {
            spawnSuccessToast(t('visuals_selected_success'))
            dispatch(listVisuals(assignmentId, normalizedOriginalType))
          }
        }
      )
    }

  }, [product, selectedFreeVisualsCount, selectedPaidVisualsCount, logGalleryEvent, dealData?.customer.id, dealData?.customer.email, selectedNotPurchasedVisualsCount, prepurchasedVisualsTotal, purchasedVisualsExist, selectVisualsMutation, assignmentId, allVisualsFilenames, spawnSuccessToast, t, dispatch, normalizedOriginalType, setIsAdditionalVisualsPurchaseModalOpen, prepurchasedVisualsRemaining, showConfirm, selected])

  const additionalVisualsPurchase = useCallback((type: CurrentActionType) => {
    setCurrentActionType(type)
    initiateArchiveDownload()
  }, [initiateArchiveDownload, setCurrentActionType])

  const handleDownload = useCallback(() => {
    logGalleryEvent(AnalyticsEvent.CLICK_DOWNLOAD_VISUALS)

    additionalVisualsPurchase(CurrentActionType.DOWNLOAD)
  }, [additionalVisualsPurchase, logGalleryEvent])

  const onCancelToolBar = useCallback(() => {
    if (purchasedVisualsExist) setToolBarType(ToolBarType.DOWNLOAD)
    setIsToolBarOpen(false)
    deselectAll()
  }, [deselectAll, purchasedVisualsExist, setIsToolBarOpen, setToolBarType])

  const handleExportingOptions = useCallback(() => {
    if (!hasUserActiveSubscription && !allowEditingToolNonSubscribedUsers) {
      logGalleryEvent(AnalyticsEvent.GALLERY_SAAS_UPSELL_EDIT_VISUALS)

      // TODO: Remove all the below code when allowEditingToolNonSubscribedUsers feature flag is removed and BKBN-2475 task is done
      return externalNavigate(Path.PRICING)
    }

    if (selectedAdditionalVisualsCount > 0) return additionalVisualsPurchase(CurrentActionType.EDITING)

    logGalleryEvent(AnalyticsEvent.GALLERY_FLOATING_BAR_EXPORT_OPTIONS)
    navigate(Path.GALLERY_VISUALS_EDIT.replace(':id', assignmentId.toString()))

  }, [additionalVisualsPurchase, allowEditingToolNonSubscribedUsers, assignmentId, hasUserActiveSubscription, logGalleryEvent, navigate, selectedAdditionalVisualsCount])

  return (
    <Fragment>

      <GalleryFloatingToolBar
        isWide
        isToolBarOpen={isToolBarOpen && mode === ToolbarMode.EXPORT_DOWNLOAD}
        toolBarLabel={
          <Fragment>
            {selectedPurchasedVisualsCount} <Box fontWeight={400} display='inline'> {t('selected')}</Box>
          </Fragment>
        }
        openToolBarButtonLabel={t('select_visuals')}
        onOpenToolBar={() => setIsToolBarOpen(true)}
        contentActionContainer={
          <Fragment>
            <MUIButton
              type="darkSecondaryNoborder"
              onClick={onCancelToolBar}
            >
              {t('cancel')}
            </MUIButton>
            {Array.from(purchasedVisualsKeys).every((key) => selected.has(key)) // Typescript v5 supports Set.isSupersetOf / isSubsetOf, however we cannot upgrade to typescript >= 5 since CRA scripts do not support it
              ? (
                <MUIButton
                  type="darkSecondaryNoborder"
                  onClick={deselectAll}
                >
                  {t('unselect_all')}
                </MUIButton>
              )
              : (
                <MUIButton
                  type="darkSecondaryNoborder"
                  onClick={selectAllPurchased}
                >
                  {t('select_all')}
                </MUIButton>
              )
            }
          </Fragment>
        }
        outputActionContainer={
          <Fragment>
            {!isVideo &&
              <MUIButton
                type="darkSecondaryNoborder"
                disabled={isVisualsEditingDisabled}
                onClick={handleExportingOptions}
              >
                {t('exporting_options')}
              </MUIButton>
            }
            <MUIButton
              type="darkPrimary"
              onClick={handleDownload}
              disabled={!selected.size}
              startIcon={<FileDownloadOutlinedIcon fontSize='large' />}
            >
              {t('direct_download')}
            </MUIButton>
          </Fragment>
        }
      />

      <GalleryFloatingToolBar
        isWide
        isToolBarOpen={isToolBarOpen && mode === ToolbarMode.SELECTION}
        toolBarLabel={selectionModeLabel}
        openToolBarButtonLabel={t('select_visuals')}
        onOpenToolBar={() => setIsToolBarOpen(true)}
        contentActionContainer={
          <Fragment>

            <MUIButton
              type="darkSecondaryNoborder"
              onClick={onCancelToolBar}
            >
              {t('cancel')}
            </MUIButton>

            {!allowSelectAllButton && Array.from(unPurchasedVisualsKeys).some((key) => selected.has(key)) &&
              <MUIButton
                type="darkSecondaryNoborder"
                onClick={deselectAll}
              >
                {t('unselect_all')}
              </MUIButton>
            }

            {allowSelectAllButton &&
              <>
                {Array.from(unPurchasedVisualsKeys).every((key) => selected.has(key)) // Typescript v5 supports Set.isSupersetOf / isSubsetOf, however we cannot upgrade to typescript >= 5 since CRA scripts do not support it
                  ? (
                    <MUIButton
                      type="darkSecondaryNoborder"
                      onClick={deselectAll}
                    >
                      {t('unselect_all')}
                    </MUIButton>
                  )
                  : (
                    <MUIButton
                      type="darkSecondaryNoborder"
                      onClick={selectAllUnpurchased}
                    >
                      {t('select_all')}
                    </MUIButton>
                  )
                }
              </>
            }

          </Fragment>
        }
        outputActionContainer={
          <MUIButton
            fullWidth
            type="darkPrimary"
            onClick={() => handleVisualsSelection()}
            startIcon={selectedPaidVisualsCount > 0 ? <AddCard fontSize="large" /> : <AddPhotoAlternate fontSize="large" />}
          >
            {!purchasedVisualsExist
              ? t('confirm_selection')
              : (prepurchasedVisualsRemaining === 0 || selectedPaidVisualsCount > 0)
                ? t('pay_and_add_to_purchased')
                : t('add_to_purchased')
            }
          </MUIButton>
        }
      />

    </Fragment>
  )
}
