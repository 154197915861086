import { AnalyticsEvent, logAnalyticsEvent, standardDebouncedLogAnalyticsEvent } from 'utils/analytics'
import { BundleProductTypes, PrestigeProductKinds, ProductKind, ProductKindsFloorPlan, ProductKindsRequiringFloorPlanCertification, ProductSegment, ProductType, ProductTypesFloorPlan, ProductTypesRequiringFloorPlanCertification } from 'constants/product'
import { CatalogueBundledProduct, CatalogueOptionProduct, CatalogueProduct } from 'dataQueries/purchase.query'
import { Reducer, useEffect, useMemo, useReducer, useState } from 'react'

import { FeeDTO } from 'models/fee'
import { Nullable } from 'models/helpers'
import { SkyReplacementOption } from 'models/purchaseFlow'
import constate from 'constate'
import { usePurchaseFlowConfig } from './PurchaseFlowConfig.context'

interface ProductInventory {
  selectedSegment: ProductSegment | null
  selectedSegmentIndex: number | null
  selectedFilterKinds: Set<ProductKind>
  selectedFilterTypes: Set<ProductType>
  selectedProducts: Record<number, PurchaseFlowProduct>
  selectedProductOptions: Record<number, Record<number, PurchaseFlowProductOption>>
}

/** Possible actions for RoomInventory reducer */
enum ProductInventoryActionType {
  UPDATE_SEGMENT = 'UPDATE_SEGMENT',
  UPDATE_SELECTED_PRODUCT = 'UPDATE_SELECTED_PRODUCT',
  TOGGLE_FILTER_KIND = 'TOGGLE_FILTER_KIND',
  TOGGLE_FILTER_TYPE = 'TOGGLE_FILTER_TYPE',
  SELECT_PRODUCT = 'SELECT_PRODUCT',
  UNSELECT_PRODUCT = 'UNSELECT_PRODUCT',
  SELECT_PRODUCT_OPTION = 'SELECT_PRODUCT_OPTION',
  UNSELECT_PRODUCT_OPTION = 'UNSELECT_PRODUCT_OPTION',
  RESET = 'RESET',
}

/** Describes room inventory action */
interface ProductInventoryAction {
  type: ProductInventoryActionType
}

/** Action for updating selected segment */
class UpdateSegmentAction implements ProductInventoryAction {
  readonly type = ProductInventoryActionType.UPDATE_SEGMENT
  constructor(
    public segment: ProductSegment | null,
    public segmentIndex: number | null,
    public availableKindsForNewSegment: Set<ProductKind>,
    public availableTypesForNewSegment: Set<ProductType>
  ) { }
}

class UpdateSelectedProductAction implements ProductInventoryAction {
  readonly type = ProductInventoryActionType.UPDATE_SELECTED_PRODUCT
  constructor(
    public productId: number,
    public newProductData: Partial<PurchaseFlowProduct>
  ) { }
}

class ToggleFilterKind implements ProductInventoryAction {
  readonly type = ProductInventoryActionType.TOGGLE_FILTER_KIND
  constructor(
    public productKind: ProductKind
  ) { }
}

class ToggleFilterType implements ProductInventoryAction {
  readonly type = ProductInventoryActionType.TOGGLE_FILTER_TYPE
  constructor(
    public productType: ProductType
  ) { }
}

class SelectProductAction implements ProductInventoryAction {
  readonly type = ProductInventoryActionType.SELECT_PRODUCT
  constructor(
    public product: CatalogueProduct,
    public quantity: number
  ) { }
}

class UnselectProductAction implements ProductInventoryAction {
  readonly type = ProductInventoryActionType.UNSELECT_PRODUCT
  constructor(
    public productId: number
  ) { }
}

class SelectProductOptionAction implements ProductInventoryAction {
  readonly type = ProductInventoryActionType.SELECT_PRODUCT_OPTION
  constructor(
    public parentId: number,
    public option: CatalogueOptionProduct,
    public quantity: number
  ) { }
}

class UnselectProductOptionAction implements ProductInventoryAction {
  readonly type = ProductInventoryActionType.UNSELECT_PRODUCT_OPTION
  constructor(
    public parentId: number,
    public optionId: number
  ) { }
}

class ResetAction implements ProductInventoryAction {
  readonly type = ProductInventoryActionType.RESET
}

/** Union type of possible actions */
type ProductInventoryActions = UpdateSegmentAction
  | SelectProductAction
  | UnselectProductAction
  | SelectProductOptionAction
  | UnselectProductOptionAction
  | ToggleFilterKind
  | ToggleFilterType
  | UpdateSelectedProductAction
  | ResetAction

export interface PurchaseFlowProduct {
  id: number
  quantity: number
  type: ProductType
  selected?: boolean
  value?: number
  kind: ProductKind
  version?: string
  feePrice: FeeDTO
  /** @deprecated */
  shootingDuration?: number
  timeOnSiteMin: Nullable<number>
  timeOnSiteMax: Nullable<number>
  comment?: string
  isBestSeller: boolean
  isLandRegistrationNeeded: boolean
  bundledProducts: CatalogueBundledProduct[]
}

export interface PurchaseFlowProductOption {
  id: number
  quantity: number
  selected?: boolean
  value?: number
  kind: ProductKind
  version?: string
  feePrice: FeeDTO
  /** @deprecated */
  shootingDuration?: number
  timeOnSiteMin: Nullable<number>
  timeOnSiteMax: Nullable<number>
  comment?: string
}

const initialInventory: ProductInventory = {
  selectedSegment: null,
  selectedSegmentIndex: null,
  selectedFilterKinds: new Set([]),
  selectedFilterTypes: new Set([]),
  selectedProducts: {},
  selectedProductOptions: {},
}

/** Reducer function that handles updating roomInventory state according to dispatched actions */
const reduceProductInventory: Reducer<ProductInventory, ProductInventoryActions> = (state = initialInventory, action) => {
  switch (action.type) {
    case ProductInventoryActionType.UPDATE_SEGMENT: {
      const newSelectedKinds = new Set(state.selectedFilterKinds)
      const newSelectedTypes = new Set(state.selectedFilterTypes)

      state.selectedFilterKinds.forEach((kind) => {
        if (action.availableKindsForNewSegment.has(kind)) return
        newSelectedKinds.delete(kind)
      })

      state.selectedFilterTypes.forEach((type) => {
        if (newSelectedTypes.has(type)) return
        newSelectedTypes.delete(type)
      })

      return {
        ...state,
        selectedSegment: action.segment,
        selectedSegmentIndex: action.segmentIndex,
        selectedProducts: {},
        selectedProductOptions: {},
        selectedFilterKinds: newSelectedKinds,
        selectedFilterTypes: newSelectedTypes,
      }
    }

    case ProductInventoryActionType.TOGGLE_FILTER_KIND: {

      const newKinds = new Set(state.selectedFilterKinds)

      const isDelete = newKinds.has(action.productKind)

      if (isDelete) newKinds.delete(action.productKind)
      else newKinds.add(action.productKind)

      const newProducts = { ...state.selectedProducts }
      const newProductsOptions = { ...state.selectedProductOptions }

      if (isDelete) {
        for (const product of Object.values(newProducts)) {
          if (product.kind !== action.productKind) continue
          delete newProducts[product.id]
          delete newProductsOptions[product.id]
        }
      }

      return {
        ...state,
        selectedFilterKinds: newKinds,
        selectedProducts: newProducts,
        selectedProductOptions: newProductsOptions,
      }
    }

    case ProductInventoryActionType.TOGGLE_FILTER_TYPE: {

      const newTypes = new Set(state.selectedFilterTypes)

      const isDelete = newTypes.has(action.productType)

      if (isDelete) newTypes.delete(action.productType)
      else newTypes.add(action.productType)

      const newProducts = { ...state.selectedProducts }
      const newProductsOptions = { ...state.selectedProductOptions }

      if (isDelete) {
        for (const product of Object.values(newProducts)) {
          if (product.type !== action.productType) continue
          delete newProducts[product.id]
          delete newProductsOptions[product.id]
        }
      }

      return {
        ...state,
        selectedFilterTypes: newTypes,
        selectedProducts: newProducts,
        selectedProductOptions: newProductsOptions,
      }
    }

    case ProductInventoryActionType.SELECT_PRODUCT:
      return {
        ...state,
        selectedProducts: {
          ...state.selectedProducts,
          [action.product.id]: {
            ...action.product,
            quantity: action.quantity,
            comment: undefined,
          }
        }
      }

    case ProductInventoryActionType.UNSELECT_PRODUCT: {
      const { [action.productId]: _, ...prunedProductOptions } = state.selectedProductOptions
      const { [action.productId]: __, ...prunedProducts } = state.selectedProducts

      return {
        ...state,
        selectedProducts: prunedProducts,
        selectedProductOptions: prunedProductOptions,
      }
    }

    case ProductInventoryActionType.SELECT_PRODUCT_OPTION:
      return {
        ...state,
        selectedProductOptions: {
          ...state.selectedProductOptions,
          [action.parentId]: {
            ...(state.selectedProductOptions[action.parentId] || {}),
            [action.option.id]: {
              ...action.option,
              quantity: action.quantity,
            },
          },
        },
      }

    case ProductInventoryActionType.UNSELECT_PRODUCT_OPTION: {
      const newProductOptions = { ...(state.selectedProductOptions[action.parentId] || {}) }
      delete newProductOptions[action.optionId]

      return {
        ...state,
        selectedProductOptions: {
          ...state.selectedProductOptions,
          [action.parentId]: newProductOptions,
        },
      }
    }

    case ProductInventoryActionType.UPDATE_SELECTED_PRODUCT: {
      if (!state.selectedProducts[action.productId]) return { ...state }

      return {
        ...state,
        selectedProducts: {
          ...state.selectedProducts,
          [action.productId]: {
            ...state.selectedProducts[action.productId],
            ...action.newProductData,
          }
        }
      }
    }

    case ProductInventoryActionType.RESET:
      return initialInventory

    default:
      return { ...state }
  }
}

export const [PurchaseFlowProductsProvider, usePurchaseFlowProducts] = constate(() => {
  const {
    availableSegments,
    getProductCatalogue,
    availableProductKindsPerSegment,
    availableProductTypesPerSegment,
  } = usePurchaseFlowConfig()

  // AI settings - in case the settings get complex, consider moving them to a separate context
  const [isSkyReplacementEnabled, setIsSkyReplacementEnabled] = useState(false)
  const [selectedSkyReplacement, setSelectedSkyReplacement] = useState<SkyReplacementOption | null>(null)

  /** Holds all selected products and filters */
  const [productInventory, dispatch] = useReducer(reduceProductInventory, initialInventory)

  function setSegment(segment: ProductSegment, segmentIndex: number): void
  function setSegment(segment: null, segmentIndex: null): void
  function setSegment(segment: ProductSegment | null, segmentIndex: number | null): void {
    logAnalyticsEvent(AnalyticsEvent.PURCHASE_FLOW_SIZE_PROPERTY_SELECTED, { Segment: segment })

    dispatch(new UpdateSegmentAction(
      segment,
      segmentIndex,
      !!segment && !!availableProductKindsPerSegment[segment]?.length
        ? new Set(availableProductKindsPerSegment[segment]?.map(({ productKind }) => productKind))
        : new Set([]),
      !!segment && !!availableProductTypesPerSegment[segment]?.all.length
        ? new Set(availableProductTypesPerSegment[segment]?.all.map(({ productType }) => productType))
        : new Set([])
    ))
  }

  const selectOptionProduct = (parentId: number, option: CatalogueOptionProduct, quantity: number) => {
    const parentProduct = productInventory.selectedProducts[parentId]
    if (parentProduct) {
      logAnalyticsEvent(AnalyticsEvent.PURCHASE_FLOW_OPTION_SELECTED, {
        ProductKind: parentProduct.kind,
        ProductType: parentProduct.type
      })
    }

    dispatch(new SelectProductOptionAction(parentId, option, quantity))
  }

  const unselectOptionProduct = (parentId: number, optionId: number) => {
    const parentProduct = productInventory.selectedProducts[parentId]
    if (parentProduct) {
      logAnalyticsEvent(AnalyticsEvent.PURCHASE_FLOW_OPTION_REMOVED, {
        ProductKind: parentProduct.kind,
        ProductType: parentProduct.type
      })
    }

    dispatch(new UnselectProductOptionAction(parentId, optionId))
  }

  const selectProduct = (product: CatalogueProduct, quantity: number) => {
    dispatch(new SelectProductAction(product, quantity))
  }

  const unselectProduct = (productId: number) => {
    dispatch(new UnselectProductAction(productId))
  }

  const setProductComment = (productId: number, comment: string | undefined) => {
    dispatch(new UpdateSelectedProductAction(productId, { comment }))
  }

  const toggleFilterKind = (productKind: ProductKind) => {
    const willBeSelected = !productInventory.selectedFilterKinds.has(productKind)

    dispatch(new ToggleFilterKind(productKind))
    standardDebouncedLogAnalyticsEvent('product_type_selected', { productType: productKind, selected: willBeSelected })
  }

  const toggleFilterType = (productType: ProductType) => {
    const willBeSelected = !productInventory.selectedFilterTypes.has(productType)

    dispatch(new ToggleFilterType(productType))
    standardDebouncedLogAnalyticsEvent('product_type_selected', { productType, selected: willBeSelected })
  }

  const resetProducts = () => {
    dispatch(new ResetAction())
  }

  const computedProductProperties = useMemo(() => {
    const selectedKindsArray: Array<ProductKind> = []
    const selectedProductTypes: Set<ProductType> = new Set([])
    let isPrestigeProductSelected = false
    let isBundleProductSelected = false

    for (let product of Object.values(productInventory.selectedProducts)) {

      const optionsArray = Object.values(productInventory.selectedProductOptions[product.id] || {})

      // Add product's kind to array
      selectedKindsArray.push(product.kind)

      // Add product's type to set
      selectedProductTypes.add(product.type)

      // Keep track if any prestigeProduct has been selected
      if (!isPrestigeProductSelected && PrestigeProductKinds.has(product.kind)) isPrestigeProductSelected = true

      // Keep track if any bundle product has been selected
      if (!isBundleProductSelected && BundleProductTypes.has(product.type)) isBundleProductSelected = true

      // Add all selected options' kinds to array
      selectedKindsArray.push(...optionsArray.map((option) => option.kind))

      // Add bundled products kinds and types
      product.bundledProducts.forEach((bundledProduct) => {
        selectedProductTypes.add(bundledProduct.type)
        selectedKindsArray.push(bundledProduct.kind)
      })
    }

    return {
      selectedKinds: new Set(selectedKindsArray),
      selectedProductTypes,
      isPrestigeProductSelected,
      isBundleProductSelected,
    }
  }, [productInventory.selectedProductOptions, productInventory.selectedProducts])

  // Measurement product check
  const hasMeasurementProduct = useMemo(() => Array.from(computedProductProperties.selectedKinds).some((kind) => kind === ProductKind.MEASUREMENT_ON_SITE), [computedProductProperties.selectedKinds])

  // Document product check
  const hasDocumentProduct = useMemo(() => Array.from(computedProductProperties.selectedKinds).some((kind) => kind === ProductKind.AUTHORITIES_DOCUMENTS), [computedProductProperties.selectedKinds])

  // Energy certificate products checks
  const hasConsumptoinCertificateProduct = useMemo(() => Array.from(computedProductProperties.selectedProductTypes).some((type) => type === ProductType.CONSUMPTION_CERTIFICATE), [computedProductProperties.selectedProductTypes])
  const hasEnergyCertificateProduct = useMemo(() => Array.from(computedProductProperties.selectedProductTypes).some((type) => type === ProductType.ENERGY_CERTIFICATE_OF_NEED), [computedProductProperties.selectedProductTypes])

  // Floor plan products checks
  const hasFloorPlanCertificationType = useMemo(() => Array.from(computedProductProperties.selectedProductTypes).some((type) => ProductTypesRequiringFloorPlanCertification.has(type)), [computedProductProperties.selectedProductTypes])
  const hasFloorPlanConfigType = useMemo(() => Array.from(computedProductProperties.selectedProductTypes).some((type) => ProductTypesFloorPlan.has(type)), [computedProductProperties.selectedProductTypes])
  const hasFloorPlanConfigKind = useMemo(() => Array.from(computedProductProperties.selectedKinds).some((kind) => ProductKindsFloorPlan.has(kind)), [computedProductProperties.selectedKinds])
  const hasFloorPlanCertificationKind = useMemo(() => Array.from(computedProductProperties.selectedKinds).some((kind) => ProductKindsRequiringFloorPlanCertification.has(kind)), [computedProductProperties.selectedKinds])
  const hasFloorPlanProduct = useMemo(() => hasFloorPlanConfigKind || (hasFloorPlanConfigType && !hasMeasurementProduct), [hasFloorPlanConfigKind, hasFloorPlanConfigType, hasMeasurementProduct])
  const hasFloorPlanCertificationProduct = useMemo(() => hasFloorPlanCertificationType && hasFloorPlanCertificationKind, [hasFloorPlanCertificationKind, hasFloorPlanCertificationType])

  const floorPlanProduct = useMemo(() => hasFloorPlanProduct ? Object.values(productInventory.selectedProducts).find(

    product => (ProductTypesFloorPlan.has(product.type) || ProductKindsFloorPlan.has(product.kind)) ? (ProductTypesFloorPlan.has(product.type) || ProductKindsFloorPlan.has(product.kind)) : productInventory.selectedProductOptions

  ) : undefined, [hasFloorPlanProduct, productInventory.selectedProductOptions, productInventory.selectedProducts])

  // Energy certificate product check
  const requiresEnergyCertificateConfig = useMemo(() => Array.from(computedProductProperties.selectedProductTypes).some((type) => type === ProductType.ENERGY_CERTIFICATE_OF_NEED), [computedProductProperties.selectedProductTypes])

  // Consumption certificate product check
  const requiresConsumptionCertificateConfig = useMemo(() => Array.from(computedProductProperties.selectedProductTypes).some((type) => type === ProductType.CONSUMPTION_CERTIFICATE), [computedProductProperties.selectedProductTypes])

  // AI settings product check
  const requiresAIEditingConfig = useMemo(() => (
    isSkyReplacementEnabled &&
    Array.from(computedProductProperties.selectedProductTypes).some((kind) => kind === ProductType.AI_PHOTO_EDITING)
  ), [computedProductProperties.selectedProductTypes, isSkyReplacementEnabled])

  // If only one segment is available, select it automatically
  useEffect(() => {
    if (availableSegments.length === 1) {
      setSegment(availableSegments[0], 0)
    }

    // Excluded setSegment
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableSegments])

  useEffect(() => {
    if (getProductCatalogue.isIdle) resetProducts()
  }, [getProductCatalogue.isIdle])

  return {
    ...computedProductProperties,
    ...productInventory,
    hasFloorPlanProduct,
    requiresEnergyCertificateConfig,
    requiresConsumptionCertificateConfig,
    hasDocumentProduct,
    hasMeasurementProduct,
    hasConsumptoinCertificateProduct,
    hasEnergyCertificateProduct,
    hasFloorPlanCertificationProduct,
    requiresAIEditingConfig,
    floorPlanProduct,
    isSkyReplacementEnabled,
    selectedSkyReplacement,
    setSelectedSkyReplacement,
    setIsSkyReplacementEnabled,
    toggleFilterType,
    toggleFilterKind,
    setProductComment,
    setSegment,
    selectProduct,
    unselectProduct,
    selectOptionProduct,
    unselectOptionProduct,
  }
})
