import { ArchiveVisualsTag, archiveVisuals } from 'redux/Individual/Visual/ArchiveVisuals'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { APIRequestState } from 'constants/API'
import { ActionTypeAPIData } from 'constants/redux'
import { AnalyticsEvent } from 'utils/analytics'
import { RootStore } from 'models/redux'
import { VisualFileType } from 'constants/visual'
import constate from 'constate'
import { useAuth0 } from 'utils/auth'
import { useGalleryAssignment } from './GalleryAssignment.context'
import { useGalleryDeal } from './GalleryDeal.context'
import { useGalleryVisualSelection } from './GalleryVisualSelection.context'
import { useGalleryVisualType } from './GalleryVisualType.context'
import { useUserData } from 'components/contexts/UserDataContext'

export const [GalleryVisualsDownloadArchiveContextProvider, useGalleryVisualsDownloadArchive] = constate(({
  assignmentId,
}: {
  assignmentId: string
}) => {
  const dispatch = useDispatch()
  const { roles } = useAuth0()
  const {
    nonPersonalUserWorkspaces
  } = useUserData()
  const {
    logGalleryEvent,
    product
  } = useGalleryAssignment()
  const { dealData } = useGalleryDeal()
  const {
    selectedPurchasedVisualsKeys,
    selectedNotPurchasedVisualsKeys,
  } = useGalleryVisualSelection()
  const {
    transformTypes,
  } = useGalleryVisualType()

  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false)

  const initiateDownloadVisualsArchive = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.ARCHIVE_VISUALS]?.[assignmentId]?.[ArchiveVisualsTag.CLIENT_ARCHIVE])
  const visualsArchiveDownloadJobId = useMemo(() => (initiateDownloadVisualsArchive && initiateDownloadVisualsArchive.data?.id) || '', [initiateDownloadVisualsArchive])
  const downloadVisualsArchive = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.DOWNLOAD_ARCHIVE]?.[visualsArchiveDownloadJobId])
  const archiveAdmin = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.ARCHIVE_VISUALS]?.[assignmentId]?.[ArchiveVisualsTag.ADMIN_ARCHIVE])
  const visualsArchiveAdminDownloadJobId = useMemo(() => (archiveAdmin && archiveAdmin.data?.id) || '', [archiveAdmin])
  const downloadVisualsArchiveAdmin = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.DOWNLOAD_ARCHIVE]?.[visualsArchiveAdminDownloadJobId])

  const isArchiveDisabled = useMemo(() => {
    if (initiateDownloadVisualsArchive && initiateDownloadVisualsArchive.state === APIRequestState.RUNNING) return true
    if (!product) return true
    if (selectedPurchasedVisualsKeys.size === 0) return true
    if (selectedNotPurchasedVisualsKeys.size > 0) return true

    return false
  }, [initiateDownloadVisualsArchive, product, selectedNotPurchasedVisualsKeys.size, selectedPurchasedVisualsKeys.size])

  const typesToDownload = useMemo(() => {
    const types = transformTypes()
    if (product?.type === 'VIDEO' || product?.type === 'FLOOR_PLAN') {
      return [VisualFileType.POST]
    } else {
      return types
    }
  }, [transformTypes, product])

  const initiateArchiveDownload = useCallback(async (visualsKey?: Set<string>) => {
    if (isArchiveDisabled && !visualsKey) return
    if (!product) return

    const downloadKeys = visualsKey || selectedPurchasedVisualsKeys

    // Trigger downloading of selected visuals
    dispatch(archiveVisuals(assignmentId, typesToDownload, Array.from(downloadKeys), ArchiveVisualsTag.CLIENT_ARCHIVE, false))

    // Open visual download popup
    setDownloadModalOpen(true)

    // Track archive download trigger
    logGalleryEvent(AnalyticsEvent.GALLERY_DOWNLOAD_TRIGGERED, {
      role: Array.from(roles.all.values()).join(','),
      workspaceIDs: nonPersonalUserWorkspaces.length ? nonPersonalUserWorkspaces.map(({ id }) => id).join(', ') : '',
      productCategory: product.category,
      productType: product.type,
      productKind: product.kind,
      productSegments: product.segments.join(','),
      assignmentOwnerId: dealData?.customer.id,
      assignmentOwnerEmail: dealData?.customer.email,
    })

  }, [isArchiveDisabled, product, dispatch, assignmentId, typesToDownload, selectedPurchasedVisualsKeys, logGalleryEvent, roles.all, nonPersonalUserWorkspaces, dealData?.customer.id, dealData?.customer.email])

  return {
    assignmentId,
    isArchiveDisabled,
    isDownloadModalOpen,
    setDownloadModalOpen,
    initiateDownloadVisualsArchive,
    downloadVisualsArchive,
    archiveAdmin,
    downloadVisualsArchiveAdmin,
    initiateArchiveDownload
  }
})
