import { FC, useEffect, useRef } from 'react'
import { Stack, useMediaQuery } from '@mui/material'
import { ToolBarType, useClientGallery } from '../_main/contexts'
import { useGalleryAssignment, useGalleryProduct, useGalleryVisualSelection, useGalleryVisualsDownloadArchive, useGalleryVisualsMeta } from '../../contexts'

import { ClientGallerySendRequest } from '../ClientGallerySendRequest'
import { FeatureFlag } from 'utils/featureFlags'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { MUIButton } from 'components/common/MUIButton'
import { MUIDivider } from 'components/common/MUIDivider'
import { redesignMUITheme } from 'constants/styling/redesignTheme'
import { useFlag } from '@unleash/proxy-client-react'
import { useTranslation } from 'react-i18next'

/**
 * Client gallery actions panel which including feedback button, editing and download setting.
 * 
 * @example <ClientGalleryActionsPanel />
 */
export const ClientGalleryActionsPanel: FC = () => {
  const { t } = useTranslation(['gallery'])
  const allowClientChangeRequest = useFlag(FeatureFlag.ALLOW_CLIENT_CHANGE_REQUEST)

  const { purchasedVisualsCount } = useGalleryVisualsMeta()
  const { initiateArchiveDownload } = useGalleryVisualsDownloadArchive()
  const {
    setIsToolBarOpen,
    setToolBarType
  } = useClientGallery()
  const {
    selectAllPurchased,
    selectedPurchasedVisualsCount,
  } = useGalleryVisualSelection()
  const { isFeedbackButtonAllowed } = useGalleryAssignment()

  const { isVideo } = useGalleryProduct()

  const isMobileView = useMediaQuery(redesignMUITheme.breakpoints.down('sm'))

  const downloadAllPurchasedTriggered = useRef(false)

  useEffect(() => {
    if (downloadAllPurchasedTriggered.current && (selectedPurchasedVisualsCount === purchasedVisualsCount)) {
      downloadAllPurchasedTriggered.current = false
      initiateArchiveDownload()
    }
  }, [initiateArchiveDownload, purchasedVisualsCount, selectedPurchasedVisualsCount])

  return (
    <Stack flexDirection={{ xs: 'column-reverse', sm: 'row' }} justifyContent="space-between" alignItems={{ xs: 'flex-start', sm: 'space-between' }} gap={{ xs: '1rem', sm: '0' }}>

      {!isVideo && isFeedbackButtonAllowed && allowClientChangeRequest &&
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <ClientGallerySendRequest />
          {!isMobileView && <MUIDivider orientation="vertical" margin={12} sx={{ height: '2.8rem' }} />}
        </Stack>
      }

      <MUIButton
        type='secondaryBorder'
        size='sm'
        variant='outlined'
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => {
          selectAllPurchased()
          setToolBarType(ToolBarType.DOWNLOAD)
          setIsToolBarOpen(true)
        }}
      >
        {t('download_visuals')}
      </MUIButton>

    </Stack>
  )
}
