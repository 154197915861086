import { FileAction, FileState, useFileAPI } from 'components/common/FileAPI'
import { useEffect, useMemo, useState } from 'react'

import { AssignmentDocumentsEndpoint } from 'constants/API'
import { DocumentUploadType } from 'constants/documents'
import { SignedURLDTO } from 'models/visuals'
import { UploadType } from 'components/common/FileAPI/fileUtils'
import constate from 'constate'
import { useAuth0 } from 'utils/auth'
import { useGetAssignmentDocuments } from 'dataQueries/assignmentDocuments.query'

export const [GalleryDocumentsContextProvider, useGalleryDocumentsContext] = constate(({ assignmentId }: { assignmentId: string }) => {

  const { roles } = useAuth0()

  const galleryDocumentsFileController = useFileAPI('GALLERY_DOCUMENTS', {
    uploadUrlResolver: (fileEntry, api) => api.post<SignedURLDTO>(
      AssignmentDocumentsEndpoint.GET_UPLOAD_URL,
      { assignmentId },
      {
        filename: fileEntry.originalFilename,
        contentType: fileEntry.fileObject.type,
        type: fileEntry.tag,
      },
      true,
      {
        params: {
          uploadType: UploadType.RESUMABLE
        }
      }
    ),
    reUploadUrlResolver: (fileEntry, api) => api.put<SignedURLDTO>(
      AssignmentDocumentsEndpoint.GET_REUPLOAD_URL,
      { assignmentId },
      {
        filename: fileEntry.gcFilename,
        contentType: fileEntry.fileObject.type,
        type: fileEntry.tag,
      },
      true
    ),
    deleteHandler: (fileEntry, api) => api.delete(
      AssignmentDocumentsEndpoint.DELETE_DOCUMENT,
      { assignmentId },
      true,
      {
        params: {
          type: fileEntry.tag,
          filename: fileEntry.gcFilename,
        }
      }
    )
  })

  const [documentTypeSelect, setDocumentTypeSelect] = useState<DocumentUploadType>(roles.isCreative ? DocumentUploadType.OUTPUT : DocumentUploadType.INPUT)
  const [isMessageThreadsVisible, setIsMessageThreadsVisible] = useState<boolean>(false)

  const assignmentDocuments = useGetAssignmentDocuments(assignmentId, documentTypeSelect)
  const assignmentDocumentsData = useMemo(() => assignmentDocuments.data?.data || [], [assignmentDocuments.data?.data])

  useEffect(() => {
    assignmentDocuments.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [documentTypeSelect])

  useEffect(() => {
    galleryDocumentsFileController.mergeAddFiles(assignmentDocumentsData.map((file) => ({
      id: file.signedUrl.filename,
      displayUrl: file.signedUrl.signedURL,
      originalFilename: file.originalName,
      gcFilename: file.signedUrl.filename,
      tag: documentTypeSelect,
      action: FileAction.LOAD,
      state: FileState.SUCCESS,
      progress: 100,
    })))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentDocumentsData])

  return {
    documentTypeSelect,
    setDocumentTypeSelect,
    assignmentDocumentsData,
    assignmentDocuments,
    galleryDocumentsFileController,
    isMessageThreadsVisible,
    setIsMessageThreadsVisible,
  }
})
