import { BEIGE_300, BEIGE_500, GRAY_700, GRAY_900 } from 'constants/styling/theme'

import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { MUIBadge } from 'components/common/MUIBadge'
import { MUIButton } from 'components/common/MUIButton'
import { Path } from 'constants/router'
import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { VisualEditingSettings } from '../VisualEditingSettings'
import { externalNavigate } from 'utils/helpers'
import { useTranslation } from 'react-i18next'

/**
 * @component
 * VisualsEditingSubscribePreview displays a preview for non-subscribed users with an option to navigate to the pricing page to subscribe.
 *
 * @example
 * <VisualsEditingSubscribePreview />
 */
export const VisualsEditingSubscribePreview: React.FC = () => {

  const { t } = useTranslation(['visuals_editing'])

  return (
    <BorderBoxWrapper backgroundColor={BEIGE_500} sx={{
      gap: '1.2rem',
      padding: '1.6rem',
      display: 'flex',
      flexDirection: 'column',
    }}>

      <Stack alignSelf="flex-start">
        <MUIBadge
          label={t('editor_non_subscribed.subscribers_only')}
          color="coral"
          icon={<LockOutlinedIcon fontSize="inherit" />}
        />
      </Stack>

      <Stack gap="1.6rem" alignItems="flex-start" marginBottom="1.2rem">
        <Stack gap="0.5rem">
          <Typography variant="text-md" fontWeight={600} color={GRAY_900}>
            {t('editor_non_subscribed.preview_title')}
          </Typography>

          <Typography variant="text-sm" color={GRAY_700}>
            {t('editor_non_subscribed.preview_description')}
          </Typography>
        </Stack>

        <MUIButton onClick={() => externalNavigate(Path.PRICING)}>
          {t('editor_non_subscribed.subscribe_backbone')}
        </MUIButton>
      </Stack>

      <BorderBoxWrapper
        backgroundColor={BEIGE_300}
        sx={{
          gap: '1rem',
          padding: '1.6rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >

        <VisualEditingSettings />

      </BorderBoxWrapper>
    </BorderBoxWrapper>
  )
}
