import * as de_DE from '@pqina/pintura/locale/de_DE'
import * as en_GB from '@pqina/pintura/locale/en_GB'
import * as es_ES from '@pqina/pintura/locale/es_ES'
import * as fr_FR from '@pqina/pintura/locale/fr_FR'
import * as it_IT from '@pqina/pintura/locale/it_IT'
import * as nl_NL from '@pqina/pintura/locale/nl_NL'

export const getPinturaLocale = (language: string) => {
  const pinturaLocale = (() => {
    switch (language) {
      case 'en':
        return en_GB
      case 'de':
        return de_DE
      case 'es':
        return es_ES
      case 'it':
        return it_IT
      case 'nl':
        return nl_NL
      case 'fr':
        return fr_FR
      default:
        return en_GB
    }
  })()

  //locale prop on the PinturaEditor component expects a single level object
  return {
    ...pinturaLocale.LocaleCore,
    ...pinturaLocale.LocaleAnnotate,
    ...pinturaLocale.LocaleCrop,
    ...pinturaLocale.LocaleFill,
    ...pinturaLocale.LocaleTrim,
    ...pinturaLocale.LocaleRedact,
    ...pinturaLocale.LocaleFilter,
    ...pinturaLocale.LocaleFinetune,
    ...pinturaLocale.LocaleFrame,
    ...pinturaLocale.LocaleResize,
    ...pinturaLocale.LocaleSticker,
    ...pinturaLocale.LocaleMarkupEditor
  }
}
