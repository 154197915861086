import './CreativeAssignmentListCard.sass'

import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { BLUE_100, BLUE_200, DEEP_SPACE_BLACK, ORANGE_200 } from 'constants/styling/theme'
import { Box, Stack } from '@mui/material'
import { ColorClass, IconType } from 'constants/assets'
import { CreativeProductInstruction, chooseProductContainingVisuals } from 'utils/product'
import { FC, Fragment, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { applyFeeDiscount, formatPrice } from 'utils/price'
import { isAssignmentAvailable, isAssignmentFinished, translateAssignmentStage } from 'utils/stages'
import { useAcceptAssignment, useArrangeDateTimeAssignment, useDeclineAssignment } from 'dataQueries/assignment.query'

import { AssignmentDTOCreative } from 'models/assignment'
import { AssignmentStage } from 'constants/assignment'
import { Badge } from 'components/common/Badges'
import Button from 'components/common/Button/Button'
import { DeadlineDate } from 'components/common/DeadlineDate'
import { DealState } from 'constants/deal'
import { DeclineAssignmentPopup } from '../DeclinePopup'
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined'
import { EventTime } from 'components/common/EventTime'
import Icon from 'components/common/Icon/Icon'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { MUIButton } from 'components/common/MUIButton'
import { MUITooltip } from 'components/common/MUITooltip'
import { Path } from 'constants/router'
import { ProductCategory } from 'models/product'
import { QueryStatus } from 'components/common/QueryStatus'
import { ScheduleMeetingPopup } from '../ScheduleMeetingPopup'
import { SystemMessage } from 'components/common/SystemMessage'
import TriangleIcon from 'components/common/TriangleIcon/TriangleIcon'
import { WarningAmber } from '@mui/icons-material'
import classnames from 'classnames'
import { externalNavigate } from 'utils/helpers'
import { getTermsLink } from 'utils/localization'
import { isEditingCategory } from 'utils/validators'
import { useActionPopup } from 'utils/hooks'
import { useAuth0 } from 'utils/auth'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useTimezone } from 'components/contexts/timezone.context'

const stagesUnlockingCreativeAcceptAssignment = new Set([
  AssignmentStage.MISSION_ORDER_PLACED,
  AssignmentStage.PRE_PRODUCTION,
  AssignmentStage.AUTOMATED,
  AssignmentStage.WAITING_FOR_CT_TO_ACCEPT,
])

const stagesUnlockingCreativeUploadVisuals = new Set([
  AssignmentStage.CREATIVE_BOOKED,
])

const stagesUnlockingCreativeScheduleMeeting = new Set([
  AssignmentStage.WAITING_FOR_DATE_AND_TIME,
])

const stagesUnlockingContactInfoForCT = new Set([
  AssignmentStage.WAITING_FOR_DATE_AND_TIME,
  AssignmentStage.CREATIVE_BOOKED,
  AssignmentStage.VISUALS_SENT_BY_CT_TO_BKBN,
  AssignmentStage.VISUALS_SENT_BY_BKBN_TO_EDITOR,
  AssignmentStage.VISUALS_SENT_BY_EDITOR_TO_BKBN,
  AssignmentStage.VISUALS_SENT_TO_CLIENT,
])

const stagesUnlockingCreativeSeeVisuals = new Set([
  AssignmentStage.VISUALS_SENT_BY_CT_TO_BKBN,
  AssignmentStage.VISUALS_SENT_BY_BKBN_TO_EDITOR,
  AssignmentStage.VISUALS_SENT_BY_EDITOR_TO_BKBN,
  AssignmentStage.VISUALS_SENT_TO_CLIENT,
])

const stagesUnlockingVisualsUploadedDeadline = new Set([
  AssignmentStage.CREATIVE_BOOKED,
  AssignmentStage.WAITING_FOR_CT_TO_ACCEPT,
  AssignmentStage.WAITING_FOR_DATE_AND_TIME
])

interface Props {
  assignment: AssignmentDTOCreative
}

export const CreativeAssignmentListCard: FC<Props> = ({ assignment }) => {

  const { t, i18n } = useTranslation(['deal_assignment_card', 'deal_assignment', 'time_translations', 'category', 'product'])
  const { roles } = useAuth0()
  const { showConfirm } = useActionPopup()
  const { userTimezone } = useTimezone()
  const { spawnSuccessToast } = useSnackbar()

  const acceptAssignment = useAcceptAssignment()
  const declineAssignment = useDeclineAssignment()
  const scheduleAssignment = useArrangeDateTimeAssignment()

  const [showDeclineAssignmentPopup, setShowDeclineAssignmentPopup] = useState<boolean>(false)
  const [showScheduleMeetingPopup, setShowScheduleMeetingPopup] = useState<boolean>(false)

  const cardColor = useMemo(() => {
    if (isAssignmentFinished(assignment, roles) && assignment.orderState === DealState.FINISHED) return 'green'
    else if (isAssignmentFinished(assignment, roles) && assignment.orderState === DealState.CANCELLED) return 'red'
    else if (isAssignmentAvailable(assignment)) return 'blue'
    else return 'orange'
  }, [assignment, roles])

  const handleAcceptAssignment = useCallback(async () => {
    const popupBody = (
      <Stack gap={2}>

        <span>
          {t('accept_assignment_prompt_text')}
        </span>

        {assignment.scheduledByCT &&
          <SystemMessage
            variant="warning"
            message={
              <div className='acceptActionPopup'>
                <Trans
                  t={t}
                  i18nKey="accept_assignment_prompt_flexible"
                  parent={null}
                  components={{ list: <ul />, item: <li />, text: <p />, bold: <strong /> }}
                >
                  &nbsp;
                </Trans>
              </div>
            }
          />
        }

      </Stack>
    )

    const terms = (
      <span className='acceptActionPopupTerms'>
        <Trans t={t} i18nKey="accept_terms">
          <span
            className="link"
            onClick={() => externalNavigate(assignment.creativeInstructionsUrl)}
          >
            &nbsp;
          </span>

          <a
            className="link"
            href={getTermsLink(i18n.language)}
            target="_blank"
            rel="noreferrer"
          >
            &nbsp;
          </a>
        </Trans>
      </span>
    )

    if (!await showConfirm(popupBody, { title: t('accept_assignment'), actionsAdditionalSlot: terms })) {
      logAnalyticsEvent(AnalyticsEvent.GUIDELINES_DENIED, {
        assignmentId: assignment.id
      })
      return
    }

    acceptAssignment.mutate({ assignmentId: assignment.id.toString() })

  }, [acceptAssignment, assignment.creativeInstructionsUrl, assignment.id, assignment.scheduledByCT, i18n.language, showConfirm, t])

  const product = useMemo(() => chooseProductContainingVisuals(assignment.products), [assignment])

  const category: ProductCategory | null = useMemo(() => assignment.products.length > 0 ? assignment.products[0].category as ProductCategory : null, [assignment.products])

  if (!roles.isCreative) return null

  return (
    <div className={classnames(
      'ctAssignmentCard',
      cardColor,
      { deleted: assignment.isDeleted }
    )}>
      <div className="content">
        <div className="topline">
          <span className="graytitle">
            {assignment.isDeleted &&
              <Fragment>
                <strong className="red-text">[{t('deal_assignment:deleted').toUpperCase()}]</strong>
                {' - '}
              </Fragment>
            }
            {category ? t(`category:${category}`) : 'N/A'}
          </span>
          <div className="right">


            <span className="number">{'#'}{assignment.id}</span>

          </div>
        </div>
        <div className="heading-wrap">

          {!!category && !isEditingCategory(category) &&
            <div className="heading-text">
              <div className='title-with-badge'>
                <h2 className="title">{assignment.hasManualAddress ? assignment.manualAddress : (assignment.address || '-')}</h2>
                {assignment.hasManualAddress &&
                  <Badge color="orange">{t('deal_assignment:manual')}</Badge>
                }
              </div>

              {assignment.hasManualAddress &&
                <div className="manual-title">
                  <span>{assignment.address}</span>
                </div>
              }
            </div>
          }

          <div className="heading-price">

            <div className="price-wrapper">

              <span className="group-icons">
                <Icon className="small car" icon={IconType.CAR} />
                <span className="plus-sign">+</span>
                <Icon className="small sallary" icon={IconType.SALARY} />
              </span>

              <strong className="price">
                {formatPrice(applyFeeDiscount(assignment.creativeRemuneration.totalRemuneration))}
              </strong>

            </div>

            <MUITooltip content={t('deal_assignment:transport_cost')}>
              <span className="transport-price" data-assignment-tip={true} data-assignment-for={`transport-cost-tooltip-${assignment.id}`}>
                {formatPrice(applyFeeDiscount(assignment.creativeRemuneration.transportRemuneration))}
                <Icon className="small gray" icon={IconType.CAR} />
              </span>
            </MUITooltip>

          </div>

        </div>

        {assignment.keyPickupDetails?.address &&
          <Stack
            gap={1}
            sx={{
              background: BLUE_100,
              padding: '1rem',
              width: 'auto',
            }}
          >
            <span>{t('key_pickup_warning')}</span>
            <strong>{assignment.keyPickupDetails.address}</strong>
          </Stack>
        }

        {assignment.hasManualAddress &&
          <div className="infoline">
            <Stack
              gap={1}
              flexDirection="row"
              padding="1rem 1.5rem"
              boxSizing="border-box"
              borderRadius=".8rem"
              sx={{
                background: BLUE_200,
                wordWrap: 'break-word',
              }}
            >
              <InfoOutlinedIcon fontSize='large' />
              <Box paddingTop=".4rem">
                <strong>{t('manual_address_note')}</strong>
              </Box>
            </Stack>
          </div>
        }

        {!!assignment.visualsUploadDeadline && stagesUnlockingVisualsUploadedDeadline.has(assignment.stage) &&
          <div className="infoline">
            <DeadlineDate
              deadlineDate={assignment.visualsUploadDeadline}
              deadlineTimezone={assignment.timezone}
              userTimezone={userTimezone}
            />
          </div>
        }

        {!!category && !isEditingCategory(category) && !!assignment.shootingStartDateTime &&
          <div className="infoline">

            <div className="info">
              <EventTime
                eventStart={assignment.shootingStartDateTime}
                eventTimezone={assignment.timezone}
                userTimezone={userTimezone}
                minDuration={assignment.timeOnSiteMin}
                maxDuration={assignment.timeOnSiteMax}
              />
            </div>

            {assignment.creativeInstructionsUrl && !assignment.scheduledByCT && !stagesUnlockingContactInfoForCT.has(assignment.stage) &&
              <div className="more">
                <Button
                  type="secondary"
                  className="creative-instruction-button"
                  onClick={() => externalNavigate(assignment.creativeInstructionsUrl)}
                >
                  {t('deal_assignment:follow_instruction_button')}
                </Button>
              </div>
            }
          </div>
        }

        {(stagesUnlockingContactInfoForCT.has(assignment.stage) || assignment.scheduledByCT) &&
          <div className="infoline">

            {assignment.scheduledByCT && !stagesUnlockingContactInfoForCT.has(assignment.stage) &&
              <div className="info">
                <Stack
                  bgcolor={ORANGE_200}
                  padding={1.5}
                  borderRadius="8px"
                  direction="row"
                  gap={1}
                  alignItems="center"
                  color={DEEP_SPACE_BLACK}
                >
                  <WarningAmber color="warning" fontSize="large" />
                  {t('ct_must_schedule')}
                </Stack>
              </div>
            }

            {stagesUnlockingContactInfoForCT.has(assignment.stage) &&
              <div className="info">
                {assignment.contactPerson?.name &&
                  <>
                    <span className="group">
                      <TriangleIcon icon={IconType.PROFILE} type={ColorClass.PRIMARY_BLUE} />
                      <span className="label">{t('deal_assignment:contact_on_site')}</span>
                    </span>
                    <span className="group">
                      <span><Icon className="small" icon={IconType.PROFILE} />{assignment.contactPerson.name}</span>
                    </span>
                    {assignment.contactPerson.phone &&
                      <span className="group">
                        <a href={`tel:${assignment.contactPerson.phone}`}><Icon className="small" icon={IconType.PHONE} />{assignment.contactPerson.phone}</a>
                      </span>
                    }
                  </>
                }
              </div>
            }

            {/** CREATIVE - SCHEDULE MEETING - CLIENT INFORMATION */}
            {stagesUnlockingContactInfoForCT.has(assignment.stage) && assignment.creativeContactInformation &&
              <div className="fullLine">
                <div className="info">

                  <span className="group">
                    <TriangleIcon icon={IconType.INFO} type={ColorClass.PRIMARY_BLUE} />
                    <span className="label">{t('client_info.title')}</span>
                  </span>

                  <div className="group">
                    <span className="label">{t('client_info.name')}</span>
                    <span>{assignment.creativeContactInformation.name || '-'}</span>
                  </div>

                  <div className="group">
                    <span className="label">{t('client_info.organization')}</span>
                    <span>{assignment.creativeContactInformation.organizationName || '-'}</span>
                  </div>

                  <div className="group">
                    <span className="label">{t('client_info.reference')}</span>
                    <span>{assignment.creativeContactInformation.reference || '-'}</span>
                  </div>

                </div>
              </div>
            }

            {assignment.creativeInstructionsUrl &&
              <div className="more">
                <Button
                  type="secondary"
                  className="creative-instruction-button"
                  onClick={() => externalNavigate(assignment.creativeInstructionsUrl)}
                >
                  {t('deal_assignment:follow_instruction_button')}
                </Button>
              </div>
            }

          </div>
        }

      </div>

      {/* ATTACHED PRODUCTS FOR ASSIGNMENT */}
      <div className={`attached-products ${cardColor}`}>

        <svg className="top-border">
          <line x1="0" x2="100%" y1="0" y2="0" strokeWidth="2" strokeLinecap="round" strokeDasharray="8, 6" />
        </svg>

        <div className="product-list">
          <div className={`product ${cardColor}`}>
            <div className="productline assignment">

              <div className="productline-info">
                {(() => {
                  const product = chooseProductContainingVisuals(assignment.products)
                  return (
                    <h3 className="productline-info_title">
                      {product ?
                        <CreativeProductInstruction
                          category={product.category}
                          type={product.type}
                          kind={product.kind}
                          attributeX={assignment.creativeInstructionAttributeX}
                          attributeY={assignment.creativeInstructionAttributeY}
                          count={product.quantity}
                          segment={product.segments[0]}
                        />
                        :
                        <Fragment>
                          {assignment.products.map(prod => {
                            const productName = t(`product:p_${prod.id}`)
                            return prod.quantity > 1 ? `${prod.quantity} x ${productName}` : productName
                          }).join(', ') || 'N/A'}
                        </Fragment>
                      }
                    </h3>
                  )
                })()}
              </div>

              <div className="productline-stage">

                {assignment.orderState !== DealState.CANCELLED && stagesUnlockingCreativeAcceptAssignment.has(assignment.stage) &&
                  <div className="assignment-accept-decline">

                    {stagesUnlockingCreativeAcceptAssignment.has(assignment.stage) &&
                      <div className="assignment-actions">
                        <Button
                          type="secondary nobackground"
                          className="accept space-right"
                          onClick={handleAcceptAssignment}
                          disabled={acceptAssignment.isPending}
                          textAndIcon={true}
                        >
                          <Icon icon={IconType.CHECK} />
                          <span>{t('accept_assignment')}</span>
                        </Button>
                        <Button
                          type="secondary red nobackground"
                          className="decline"
                          onClick={() => setShowDeclineAssignmentPopup(true)}
                          disabled={acceptAssignment.isPending}
                          textAndIcon={true}
                        >
                          <Icon icon={IconType.CROSS} />
                          <span>{t('decline_assignment')}</span>
                        </Button>
                      </div>
                    }

                  </div>
                }

                {!stagesUnlockingCreativeAcceptAssignment.has(assignment.stage) &&
                  <span className="state-text">
                    <span className="iconrectangle">
                      <Icon icon={IconType.PHOTO} />
                    </span>
                    <span className="inner-text">
                      {translateAssignmentStage(assignment.stage, roles).replace(/_/igm, '_\u200B')}
                    </span>
                  </span>
                }

                {stagesUnlockingCreativeUploadVisuals.has(assignment.stage) &&
                  <Button
                    className="action-button"
                    type="secondary nobackground"
                    // With the Dashboard redesign, we want to open assignments in a blank page to easily handle the loading of a list of assignment with URL parameters.
                    onClick={() => window.open(Path.GALLERY.replace(':id', assignment.id.toString()), '_blank')}
                  >
                    {t('upload_visuals')}
                  </Button>
                }

                {stagesUnlockingCreativeSeeVisuals.has(assignment.stage) && assignment.orderState !== DealState.CANCELLED &&
                  <div className="status-button-wrapper">
                    <Button
                      className="action-button"
                      type="secondary nobackground"
                      // With the Dashboard redesign, we want to open assignments in a blank page to easily handle the loading of a list of assignment with URL parameters.
                      onClick={() => window.open(Path.GALLERY.replace(':id', assignment.id.toString()), '_blank')}
                    >
                      {t('see_visuals')}
                    </Button>
                  </div>
                }

                {stagesUnlockingCreativeScheduleMeeting.has(assignment.stage) && assignment.scheduledByCT &&
                  <MUIButton
                    type="orangePrimary"
                    startIcon={<EventNoteOutlinedIcon sx={{ paddingTop: '.2rem' }} />}
                    onClick={() => setShowScheduleMeetingPopup(true)}
                  >
                    {t('schedule_meeting')}
                  </MUIButton>
                }

              </div>

            </div>

            {stagesUnlockingCreativeAcceptAssignment.has(assignment.stage) &&
              <QueryStatus
                query={acceptAssignment}
                successMessage={t('deal_assignment:assignment_accepted')}
                spaceTopRem={2}
              />
            }

          </div>
        </div>

      </div>

      <DeclineAssignmentPopup
        isOpen={showDeclineAssignmentPopup}
        products={[product]}
        mutation={declineAssignment}
        shootingStart={assignment.shootingStartDateTime}
        shootingDuration={assignment.shootingDuration}
        onClose={() => setShowDeclineAssignmentPopup(false)}
        handleDecline={(reason, reasonFreetext, unavailabilityInterval) => {
          declineAssignment.mutate({
            assignmentId: assignment.id,
            reason,
            reasonFreetext,
            unavailabilityInterval
          })
        }}
      />

      <ScheduleMeetingPopup
        isOpen={showScheduleMeetingPopup}
        mutation={scheduleAssignment}
        assignment={assignment}
        handleSubmit={(payload) => {
          scheduleAssignment.mutate(
            payload,
            {
              onSuccess: () => {
                spawnSuccessToast(t('schedule_meeting_popup.submit_success_message'), { title: t('schedule_meeting_popup.submit_success_title') })
                setShowScheduleMeetingPopup(false)
              },
            }
          )
        }}
        onClose={() => setShowScheduleMeetingPopup(false)}
      />

    </div>
  )
}
