import { ClientPropertyAITextGenerator } from '../../ClientPropertyAITextGenerator'
import { FC } from 'react'

export const ClientPropertyAITextGeneratorPage: FC = () => {

  return (
    // TODO ADD PROVIDERS HERE WHEN THE CONTENT IS DEVELOPED
    <ClientPropertyAITextGenerator />
  )
}
