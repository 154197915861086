import { FC, useCallback, useMemo } from 'react'
import { GRAY_900, ORANGE_600 } from 'constants/styling/theme'
import { useStyleSelection, useVirtualFurnitureStylesFilter } from '../main/contexts'

import { AnalyticsEvent } from 'utils/analytics'
import { SelectableButtonsGrid } from 'components/common/SelectableButtonsGrid'
import Stack from '@mui/material/Stack'
import { ToggleFilterValue } from 'components/common/ToggleFilter'
import Typography from '@mui/material/Typography'
import { VirtualFurnitureRoomType } from 'constants/virtualFurniture'
import styles from './RoomStyle.module.sass'
import { useRoomAPI } from 'components/contexts/RoomAPI.context'
import { useTranslation } from 'react-i18next'

/** 
 * @component
 * RoomTypes display options for selecting different room types. Currently, the system allows selecting one room type per visual,
 * but this may be changed in future versions to allow combining room types within a single visual.
 * 
 * @example
 * <RoomTypes />
 */
export const RoomTypes: FC = () => {
  const { updateRoom } = useRoomAPI()
  const { availableRoomTypes } = useVirtualFurnitureStylesFilter()
  const { activeRoom, stagingFlowAnalytics } = useStyleSelection()

  const { t } = useTranslation(['style_selection_order_step'])

  /** Available room types mapped to objects */
  const roomTypeToggleValues: ToggleFilterValue<VirtualFurnitureRoomType>[] = useMemo(
    () => availableRoomTypes.map((roomType) => {

      const isEmptyTypeSelected = activeRoom.roomTypes.has(VirtualFurnitureRoomType.EMPTY_ROOM)
      const areNonEmptyTypesSelected = activeRoom.roomTypes.size > 0 && !isEmptyTypeSelected

      let isDisabled = false

      if (isEmptyTypeSelected && roomType !== VirtualFurnitureRoomType.EMPTY_ROOM) isDisabled = true
      if (areNonEmptyTypesSelected && roomType === VirtualFurnitureRoomType.EMPTY_ROOM) isDisabled = true

      return {
        value: roomType,
        displayName: t(`virtual_furniture_room_type:${roomType}`),
        disabled: isDisabled,
      }
    }),
    [activeRoom.roomTypes, availableRoomTypes, t]
  )

  const onSelectRoomType = useCallback((type: VirtualFurnitureRoomType) => {
    stagingFlowAnalytics(AnalyticsEvent.ROOM_TYPE_SELECTED, { RoomTypes: type })
    updateRoom(activeRoom.key, { roomTypes: activeRoom.roomTypes.add(type) })
  }, [activeRoom.key, activeRoom.roomTypes, stagingFlowAnalytics, updateRoom])

  return (
    <div>

      <Stack gap={0.4} marginBottom={1.6} textAlign="center">

        <Typography color={GRAY_900} variant='text-lg' fontWeight={600}>
          {t('style_selection_order_step:room_types')}
          &nbsp;
          <span style={{
            fontSize: '1.4rem',
            color: ORANGE_600,
            fontWeight: 'bold',
          }}>
            ({t('style_selection_order_step:mandatory')})
          </span>
        </Typography>

        <Typography variant="text-sm">
          {t('style_selection_order_step:room_types_description')}
        </Typography>

      </Stack>

      <SelectableButtonsGrid
        values={roomTypeToggleValues}
        selectedValue={activeRoom.roomTypes.toArray()}
        className={styles.roomGridContainer}
        onSelect={type => onSelectRoomType(type)}
        onUnselect={type => updateRoom(activeRoom.key, { roomTypes: activeRoom.roomTypes.delete(type) })}
        showCheckbox
        activeButtonClickable
      />

    </div>
  )
}
