import { ActionLoadEditedVisual, purgeLoadEditedVisuals } from 'redux/Individual/VisualsEditing/LoadEditedVisuals'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { Dispatch, FC, Fragment, SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { EditedVisualFileType, exportTypeToEditedVisualsType, exportTypeToEditedVisualsWebType } from 'constants/visualEditing'
import { ExportOption, VisualEditingSteps, patternList } from 'constants/misc'
import { archiveEditingVisuals, purgeArchiveEditingVisuals } from 'redux/Individual/VisualsEditing/ArchivedEditingVisuals'

import { APIRequest } from 'models/API'
import { AnyAction } from 'redux'
import { ArchiveVisualsTag } from 'redux/Individual/Visual/ArchiveVisuals'
import Button from 'components/common/Button/Button'
import { WorkspaceRole } from 'models/user'
import { createAPIAction } from 'redux/Helpers'
import { runWorker } from 'utils/visuals'
import styles from 'components/pages/VisualsEditing/VisualEditingFooter/VisualEditingFooter.module.sass'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'
import { useVisualsEditing } from '../VisualsEditing.context'
import { useWorkspacesProfileContext } from 'components/pages/Client/WorkspacesProfile/_main/WorkspacesProfile.context'

/**
 * @interface Props Input properties
 */
interface Props {
  /** SetState action control EditProcessingPopup */
  setEditProcessingPopup: Dispatch<SetStateAction<boolean>>
  /** SetState action control SaveTemplatePopup */
  setSaveTemplatePopup: Dispatch<SetStateAction<boolean>>
  /** SetState action control DownloadPopup */
  setDownloadPopup: Dispatch<SetStateAction<boolean>>
}

/**
 * @component VisualsEditingFooter 
 * @example
 * <VisualsEditingFooter />
 */
export const VisualsEditingFooter: FC<Props> = ({
  setEditProcessingPopup,
  setSaveTemplatePopup,
  setDownloadPopup
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['visuals_editing'])

  const { workspaceDataOfUser } = useWorkspacesProfileContext()
  const { currentUserWorkspace, clientData, hasUserActiveSubscription } = useUserData()

  const {
    assignmentId,
    processPreviewDisabled,
    logEditEvent,
    logSubmitEdit,
    activeFlowStep,
    setActiveFlowStep,
    isWatermarkActive,
    uploadedWatermarkLogo,
    visualEditingFilenames,
    selectedWatermarkSize,
    selectedWatermarkOpacity,
    selectedWatermarkPosition,
    selectedRatioPattern,
    exportOption,
    isDownloadRunning,
    saveTemplateStatusSuccess,
    triggerVisualEditing,
    longPollJob,
    sendEditedVisualsNoSubscription,
    isDownloadReady,
  } = useVisualsEditing()

  const onGoBackToEditing = useCallback(() => {
    logEditEvent(AnalyticsEvent.CANCEL_DOWNLOAD_BULK_EDITING)
    setActiveFlowStep(VisualEditingSteps.SETTINGS)
    dispatch(purgeLoadEditedVisuals())
    dispatch(purgeArchiveEditingVisuals(assignmentId))
  }, [setActiveFlowStep, logEditEvent, assignmentId, dispatch])

  const isWorkspaceAdmin = workspaceDataOfUser?.role === WorkspaceRole.ADMIN

  const submitError = useMemo(() => {
    // The Active Watermark section is missing a logo, either completely or due to user deletion.
    if (isWatermarkActive && !uploadedWatermarkLogo?.signedURL) return t('error_upload_logo')
    // The user hasn't made any changes to the visuals.
    if (processPreviewDisabled) return t('error_select_options')
    return null
  }, [isWatermarkActive, processPreviewDisabled, t, uploadedWatermarkLogo])

  const handleLoadEditedVisuals = async (jobId: string, assignmentId: string, filenames: string[], visualType: EditedVisualFileType, dispatch: Dispatch<AnyAction>) => {

    for (const filename of filenames) {
      const action: ActionLoadEditedVisual = createAPIAction([ActionTypeAPIEvent.FETCH, ActionTypeAPIData.LOAD_EDITED_VISUAL], {
        jobId,
        assignmentId,
        visualType,
        filename,
        request: new APIRequest()
      })

      const getWorker: ActionLoadEditedVisual | undefined = await runWorker(action)

      if (getWorker) {
        dispatch(getWorker)
      }
    }
  }

  /** Dispatches actions to archive editing visuals for a given jobId based on the export option. */
  const handleVisualsExportDispatch = useCallback((jobId: string) => {
    if (exportOption === ExportOption.WEB) {
      dispatch(archiveEditingVisuals(assignmentId, jobId, [exportTypeToEditedVisualsWebType[exportOption]], visualEditingFilenames, ArchiveVisualsTag.CLIENT_ARCHIVE, false))
    } else {
      dispatch(archiveEditingVisuals(assignmentId, jobId, [exportTypeToEditedVisualsType[exportOption]], visualEditingFilenames, ArchiveVisualsTag.CLIENT_ARCHIVE, false))
    }
  }, [assignmentId, dispatch, exportOption, visualEditingFilenames])

  const handlePreviewAndArchiveVisuals = useCallback(async (jobId: string) => {
    try {
      await longPollJob.mutateAsync(jobId)

      handleLoadEditedVisuals(jobId, assignmentId, visualEditingFilenames, exportTypeToEditedVisualsType[exportOption], dispatch)
      handleLoadEditedVisuals(jobId, assignmentId, visualEditingFilenames, exportTypeToEditedVisualsWebType[exportOption], dispatch)

      handleVisualsExportDispatch(jobId)
    } catch (e) {
      return console.error(e)
    }
  }, [assignmentId, dispatch, handleVisualsExportDispatch, exportOption, longPollJob, visualEditingFilenames])

  const onEditVisuals = useCallback(() => {
    if (!currentUserWorkspace) return

    triggerVisualEditing.mutate({
      filenames: visualEditingFilenames,
      assignmentId,
      applyWatermark: isWatermarkActive,
      watermarkSize: selectedWatermarkSize,
      watermarkOpacity: selectedWatermarkOpacity,
      watermarkPositioning: selectedWatermarkPosition,
      imageRatio: selectedRatioPattern.value,
      exportOption,
      workspaceId: currentUserWorkspace.id
    }, {
      onSuccess: (response) => {
        const jobId = response.data.id
        handlePreviewAndArchiveVisuals(jobId)
      }
    })

    logSubmitEdit()
    setEditProcessingPopup(true)
  }, [assignmentId, currentUserWorkspace, exportOption, handlePreviewAndArchiveVisuals, isWatermarkActive, logSubmitEdit, selectedRatioPattern.value, selectedWatermarkOpacity, selectedWatermarkPosition, selectedWatermarkSize, setEditProcessingPopup, triggerVisualEditing, visualEditingFilenames])

  const handleProcessPreview = useCallback(() => {
    if (submitError) return
    onEditVisuals()
  }, [onEditVisuals, submitError])

  const handleBasicDownload = useCallback(async () => {
    sendEditedVisualsNoSubscription.mutate({
      filenames: visualEditingFilenames,
      assignmentId,
      applyWatermark: false,
      imageRatio: patternList[0].value, // Use default, ORIGINAL
      exportOption,
      format: exportOption,
    }, {
      onSuccess: (response) => {
        const jobId = response.data.id
        try {
          handleVisualsExportDispatch(jobId)
        } catch (e) {
          return console.error(e)
        }
      }
    })
  }, [assignmentId, exportOption, handleVisualsExportDispatch, sendEditedVisualsNoSubscription, visualEditingFilenames])

  // Start download by opening the download popup after the download is ready
  useEffect(() => {
    if (isDownloadReady && sendEditedVisualsNoSubscription.isSuccess && !isDownloadRunning) {
      setDownloadPopup(true)
    }
  }, [isDownloadReady, isDownloadRunning, sendEditedVisualsNoSubscription.isSuccess, setDownloadPopup])

  return (
    <div className={styles.footerContent}>

      {activeFlowStep === VisualEditingSteps.SETTINGS &&
        <Fragment>

          <span className={styles.processPreviewError}>{submitError}</span>

          {hasUserActiveSubscription
            ? (
              <Button type="primary" disabled={!!submitError} onClick={handleProcessPreview}>
                {t('preview_visuals')}
              </Button>
            )
            : (
              <Button
                type="primary"
                disabled={!!submitError || (sendEditedVisualsNoSubscription.isSuccess && isDownloadRunning)}
                isLoading={sendEditedVisualsNoSubscription.isSuccess && !isDownloadReady}
                onClick={handleBasicDownload}
              >
                {t('export_visuals')}
              </Button>
            )
          }

        </Fragment>
      }

      {activeFlowStep === VisualEditingSteps.PREVIEW &&
        <Fragment>

          <Button type="secondary noborder" onClick={onGoBackToEditing} className={styles.actionButton}>
            {t('go_back_to_editing')}
          </Button>

          {isWorkspaceAdmin &&
            <Button
              type="secondary"
              className={styles.actionButton}
              disabled={saveTemplateStatusSuccess}
              onClick={() => {
                logAnalyticsEvent(AnalyticsEvent.CLICK_SAVE_TEMPLATE, {
                  userEmail: clientData?.email,
                  organizationId: clientData?.organizationId,
                  logoSetting: isWatermarkActive ? `${selectedWatermarkSize},${selectedWatermarkOpacity},${selectedWatermarkPosition}` : '',
                  ratioSetting: selectedRatioPattern.value,
                  exportSetting: exportOption,
                })
                setSaveTemplatePopup(true)
              }}
            >
              {t(saveTemplateStatusSuccess ? 'save_template.template_saved' : 'save_template.save_settings_as_template')}
            </Button>
          }

          <Button
            type="primary"
            disabled={isDownloadRunning}
            isLoading={isDownloadRunning}
            onClick={() => {
              logEditEvent('Download Bulk Editing', { numberOfVisuals: visualEditingFilenames.length })
              setDownloadPopup(true)
            }}
          >
            {t('export_visuals')}
          </Button>

        </Fragment>
      }

    </div>
  )
}
