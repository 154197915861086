const googleApiKey = process.env.CI === 'true' ? 'GOOGLE_API_KEY' : process.env.REACT_APP_GOOGLE_API_KEY || process.env.STORYBOOK_GOOGLE_API_KEY
if (!googleApiKey) throw new Error('GOOGLE_API_KEY env variable is missing')

const googleMapId = process.env.CI === 'true' ? 'GOOGLE_MAP_ID' : process.env.REACT_APP_GOOGLE_MAP_ID
if (!googleMapId) throw new Error('GOOGLE_MAP_ID env variable is missing')

/** Google API key */
export const GOOGLE_API_KEY = googleApiKey

/** Google Map ID */
export const GOOGLE_MAP_ID = googleMapId
