import { EditingCategory, ProductKind, ProductKindsFloorPlan, ProductKindsWithDocumentGallery, ProductType, ProductTypesFloorPlan } from 'constants/product'
import { FC, ReactNode, createContext, useContext, useMemo } from 'react'

import { ProductDTO } from 'models/product'
import { VisualFileType } from 'constants/visual'
import { useGalleryAssignment } from './GalleryAssignment.context'
import { useGalleryVisualType } from './GalleryVisualType.context'

interface GalleryProductInterface {
  product?: ProductDTO
  isVideo: boolean
  isVirtualVisit: boolean
  isFloorPlan: boolean
  isFloorPlanWithTypeVirtualVisit: boolean
  isDroneProduct: boolean
  isDocumentProduct: boolean
  isVirtualVisitMatterport: boolean
  isEnergyCertificateProduct: boolean
  isFloorPlanCertificationProduct: boolean
  isAuthoritiesDocumentsProduct: boolean
}

const defaultGalleryProductValue: GalleryProductInterface = {
  isVideo: false,
  isVirtualVisit: false,
  isFloorPlan: false,
  isFloorPlanWithTypeVirtualVisit: false,
  isDroneProduct: false,
  isDocumentProduct: false,
  isVirtualVisitMatterport: false,
  isEnergyCertificateProduct: false,
  isFloorPlanCertificationProduct: false,
  isAuthoritiesDocumentsProduct: false,
}

/** Gallery product context */
export const GalleryProductContext = createContext<GalleryProductInterface>(defaultGalleryProductValue)
/** Gallery product context hook */
export const useGalleryProduct = (): GalleryProductInterface => useContext(GalleryProductContext)

/** Context provider for gallery product */
export const GalleryProductContextProvider: FC<{
  assignmentId: string
  children?: ReactNode
}> = ({
  children,
}) => {
    const {
      assignmentData,
      product
    } = useGalleryAssignment()
    const { normalizedOriginalType } = useGalleryVisualType()

    const isVideo = useMemo(() => (
      assignmentData?.type === ProductType.VIDEO
    ), [assignmentData])

    // Product type is virtual visit also know as virtual tour matterport and kinds HD
    const isVirtualVisitHD = useMemo(() => (
      (assignmentData?.type === ProductType.VIRTUAL_VISIT && product?.kind === ProductKind.HD)
    ), [assignmentData, product])

    // Product type is kind SD
    const isVirtualVisitSD = useMemo(() => (
      (product?.kind === ProductKind.SD)
    ), [product])

    const isVirtualVisit = useMemo(() => {
      if (isVirtualVisitHD || isVirtualVisitSD) return true

      if (product?.kind && ProductKindsWithDocumentGallery.has(product.kind)) {
        return false
      }

      if (assignmentData?.type === ProductType.VIRTUAL_VISIT && product?.kind === ProductKind.MATTERPORT_FLOOR_PLAN_WITH_VISIT) {
        return normalizedOriginalType === VisualFileType.RAW
      }

      if (assignmentData?.type === ProductType.FLOOR_PLAN && (product?.kind !== ProductKind.MOBILE_APPLICATION_FLOOR_PLAN && product?.category !== EditingCategory.EDITING)) {
        return normalizedOriginalType === VisualFileType.RAW
      }

      return false
    }, [isVirtualVisitHD, isVirtualVisitSD, assignmentData?.type, product?.category, product?.kind, normalizedOriginalType])

    const isVirtualVisitMatterport = useMemo(() => isVirtualVisit && (isVirtualVisitHD || isVirtualVisitSD), [isVirtualVisit, isVirtualVisitHD, isVirtualVisitSD])

    const isFloorPlan = useMemo(() => (
      (
        !!product?.kind &&
        ProductKindsFloorPlan.has(product?.kind) &&
        !ProductKindsWithDocumentGallery.has(product.kind) // MARK: Check with Tom
      ) ||
      (
        !!product?.type &&
        ProductTypesFloorPlan.has(product?.type)
      )
    ), [product])

    const isFloorPlanWithTypeVirtualVisit = useMemo(() => !!product && (product.kind === ProductKind.MATTERPORT_FLOOR_PLAN_WITH_VISIT) && (product.type === ProductType.VIRTUAL_VISIT), [product])

    const isDroneProduct = useMemo(() => !!product && (product.kind === ProductKind.DRONE_PHOTO || product.kind === ProductKind.STANDARD_DRONE_VIDEO), [product])

    const isDocumentProduct = useMemo(() => !!product && ProductKindsWithDocumentGallery.has(product.kind), [product])

    // Also known as living area calculation
    const isFloorPlanCertificationProduct = useMemo(() => !!product && product.kind === ProductKind.FLOOR_PLAN_CERTIFICATION, [product])

    const isEnergyCertificateProduct = useMemo(() => !!product && product.kind === ProductKind.ENERGY_CERTIFICATE, [product])
    const isAuthoritiesDocumentsProduct = useMemo(() => !!product && product.kind === ProductKind.AUTHORITIES_DOCUMENTS, [product])

    return (
      <GalleryProductContext.Provider
        value={{
          product,
          isVideo,
          isVirtualVisit,
          isFloorPlan,
          isFloorPlanWithTypeVirtualVisit,
          isDroneProduct,
          isDocumentProduct,
          isVirtualVisitMatterport,
          isEnergyCertificateProduct,
          isFloorPlanCertificationProduct,
          isAuthoritiesDocumentsProduct,
        }}
      >
        {children}
      </GalleryProductContext.Provider>
    )
  }
