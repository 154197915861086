import { CloudUploadOutlined, MessageOutlined, ShoppingCartOutlined } from '@mui/icons-material'
import { FC, useMemo, useState } from 'react'
import { GRAY_900, MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { GalleryTab, useGalleryAssignment, useGalleryDeal, useGalleryProduct } from 'components/pages/Gallery/_main/contexts'
import { Stack, useMediaQuery } from '@mui/material'

import { AssignmentStage } from 'constants/assignment'
import { AssignmentStageWithDocumentSendToClient } from 'components/pages/Gallery/_main/ClientGallery/ClientGalleryContent'
import { ClientGalleryDocuments } from 'components/pages/Gallery/_main/ClientGallery/ClientGalleryDocuments'
import { ClientPropertyDocumentTypeFilter } from '../ClientPropertyDocumentTypeFilter'
import { ClientPropertyEmptyContent } from '../ClientPropertyEmptyContent'
import { PageContent } from 'components/common/Page/PageContent'
import { Path } from 'constants/router'
import { useClientProperty } from '../_main/contexts'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

/**
 * Client Property Documents content.
 *
 * @example <ClientPropertyDocumentsGallery />
 */
export const ClientPropertyDocumentsGallery: FC = () => {
  const { t } = useTranslation(['gallery'])
  const navigate = useNavigate()

  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)
  const { assignmentStage, isDocumentWithValidateDraft } = useGalleryAssignment()
  const { requiresPowerOfAttorneyDocuments, totalRequestCountData } = useGalleryDeal()
  const { isDocumentProduct } = useGalleryProduct()
  const {
    assignmentId,
    propertyAssignmentsData
  } = useClientProperty()

  const [activeAssignmentDocumentsFilterTab, setActiveAssignmentDocumentsFilterTab] = useState(
    assignmentId ?? (propertyAssignmentsData ? propertyAssignmentsData[0].id : '')
  )

  const isDocSendToClient = useMemo(() => assignmentStage && AssignmentStageWithDocumentSendToClient.has(assignmentStage), [assignmentStage])
  const isWaitingPoADoc = useMemo(() => assignmentStage === AssignmentStage.WAITING_FOR_POWER_OF_ATTORNEY_DOCUMENTS, [assignmentStage])
  const isDocumentInStageProgress = useMemo(() =>
    assignmentStage === AssignmentStage.WAITING_FOR_CLIENT_TO_UPLOAD_DOCUMENTS
    || assignmentStage === AssignmentStage.REQUEST_SENT_TO_PROCESSOR
    || assignmentStage === AssignmentStage.DOCUMENTS_RECEIVED_FROM_PROCESSOR, [assignmentStage])

  const isShowDefaultInProgressContent = useMemo(() => {
    if (isDocSendToClient || isWaitingPoADoc) return false
    if (isDocSendToClient || isDocumentWithValidateDraft) return false

    return true
  }, [isDocSendToClient, isDocumentWithValidateDraft, isWaitingPoADoc])

  const hasMessageRequests = useMemo(() => {
    if (!isDocumentProduct) return false
    if (!isDocumentInStageProgress) return false
    if (isDocSendToClient) return false

    const hasOpenRequests = !!totalRequestCountData && totalRequestCountData > 0

    return hasOpenRequests && assignmentStage === AssignmentStage.WAITING_FOR_CLIENT_TO_UPLOAD_DOCUMENTS
  }, [assignmentStage, isDocSendToClient, isDocumentInStageProgress, isDocumentProduct, totalRequestCountData])

  const onNavigationChange = (tab: GalleryTab) => {
    if (!activeAssignmentDocumentsFilterTab) return
    navigate(Path.GALLERY_INFO.replace(':id', activeAssignmentDocumentsFilterTab.toString()).replace(':tab', tab))
  }

  return (
    <Stack width="100%" paddingTop={isMobileView ? '10rem' : '0'}>

      <Stack width="100%" alignItems="center" justifyContent="center" >
        <PageContent>
          {/* ASSIGNMENT TYPES FILTERS */}
          {!!propertyAssignmentsData &&
            <ClientPropertyDocumentTypeFilter
              activeAssignmentDocumentsFilterTab={activeAssignmentDocumentsFilterTab}
              callback={setActiveAssignmentDocumentsFilterTab}
            />
          }
        </PageContent>
      </Stack>

      {/** ASSIGNMENT NOT DELIVERED TO CLIENT */}
      {isShowDefaultInProgressContent && !hasMessageRequests && (
        <ClientPropertyEmptyContent
          title={t('client_gallery.empty')}
          buttonText={t('client_gallery.empty_action')}
          onButtonClick={() => onNavigationChange(GalleryTab.ORDER_INFO)}
          icon={<ShoppingCartOutlined fontSize='large' sx={{ color: GRAY_900 }} />}
        />
      )}

      {/** ASSIGNMENT NOT DELIVERED TO CLIENT AND HAS REQUESTS. Documents */}
      {hasMessageRequests &&
        <ClientPropertyEmptyContent
          title={t('client_gallery.available_requests.title')}
          description={t('client_gallery.available_requests.description')}
          buttonText={t('client_gallery.available_requests.see_request')}
          onButtonClick={() => onNavigationChange(GalleryTab.REQUESTS)}
          icon={<MessageOutlined fontSize='large' sx={{ color: GRAY_900 }} />}
        />
      }

      {/** ASSIGNMENT WAITING FOR PoA UPLOAD. Documents */}
      {isDocumentProduct && isWaitingPoADoc && requiresPowerOfAttorneyDocuments &&
        <ClientPropertyEmptyContent
          title={t('client_gallery.empty_waiting_power_of_attorney')}
          description={t('client_gallery.empty_waiting_power_of_attorney_description')}
          buttonText={t('client_gallery.empty_waiting_power_of_attorney_action')}
          onButtonClick={() => onNavigationChange(GalleryTab.ORDER_INFO)}
          icon={<CloudUploadOutlined fontSize='large' sx={{ color: GRAY_900 }} />}
        />
      }

      {/** ASSIGNMENT DELIVERED TO CLIENT. Documents */}
      {(isDocSendToClient || isDocumentWithValidateDraft) && isDocumentProduct &&
        <ClientGalleryDocuments assignmentId={activeAssignmentDocumentsFilterTab} />
      }

    </Stack>
  )
}
