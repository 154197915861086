import { ReactComponent as OrderTypeDocuments } from 'assets/img/icons/order_type_documents.svg'
import { ReactComponent as OrderTypeDroneIcon } from 'assets/img/icons/order_type_drone.svg'
import { ReactComponent as OrderTypeEditingIcon } from 'assets/img/icons/order_type_editing.svg'
import { ReactComponent as OrderTypeFloorPlanIcon } from 'assets/img/icons/order_type_floor_plan.svg'
import { ReactComponent as OrderTypePhotoIcon } from 'assets/img/icons/order_type_photo.svg'
import { ReactComponent as OrderTypeRenderingIcon } from 'assets/img/icons/order_type_rendering.svg'
import { ReactComponent as OrderTypeStagingIcon } from 'assets/img/icons/order_type_staging.svg'
import { ReactComponent as OrderTypeVideoIcon } from 'assets/img/icons/order_type_video.svg'
import { ReactComponent as OrderTypeVirtualTourIcon } from 'assets/img/icons/order_type_virtual_visit.svg'
import { ProductKind } from 'constants/product'
import { ReactNode } from 'react'

/**
 * This function returns an icon component based on the type of product kind passed as an argument.
 * If the product kind doesn't match any defined cases, it defaults to returning a default photo icon.
 * @param kind - The product kind to determine the icon for.
 * @returns A ReactNode representing the icon component for the given product kind.
 */
export const getProductKindIcon = (kind: ProductKind): ReactNode => {
  switch (kind) {
    case ProductKind.GROUND_PHOTO_PRESTIGE:
    case ProductKind.GROUND_PHOTO:
    case ProductKind.PORTRAIT:
    case ProductKind.MATTERPORT_PHOTO:
      return <OrderTypePhotoIcon />

    case ProductKind.RESIDENTIAL_STAGING:
    case ProductKind.COMMERCIAL_STAGING:
      return <OrderTypeStagingIcon />

    case ProductKind.HD:
      return <OrderTypeVirtualTourIcon />

    case ProductKind.PRESTIGE_GROUND_AND_DRONE_VIDEO:
    case ProductKind.STANDARD_GROUND_AND_DRONE_VIDEO:
    case ProductKind.REEL_VIDEO:
    case ProductKind.STANDARD_DRONE_VIDEO:
    case ProductKind.STANDARD_GROUND_VIDEO:
    case ProductKind.SLIDESHOW:
    case ProductKind.TEASER_VIDEO:
      return <OrderTypeVideoIcon />

    case ProductKind.MOBILE_APPLICATION_FLOOR_PLAN:
    case ProductKind.FLOOR_PLAN_EDITING_2D:
    case ProductKind.FLOOR_PLAN_EDITING_3D:
    case ProductKind.FLOOR_PLAN_CERTIFICATION:
    case ProductKind.MATTERPORT_FLOOR_PLAN_WITHOUT_VISIT:
    case ProductKind.MATTERPORT_FLOOR_PLAN_WITH_VISIT:
      return <OrderTypeFloorPlanIcon />

    case ProductKind.DRONE_PHOTO:
      return <OrderTypeDroneIcon />

    case ProductKind.PHOTO:
      return <OrderTypeEditingIcon />

    case ProductKind.RESIDENTIAL_RENDERING:
    case ProductKind.COMMERCIAL_RENDERING:
    case ProductKind.FLOOR_PLAN_RENDERING_3D:
      return <OrderTypeRenderingIcon />

    case ProductKind.AUTHORITIES_DOCUMENTS:
      return <OrderTypeDocuments />

    default:
      return <OrderTypePhotoIcon />
  }
}
