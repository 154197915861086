import { Nullable } from 'models/helpers'

/** Enum of available sky replacement options. */
export enum SkyReplacementOption {
  NO_CLOUD = 'CLEAR',
  LOW_CLOUD = 'LOW_CLOUD',
  CLOUDY = 'HIGH_CLOUD',
}

/** Represents the DTO for AI editing options. */
export interface AIEditingDTO {
  /** Whether the sky replacement is enabled. */
  skyReplacement: boolean
  /** Specifies the type of cloud to be used in sky replacement. */
  cloudType?: Nullable<SkyReplacementOption>
}
