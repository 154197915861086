import { BOX_SHADOW_LG, BOX_SHADOW_MD, BOX_SHADOW_SM, BOX_SHADOW_XS } from 'constants/styling/theme'
import { Box, BoxProps } from '@mui/material'
import { FC, ReactNode, useMemo } from 'react'

/** Represents the size of the box shadow */
export type ShadowElevation = 'xs' | 'sm' | 'md' | 'lg' | 'none'

/**
 * Props for the BorderBoxWrapper component.
 * @interface Props
 * @extends BoxProps
 */
export interface BorderBoxWrapperProps extends BoxProps {
  /** The children components or content to be rendered. */
  children: ReactNode
  /** The background color of the component. */
  backgroundColor?: string
  /** Specifies the size of the box shadow.  */
  elevation?: ShadowElevation
}

/**
 * @component
 * BorderBoxWrapper component displays it's children in a box layout with border and an optional background color.
 * 
 * @example
 * <BorderBoxWrapper backgroundColor="red" borderColor="blue">
 *   <ChildComponent />
 * </BorderBoxWrapper>
 */
export const BorderBoxWrapper: FC<BorderBoxWrapperProps> = ({
  children,
  backgroundColor = 'white',
  elevation = 'xs',
  ...props
}) => {

  const elevationShadow = useMemo(() => {
    switch (elevation) {
      case 'xs':
        return BOX_SHADOW_XS
      case 'sm':
        return BOX_SHADOW_SM
      case 'md':
        return BOX_SHADOW_MD
      case 'lg':
        return BOX_SHADOW_LG
      default:
        return undefined
    }
  }, [elevation])

  return (
    <Box
      {...props}
      sx={{
        borderRadius: props.borderRadius ?? '0.8rem',
        boxSizing: 'border-box',
        boxShadow: elevationShadow,
        background: backgroundColor,
        border: props.borderColor ? `1px solid ${props.borderColor}` : props.border ?? 'inherit',
        ...props.sx,
      }}
    >
      {children}
    </Box>
  )
}
