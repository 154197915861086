import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import { EditorOption } from '../EditorOption/EditorOption.component'
import { EditorOptionButton } from '../EditorOptionButton'
import { MUIDivider } from 'components/common/MUIDivider'
import { NoAvailableTemplate } from './NoAvailableTemplate.module'
import { QueryStatus } from 'components/common/QueryStatus'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'
import { useVisualsEditing } from 'components/pages/VisualsEditing/VisualsEditing.context'

/**
 * @component EditorOptionApplyTemplate 
 * @example
 * <EditorOptionApplyTemplate />
 */
export const EditorOptionApplyTemplate: React.FC = () => {
  const { t } = useTranslation(['visuals_editing', 'editor_options'])

  const { hasUserActiveSubscription } = useUserData()

  const {
    isTemplateApplied,
    setIsTemplateApplied,
    resetVisualsEditingContext,
    logEditEvent,
    productParams,
    templateId,
    savedTemplateOptionsData,
    savedTemplateOptionsResponse
  } = useVisualsEditing()

  return (
    <EditorOption
      title={t('editor_options.apply_template.title')}
      isActive={isTemplateApplied}
      onResetClick={resetVisualsEditingContext}
      button={
        templateId ?
          <EditorOptionButton
            isActionButton
            title={savedTemplateOptionsData?.name ?? t('editor_options.apply_template.default_name')}
            icon={<BookmarkBorderIcon fontSize='inherit' />}
            isActive={isTemplateApplied}
            disabled={!hasUserActiveSubscription}
            onClick={() => {
              if (!hasUserActiveSubscription) return

              logEditEvent('Apply Template - VE Flow', productParams)
              setIsTemplateApplied(true)
            }}
          />
          : <NoAvailableTemplate />
      }
    >
      <QueryStatus
        spaceTopRem={2}
        query={savedTemplateOptionsResponse}
        showStates={['error']}
      />
      <MUIDivider margin={16} />
    </EditorOption>
  )
}
