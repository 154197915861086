import { propertyTabs, useClientProperty } from '../contexts'

import { FC } from 'react'
import { GalleryHeader } from 'components/common/Gallery/GalleryHeader'
import { GalleryNotFound } from 'components/pages/Gallery/_main/ClientGallery/common/GalleryNotFound'
import { Outlet } from 'react-router-dom'
import { PageContent } from 'components/common/Page/PageContent'
import { PageHeader } from 'components/common/Page/PageHeader'
import { PageLayout } from 'components/common/Page/PageLayout'
import { PageTab } from 'components/common/Page/PageTab'
import { PageTabs } from 'components/common/Page/PageTabs'
import { PropertyPageActions } from '../../../_main/PropertyPageActions'
import Stack from '@mui/material/Stack'
import { useTranslation } from 'react-i18next'

export const ClientPropertyPageLayout: FC = () => {
  const { t } = useTranslation(['property'])
  const {
    propertyData,
    handleTabChange,
    activeTab,
    setActiveTab,
    propertySubTitle,
    showPropertyNotFound
  } = useClientProperty()

  return (
    <PageLayout
      header={
        <PageHeader actions={<PropertyPageActions />}>
          <GalleryHeader
            title={propertyData?.title || '-'}
            subTitle={propertySubTitle}
          />
        </PageHeader>
      }
      tabs={
        <PageTabs>
          {[...propertyTabs].map(propertyTab =>
            <PageTab
              key={propertyTab}
              tab={propertyTab}
              tabText={t(`tabs.${propertyTab}`)}
              activeTab={activeTab}
              onClick={() => {
                setActiveTab(propertyTab)
                handleTabChange(propertyTab)
              }}
            />
          )}
        </PageTabs>
      }
      layoutErrorContent={showPropertyNotFound && <GalleryNotFound />}
    >
      <Stack width="100%">
        <PageContent>
          <Outlet />
        </PageContent>
      </Stack>
    </PageLayout>
  )
}
