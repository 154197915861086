import { BEIGE_300, BEIGE_600, BEIGE_800, BLUE_100, BLUE_300, BLUE_700, CORAL_100, CORAL_300, CORAL_700, GRAY_700, GRAY_900, GREEN_100, GREEN_300, ORANGE_100, ORANGE_300, ORANGE_700 } from 'constants/styling/theme'
import { Box, Stack, SxProps, Theme, Typography } from '@mui/material'
import React, { ReactNode, useMemo } from 'react'

import { BorderBoxWrapper } from '../BorderBoxWrapper'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Trans } from 'react-i18next'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'

export type SystemType = 'info' | 'error' | 'warning' | 'success' | 'system'

const BorderColorVariantMap: Record<SystemType, string> = {
  error: CORAL_300,
  success: GREEN_300,
  warning: ORANGE_300,
  info: BEIGE_600,
  system: BLUE_300,
}

const BackgroundColorVariantMap: Record<SystemType, string> = {
  error: CORAL_100,
  success: GREEN_100,
  warning: ORANGE_100,
  info: BEIGE_300,
  system: BLUE_100,
}

const IconColorVariantMap: Record<SystemType, string> = {
  error: CORAL_700,
  success: GRAY_700,
  warning: ORANGE_700,
  info: BEIGE_800,
  system: BLUE_700,
}

interface Props {
  /** Text content of the message */
  message: string | ReactNode
  /** Title of the box */
  title?: string
  /** Style variant of the box. */
  variant?: SystemType
  /** Optional extra styling */
  sx?: SxProps<Theme>
}

/**
 * Component for displaying system messages to user.
 * 
 * @example
 * <SystemMessage message="I love snails." variant="info"/> 
 */
export const SystemMessage: React.FC<Props> = ({
  message,
  title,
  variant = 'system',
  sx = {},
}) => {

  const systemIcon = useMemo(() => {
    switch (variant) {
      case 'error':
        return <ErrorOutlineOutlinedIcon sx={{ fontSize: 18, color: IconColorVariantMap[variant] }} />
      case 'success':
        return <CheckCircleOutlinedIcon sx={{ fontSize: 18, color: IconColorVariantMap[variant] }} />
      case 'warning':
        return <WarningAmberOutlinedIcon sx={{ fontSize: 18, color: IconColorVariantMap[variant] }} />
      case 'info':
      case 'system':
        return <InfoOutlinedIcon sx={{ fontSize: 18, color: IconColorVariantMap[variant] }} />
      default:
        return <></>
    }
  }, [variant])

  return (
    <BorderBoxWrapper
      gap={1}
      padding={1.5}
      display='flex'
      alignItems='center'
      borderColor={BorderColorVariantMap[variant]}
      backgroundColor={BackgroundColorVariantMap[variant]}
      sx={{ alignItems: 'flex-start', ...sx }}
    >

      <Box>
        {systemIcon}
      </Box>

      <Stack gap=".2rem">

        {title &&
          <Typography variant="text-sm" fontWeight="semiBold" color={GRAY_900}>
            {title}
          </Typography>
        }

        <Trans parent={undefined}>
          <Typography variant="text-sm" color={GRAY_900}>
            {message}
          </Typography>
        </Trans>

      </Stack>

    </BorderBoxWrapper>
  )
}
