import { PropertyTab } from 'components/pages/Property/ClientProperty/_main/contexts'
import { ProductKindsWithDocumentGallery } from 'constants/product'
import { RealEstatePropertyAssignmentDTO } from 'models/property'

export const getPropertyAssignmentsByTab = (tab: PropertyTab, data: RealEstatePropertyAssignmentDTO[] | null) => {
  if (!data) return []

  if (tab === PropertyTab.DOCUMENTS) {
    const missionsWithDocumentGallery = data
      .filter(assignment => assignment.products
        .some((product) => ProductKindsWithDocumentGallery.has(product.kind))
      )
    return missionsWithDocumentGallery
  } else {
    const missionsWithoutDocumentGallery = data
      .filter(assignment => !assignment.products
        .some((product) => ProductKindsWithDocumentGallery.has(product.kind)))
    return missionsWithoutDocumentGallery
  }
}
