import { EntityKeys, QueryType, getMutation } from 'utils/reactQuery'
import { ExportOption, Opacity, Positioning, Size } from 'constants/misc/editingOptions'
import { RatioDTO, VisualEditingTemplateOptionsDTO } from 'models/visualsEditing'

import { AxiosResponse } from 'axios'
import { JobDTO } from 'models/misc'
import { NewWorkspaceDTO } from 'models/workspaces'
import { Nullable } from 'models/helpers'
import { useAPI } from 'utils/API'
import { useQuery } from '@tanstack/react-query'

enum Endpoints {
  UPDATE_VISUAL_EDITING_TEMPLATE = '/workspace/{workspaceId}/assets/template/',
  GET_VISUAL_EDITING_TEMPLATE_OPTIONS = '/workspace/{workspaceId}/assets/template/{templateId}',
  DELETE_VISUAL_EDITING_TEMPLATE = '/workspace/{workspaceId}/assets/template/{templateId}',
  VISUAL_EDITING_TEMPLATE = '/workspace/{workspaceId}/assets/template/',
  TRIGGER_VISUALS_EDITING = '/assignment/{assignmentId}/visualEditing/submit',
  WATERMARK_DELETE = '/workspace/{workspaceId}/assets/watermark/{watermarkId}',
  SUBMIT_EDITED_VISUALS_UNSUBSCRIBED = '/assignment/{assignmentId}/editedVisuals/submit-unsubscribed',
}

interface UpdateVisualEditingTemplatePayload {
  templateName?: string | null,
  watermarkId?: string | null,
  watermarkSize: Size,
  watermarkOpacity: Opacity,
  watermarkPositioning: Positioning,
  imageRatio: RatioDTO['value'],
  exportOption: ExportOption,
  workspaceId: string
}

interface VisualsEditingOptionsPayload {
  assignmentId: string
  filenames: Array<File['name']>
  applyWatermark: boolean
  watermarkSize: Size
  watermarkOpacity: number
  watermarkPositioning: Positioning
  imageRatio: RatioDTO['value']
  exportOption: ExportOption
  workspaceId: string
}

interface VisualsEditingNoSubscriptionOptionsPayload extends Omit<VisualsEditingOptionsPayload, 'watermarkId' | 'watermarkSize' | 'watermarkOpacity' | 'watermarkPositioning' | 'workspaceId'> {
  format: ExportOption
}

// QUERIES

export function useVisualsEditingTemplateOptions(workspaceId: Nullable<NewWorkspaceDTO['id']>, templateId: Nullable<VisualEditingTemplateOptionsDTO['id']>, enabled: boolean) {
  const api = useAPI<Endpoints>()

  return useQuery<VisualEditingTemplateOptionsDTO, Error>({
    queryKey: [EntityKeys.VISUALS_EDITING, 'GET_TEMPLATE'],
    queryFn: () => api.get<VisualEditingTemplateOptionsDTO>(
      Endpoints.GET_VISUAL_EDITING_TEMPLATE_OPTIONS,
      {
        workspaceId: workspaceId?.toString() || '',
        templateId: templateId?.toString() || ''
      },
      false
    ),
    enabled
  })
}

// MUTATIONS

/**
 * We support only single template on FE.
 * If templateName is empty "${workspace.name} template" as a name will be used by default
 */
export const useUpdateVisualsEditingTemplate = () => {
  const api = useAPI<Endpoints>()

  return getMutation<{}, UpdateVisualEditingTemplatePayload>(
    ({ templateName = null, watermarkId = null, watermarkSize, watermarkOpacity, watermarkPositioning, imageRatio, exportOption, workspaceId }) => api.post(
      Endpoints.UPDATE_VISUAL_EDITING_TEMPLATE,
      {
        workspaceId
      },
      {
        templateName,
        watermarkId,
        watermarkSize,
        watermarkOpacity,
        watermarkPositioning,
        imageRatio,
        export: exportOption
      },
      false
    ),
    (client) => {
      client.invalidateQueries({ queryKey: [EntityKeys.USER_WORKSPACE, QueryType.LIST_MINE] })
    }
  )
}

export const useDeleteVisualsEditingLogoAndTemplate = () => {
  const api = useAPI<Endpoints>()
  const deleteLogo = useDeleteLogo()

  return getMutation<{}, { templateId: string, workspaceId: string, watermarkId: string }>(
    ({ templateId, workspaceId }) => api.delete(
      Endpoints.DELETE_VISUAL_EDITING_TEMPLATE,
      {
        templateId,
        workspaceId
      },
      false
    ),
    (_, { workspaceId, watermarkId }) => {
      deleteLogo.mutate({ workspaceId, watermarkId })
    }
  )
}

/** Mutation for delete logo */
export function useDeleteLogo() {
  const api = useAPI<Endpoints>()

  return getMutation<unknown, { workspaceId: string, watermarkId: string }>(
    ({ workspaceId, watermarkId }) => api.delete(
      Endpoints.WATERMARK_DELETE,
      {
        workspaceId: workspaceId.toString(),
        watermarkId: watermarkId.toString(),
      },
      false
    ),
    (client) => {
      client.invalidateQueries({ queryKey: [EntityKeys.USER_WORKSPACE, QueryType.LIST_MINE] })
    }
  )
}

/**
 * Mutation for triggering visuals editing
 */
export const useTriggerVisualsEditing = () => {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<JobDTO>, VisualsEditingOptionsPayload>(
    ({ filenames, applyWatermark, watermarkSize, watermarkOpacity, watermarkPositioning, imageRatio, exportOption, workspaceId, assignmentId }) => api.post(
      Endpoints.TRIGGER_VISUALS_EDITING,
      {
        assignmentId: assignmentId.toString()
      },
      {
        filenames,
        applyWatermark,
        watermarkSize,
        watermarkOpacity,
        watermarkPositioning,
        imageRatio,
        export: exportOption,
        workspaceId
      },
      true
    )
  )
}

/**
 * Mutation for submitting edited visuals for users without subscription
 */
export const useSubmitEditedVisualsNoSubscription = () => {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<JobDTO>, VisualsEditingNoSubscriptionOptionsPayload>(
    ({ filenames, exportOption, assignmentId, applyWatermark, imageRatio, format }) => api.post(
      Endpoints.SUBMIT_EDITED_VISUALS_UNSUBSCRIBED,
      {
        assignmentId: assignmentId.toString()
      },
      {
        format,
        filenames,
        imageRatio,
        applyWatermark,
        export: exportOption,
      },
      true
    )
  )
}
