import { AccountCircleOutlined, BusinessCenterOutlined, CalendarToday, DirectionsCarOutlined, HourglassTop, LocalPhoneOutlined } from '@mui/icons-material'
import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { BLUE_600, CORAL_600, GRAY_1000, GRAY_200, GRAY_300, GREEN_600, ORANGE_600 } from 'constants/styling/theme'
import { Box, Stack, Typography } from '@mui/material'
import { FC, Fragment, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { _invalidateOrder, useAcceptOrder, useArrangeDateTimeAssignment, useDeclineOrder } from 'dataQueries'
import { applyFeeDiscount, bigFromFee, decimalFromBig, formatPrice } from 'utils/price'

import { AssignmentDTOCreative } from 'models/assignment'
import { AssignmentListItem } from './AssignmentListItem'
import Big from 'big.js'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { DeadlineDate } from 'components/common/DeadlineDate'
import { DeclineAssignmentPopup } from '../DeclinePopup'
import { EventTime } from 'components/common/EventTime'
import { FeeDTO } from 'models/fee'
import { MUIBadge } from 'components/common/MUIBadge'
import { MUIButton } from 'components/common/MUIButton'
import { OrderCreativeDTO } from 'models/deal'
import { ScheduleMeetingPopup } from '../ScheduleMeetingPopup'
import { SystemMessage } from 'components/common/SystemMessage'
import { ZERO_DECIMAL } from 'constants/misc'
import { chooseProductContainingVisuals } from 'utils/product'
import { externalNavigate } from 'utils/helpers'
import { getTermsLink } from 'utils/localization'
import { getTimeDurationStringFromMinutesAndHours } from 'utils/time'
import { useActionPopup } from 'utils/hooks'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useTimezone } from 'components/contexts/timezone.context'

export enum CreativeAssignmentCardMode {
  AVAILABLE = 'AVAILABLE',
  NEEDS_ATTENTION = 'NEEDS_ATTENTION',
  AWAITS_UPLOAD = 'AWAITS_UPLOAD',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED'
}

const stagesUnlockingScheduleNotice = new Set([
  CreativeAssignmentCardMode.AVAILABLE,
  CreativeAssignmentCardMode.NEEDS_ATTENTION
])

const modesUnlockingContactInfo = new Set([
  CreativeAssignmentCardMode.NEEDS_ATTENTION,
  CreativeAssignmentCardMode.AWAITS_UPLOAD,
  CreativeAssignmentCardMode.DONE,
  CreativeAssignmentCardMode.CANCELLED
])

const stagesUnlockingVisualsUploadDeadline = new Set([
  CreativeAssignmentCardMode.AWAITS_UPLOAD,
  CreativeAssignmentCardMode.NEEDS_ATTENTION
])

const noFooterModes = new Set([
  CreativeAssignmentCardMode.CANCELLED,
  CreativeAssignmentCardMode.AWAITS_UPLOAD,
  CreativeAssignmentCardMode.DONE
])

const modeToColorMap: Record<CreativeAssignmentCardMode, string> = {
  [CreativeAssignmentCardMode.AVAILABLE]: BLUE_600,
  [CreativeAssignmentCardMode.NEEDS_ATTENTION]: ORANGE_600,
  [CreativeAssignmentCardMode.AWAITS_UPLOAD]: ORANGE_600,
  [CreativeAssignmentCardMode.DONE]: GREEN_600,
  [CreativeAssignmentCardMode.CANCELLED]: CORAL_600,
}

interface RemunerationSum {
  total: FeeDTO
  base: FeeDTO
  bonus: FeeDTO
  transport: FeeDTO
}

export const CreativeGroupedAssignmentListCard: FC<{ mode: CreativeAssignmentCardMode, orderData: OrderCreativeDTO }> = ({ mode, orderData }) => {
  const { t, i18n } = useTranslation(['deal_assignment_card', 'deal_assignment', 'time_translations', 'category', 'product', 'common'])
  const queryClient = useQueryClient()

  const { userTimezone } = useTimezone()
  const { showConfirm } = useActionPopup()
  const { spawnSuccessToast } = useSnackbar()

  const scheduleAssignment = useArrangeDateTimeAssignment()
  const acceptOrder = useAcceptOrder()
  const declineOrder = useDeclineOrder()

  const [showDeclinePopup, setShowDeclinePopup] = useState<boolean>(false)
  const [showScheduleMeetingPopup, setShowScheduleMeetingPopup] = useState<boolean>(false)

  const remuneration: RemunerationSum = useMemo(() => {
    let total = new Big(0)
    let base = new Big(0)
    let bonus = new Big(0)
    let transport = new Big(0)

    const currency = orderData.assignments[0].creativeRemuneration.baseRemuneration.currency

    const getFee = (bigValue: Big) => ({
      value: decimalFromBig(bigValue),
      currency,
      discountPercentage: ZERO_DECIMAL
    })

    for (const { creativeRemuneration } of orderData.assignments) {
      total = total.plus(bigFromFee(applyFeeDiscount(creativeRemuneration.totalRemuneration)))
      base = base.plus(bigFromFee(applyFeeDiscount(creativeRemuneration.baseRemuneration)))
      bonus = bonus.plus(bigFromFee(applyFeeDiscount(creativeRemuneration.bonusRemuneration)))
      transport = transport.plus(bigFromFee(applyFeeDiscount(creativeRemuneration.transportRemuneration)))
    }

    return {
      total: getFee(total),
      base: getFee(base),
      bonus: getFee(bonus),
      transport: getFee(transport),
    }
  }, [orderData.assignments])

  const groupData: AssignmentDTOCreative | undefined = useMemo(() => orderData.assignments[0], [orderData.assignments])

  /* ACCEPT */
  const handleAccept = useCallback(async () => {
    const popupBody = (
      <Stack gap={2}>

        <span>
          {t('accept_assignment_prompt_text')}
        </span>

        {groupData.scheduledByCT &&
          <SystemMessage
            variant="warning"
            message={
              <div className='acceptActionPopup'>
                <Trans
                  t={t}
                  i18nKey="accept_assignment_prompt_flexible"
                  parent={null}
                  components={{ list: <ul />, item: <li />, text: <p />, bold: <strong /> }}
                >
                  &nbsp;
                </Trans>
              </div>
            }
          />
        }

      </Stack>
    )

    const terms = (
      <span className='acceptActionPopupTerms'>
        <Trans t={t} i18nKey="accept_terms">
          <span
            className="link"
            onClick={() => orderData.assignments.forEach(assignment => externalNavigate(assignment.creativeInstructionsUrl))}
          >
            &nbsp;
          </span>

          <a
            className="link"
            href={getTermsLink(i18n.language)}
            target="_blank"
            rel="noreferrer"
          >
            &nbsp;
          </a>
        </Trans>
      </span>
    )

    if (!await showConfirm(popupBody, { title: t('accept_assignment'), actionsAdditionalSlot: terms })) {
      logAnalyticsEvent(AnalyticsEvent.GUIDELINES_DENIED, {
        assignmentId: groupData.id
      })
      return
    }

    acceptOrder.mutate({ orderId: orderData.id })

  }, [acceptOrder, groupData.creativeInstructionsUrl, groupData.id, groupData.scheduledByCT, i18n.language, orderData.id, showConfirm, t])

  return (
    <BorderBoxWrapper borderTop={`6px solid ${modeToColorMap[mode]} !important`}>

      {/* CONTENT */}
      <Box padding={2}>
        <Stack width="100%" gap={2} color={GRAY_1000} fontSize={14}>

          {/* Top row */}
          <Stack direction="row" justifyContent="space-between" gap={1} width="100%" alignItems="center">

            <Typography variant="text-sm" m={0}>
              {orderData.productCategory ? t(`category:${orderData.productCategory}`) : t('common:not_applicable')}
            </Typography>

            <MUIBadge label={`#${orderData.id}`} color="gray" size="sm" />

          </Stack>

          {/* Header */}
          <Stack direction="row" gap={2} justifyContent="flex-start" alignItems="stretch">

            {/* Basic info */}
            <Stack gap={1} flex="1 1 auto">

              <Typography variant="h2" fontWeight="bold">
                {orderData.hasManualAddress ? orderData.manualAddress : orderData.address}
              </Typography>

              {!!orderData.shootingStartDateTime && !groupData.scheduledByCT &&
                <EventTime eventStart={orderData.shootingStartDateTime} eventTimezone={orderData.timezone} userTimezone={userTimezone} />
              }

              {stagesUnlockingScheduleNotice.has(mode) && groupData.scheduledByCT &&
                <MUIBadge icon={<CalendarToday fontSize="medium" />} label="Needs to be scheduled by you" color="orange" sx={{ alignSelf: 'flex-start' }} />
              }

            </Stack>

            {/* Remuneration */}
            <Stack flex="0 0 20rem" alignItems="flex-end" gap={.5}>

              <BorderBoxWrapper
                elevation="none"
                backgroundColor="transparent"
                border="1px solid"
                p=".6rem"
                pb=".3rem"
                borderRadius=".2rem"
                borderColor={GRAY_300}
              >
                <Typography variant="h3" justifySelf="center">
                  {formatPrice(remuneration.total)}
                </Typography>
              </BorderBoxWrapper>

              <Stack direction="row" alignItems="center" gap={.5} fontSize="1.3rem" fontWeight={500} mr={.7}>
                <BusinessCenterOutlined />
                <span>{formatPrice(remuneration.base)}</span>
              </Stack>

              <Stack direction="row" alignItems="center" gap={.5} fontSize="1.3rem" fontWeight={500} mr={.7}>
                <DirectionsCarOutlined />
                <span>{formatPrice(remuneration.transport)}</span>
              </Stack>


            </Stack>

          </Stack>

          {/* Notices */}

          {groupData.hasManualAddress &&
            <SystemMessage
              sx={{ alignSelf: 'flex-start' }}
              variant="system"
              message={<strong>{t('manual_address_note')}</strong>}
            />
          }

          {groupData.keyPickupDetails?.address &&
            <SystemMessage
              sx={{ alignSelf: 'flex-start' }}
              variant="system"
              message={
                <Fragment>
                  <span>{t('key_pickup_warning')}</span> <strong>{groupData.address}</strong>
                </Fragment>
              }
            />
          }

          {!!groupData.visualsUploadDeadline && stagesUnlockingVisualsUploadDeadline.has(mode) &&
            <Box alignSelf="flex-start">
              <DeadlineDate
                deadlineDate={groupData.visualsUploadDeadline}
                deadlineTimezone={groupData.timezone}
                userTimezone={userTimezone}
              />
            </Box>
          }

          {/* Details */}
          <Stack gap={.5}>

            <Stack direction="row" gap={1.5} alignItems="center" flexWrap="wrap">

              <Typography variant="h5">
                {t('deal_assignment:time_on_site')}
              </Typography>

              <Stack direction="row" gap={.5} alignItems="center">
                <HourglassTop />
                {t('time_translations:approx')} {orderData.timeOnSiteMin > 0 && `${getTimeDurationStringFromMinutesAndHours(orderData.timeOnSiteMin)} - `} {getTimeDurationStringFromMinutesAndHours(orderData.timeOnSiteMax)}
              </Stack>

            </Stack>

            {modesUnlockingContactInfo.has(mode) &&
              <Fragment>

                <Stack direction="row" gap={1.5} alignItems="center" flexWrap="wrap">

                  <Typography variant="h5">
                    {t('deal_assignment:contact_on_site')}
                  </Typography>

                  <Stack direction="row" gap={.5} alignItems="center">
                    <AccountCircleOutlined />
                    {orderData.contactPerson.name}
                  </Stack>

                  <Stack direction="row" gap={.5} alignItems="center">
                    <LocalPhoneOutlined />
                    <a href={orderData.contactPerson.phone ? `tel:${orderData.contactPerson.phone}` : undefined}>{orderData.contactPerson.phone ?? ' - '}</a>
                  </Stack>

                </Stack>

                <Stack direction="row" gap={1.5} alignItems="center" flexWrap="wrap">

                  <Typography variant="h5">
                    {t('client_info.title')}
                  </Typography>

                  <Stack direction="row" gap={.5} alignItems="center">
                    <span style={{ opacity: .6 }}>{t('client_info.name')}</span>
                    <span>{groupData?.creativeContactInformation?.name ?? '-'}</span>
                  </Stack>

                  <Stack direction="row" gap={.5} alignItems="center">
                    <span style={{ opacity: .6 }}>{t('client_info.organization')}</span>
                    <span>{groupData?.creativeContactInformation?.organizationName ?? '-'}</span>
                  </Stack>

                  <Stack direction="row" gap={.5} alignItems="center">
                    <span style={{ opacity: .6 }}>{t('client_info.reference')}</span>
                    <span>{groupData?.creativeContactInformation?.reference ?? '-'}</span>
                  </Stack>

                </Stack>

              </Fragment>
            }

          </Stack>

          {/* Assignment list */}
          <Stack gap={1}>

            {orderData.assignments.map((assignment) =>
              <AssignmentListItem assignmentData={assignment} key={assignment.id} />
            )}

          </Stack>

        </Stack>
      </Box>


      {/* FOOTER */}
      {!noFooterModes.has(mode) &&
        <Box p={2} borderTop="1px solid" borderColor={GRAY_200}>
          <Stack direction="row" justifyContent="flex-end" gap={1}>

            {mode === CreativeAssignmentCardMode.AVAILABLE &&
              <Fragment>
                <MUIButton
                  type="orangeSecondaryBorder"
                  onClick={() => setShowDeclinePopup(true)}
                  disabled={acceptOrder.isPending || declineOrder.isPending}
                  isLoading={declineOrder.isPending}
                >
                  {t('decline_assignment')}
                </MUIButton>

                <MUIButton
                  onClick={() => handleAccept()}
                  disabled={acceptOrder.isPending || declineOrder.isPending}
                  isLoading={acceptOrder.isPending}
                >
                  {t('accept_assignment')}
                </MUIButton>
              </Fragment>
            }

            {mode === CreativeAssignmentCardMode.NEEDS_ATTENTION &&
              <MUIButton onClick={() => setShowScheduleMeetingPopup(true)}>
                {t('schedule_meeting')}
              </MUIButton>
            }

          </Stack>
        </Box>
      }

      <DeclineAssignmentPopup
        isOpen={showDeclinePopup}
        products={orderData.assignments.map((assignment) => chooseProductContainingVisuals(assignment.products))}
        shootingStart={orderData.shootingStartDateTime}
        shootingDuration={orderData.timeOnSiteMax}
        mutation={declineOrder}
        onClose={() => setShowDeclinePopup(false)}
        handleDecline={(reason, reasonFreetext, unavailabilityInterval) => {
          declineOrder.mutate({
            orderId: orderData.id,
            reason,
            reasonFreetext,
            unavailabilityInterval
          })
        }}
      />

      <ScheduleMeetingPopup
        isOpen={showScheduleMeetingPopup}
        assignment={groupData}
        mutation={scheduleAssignment}
        handleSubmit={(payload) => {
          scheduleAssignment.mutate(
            payload,
            {
              onSuccess: () => {
                spawnSuccessToast(t('schedule_meeting_popup.submit_success_message'), { title: t('schedule_meeting_popup.submit_success_title') })
                setShowScheduleMeetingPopup(false)
                _invalidateOrder(queryClient, orderData.id)
              },
            }
          )
        }}
        onClose={() => setShowScheduleMeetingPopup(false)}
      />

    </BorderBoxWrapper>
  )
}
