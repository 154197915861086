import { BEIGE_600, GRAY_400, GRAY_700, GRAY_900, ORANGE_600, WHITE } from 'constants/styling/theme'
import { DownloadImage, UploadImage } from 'models/redux'
import React, { Fragment, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import { CircleIcon } from 'components/common/CircleIcon'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined'
import { LinearProgressBar } from '../../pages/PurchaseFlow/common/LinearProgressBar'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { UploadWrapper } from './UploadWrapper.component'
import { WarningAmberRounded } from '@mui/icons-material'

type UploadType = 'preload' | 'download' | 'uploading'

/** @interface Props for the UploadVisualItem component. */
interface Props {
  /** The unique key for the visual item. */
  reactKey: string
  /** The label or name of the file. */
  label?: string
  /** The type of the upload visual item. */
  type: UploadType
  /** The name of the file. */
  fileName?: string
  /** The size of the file in bytes. */
  size?: number
  /** The image details, either a DownloadImage or UploadImage. */
  image?: DownloadImage | UploadImage
  /** Indicates whether there is an error with the visual item. */
  isError?: boolean
  /** Disables the ability to select or interact with the visual item. */
  disabledSelection?: boolean
  /** Whether visual is low quality and warning should be displayed */
  isLowQuality?: boolean
  /** Callback function invoked when the delete button is clicked. */
  onDelete: (reactKey: string) => void
}

/**
 * @component
 * UploadVisualItem displays an uploaded or uploading visual item with image details and delete button.
 *
 * @example
 * <UploadVisualItem reactKey="1" type="uploading" label="example.jpg" image={{ progress: 50 }} />
 */
export const UploadVisualItem: React.FC<Props> = ({
  reactKey,
  type,
  label,
  image,
  isError,
  fileName,
  size,
  disabledSelection,
  isLowQuality = false,
  onDelete,
}) => {
  const { t } = useTranslation(['order'])

  const isDeletingImage: boolean = useMemo(() => image?.deleting ?? false, [image?.deleting])

  const showProgressBar = useMemo(() => {
    if (type === 'uploading') return true
    if (isDeletingImage) return true

    return false
  }, [isDeletingImage, type])

  const fileStatusTextKey = useMemo(() => {
    if (isError) return ''
    if (isDeletingImage) return 'step_product.deleting'
    if (type === 'uploading') return 'step_product.processing'

    return 'step_product.file_uploaded'
  }, [isDeletingImage, isError, type])

  const handleDelete = () => {
    if (reactKey && !isDeletingImage && !disabledSelection) {
      onDelete(reactKey)
    }
  }

  return (
    <UploadWrapper type={isError ? 'error' : 'default'}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>

        {/** ICON */}
        <CircleIcon
          size="3rem"
          icon={<InsertPhotoOutlinedIcon sx={{ color: GRAY_900, fontSize: 18 }} />}
          circleColor={!isError ? BEIGE_600 : WHITE}
        />

        {/** IMAGE DETAILS */}
        <Stack flex={1} marginLeft={1}>
          {isError
            ? (
              <Typography variant='text-sm' color={GRAY_900} fontWeight={500}>
                {t('step_product.error_not_found')}
              </Typography>
            )
            : (
              <Fragment>

                {/** Name */}
                <Typography variant='text-sm' color={GRAY_900} fontWeight={500}>
                  {label ?? fileName}
                </Typography>

                <Stack direction="row" alignItems="center" gap={0.5}>

                  {/** Size */}
                  {!!size && (
                    <Fade in={true}>
                      <Box>

                        <Typography variant='text-sm' color={GRAY_700}>
                          {`${((size ?? 0) / 1000000).toFixed(1)} MB`}
                        </Typography>

                        <Typography variant='text-lg' color={GRAY_400}>
                          {' •'}
                        </Typography>

                      </Box>
                    </Fade>
                  )}

                  {/** Status */}
                  <Fade in={!!fileStatusTextKey}>
                    <Typography variant='text-sm' color={GRAY_700}>
                      {t(fileStatusTextKey)}
                    </Typography>
                  </Fade>

                </Stack>

              </Fragment>
            )
          }
        </Stack>

        {/** DELETE BUTTON */}
        {!disabledSelection &&
          <IconButton
            size='medium'
            disabled={!!image?.deleting}
            onClick={handleDelete}
          >
            <DeleteOutlineRoundedIcon sx={{ color: GRAY_900, fontSize: 18 }} />
          </IconButton>
        }
      </Box>

      {/** UPLOAD PROGRESS BAR */}
      {showProgressBar && image && ('progress' in image) &&
        <Fade in={showProgressBar}>
          <Box sx={{ width: '100%' }}>
            <LinearProgressBar isShowPercentage value={image.progress} />
          </Box>
        </Fade>
      }

      {isLowQuality &&
        <Box width="100%">
          <UploadWrapper type="warning">
            <Stack direction="row" gap={1} width="100%">

              <WarningAmberRounded sx={{ fontSize: 20, color: ORANGE_600 }} />

              <Stack>

                <Typography variant="text-sm" color={GRAY_900} fontWeight={600}>
                  {t('step_product.upload_visuals_low_quality_warning.title')}
                </Typography>

                <Typography variant="text-sm" color={GRAY_900}>
                  <Trans t={t} parent={null} i18nKey={'step_product.upload_visuals_low_quality_warning.text'}>
                    &nbsp;
                  </Trans>
                </Typography>

              </Stack>

            </Stack>
          </UploadWrapper>
        </Box>
      }

    </UploadWrapper>
  )
}
