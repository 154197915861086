import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { useMemo, useState } from 'react'

import { Path } from 'constants/router'
import { RealEstatePropertyAssignmentDTO } from 'models/property'
import constate from 'constate'
import { getPropertyAssignmentsByTab } from 'utils/helpers/getPropertyAssignmentsByTab'
import { useGetPropertyDetail } from 'dataQueries'
import { useNavigate } from 'react-router-dom'
import { useUserData } from 'components/contexts/UserDataContext'

export enum PropertyTab {
  GALLERY = 'gallery', // DEFAULT tab
  DOCUMENTS = 'documents',
  AI_TEXT_GENERATOR = 'ai_text_generator',
  ORDERS = 'orders',
  INFORMATION = 'information',
}

export const propertyTabs = new Set([
  PropertyTab.GALLERY,
  PropertyTab.DOCUMENTS,
  PropertyTab.AI_TEXT_GENERATOR,
  PropertyTab.ORDERS,
  PropertyTab.INFORMATION,
])

export const [ClientPropertyContextProvider, useClientProperty] = constate(({
  propertyId,
  tab = PropertyTab.GALLERY,
  assignmentId,
  propertyAssignmentsData
}: {
  propertyId: string
  tab: string
  assignmentId?: string
  propertyAssignmentsData: RealEstatePropertyAssignmentDTO[] | null
}) => {
  const navigate = useNavigate()
  const { userPropertyWorkspaceId } = useUserData()

  const [activeTab, setActiveTab] = useState<PropertyTab>(tab as PropertyTab)

  const getPropertyDetailQuery = useGetPropertyDetail(propertyId, userPropertyWorkspaceId, false)
  const propertyData = useMemo(() => getPropertyDetailQuery.data, [getPropertyDetailQuery.data])

  const handleTabChange = (selectedTab: PropertyTab) => {
    if (selectedTab === PropertyTab.GALLERY || selectedTab === PropertyTab.DOCUMENTS) {
      const firstAssignmentId = getPropertyAssignmentsByTab(selectedTab, propertyAssignmentsData)[0]?.id

      if (firstAssignmentId) navigate(Path.PROPERTY_TAB_ASSIGNMENT
        .replace(':id', propertyId.toString())
        .replace(':tab', selectedTab ?? PropertyTab.GALLERY)
        .replace(':assignmentId', firstAssignmentId))

      else navigate(Path.PROPERTY_TAB
        .replace(':id', propertyId.toString())
        .replace(':tab', selectedTab ?? PropertyTab.GALLERY)
      )
    } else {
      navigate(Path.PROPERTY_TAB.replace(':id', propertyId.toString()).replace(':tab', selectedTab))
    }

    let eventName = AnalyticsEvent.CLICK_GALLERY_TAB

    switch (selectedTab) {
      case PropertyTab.DOCUMENTS:
        eventName = AnalyticsEvent.CLICK_PROPERTY_DOCUMENTS
        break
      case PropertyTab.AI_TEXT_GENERATOR:
        eventName = AnalyticsEvent.CLICK_PROPERTY_AI_TEXT_GENERATOR_TAB
        break
      case PropertyTab.ORDERS:
        eventName = AnalyticsEvent.CLICK_PROPERTY_ORDERS_TAB
        break
      case PropertyTab.INFORMATION:
        eventName = AnalyticsEvent.CLICK_PROPERTY_INFORMATION_TAB
        break
      default:
        eventName = AnalyticsEvent.CLICK_PROPERTY_VISUALS_GALLERY_TAB
        break
    }

    if (eventName) {
      logAnalyticsEvent(eventName, {})
    }
  }

  const showPropertyNotFound = useMemo(() => !propertyData, [propertyData])

  const propertySubTitle = useMemo(() => {
    const address = propertyData?.address

    if (!address) return '-' // edge case

    return `${address.street} ${address.streetNumber}, ${address.city}, ${address.country}`
  }, [propertyData?.address])

  return {
    propertyId,
    propertyData,
    propertyAssignmentsData,
    handleTabChange,
    activeTab,
    setActiveTab,
    assignmentId,
    showPropertyNotFound,
    propertySubTitle
  }
})
