import { ProductKind, ProductType } from 'constants/product'

import { AssignmentStage } from 'constants/assignment'
import { Nullable } from 'models/helpers'
import { MonetaryValueDTO } from 'models/misc/monetaryValue'
import { ProductCategory } from 'models/product'

/** Real estate property type */
export enum PropertyType {
  COMMERCIAL = 'COMMERCIAL',
  RESIDENTIAL = 'RESIDENTIAL'
}

export enum PropertyFloor {
  BASEMENT = 'BASEMENT',
  GROUND = 'GROUND',
  UPPER = 'UPPER',
  ATTIC = 'ATTIC',
}

/** Real estate property address */
export interface PropertyAddressDTO {
  /** Street name */
  street: string
  /** Building number */
  streetNumber: string
  /** Postal code */
  postalCode: string
  /** City */
  city: string
  /** Country */
  country: string
  /** Region code */
  regionCode: string
  /** GEO coordinates of the address */
  coordinates?: {
    lat: number
    lng: number
  }
}

export interface PropertyFloorInfo {
  floor: PropertyFloor
  description: string
}

/** Real estate property DTO. */
export interface RealEstatePropertyPurchaseFlowDTO {
  /** Type of property - COMMERCIAL or RESIDENTIAL. */
  propertyType?: Nullable<PropertyType>,
  address?: Nullable<PropertyAddressDTO>
}

export interface RealEstatePropertyDTO {
  /** Unique id of the real estate property */
  id: string
  /** Broker id */
  brokerId: string
  /** Title of the real estate property */
  title: string
  /** Description of the real estate property */
  externalInfo: string
  /** Address of the real estate property */
  address: PropertyAddressDTO
  /** Floor information of the real estate property */
  floorInfo: PropertyFloorInfo
  /** Real estate property reference id of the client */
  objectReferenceId: string
  /** Type of property - COMMERCIAL or RESIDENTIAL */
  propertyType: PropertyType
  /** Price of the real estate property */
  price: MonetaryValueDTO
  /** Workspace id */
  workspaceId: string
  /** List of order ids */
  orderIds: Array<string>
  /** Broker detail of the real estate property */
  broker: BrokerDetailsDTO
  /** Created date of the real estate property */
  createdOn: string // ISO-8061 String
}

export interface RealEstateProperyAssignmentProductsDTO {
  id: number
  /** Product category (e.g. Real Estate, Event) */
  category: ProductCategory
  /** Product kind (e.g. ground photo) */
  kind: ProductKind
  /** Product type (e.g. Photo, Video) */
  type: ProductType
}

export interface RealEstatePropertyAssignmentDTO {
  /** Unique identifier of the real estate property */
  id: string
  /** Order id related to assignment */
  orderId: number
  /** Assignment stage */
  stage: AssignmentStage
  /** Primary product of assignment */
  primaryProduct: RealEstateProperyAssignmentProductsDTO
  /** List of products */
  products: RealEstateProperyAssignmentProductsDTO[]
}

export interface PageRealEstatePropertyListItemDTO {
  /** Total number of pages */
  totalPages: number
  /** Total number of elements */
  totalElements: number
  /** Size of the page */
  size: number
  /** List of real estate properties */
  content: RealEstatePropertyListItemDTO[]
  /** Current page number */
  number: number
  /** Sort object */
  sort: SortObject
  /** Is first page */
  first: boolean
  /** Is last page */
  last: boolean
  /** Number of elements */
  numberOfElements: number
  /** Pageable object */
  pageable: PageableObject
  /** Is empty */
  empty: boolean
}

export interface RealEstatePropertyListItemDTO {
  /** Unique identifier of the real estate property */
  id: string
  /** Title of the real estate property */
  title?: string
  /** Address of the real estate property */
  address: PropertyAddressDTO
  /** Broker detail of the real estate property */
  broker: BrokerDetailsDTO
  /** Created date of the real estate property */
  createdOn: string // ISO-8061 String
  /** Real estate property reference id of the client integration */
  objectReferenceId?: string
}

export interface SortObject {
  /** Is empty */
  empty: boolean
  /** Is sorted */
  sorted: boolean
  /** Is unsorted */
  unsorted: boolean
}

export interface PageableObject {
  /** Page offset */
  offset: number
  /** Page sort object */
  sort: SortObject,
  /** Page size */
  pageSize: number,
  /** Page number */
  pageNumber: number,
  /** Is paged */
  paged: boolean,
  /** Is unpaged */
  unpaged: boolean,
}

export interface BrokerDetailsDTO {
  /** Broker name */
  name: string
}
