import { GRAY_700, GRAY_900 } from 'constants/styling/theme'
import React, { useState } from 'react'

import { MUIFloatlNumberField } from 'components/common/MUIFloatNumberField'
import { MUIInputFieldCounter } from 'components/common/MUIInputFieldCounter'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useConstructionMethod } from './_main/ConstructionMethod.context'
import { useTranslation } from 'react-i18next'

/**
 * @component Floor renders options for the user to select number of floors and its height. 
 *
 * @example
 *   <Floor />
 */
export const Floor: React.FC = () => {

  const { t } = useTranslation(['purchase_flow'], { keyPrefix: 'energy_certificate_step.construction_method_page' })

  const {
    numberOfFullFloors,
    setNumberOfFullFloors,
    floorHeight,
    setFloorHeight,
  } = useConstructionMethod()

  const [showError, setShowError] = useState<boolean>(false)

  // If any of the required fields are empty, show error for all of them.
  const handleOnInputBlur = () => {
    if (!showError) setShowError(true)
  }

  return (
    <SectionedBorderBox
      title={
        <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
          {t('floors_title')}
        </Typography>
      }
    >
      <Stack
        sx={{
          flexDirection: { xs: 'column', lg: 'row' }
        }}
        spacing={2}
        width="100%"
      >
        <Stack alignItems="flex-start" gap={1.2} marginBottom={{ xs: '2.8rem', sm: 0 }}>

          {/* NUMBER OF FLOORS */}
          <MUIInputFieldCounter
            required
            counterValue={numberOfFullFloors ?? 1}
            counterOnChange={(value) => setNumberOfFullFloors(value ?? 1)}
            inputBoxWidth='14rem'
            label={t('number_of_floors')}
          />

          {/* FLOOR HEIGHT */}
          <Stack>

            <MUIFloatlNumberField
              required
              suffix={'M'}
              inputBoxWidth='100%'
              label={t('floor_height')}
              value={floorHeight}
              isError={showError && floorHeight === ''}
              onBlur={handleOnInputBlur}
              onChange={(value) => setFloorHeight(value ?? 0)}
            />

            <Typography variant="text-xs" fontWeight="medium" color={GRAY_700}>
              {t('averaging_multiple_floors')}
            </Typography>

          </Stack>

        </Stack>

      </Stack>
    </SectionedBorderBox>
  )
}
