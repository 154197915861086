import { FC, ReactNode, createContext, useContext, useMemo } from 'react'
import { VisualAdminDTO, VisualCreativeDTO } from 'models/visuals'
import { VisualListResponseIsAdministratorResponse, VisualListResponseIsClientResponse, VisualListResponseIsCreativeResponse } from 'utils/typeguards'

import { ActionTypeAPIData } from 'constants/redux'
import { ListVisualsStoreItem } from 'redux/Individual/Visual/ListVisuals'
import { Nullable } from 'models/helpers'
import { RootStore } from 'models/redux'
import { useAuth0 } from 'utils/auth'
import { useGalleryVisualType } from './GalleryVisualType.context'
import { useSelector } from 'react-redux'

interface GalleryVisualsMetaInterface {
  allVisuals?: Nullable<ListVisualsStoreItem>
  allVisualsFilenames: string[]
  purchasedVisualsKeys: Set<string>
  unPurchasedVisualsKeys: Set<string>
  purchasedVisualsCount: number
  unPurchasedVisualsCount: number
  purchasedVisualsExist: boolean
  unPurchasedVisualsExist: boolean
  uploadMinCount: number
  uploadMaxCount: number
  preselectedFilenames: string[]
  selectedVisualsForStagingKeys: Set<string>
  selectedVisualsForStagingCount: number
  selectedVisualsForStagingExist: boolean
  visualsMetadataMap: Record<string, VisualAdminDTO | VisualCreativeDTO>
  prepurchasedVisualsTotal: number
  prepurchasedVisualsRemaining: number
}

const defaultGalleryVisualsMetaValue: GalleryVisualsMetaInterface = {
  purchasedVisualsKeys: new Set(),
  unPurchasedVisualsKeys: new Set(),
  allVisualsFilenames: [],
  purchasedVisualsCount: 0,
  unPurchasedVisualsCount: 0,
  purchasedVisualsExist: false,
  unPurchasedVisualsExist: false,
  uploadMinCount: 0,
  uploadMaxCount: 0,
  preselectedFilenames: [],
  selectedVisualsForStagingKeys: new Set(),
  selectedVisualsForStagingCount: 0,
  selectedVisualsForStagingExist: false,
  visualsMetadataMap: {},
  prepurchasedVisualsTotal: 0,
  prepurchasedVisualsRemaining: 0,
}

/** Gallery visuals meta context */
export const GalleryVisualsMetaContext = createContext<GalleryVisualsMetaInterface>(defaultGalleryVisualsMetaValue)
/** Gallery visuals meta context hook */
export const useGalleryVisualsMeta = (): GalleryVisualsMetaInterface => useContext(GalleryVisualsMetaContext)

/** Context provider for gallery visuals meta */
export const GalleryVisualsMetaContextProvider: FC<{
  assignmentId: string
  children?: ReactNode
}> = ({
  assignmentId,
  children,
}) => {
    const { normalizedOriginalType } = useGalleryVisualType()
    const { roles } = useAuth0()

    const allVisuals = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.LIST_VISUALS]?.[assignmentId]?.[normalizedOriginalType])
    const purchasedVisualsKeys = useMemo(() => new Set(
      allVisuals?.data && (VisualListResponseIsClientResponse(allVisuals.data) || VisualListResponseIsAdministratorResponse(allVisuals.data))
        ? [
          ...(allVisuals.data.purchasedFilenames ?? []),
          ...(allVisuals.data.selfEditedFilenames ?? [])
        ]
        : []
    ), [allVisuals])

    const unPurchasedVisualsKeys = useMemo(() => new Set(allVisuals?.data && VisualListResponseIsClientResponse(allVisuals.data) ? Object.values(allVisuals.data.visuals).filter(visual => !purchasedVisualsKeys.has(visual.name))
      .map(visual => visual.name) : []), [allVisuals, purchasedVisualsKeys])
    const purchasedVisualsCount = useMemo(() => purchasedVisualsKeys.size, [purchasedVisualsKeys])
    const unPurchasedVisualsCount = useMemo(() => unPurchasedVisualsKeys.size, [unPurchasedVisualsKeys])
    const purchasedVisualsExist = useMemo(() => purchasedVisualsCount > 0, [purchasedVisualsCount])
    const unPurchasedVisualsExist = useMemo(() => unPurchasedVisualsKeys.size > 0, [unPurchasedVisualsKeys])
    const uploadMinCount = useMemo(() => {
      if (roles.isAdmin) return 1
      return allVisuals?.data && VisualListResponseIsCreativeResponse(allVisuals.data) ? allVisuals.data.minUploadCount : 0
    }, [allVisuals?.data, roles.isAdmin])
    const uploadMaxCount = useMemo(() => (allVisuals?.data && VisualListResponseIsCreativeResponse(allVisuals.data) ? allVisuals.data.maxUploadCount : 0), [allVisuals])

    const preselectedFilenames = useMemo(() => (allVisuals?.data && (VisualListResponseIsCreativeResponse(allVisuals.data) || VisualListResponseIsAdministratorResponse(allVisuals.data)) ? allVisuals.data.preselectedFilenames : []), [allVisuals])
    const selectedVisualsForStagingKeys = useMemo(() => new Set(allVisuals?.data && VisualListResponseIsClientResponse(allVisuals.data) ? Object.values(allVisuals.data.purchasedFilenames) : []), [allVisuals])
    const selectedVisualsForStagingCount = useMemo(() => selectedVisualsForStagingKeys.size, [selectedVisualsForStagingKeys])
    const selectedVisualsForStagingExist = useMemo(() => selectedVisualsForStagingCount > 0, [selectedVisualsForStagingCount])

    // Selection quantities
    const prepurchasedVisualsTotal = useMemo(() => {
      // Guard
      if (!allVisuals?.data) return 0

      if (VisualListResponseIsCreativeResponse(allVisuals.data)) return 0
      else return allVisuals.data.visualsQuantities?.prepurchasedTotalQuantity ?? 0
    }, [allVisuals?.data])

    const prepurchasedVisualsRemaining = useMemo(() => {
      // Guard
      if (!allVisuals?.data) return 0

      if (VisualListResponseIsCreativeResponse(allVisuals.data)) return 0

      return allVisuals.data.visualsQuantities?.prepurchasedRemainingQuantity ? Math.max(allVisuals.data.visualsQuantities.prepurchasedRemainingQuantity, 0) : 0
    }, [allVisuals?.data])

    const allVisualsFilenames = useMemo(() => {
      if (!allVisuals?.data?.visuals) return []

      return allVisuals.data.visuals.map(visual => visual.name)
    }, [allVisuals])

    const visualsMetadataMap = useMemo(() => {
      const visuals = allVisuals?.data && (VisualListResponseIsCreativeResponse(allVisuals.data) || VisualListResponseIsAdministratorResponse(allVisuals.data)) ? allVisuals.data.visuals : []

      return visuals.reduce((acc, item: VisualCreativeDTO | VisualAdminDTO) => {
        acc[item.name] = item
        return acc
      }, {} as Record<string, VisualCreativeDTO | VisualAdminDTO>)
    }, [allVisuals])

    return (
      <GalleryVisualsMetaContext.Provider
        value={{
          allVisuals,
          allVisualsFilenames,
          purchasedVisualsKeys,
          purchasedVisualsCount,
          unPurchasedVisualsCount,
          purchasedVisualsExist,
          unPurchasedVisualsExist,
          unPurchasedVisualsKeys,
          uploadMinCount,
          uploadMaxCount,
          preselectedFilenames,
          prepurchasedVisualsTotal,
          prepurchasedVisualsRemaining,
          selectedVisualsForStagingKeys,
          selectedVisualsForStagingCount,
          selectedVisualsForStagingExist,
          visualsMetadataMap,
        }}
      >
        {children}
      </GalleryVisualsMetaContext.Provider>
    )
  }
